"use strict";
var utilFunctions = {
  mongoMapping: function mongoMapping(
    scope,
    data,
    mongoMappingMode,
    oldIDFlag,
    targetCollection,
    closePanelFlag,
    classPlanStudentsFlag
  ) {
    let mode;
    let mongoMappedRows = 0;
    if (mongoMappingMode != null) {
      mode = mongoMappingMode;
    } else {
      mode = scope.activeTab.mongoMappingMode;
    }
    if (mode == null) {
      mode = "default";
    }
    let mongoMappingNewEls = [];
    for (let i = 0; i < data.length; i++) {
      let dataEl = data[i];
      for (let key in dataEl) {
        if (classPlanStudentsFlag && key == "class") {
          //Skip class
        } else {
          if (dataEl.hasOwnProperty(key)) {
            let value = dataEl[key];
            if (scope.activeTab.fields[key] && scope.activeTab.fields[key].fromTable != null) {
              let fromTableData = scope.getFromTableData(scope.activeTab.fields[key].fromTable);
              if (scope.activeTab.fields[key].multiple == null && !Array.isArray(value)) {
                let filteredRow;

                //Default
                if (mode == "default") {
                  if (oldIDFlag == null) {
                    filteredRow = fromTableData.filter(
                      (el) => el[key] === value && el.organization == dataEl.organization
                    )[0];
                  } else {
                    filteredRow = fromTableData.filter(
                      (el) => el.oldID === value && el.organization == dataEl.organization
                    )[0];
                  }
                }
                //Essential Leanings, first parse schoolYears, then this
                if (mode == "essentialLearnings") {
                  filteredRow = fromTableData.filter(
                    (el) =>
                      el[key] === value && el.organization == dataEl.organization && el.schoolYear == dataEl.schoolYear
                  )[0];
                }
                //Students class, first parse without classesFromTable, then with this
                if (mode == "students") {
                  filteredRow = fromTableData.filter(
                    (el) =>
                      el[key] === value &&
                      el.organization == dataEl.organization &&
                      el.year == dataEl.year &&
                      el.cluster == dataEl.cluster &&
                      el.school == dataEl.school &&
                      el.schoolYear.indexOf(dataEl.schoolYear) != -1
                  )[0];
                }

                if (filteredRow != null) {
                  dataEl[key] = filteredRow.id;
                } else {
                  if (!isNaN(Number(value))) {
                    filteredRow = fromTableData.filter(
                      (el) => el[key] === Number(value) && el.organization == dataEl.organization
                    )[0];
                    if (filteredRow != null) {
                      dataEl[key] = filteredRow.id;
                    }
                  }
                }
              } else {
                for (let j = 0; j < value.length; j++) {
                  let valueEl = value[j];
                  let filteredRow = fromTableData.filter(
                    (el) => el[key] === valueEl && el.organization == dataEl.organization
                  )[0];
                  if (filteredRow != null) {
                    dataEl[key][j] = filteredRow.id;
                  } else {
                    if (!isNaN(Number(valueEl))) {
                      filteredRow = fromTableData.filter(
                        (el) => el[key] === Number(valueEl) && el.organization == dataEl.organization
                      )[0];
                      if (filteredRow != null) {
                        dataEl[key][j] = filteredRow.id;
                      }
                    }
                  }
                }
              }
            } else {
              if (
                scope.activeTab.fields[key] &&
                scope.activeTab.fields[key].viewType == "dynamicField" &&
                dataEl[key].length > 0
              ) {
                for (let w = 0; w < dataEl[key].length; w++) {
                  let dynRow = dataEl[key][w];
                  for (const dynField in dynRow) {
                    if (dynRow.hasOwnProperty(dynField)) {
                      let dynValue = dynRow[dynField];
                      if (
                        dynValue &&
                        scope.activeTab.fields[key].dynamicFields[dynField] &&
                        scope.activeTab.fields[key].dynamicFields[dynField].fromTable != null
                      ) {
                        let fromTableData = scope.getFromTableData(
                          scope.activeTab.fields[key].dynamicFields[dynField].fromTable
                        );
                        let filteredRow;
                        if (mode == "default") {
                          filteredRow = fromTableData.filter(
                            (el) => el[dynField] === dynValue && el.organization == dataEl.organization
                          )[0];
                        }
                        if (mode == "transpTickets") {
                          filteredRow = fromTableData.filter(
                            (el) =>
                              el[dynField] === dynValue &&
                              el.organization == dataEl.organization &&
                              el.transport == dynRow.transport
                          )[0];
                        }
                        if (filteredRow != null) {
                          dataEl[key][w][dynField] = filteredRow.id;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (targetCollection != null) {
        scope.genericFactory.setRouteName(targetCollection);
        scope.genericFactory.modify(dataEl.id, dataEl).then(() => {
          console.log("mongoMapping modify.");
        });
      }
      mongoMappingNewEls.push(dataEl);
    }
    if (targetCollection == null) {
      async.eachSeries(
        mongoMappingNewEls,
        function iteratee(item, callback) {
          if (targetCollection != null) {
            scope.genericFactory.setRouteName(targetCollection);
          }
          scope.genericFactory.modify(item.id, item).then(() => {
            console.log("mongoMapping modify.");
            mongoMappedRows += 1;
            callback();
          });
        },
        function done() {
          //scope.hideLoad();
          if (mongoMappedRows == mongoMappingNewEls.length) {
            console.log("mongoMapping done.");
            if (classPlanStudentsFlag) {
              scope.genericFactory.getByProperty("organization", scope.selectedRow.id).then(function (newStudents) {
                let studentDataToMap = newStudents.filter((stud) => stud.year == getCurrentScholarYear());
                utilFunctions["mongoMapping"](scope, studentDataToMap, "students", null, null, true);
              });
            }
            if (closePanelFlag) {
              if (classPlanStudentsFlag) {
                scope.$parent.hideLoader();
              } else {
                scope.$parent.hideLoader();
                scope.closePanel();
              }
            }
          }
        },
        function (err) {
          if (err) {
            console.log(err);
          } else {
            //Done
          }
        }
      );
    }
  },
  /* Module onload transformation function */

  onload: function onloadGeneric(scope, data) {
    var calculatedFields = {};
    var calculatedFromTableFields = {};
    var activeTab = scope.activeTab;
    var getFromTableData = scope.getFromTableData;
    let filterFields = {};
    let rowCreatorEditViewPermFlag = false;
    if (activeTab.rowCreatorEditViewPerm != null && activeTab.rowCreatorEditViewPerm == true) {
      rowCreatorEditViewPermFlag = true;
    }

    for (var field in activeTab.fields) {
      if (
        activeTab.fields[field].calculated != null &&
        activeTab.fields[field].filter == null &&
        activeTab.fields[field].fromTable == null
      ) {
        calculatedFields[field] = activeTab.fields[field].calculated;
      }
      if (
        activeTab.fields[field].calculated != null &&
        activeTab.fields[field].filter == null &&
        activeTab.fields[field].fromTable != null
      ) {
        calculatedFromTableFields[field] = activeTab.fields[field].calculated;
      }
      if (activeTab.fields[field].filter != null) {
        let filterIndex = getFilterIndex(scope, Object.keys(activeTab.fields), field);
        if (activeTab.fields[field].filter == "latestNum") {
          filterFields[field] = {};
          filterFields[field].filter = "latestNum";
          filterFields[field].colIndex = filterIndex;
          filterFields[field].value = 0;
          /* let latestNum = 0;
          for (let row = 0; row < data.length; row++) {
            const element = data[row];
            if (element[field] > latestNum) {
              latestNum = element[field];
            }
          }
          if (latestNum != 0) {
            scope.gridApi.grid.columns[filterIndex].filters[0].term = latestNum;
          } */
        } else {
          filterFields[field] = {};
          filterFields[field].filter = "value";
          filterFields[field].colIndex = filterIndex;
          filterFields[field].value = activeTab.fields[field].filter;
          scope.gridApi.grid.columns[filterIndex].filters[0].term = activeTab.fields[field].filter;
        }
      }
    }

    var previousFieldFromTable = null;
    var fromTableData;

    data.forEach(function (el) {
      if (rowCreatorEditViewPermFlag) {
        if (el.createdBy != null) {
          el.creator = el.createdBy.name + " " + el.createdBy.surname;
        }
      }
      // Get latestNum filter value
      for (const filterField in filterFields) {
        if (Object.hasOwnProperty.call(filterFields, filterField)) {
          let filterDesc = filterFields[filterField];
          if (filterDesc.filter == "latestNum") {
            if (el[filterField] > filterDesc.value) {
              filterDesc.value = el[filterField];
            }
          }
        }
      }
      for (var calcField in calculatedFields) {
        el[calcField] = eval(
          calculatedFields[calcField].replace(new RegExp("{}", "g"), "el").replace(new RegExp("><.", "g"), "")
        );
      }
      for (var calcFromField in calculatedFromTableFields) {
        if (el[calcFromField] == null || el[calcFromField] == undefined || activeTab.forceCalculate) {
          if (activeTab.fields[calcFromField].fromTable != previousFieldFromTable) {
            fromTableData = getFromTableData(activeTab.fields[calcFromField].fromTable);
          }
          previousFieldFromTable = activeTab.fields[calcFromField].fromTable;
          if (fromTableData != null) {
            el[calcFromField] = eval(
              calculatedFromTableFields[calcFromField]
                .replace(new RegExp("{}", "g"), "el")
                .replace(new RegExp("<>", "g"), "fromTableData")
                .replace(new RegExp("><", "g"), "scope")
            );
          }
        }
      }
    });

    // Set latestNum filter value
    for (const filterField in filterFields) {
      if (Object.hasOwnProperty.call(filterFields, filterField)) {
        let filterDesc = filterFields[filterField];
        if (filterDesc.filter == "latestNum" && filterDesc.value != 0) {
          scope.gridApi.grid.columns[filterDesc.colIndex].filters[0].term = filterDesc.value;
        }
      }
    }
  },

  /* Module Panel onload transformation function */

  onloadPanel: function onloadGenericPanel(scope) {
    var result = {};
    var selected = scope.selected;
    var activeTab = scope.activeTab;

    var checkboxFields = [];
    var calculatedFilteredByFields = {};
    var dynamicFields = [];
    var dynamicCheckboxFields = [];
    var dynamicFilteredByFields = {};
    var replicateChangeFields = {};
    var customLabelFields = {};

    removeInputNumScrolls();
    /* removeSelectInput(); */

    //Remove dynamic fields on update

    if (scope.mode && scope.mode == "update") {
      for (var field in activeTab.fields) {
        if (
          scope.activeTab.fields[field].saveOnUpdate != undefined &&
          !scope.activeTab.fields[field].saveOnUpdate &&
          scope.selected[field] != null
        ) {
          delete scope.selected[field];
        }
      }
      /* for (let w = 0; w < scope.dynamicFields.length; w++) {
        let dynField = scope.dynamicFields[w];
        if (scope.activeTab.fields[dynField].saveOnUpdate != undefined && !scope.activeTab.fields[dynField].saveOnUpdate) {
          scope.selected[dynField] = [];
        }
      } */
    }

    //Normal Field Scan
    for (var field in activeTab.fields) {
      if (activeTab.fields[field].viewType == "checkbox") {
        checkboxFields.push(field);
      }
      if (activeTab.fields[field].filteredBy != null && activeTab.fields[field].calculated != null) {
        calculatedFilteredByFields[field] = activeTab.fields[field].calculated;
      }
      if (activeTab.fields[field].viewType == "dynamicField") {
        dynamicFields.push(field);
      }
      if (activeTab.fields[field].replicateChanges != null) {
        replicateChangeFields[field] = activeTab.fields[field].replicateChanges;
      }
      if (activeTab.fields[field].customLabel != null) {
        customLabelFields[field] = {};
        customLabelFields[field].customLabelFormula = activeTab.fields[field].customLabel;
        customLabelFields[field].fromTable = activeTab.fields[field].fromTable;
        customLabelFields[field].getAllFromTable = activeTab.fields[field].getAllFromTable;
        customLabelFields[field].parseData = activeTab.fields[field].parseData;
      }
      if (activeTab.fields[field].viewType == "fileUpload" && scope.mode != "update") {
        let containerName = scope.currentUser.organization + scope.module.collection;
        let uniqueField = JSON.parse(JSON.stringify(field));
        scope.uploadedFilesByField = {};
        scope.storageFactory
          .getContainer(containerName)
          .then((ct) => {
            scope.storageFactory.listFilesInContainer(containerName).then((fileList) => {
              //Get files by selected id
              let uploadedFiles = [];
              for (let k = 0; k < fileList.data.length; k++) {
                let storedFile = fileList.data[k];
                if (storedFile.name.indexOf(selected.id) != -1) {
                  uploadedFiles.push(storedFile);
                }
              }
              if (uploadedFiles.length > 0) {
                scope.uploadedFilesByField[uniqueField] = uploadedFiles;
              }
            });
          })
          .catch((response) => {
            if (response.status === 404) {
              //catch error
            }
          });
      }
    }

    //Dynamic Field Scan
    for (let j = 0; j < dynamicFields.length; j++) {
      let dynField = activeTab.fields[dynamicFields[j]];
      for (const key in dynField.dynamicFields) {
        if (dynField.dynamicFields.hasOwnProperty(key)) {
          let dynEl = dynField.dynamicFields[key];

          if (dynEl.viewType == "checkbox") {
            if (selected[dynamicFields[j]]) {
              selected[dynamicFields[j]][key + "checkbox"] = {};
              for (let y = 0; y < selected[dynamicFields[j]].length; y++) {
                const dynamicFieldsEl = selected[dynamicFields[j]][y];
                if (dynamicFieldsEl[key] != undefined) {
                  for (let k = 0; k < dynamicFieldsEl[key].length; k++) {
                    let dynCheckboxField = dynamicFieldsEl[key][k];
                    if (dynamicFieldsEl[key + "checkbox"] == null) {
                      dynamicFieldsEl[key + "checkbox"] = {};
                    }
                    dynamicFieldsEl[key + "checkbox"][dynCheckboxField] = true;
                  }
                }
              }
            }
          }

          if (dynEl.filteredBy != null) {
            dynamicFilteredByFields[key] = dynEl.filteredBy;
          }

          if (dynEl.customLabel != null) {
            customLabelFields[key] = {};
            customLabelFields[key].customLabelFormula = dynEl.customLabel;
            customLabelFields[key].fromTable = dynEl.fromTable;
            customLabelFields[key].parseData = dynEl.parseData;
          }
        }
      }
    }

    //Normal Field Checkbox Verification
    for (let i = 0; i < checkboxFields.length; i++) {
      if (selected[checkboxFields[i]]) {
        var key = checkboxFields[i] + "checkbox";
        selected[key] = {};
        for (let j = 0; j < selected[checkboxFields[i]].length; j++) {
          selected[key][selected[checkboxFields[i]][j]] = true;
        }
      }
    }

    //Custom Label Fields Setup

    if (customLabelFields != null) {
      for (const field in customLabelFields) {
        if (customLabelFields.hasOwnProperty(field)) {
          let customLabelFormula = customLabelFields[field].customLabelFormula;

          if (customLabelFields[field].options == null) {
            customLabelFields[field].options = [];
          }

          let data = [];
          if (customLabelFields[field].fromTable && customLabelFields[field].getAllFromTable) {
            data = scope.genericScope.getFromTableData(
              customLabelFields[field].fromTable,
              customLabelFields[field].getAllFromTable
            );
          } else if (customLabelFields[field].fromTable) {
            data = scope.genericScope.getFromTableData(customLabelFields[field].fromTable);
          } else {
            data = scope.genericScope.getFromTableData(scope.activeTab.fields[field].fromTable);
          }

          let fromTableRows;
          let currentUserAlternativeField;
          if (scope.activeTab.fields[field]) {
            currentUserAlternativeField = scope.activeTab.fields[field].currentUserAlternativeField;
          }
          if (
            currentUserAlternativeField != null &&
            scope.currentUser[currentUserAlternativeField] != null &&
            Array.isArray(scope.currentUser[currentUserAlternativeField]) &&
            scope.currentUser[currentUserAlternativeField].length > 0 &&
            !scope.activeTab.fields[field].unfilteredFromTable
          ) {
            fromTableRows = data.filter((row) => scope.currentUser[currentUserAlternativeField].indexOf(row.id) != -1);
          } else if (
            scope.currentUser[field] != null &&
            (scope.activeTab.fields[field] == null || !scope.activeTab.fields[field].unfilteredFromTable)
          ) {
            fromTableRows = data.filter((row) => scope.currentUser[field].indexOf(row.id) != -1);
          } else {
            fromTableRows = data;
          }

          for (let k = 0; k < fromTableRows.length; k++) {
            let fromTableRow = fromTableRows[k];
            let parsedLabel = eval(customLabelFormula.replace(new RegExp("{}", "g"), "fromTableRow"));

            fromTableRow.parsedLabel = parsedLabel;

            //cleanNewElement(fromTableRow);

            customLabelFields[field].options.push(fromTableRow);
          }
        }
      }
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    result["checkboxFields"] = checkboxFields;
    result["calculatedFilteredByFields"] = calculatedFilteredByFields;
    result["dynamicFields"] = dynamicFields;
    result["dynamicFilteredByFields"] = dynamicFilteredByFields;
    result["replicateChangeFields"] = replicateChangeFields;
    result["customLabelFields"] = customLabelFields;

    return result;
  },
  lastModification: function lastModification(scope, data) {
    data.forEach((el) => {
      if (el.modifiedBy != null) {
        if (el.modifiedBy.surname == null) {
          el.lastModification = new Date(el.modifiedAt).toLocaleDateString() + ";" + el.modifiedBy;
        } else {
          el.lastModification =
            new Date(el.modifiedAt).toLocaleDateString() +
            ";" +
            el.modifiedBy.name.charAt(0) +
            el.modifiedBy.surname.charAt(0);
        }
      } else if (el.createdBy != null) {
        if (el.createdBy.surname == null) {
          el.lastModification = new Date(el.createdAt).toLocaleDateString() + ";" + el.createdBy;
        } else {
          el.lastModification =
            new Date(el.createdAt).toLocaleDateString() +
            ";" +
            el.createdBy.name.charAt(0) +
            el.createdBy.surname.charAt(0);
        }
      }
    });
  },
  customCalculatedFields: function customCalculatedFields(scope, data) {
    if (scope.activeTab.customCalculatedFields) {
      for (const field in scope.activeTab.customCalculatedFields) {
        if (!Object.hasOwnProperty.call(scope.customCalculatedFieldsValues, field)) {
          if (Object.hasOwnProperty.call(scope.activeTab.customCalculatedFields, field)) {
            const customCalculatedField = scope.activeTab.customCalculatedFields[field];
            eval(
              customCalculatedField.function
                .replace(new RegExp("<>", "g"), "data")
                .replace(new RegExp("><", "g"), "scope")
                .replace(new RegExp("##", "g"), "scope.currentUser")
            );
          }
        }
      }
    }
  },
  checkOnloadFieldFilters: function checkOnloadFieldFilters(scope, data) {
    let fields = scope.activeTab.fields;

    for (var field in fields) {
      if (fields[field].filter != null) {
        let filterIndex = getFilterIndex(scope, Object.keys(fields), field);
        if (fields[field].filter == "latestNum") {
          let latestNum = 0;
          for (let row = 0; row < data.length; row++) {
            const element = data[row];
            if (element[field] > latestNum) {
              latestNum = element[field];
            }
          }
          if (latestNum != 0) {
            scope.gridApi.grid.columns[filterIndex].filters[0].term = latestNum;
          }
        } else {
          scope.gridApi.grid.columns[filterIndex].filters[0].term = fields[field].filter;
        }
      }
    }
  },
  countDistinctRowsByField: function countDistinctRowsByField(scope, args) {
    let uniqueRows = [];
    let data = scope.gridApi.core.getVisibleRows(scope.gridApi.grid);

    for (let row = 0; row < data.length; row++) {
      const element = data[row].entity;
      if (uniqueRows.indexOf(element[args.field]) == -1) {
        uniqueRows.push(element[args.field]);
      }
    }

    scope.$mdDialog.show(
      scope.$mdDialog
        .alert()
        .clickOutsideToClose(true)
        .title("Contagem de registos únicos segundo o campo: " + scope.activeTab.fields[args.field].label)
        .textContent("Foram encontrados " + uniqueRows.length + " registos.")
        .ariaLabel("Contagem de registos únicos")
        .ok("Ok")
    );
  },

  countStudsByClassroomCapacity: function countStudsByClassroomCapacity(scope, args) {
    let students = scope.getFromTableData("Class_Plan_Students");
    let schools = scope.getFromTableData("Class_Plan_Schools");
    let educationLevels = scope.getFromTableData("Class_Plan_Education_Levels");
    let studentsNumberBySchoolAndEducationLevel = {};
    students.forEach((stud) => {
      if (stud.status_matricula != null && stud.status_matricula == "Matriculado") {
        let key = "";
        let parsedSchool = schools.filter((el) => el.id == stud.school);
        let parsedEducationLevel = educationLevels.filter((el) => el.id == stud.educationLevel);
        if (parsedSchool.length && parsedEducationLevel.length) {
          let key = parsedSchool[0].school + parsedEducationLevel[0].educationLevel;
          if (studentsNumberBySchoolAndEducationLevel[key] == null) {
            studentsNumberBySchoolAndEducationLevel[key] = 0;
          }
          studentsNumberBySchoolAndEducationLevel[key] += 1;
        }
      }
    });

    let rowsToUpdate = [];

    for (let i = 0; i < scope.gridOptions.data.length; i++) {
      let capacityEl = scope.gridOptions.data[i];
      if (capacityEl.school != null && capacityEl.educationLevel != null && capacityEl.educationLevel.length) {
        let totalStudentNumbers = 0;
        if (capacityEl.educationLevel.length == 1) {
          let key =
            scope.parseValue(capacityEl.school, "school", "Schools") +
            scope.parseValue(capacityEl.educationLevel, "educationLevel", "Class_Plan_Education_Levels");
          if (studentsNumberBySchoolAndEducationLevel[key] != null) {
            totalStudentNumbers = studentsNumberBySchoolAndEducationLevel[key];
          }
        } else {
          capacityEl.educationLevel.forEach((eduLevel) => {
            let key =
              scope.parseValue(capacityEl.school, "school", "Schools") +
              scope.parseValue(eduLevel, "educationLevel", "Class_Plan_Education_Levels");
            if (studentsNumberBySchoolAndEducationLevel[key] != null) {
              totalStudentNumbers += studentsNumberBySchoolAndEducationLevel[key];
            }
          });
        }
        if (totalStudentNumbers > 0) {
          capacityEl.studentNumber = totalStudentNumbers;
          rowsToUpdate.push(capacityEl);
        }
      }
    }
    if (rowsToUpdate.length) {
      let numUpdatedRows = 0;
      scope.parentScope.$parent.showDeterminateLoader(rowsToUpdate.length);
      async.eachSeries(
        rowsToUpdate,
        function iteratee(item, callback) {
          scope.genericFactory.modify(item.id, item).then((el) => {
            numUpdatedRows += 1;
            scope.parentScope.$parent.updateDeterminateLoader();
            callback();
          });
        },
        function done() {
          if (numUpdatedRows == rowsToUpdate.length) {
            scope.parentScope.$parent.hideLoader();
            scope.getModuleData();
          }
        },
        function (err) {
          if (err) {
            console.log(err);
          } else {
            //Done
            console.log("done");
          }
        }
      );
    }
  },
  /* Generic percentage calculation: AAAF "Comparticipação" report */

  percentages: function percentages(scope, data) {
    var percentages = {};
    var activeTab = scope.activeTab;
    var parseValue = scope.parseValue;

    if (activeTab.percentages != null) {
      data.forEach(function (el) {
        if (el[activeTab.percentages[0]] != null && el[activeTab.percentages[0]] != undefined) {
          var key = el[activeTab.percentages[0]].toString();
          if (percentages[key] != null) {
            percentages[key] = percentages[key] + 1;
          } else {
            percentages[key] = 1;
          }
          for (var i = 1; i < activeTab.percentages.length; i++) {
            if (el[activeTab.percentages[i]] != null && el[activeTab.percentages[i]] != undefined) {
              key = key + el[activeTab.percentages[i]].toString();
              if (percentages[key] != null) {
                percentages[key] = percentages[key] + 1;
              } else {
                percentages[key] = 1;
              }
            }
          }
        }
      });

      data.forEach(function (el) {
        for (var i = activeTab.percentages.length - 1; i > 0; i--) {
          if (el[activeTab.percentages[i]] != null && el[activeTab.percentages[i]] != undefined) {
            var key1 = "";
            var key2 = "";
            for (var j = 0; j <= i; j++) {
              key1 = key1 + el[activeTab.percentages[j]];
              if (j != i) {
                key2 = key2 + el[activeTab.percentages[j]];
              }
            }
            el[activeTab.percentages[i] + "percentage"] =
              el[activeTab.percentages[i]] + " " + ((percentages[key1] / percentages[key2]) * 100).toFixed(1) + "%";
            if (activeTab.fields[activeTab.percentages[i]].fromTable != null)
              el[activeTab.percentages[i] + "percentage"] = parseValue(
                el[activeTab.percentages[i] + "percentage"],
                activeTab.percentages[i]
              );
            key1 = "";
            key2 = "";
          }
        }
      });
    }
    return activeTab;
  },

  /* ASE "Resumo" report month split */

  splitMonths: function splitMonths(scope, data) {
    data.forEach(function (el) {
      el.completionDate = new Date(el.completionDateMs);
      el.regDate = new Date(el.regDateMs);
      if (Number(el.year) > 2016) {
        if (el.regDateMs != null && el.completionDateMs != null) {
          // Duplicate entries according to the number of months between regDate and
          // completionDate

          /* if (el.completionDate.getMonth() != el.regDate.getMonth() && el.completionDate.getMonth() > el.regDate.getMonth()) {
            for (var i = el.regDate.getMonth(); i <= el.completionDate.getMonth(); i++) {
              //el = $scope.getSituationColumn(el);
              var c = i + 1;
              var b = c + "";
              if (i === el.regDate.getMonth()) {
                el["month"] = b;
              }
              else {
                var duplicate = JSON.parse(JSON.stringify(el));
                duplicate["month"] = b;
                data.push(duplicate);
              }
            }
          } else { */
          //el = $scope.getSituationColumn(el);
          var a = el.regDate.getMonth() + 1;
          el["month"] = a + "";
          /* } */
        } else {
          // el = $scope.getSituationColumn(el);
          el["month"] = "Datas em Falta";
        }
      } else {
        el["month"] = "--";
      }
    });
  },
  /* VFX ASE check on save */
  vfxCheckASE: function vfxCheckASE(scope, selected) {
    if (selected.aseEchelonChange != null && selected.changeDateMs == null) {
      scope.genericScope.$parent.alertVFXEmptyChangeDate();
      return false;
    } else {
      return true;
    }
  },
  /* VFX ETI check on save */
  vfxCheckETI: function vfxCheckASE(scope, selected) {
    if (scope.genericScope.parseValue(selected.schoolYear, "schoolYear", "Transp_Scho_Years") != "0") {
      selected.allowanceEchelon = null;
      selected.aseEchelon = null;
      return true;
    }
  },

  /* IPT "Gestão de Rateio" report entry filter */

  filterDistributionManagement: function filterDistributions(scope, data) {
    for (let i = 0; i < data.length; ) {
      const element = data[i];
      if (
        element.costType == "Depreciações e amortizações" &&
        (element.distributionDescrip == null ||
          element.distributionDescrip == undefined ||
          element.distributionDescrip == "")
      ) {
        /* if ((element.costType == "Depreciações e amortizações" || element.costType == "Gastos de Pessoal" || element.costType == "Renda de Concessão") && (element.distributionDescrip == null || element.distributionDescrip == undefined || element.distributionDescrip == "")) { */
        /* if (element.distributionDescrip == null || element.distributionDescrip == undefined || element.distributionDescrip == "") { */
        data.splice(i, 1);
      } else {
        i++;
      }
    }
  },

  /* IPT "Rateio" report entry filter */

  filterCostAssessment: function filterDistributions(scope, data) {
    for (let i = 0; i < data.length; ) {
      const element = data[i];
      /* if ((element.costType == "Depreciações e amortizações" || element.costType == "Gastos de Pessoal" || element.costType == "Renda de Concessão") &&
        (element.distributionDescrip == null || element.distributionDescrip == undefined || element.distributionDescrip == "")) { */
      if (
        (element.distribution == null || element.distribution == undefined || element.distribution == "") &&
        (element.client == null || element.client == undefined || element.client == "") &&
        (element.product == null || element.product == undefined || element.product == "") &&
        (element.datacenter == null || element.datacenter == undefined || element.datacenter == "")
      ) {
        data.splice(i, 1);
      } else {
        i++;
      }
    }
  },

  /* IPT "Validação Repartição" report entry filter */

  filterCostDistributionValidationReport: function filterDistributions(scope, data) {
    for (let i = 0; i < data.length; i++) {
      let element = data[i];
      if (element.distribution) {
        element.distribution = element.distribution.distribution;
        element.distribution = scope.parseValue(element.distribution, "distribution");
        if (element.distribution == null || element.distribution == "") {
          element.distribution = "Sem Repartição";
        }
      } else {
        element.distribution = "Sem Repartição";
      }
    }
  },

  /* IPT "Regras de Rateio" tab replicate changes */

  iptRepChangBySimAssessFields: function iptRepChangBySimAssessFields(scope, selected) {
    let replicateMap = {};

    for (let k = 0; k < scope.activeTab.dynamicFields.length; k++) {
      let costDynField = scope.activeTab.dynamicFields[k];
      if (
        selected[costDynField + "Descrip"] != "" &&
        selected[costDynField + "Descrip"] != null &&
        selected[costDynField] != null &&
        selected[costDynField] != null &&
        Array.isArray(selected[costDynField]) &&
        selected[costDynField].length !== 0
      ) {
        if (replicateMap[selected.year + costDynField + selected[costDynField + "Descrip"]] == null) {
          replicateMap[selected.year + costDynField + selected[costDynField + "Descrip"]] = selected[costDynField];
        }
      }
    }

    let toReplicateChangesElements = [];

    if (Object.keys(replicateMap).length !== 0) {
      for (let i = 0; i < scope.genericScope.gridOptions.data.length; i++) {
        let element = scope.genericScope.gridOptions.data[i];
        if (element.year == selected.year && element.id != selected.id) {
          let updatedElementFlag = false;
          for (let k = 0; k < scope.activeTab.dynamicFields.length; k++) {
            let costDynField = scope.activeTab.dynamicFields[k];
            if (
              (element[costDynField] == null ||
                (Array.isArray(element[costDynField]) && element[costDynField].length == 0)) &&
              replicateMap[element.year + costDynField + element[costDynField + "Descrip"]] != null
            ) {
              element[costDynField] = replicateMap[element.year + costDynField + element[costDynField + "Descrip"]];
              updatedElementFlag = true;
            }
          }
          if (updatedElementFlag) {
            //scope.genericFactory.modify(element.id, element).then(() => {});
            toReplicateChangesElements.push(element);
          }
        }
      }
    }

    if (toReplicateChangesElements.length > 0) {
      return new Promise((resolve, reject) => {
        var confirm = scope.$mdDialog
          .confirm()
          .title("Replicação de Regras de Rateio")
          .textContent(
            "Deseja replicar as regras de rateio introduzidas neste registo para outros com as mesmas descrições de repartição, produto, cliente e datacenter?"
          )
          .ariaLabel("Confirmar alteração")
          .ok("Sim")
          .cancel("Não")
          .clickOutsideToClose(true);

        scope.$mdDialog.show(confirm).then(
          function () {
            scope.parentScope.showDeterminateLoader(toReplicateChangesElements.length);
            let replicatedElementsChanged = 0;

            async.eachSeries(
              toReplicateChangesElements,
              function iteratee(item, callback) {
                scope.genericFactory.modify(item.id, item).then(() => {
                  scope.parentScope.updateDeterminateLoader();
                  replicatedElementsChanged += 1;
                  callback();
                });
              },
              function done() {
                if (replicatedElementsChanged == toReplicateChangesElements.length) {
                  scope.genericScope.hideLoader();
                  resolve(true);
                }
              },
              function (err) {
                if (err) {
                  console.log(err);
                } else {
                  //Done
                  resolve(true);
                }
              }
            );
          },
          function () {
            resolve(true);
          }
        );
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    }
  },

  /* IPT "Geral" new and unitary distributions verification */

  updateDistributions: function updateDistributions(scope, data) {
    /* let indexOfData = scope
      .deps
      .indexOf("IPT_Distributions"); */
    let distributionUpdateHashmap = {};
    let numUniMultiDistrs = 0;
    let numUpdatableDistrs = 0;
    let dynamicFields = scope.dynamicFields;

    if (dynamicFields.length == 0) {
      dynamicFields = scope.activeTab.dynamicFields;
    }

    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      var resultUnitary = [];
      let elementHasUnitaryDistrToUpdate = false;
      for (let k = 0; k < scope.activeTab.dynamicFields.length; k++) {
        let costDynField = scope.activeTab.dynamicFields[k];
        let indexFromTableDistribution = getFromTableDataDataDistribution(costDynField);
        if (
          element[costDynField + "Descrip"] != "" &&
          element[costDynField + "Descrip"] != null &&
          indexFromTableDistribution != null &&
          Array.isArray(scope.depsData[indexFromTableDistribution]) &&
          (element[costDynField] == null || element[costDynField].length === 0)
        ) {
          let parsedUnitaryDistribution = JSON.parse(JSON.stringify(element[costDynField + "Descrip"]));
          parsedUnitaryDistribution = parsedUnitaryDistribution.replace(/\%/gi, "").split("-");
          if (parsedUnitaryDistribution.length == 2) {
            resultUnitary = scope.depsData[indexFromTableDistribution].filter(
              (el) => el[costDynField] == parsedUnitaryDistribution[0].trim()
            );
          } else {
            //Try to parse unitary distributions
            resultUnitary = scope.depsData[indexFromTableDistribution].filter(
              (el) => el[costDynField] == element[costDynField + "Descrip"]
            );
          }

          if (resultUnitary.length > 0) {
            let unitaryDistribution = {};
            unitaryDistribution[costDynField] = resultUnitary[0].id;
            unitaryDistribution["value"] = 100;
            unitaryDistribution["vn"] = ["Sim"];
            if (distributionUpdateHashmap[i] == null || distributionUpdateHashmap[i] == undefined) {
              distributionUpdateHashmap[i] = {};
            }
            distributionUpdateHashmap[i][costDynField] = [];
            distributionUpdateHashmap[i][costDynField].push(unitaryDistribution);
            if (!elementHasUnitaryDistrToUpdate) {
              elementHasUnitaryDistrToUpdate = true;
              numUniMultiDistrs++;
            }
          } else {
            //Try to parse multiple distributions
            let splitMultipleDistributions = element[costDynField + "Descrip"].replace(/\%/gi, "").split(";");
            if (
              splitMultipleDistributions &&
              Array.isArray(splitMultipleDistributions) &&
              splitMultipleDistributions.length > 1
            ) {
              //Multiple distributions with percentages
              if (element[costDynField + "Descrip"].indexOf("-") != -1) {
                let totalPer = 0;
                let parsedMultipleDistribution = [];
                let fromTableDistParseErrorFlag = false;
                for (let w = 0; w < splitMultipleDistributions.length; w++) {
                  let dist = splitMultipleDistributions[w].split("-");
                  let distName = dist[0];
                  let distPer = dist[1];
                  if (distName && distPer) {
                    distName = distName.trim();
                    distPer = distPer.trim();
                    distPer = parseFloat(distPer.replace(",", ".").replace(" ", ""));
                    let fromTableDist = scope.depsData[indexFromTableDistribution].filter(
                      (el) => el[costDynField] == distName
                    );
                    if (fromTableDist.length > 0) {
                      let partialMultipleDist = {};
                      partialMultipleDist[costDynField] = fromTableDist[0].id;
                      partialMultipleDist["value"] = distPer;
                      totalPer += distPer;
                      parsedMultipleDistribution.push(partialMultipleDist);
                    } else {
                      fromTableDistParseErrorFlag = true;
                    }
                  }
                }
                totalPer = Math.round(totalPer * 100) / 100;
                if (totalPer == 100 && !fromTableDistParseErrorFlag) {
                  if (distributionUpdateHashmap[i] == null || distributionUpdateHashmap[i] == undefined) {
                    distributionUpdateHashmap[i] = {};
                  }
                  distributionUpdateHashmap[i][costDynField] = parsedMultipleDistribution;
                  if (!elementHasUnitaryDistrToUpdate) {
                    elementHasUnitaryDistrToUpdate = true;
                    numUniMultiDistrs++;
                  }
                }
              }
              //Multiple distributions without percentages
              else {
                let parsedMultipleDistribution = [];
                let fromTableDistParseErrorFlag = false;
                for (let w = 0; w < splitMultipleDistributions.length; w++) {
                  let dist = splitMultipleDistributions[w].trim();
                  let fromTableDist = scope.depsData[indexFromTableDistribution].filter(
                    (el) => el[costDynField] == dist
                  );
                  if (fromTableDist.length > 0) {
                    let partialMultipleDist = {};
                    partialMultipleDist[costDynField] = fromTableDist[0].id;
                    partialMultipleDist["value"] = 100;
                    partialMultipleDist["vn"] = ["Sim"];
                    parsedMultipleDistribution.push(partialMultipleDist);
                  } else {
                    fromTableDistParseErrorFlag = true;
                  }
                }
                if (!fromTableDistParseErrorFlag) {
                  if (distributionUpdateHashmap[i] == null || distributionUpdateHashmap[i] == undefined) {
                    distributionUpdateHashmap[i] = {};
                  }
                  distributionUpdateHashmap[i][costDynField] = parsedMultipleDistribution;
                  if (!elementHasUnitaryDistrToUpdate) {
                    elementHasUnitaryDistrToUpdate = true;
                    numUniMultiDistrs++;
                  }
                }
              }
            }
          }
        }
      }

      function getFromTableDataDataDistribution(field) {
        switch (field) {
          case "distribution":
            return scope.deps.indexOf("IPT_Distributions");
          case "product":
            return scope.deps.indexOf("IPT_Products");
          case "client":
            return scope.deps.indexOf("IPT_Clients");
          case "datacenter":
            return scope.deps.indexOf("IPT_Datacenters");
          default:
            return null;
        }
      }

      //New unitary distribution verification
      /* if (element.distributionDescrip != "" && element.distributionDescrip != null) {
        if (Array.isArray(scope.depsData[indexOfData]) && (element.distribution == null || element.distribution.length === 0)) {
          resultUnitary = scope
            .depsData[indexOfData]
            .filter(el => el.distribution === element.distributionDescrip);
        }
        if (resultUnitary.length > 0) {
          let unitaryDistribution = {};
          unitaryDistribution["distribution"] = resultUnitary[0].id;
          unitaryDistribution["value"] = 100;
          unitaryDistribution["vn"] = ["Sim"];
          if (distributionUpdateHashmap[i] == null || distributionUpdateHashmap[i] == undefined) {
            distributionUpdateHashmap[i] = {};
          }
          distributionUpdateHashmap[i]["distribution"] = [];
          distributionUpdateHashmap[i]["distribution"].push(unitaryDistribution);
          numUniMultiDistrs++;
        }
      } */

      //Updatable distribution verification
      //if element and el have the same year and element period > el period
      //only updates new element asessement fields if they are empty
      if (element.docPurchase != "" && element.docPurchase != null) {
        var maxPeriod = 0;
        var resultUpdatable = data.map((el) => {
          if (
            el.docPurchase != undefined &&
            el.docPurchase.toString() === element.docPurchase.toString() &&
            element.period > maxPeriod &&
            el.period > maxPeriod &&
            element.period > el.period &&
            element.year == el.year &&
            element.id != el.id &&
            element.distribution == null &&
            element.product == null &&
            element.client == null &&
            element.datacenter == null &&
            (element.distributionDescrip == null || element.distributionDescrip == "") &&
            (element.productDescrip == null || element.productDescrip == "") &&
            (element.clientDescrip == null || element.clientDescrip == "") &&
            (element.datacenterDescrip == null || element.datacenterDescrip == "") &&
            ((el.distribution != null && el.distribution.length > 0) ||
              (el.product != null && el.product.length > 0) ||
              (el.client != null && el.client.length > 0) ||
              (el.datacenter != null && el.datacenter.length > 0))
          ) {
            if (maxPeriod == 0) {
              numUpdatableDistrs++;
              if (distributionUpdateHashmap[i] != null) {
                numUniMultiDistrs--;
                distributionUpdateHashmap[i] = null;
              }
            }
            maxPeriod = el.period;
            if (distributionUpdateHashmap[i] == null || distributionUpdateHashmap[i] == undefined) {
              distributionUpdateHashmap[i] = {};
            }
            for (let j = 0; j < dynamicFields.length; j++) {
              const dynField = dynamicFields[j];
              if (el[dynField] != null && el[dynField].length > 0) {
                distributionUpdateHashmap[i][dynField] = [];
                for (let k = 0; k < el[dynField].length; k++) {
                  const dynValue = el[dynField][k];
                  distributionUpdateHashmap[i][dynField].push(dynValue);
                }
              }
            }
          }
        });
      }
    }

    if (numUniMultiDistrs > 0 || numUpdatableDistrs > 0) {
      var confirm = scope.$mdDialog
        .confirm()
        .title("Verificação de Repartições")
        .textContent(
          "Existem " +
            numUniMultiDistrs +
            " novas repartições (unitárias ou múltiplas) e " +
            numUpdatableDistrs +
            " \
        repartições que podem ser actualizadas com os valores de períodos ant" +
            "eriores. \
        Deseja atribuí-las automaticamente? Esta operação pode demora" +
            "r alguns minutos."
        )
        .ariaLabel("Confirmar atribuição")
        .ok("Sim")
        .cancel("Não")
        .clickOutsideToClose(true);

      scope.$mdDialog.show(confirm).then(
        function () {
          var promiseArray = [];
          for (const key in distributionUpdateHashmap) {
            if (distributionUpdateHashmap.hasOwnProperty(key)) {
              const fields = distributionUpdateHashmap[key];
              for (const field in fields) {
                if (fields.hasOwnProperty(field)) {
                  const fieldValue = fields[field];
                  data[key][field] = fieldValue;
                }
              }
              promiseArray.push(data[key]);
            }
          }
          scope.parentScope.showDeterminateLoader(promiseArray.length);
          async.eachSeries(
            promiseArray,
            function iteratee(item, callback) {
              async.setImmediate(function () {
                scope.genericFactory.modify(item.id, item).then(() => {
                  scope.parentScope.updateDeterminateLoader();
                });
              });
              setTimeout(callback, 0);
            },
            function done() {
              scope.hideLoad();
              console.log("done");
            },
            function (err) {
              if (err) {
                console.log(err);
              } else {
                //Done
              }
            }
          );

          return;
        },
        function () {
          return;
        }
      );
    }
  },

  /* IPT "Rateio" report */

  costAssessment: function costAssessment(scope, data) {
    let costAssessmentCod = 1;
    for (let p = 0; p < data.length; ) {
      const el = data[p];
      let assessmentElHashmap = {};
      let numArrayDynaFields = 0;

      for (let i = 0; i < scope.dynamicFields.length; i++) {
        const dynaField = scope.dynamicFields[i];
        if (el[dynaField] != undefined && el[dynaField] != null && el[dynaField].length > 0) {
          for (let j = 0; j < el[dynaField].length; j++) {
            const dynaFieldValue = el[dynaField][j];
            if (Object.keys(assessmentElHashmap).indexOf(dynaField) == -1) {
              assessmentElHashmap[dynaField] = [];
            }
            assessmentElHashmap[dynaField].push(dynaFieldValue);
          }
          numArrayDynaFields++;
        }
      }

      if (numArrayDynaFields == 0) {
        let check = 0;
        for (let i = 0; i < scope.dynamicFields.length; i++) {
          const dynaField = scope.dynamicFields[i];
          if (el[dynaField] != undefined && el[dynaField] != null) {
            check++;
          }
        }
        if (check == 0) {
          data.splice(p, 1);
        } else {
          break;
        }
      }

      let numResultingRows = 0;
      for (const key in assessmentElHashmap) {
        if (assessmentElHashmap.hasOwnProperty(key)) {
          const element = assessmentElHashmap[key];
          if (numResultingRows == 0) {
            numResultingRows = element.length;
          } else {
            numResultingRows = numResultingRows + element.length;
          }
        }
      }

      let assessmenElData = [];
      for (const key in assessmentElHashmap) {
        if (assessmentElHashmap.hasOwnProperty(key)) {
          const assessmenElHashmapValues = assessmentElHashmap[key];
          for (let j = 0; j < assessmenElHashmapValues.length; j++) {
            const element = assessmenElHashmapValues[j];
            let arr = JSON.parse(JSON.stringify(el));
            arr[key] = element;
            /* for (let p = 0; p < scope.dynamicFields.length; p++) {
              const dynaField = scope.dynamicFields[p];
              if (element[dynaField] != undefined && element[dynaField] != null) {
                arr[dynaField] = element[dynaField]
              }
            } */
            assessmenElData.push(arr);
          }
        }
      }

      assessmenElData.forEach((ele) => {
        for (let i = 0; i < scope.dynamicFields.length; i++) {
          const dynaField = scope.dynamicFields[i];
          if (ele[dynaField] != undefined && ele[dynaField] != null) {
            if (ele[dynaField].length >= 0) {
              delete ele[dynaField];
            } else {
              for (const k in ele[dynaField]) {
                if (ele[dynaField].hasOwnProperty(k)) {
                  let nestedDynField = ele[dynaField][k];
                  for (let j = 0; j < scope.dynamicFields.length; j++) {
                    let otherDynField = scope.dynamicFields[j];
                    if (k == otherDynField && k != dynaField && nestedDynField.constructor === Object) {
                      ele[otherDynField] = nestedDynField;
                    }
                  }
                }
              }

              if (
                ele[dynaField].vn != null &&
                ele[dynaField].vn != undefined &&
                ele[dynaField].vn.length > 0 &&
                ele[dynaField].vn[0] === "Sim"
              ) {
                ele.vn = "Sim";
              } else {
                ele.vn = "Não";
              }
              ele[dynaField + "Percentage"] = ele[dynaField].value;
              if (ele[dynaField].value != 0 && ele[dynaField].value != null)
                ele[dynaField + "Value"] = Math.round((ele[dynaField].value / 100) * ele.amount * 100) / 100;
            }
          }
        }
        ele["costAssessmentCod"] = costAssessmentCod;
        data.push(ele);
      });
      costAssessmentCod++;
      data.splice(p, 1);
    }
  },
  iptAssessmentConcludedAlert: function iptAssessmentConcludedAlert(scope, functionArgs, gridData) {
    let rowsWithIncompletedAsessment = gridData.filter((el) => el.distributionFlag == "Sim");

    if (rowsWithIncompletedAsessment.length > 0) {
      scope.parentScope.$parent.alertIPTAssessmentNotConcluded();
    } else {
      let info = {};
      info.module = scope.module.name;
      info.userName = scope.currentUser.name + " " + scope.currentUser.surname;
      scope.parentScope.$parent.showLoader();
      scope.clientsFactory
        .iptAssessmentConcludedAlert(info)
        .then(function () {
          scope.parentScope.$parent.alertIPTAssessmentConcludedSuccessAlert();
          scope.parentScope.$parent.hideLoader();
          return true;
        })
        .catch(function () {
          scope.parentScope.$parent.alertIPTAssessmentConcludedErrorAlert();
          scope.parentScope.$parent.hideLoader();
          return false;
        });
    }
  },
  cmsBuildingManagementCheckFields: function cmsBuildingManagementCheckFields(scope, selected, data) {
    if (
      selected != null &&
      selected.spaceArea == null &&
      (selected.otherSpaceArea == null || selected.otherSpaceArea == "")
    ) {
      scope.parentScope.$parent.alertCMSBuildingManagementSpaceAreaField();
      return false;
    } else {
      return true;
    }
  },
  vfxTransportsCardRequests: function transportsCardRequests(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.cardRequestFlag) {
        break;
      }
      if (el.transportInfo && el.transportInfo.length > 0) {
        for (let k = 0; k < el.transportInfo.length; ) {
          let transpInfo = el.transportInfo[k];
          if (transpInfo.card.length > 0) {
            let newElement = JSON.parse(JSON.stringify(el));
            newElement.cardRequestFlag = true;
            newElement.transportInfo = [];
            newElement.transportInfo.push(transpInfo);
            data.push(newElement);
          }
          el.transportInfo.splice(0, 1);
        }
      }
      data.splice(0, 1);
    }
  },

  vfxTransportsDistTickets: function transportsCardRequests(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.transportInfo && el.voucherExpHist && el.voucherExpHist.length > 0 && el.transportInfo.length > 0) {
        for (let k = 0; k < el.transportInfo.length; k++) {
          let transpInfo = el.transportInfo[k];

          for (let w = 0; w < el.voucherExpHist.length; w++) {
            let voucherHist = el.voucherExpHist[w];
            if (validateVoucherExpHistYearAndMonth(el, voucherHist)) {
              if (voucherHist.idTrans == transpInfo.idTrans) {
                let newElement = JSON.parse(JSON.stringify(el));
                newElement.idTrans = transpInfo.idTrans;
                newElement.distributionPoint = transpInfo.distributionPoint;
                newElement.transport = transpInfo.transport;
                newElement.origin = transpInfo.origin;
                newElement.destination = transpInfo.destination;
                newElement.ticket = transpInfo.ticket;
                /*  for (let j = 0; j < el.transportInfo.length; j++) {
                  let transpInfo2 = el.transportInfo[j];
                  if (j != k) {
                    newElement.transportInfo.push(transpInfo2);
                  }
                } */
                newElement.year = voucherHist.year;
                newElement.month = voucherHist.month;
                cleanElement(newElement);
                data.unshift(newElement);
                i++;
              }
            }
          }
        }
      }
      data.splice(i, 1);
    }
    function validateVoucherExpHistYearAndMonth(el, voucherHist) {
      let numberElYear = Number(el.year);
      let numberVoucherYear = Number(voucherHist.year);
      if (numberElYear > numberVoucherYear) {
        return false;
      } else if (numberVoucherYear > numberElYear + 1) {
        return false;
      } else if (
        numberElYear + 1 == numberVoucherYear &&
        (voucherHist.month == "Agosto" ||
          voucherHist.month == "Setembro" ||
          voucherHist.month == "Outubro" ||
          voucherHist.month == "Novembro" ||
          voucherHist.month == "Dezembro")
      ) {
        return false;
      } else if (
        numberElYear == numberVoucherYear &&
        (voucherHist.month == "Janeiro" ||
          voucherHist.month == "Fevereiro" ||
          voucherHist.month == "Março" ||
          voucherHist.month == "Abril" ||
          voucherHist.month == "Maio" ||
          voucherHist.month == "Junho" ||
          voucherHist.month == "Julho")
      ) {
        return false;
      } else {
        return true;
      }
    }
    function cleanElement(element) {
      if (element.transportInfo != null) {
        delete element.transportInfo;
      }
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }
  },
  vfxTransportsInfoSplit: function vfxTransportsInfoSplit(scope, data) {
    let distTicketsFlag = false;
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      /* if (distTicketsFlag) {
        break;
      } */
      if (el.transportInfo && el.transportInfo.length > 0) {
        for (let k = 0; k < el.transportInfo.length; k++) {
          let transpInfo = el.transportInfo[k];
          let newElement = JSON.parse(JSON.stringify(el));
          newElement.transportInfo = [];
          newElement.transportInfo.push(transpInfo);
          for (let j = 0; j < el.transportInfo.length; j++) {
            let transpInfo2 = el.transportInfo[j];
            if (j != k) {
              newElement.transportInfo.push(transpInfo2);
            }
          }
          /* distTicketsFlag = true; */
          data.unshift(newElement);
          i++;
          /* el.transportInfo.splice(0, 1); */
        }
      }
      data.splice(i, 1);
    }
    return data;
  },
  cmsManagementControlTwelfth: function cmsManagementControlTwelfth(scope, data) {
    let twelfthMap = {};
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      let newElement = JSON.parse(JSON.stringify(el));
      if (twelfthMap[el.year + el.civilYear + el.month + el.cluster] == null) {
        twelfthMap[el.year + el.civilYear + el.month + el.cluster] = newElement;
        processTwelfthEl(twelfthMap[el.year + el.civilYear + el.month + el.cluster], newElement);
      } else {
        processTwelfthEl(twelfthMap[el.year + el.civilYear + el.month + el.cluster], newElement);
      }

      data.splice(i, 1);
    }

    function processTwelfthEl(twelfthMapEl, newEl) {
      let typeOfTwelfth =
        // In 2023, charges with subType 'Conservacao 67' belong to the chargeType 'Encargos de Instalações'
        newEl.chargeType == "6495c47e9de1a0009670b0e0" || newEl.chargeSubType == "64a82abf2bbdd5007cf28d1c"
          ? "Facilities"
          : "Upkeep";
      if (newEl.documentType == "Fatura" || newEl.documentType == "Nota Crédito") {
        if (newEl.paidDocument == "Sim") {
          twelfthMapEl["paidValue" + typeOfTwelfth] =
            twelfthMapEl["paidValue" + typeOfTwelfth] != null
              ? twelfthMapEl["paidValue" + typeOfTwelfth] + newEl.value
              : newEl.value;
        } else {
          twelfthMapEl["missingValue" + typeOfTwelfth] =
            twelfthMapEl["missingValue" + typeOfTwelfth] != null
              ? twelfthMapEl["missingValue" + typeOfTwelfth] + newEl.value
              : newEl.value;
        }
      } else if (newEl.documentType == "Nota Débito") {
        twelfthMapEl["paidValue" + typeOfTwelfth] =
          twelfthMapEl["paidValue" + typeOfTwelfth] != null
            ? twelfthMapEl["paidValue" + typeOfTwelfth] + newEl.value
            : newEl.value;
      } else if (newEl.documentType == "Guia Reposição") {
        twelfthMapEl["twelfth" + typeOfTwelfth] =
          twelfthMapEl["twelfth" + typeOfTwelfth] != null
            ? twelfthMapEl["twelfth" + typeOfTwelfth] - newEl.value
            : -newEl.value;
      } else if (newEl.documentType == "Duodécimo" || newEl.documentType == "Duodécimo - Conservação") {
        twelfthMapEl["twelfth" + typeOfTwelfth] =
          twelfthMapEl["twelfth" + typeOfTwelfth] != null
            ? twelfthMapEl["twelfth" + typeOfTwelfth] + newEl.value
            : newEl.value;
      } else if (newEl.documentType == "Saldo Inicial") {
        twelfthMapEl["initialBalance" + typeOfTwelfth] =
          twelfthMapEl["initialBalance" + typeOfTwelfth] != null
            ? twelfthMapEl["initialBalance" + typeOfTwelfth] + newEl.value
            : newEl.value;
      }
    }

    if (Object.values(twelfthMap).length > 0) {
      Object.values(twelfthMap).forEach((el) => {
        ["Facilities", "Upkeep"].forEach((typeOfTwelfth) => {
          if (el["initialBalance" + typeOfTwelfth] != null) {
            el["initialBalance" + typeOfTwelfth] = Math.round(el["initialBalance" + typeOfTwelfth] * 100) / 100;
          }
          if (el["twelfth" + typeOfTwelfth] != null) {
            el["twelfth" + typeOfTwelfth] = Math.round(el["twelfth" + typeOfTwelfth] * 100) / 100;
          }
          if (el["paidValue" + typeOfTwelfth] != null) {
            el["paidValue" + typeOfTwelfth] = Math.round(el["paidValue" + typeOfTwelfth] * 100) / 100;
          }
          if (el["missingValue" + typeOfTwelfth] != null) {
            el["missingValue" + typeOfTwelfth] = Math.round(el["missingValue" + typeOfTwelfth] * 100) / 100;
          }
          if (el["twelfth" + typeOfTwelfth] != null && el["paidValue" + typeOfTwelfth] != null) {
            el["balance" + typeOfTwelfth] =
              Math.round((el["twelfth" + typeOfTwelfth] - el["paidValue" + typeOfTwelfth]) * 100) / 100;
          } else if (el["twelfth" + typeOfTwelfth] != null) {
            el["balance" + typeOfTwelfth] = el["twelfth" + typeOfTwelfth];
          } else if (el["paidValue" + typeOfTwelfth] != null) {
            el["balance" + typeOfTwelfth] = -el["paidValue" + typeOfTwelfth];
          }
          if (el["initialBalance" + typeOfTwelfth] != null) {
            if (el["balance" + typeOfTwelfth] == null) {
              el["balance" + typeOfTwelfth] = el["initialBalance" + typeOfTwelfth];
            } else {
              el["balance" + typeOfTwelfth] =
                Math.round((el["balance" + typeOfTwelfth] + el["initialBalance" + typeOfTwelfth]) * 100) / 100;
            }
          }
        });

        data.push(el);
      });
    }
  },
  cmsManagementControlTwelfthInvoices: function cmsManagementControlTwelfthInvoices(scope, data) {
    let invoicesMap = {};
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.documentType == "Fatura" && el.document != null && el.document != "") {
        let invoiceKey = el.cluster + el.document;
        let elCopy = structuredClone(el);
        if (invoicesMap[invoiceKey] == null) {
          elCopy.invoiceDateMonthMs = new Date(
            getNumberStringFromMonth(elCopy.month) + "-" + "01" + "-" + elCopy.civilYear
          ).getTime();
          invoicesMap[invoiceKey] = elCopy;
        } else {
          if (
            invoicesMap[invoiceKey].invoiceDateMonthMs <
            new Date(getNumberStringFromMonth(elCopy.month) + "-" + "01" + "-" + elCopy.civilYear).getTime()
          ) {
            invoicesMap[invoiceKey].year = elCopy.year;
            invoicesMap[invoiceKey].civilYear = elCopy.civilYear;
            invoicesMap[invoiceKey].paidDocument = elCopy.paidDocument;
            invoicesMap[invoiceKey].value = elCopy.value;
          }
        }
      }
      data.splice(i, 1);
    }
    if (Object.values(invoicesMap).length > 0) {
      Object.values(invoicesMap).forEach((el) => {
        data.push(el);
      });
    }
  },
  cmsManagementControlTwelfthInvoicesArticle4Upkeep: function cmsManagementControlTwelfthInvoicesArticle4Upkeep(
    scope,
    data
  ) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.documentType == "Fatura" && el.chargeSubType == "64a82ac92bbdd5007cf28d1d" && el.paidDocument == "Sim") {
        i++;
      } else {
        data.splice(i, 1);
      }
    }
  },
  cmsManagementControlTwelfthClusterFilter: function cmsManagementControlTwelfthClusterFilter(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (
        el.documentType == "Duodécimo" ||
        el.documentType == "Duodécimo - Conservação" ||
        el.documentType == "Saldo Inicial"
      ) {
        data.splice(i, 1);
      } else {
        i += 1;
      }
    }
  },
  // Check if the selected products belong to the selected distribution, for example S160100 belongs to S16
  iptCheckProductFamily: function iptCheckProductFamily(scope, selected) {
    if (selected["distribution"] != null && selected["product"]) {
      for (let j = 0; j < selected["distribution"].length; ) {
        let dis = selected["distribution"][j];
        for (let i = 0; i < selected["product"].length; i++) {
          let pro = selected["product"][i];
          let product = parseValue(pro["product"], "product", scope.deps, scope.depsData, scope.activeTab);
          let distribution = parseValue(
            dis["distribution"],
            "distribution",
            scope.deps,
            scope.depsData,
            scope.activeTab
          );
          if (product.indexOf(distribution) > -1) {
            let newDis = JSON.parse(JSON.stringify(dis));
            pro.percentageCheckField = distribution;
            pro.percentageCheckTotal = newDis.value;
            newDis.value = null;
            pro.distribution = newDis;
          }
        }
        if (selected["distribution"].indexOf(dis) != -1) {
          j++;
        }
      }
    }
  },
  iptCheckPercentages: function iptCheckPercentages(scope, selected) {
    let fieldsIncorrectPercentages = [];

    if (Array.isArray(scope.dynamicFields) && scope.dynamicFields.length > 0 && scope.dynamicFields !== undefined) {
      for (let i = 0; i < scope.dynamicFields.length; i++) {
        let percentages = {};
        const element = scope.dynamicFields[i];
        if (scope.selected[element] != null && scope.selected[element] != undefined) {
          let totalPercentage = 0;
          for (let j = 0; j < scope.selected[element].length; j++) {
            let el = scope.selected[element][j];
            if (angular.equals(el, {})) {
              scope.selected[element].splice(j, 1);
            } else {
              if (el.vn != undefined) {
                if (el.vn[0] === "Sim") {
                  break;
                }
              }
              /* if (el.value && (Math.round(el.value * 100) / 100) == 100 && scope.selected[element].length == 1) {
                el.vn[0] = "Sim";
              } */
              if (el["percentageCheckField"] != null) {
                if (
                  percentages[el["percentageCheckField"]] == null ||
                  percentages[el["percentageCheckField"]] == undefined
                ) {
                  percentages[el["percentageCheckField"]] = {};
                  percentages[el["percentageCheckField"]]["addedTotal"] = 0;
                  percentages[el["percentageCheckField"]]["total"] = el["percentageCheckTotal"];
                }
                percentages[el["percentageCheckField"]]["addedTotal"] =
                  percentages[el["percentageCheckField"]]["addedTotal"] + el.value;
              }
              totalPercentage = totalPercentage + el.value;
              if (el.value == null || el.value == undefined) {
                el.value = 0;
              }
            }
          }

          if (Object.keys(percentages).length !== 0 && percentages.constructor === Object) {
            for (const k in percentages) {
              if (percentages.hasOwnProperty(k)) {
                const e = percentages[k];
                if (e.addedTotal != e.total) {
                  if (fieldsIncorrectPercentages.indexOf(scope.activeTab.fields[element].label) == -1) {
                    fieldsIncorrectPercentages.push(scope.activeTab.fields[element].label + " - " + k);
                  }
                }
              }
            }
          }
          totalPercentage = Math.round(totalPercentage * 100) / 100;
          if (
            totalPercentage != 100 &&
            totalPercentage != 0 &&
            scope.selected[element].length > 0 &&
            fieldsIncorrectPercentages.indexOf(scope.activeTab.fields[element].label) == -1
          ) {
            fieldsIncorrectPercentages.push(scope.activeTab.fields[element].label);
          }
        }
      }
    }

    if (fieldsIncorrectPercentages.length > 0) {
      scope.genericScope.$parent.alertIncorrectPercentages(fieldsIncorrectPercentages);
      return false;
    }

    return true;
  },
  checkIfSubjectCodesAreEmpty: function checkIfSubjectCodesAreEmpty(scope, selected, data) {
    if (
      (selected.subjectCode == null || selected.subjectCode == "") &&
      (selected.subjectANQEPCode == null || selected.subjectANQEPCode == "")
    ) {
      scope.genericScope.$parent.alertSubjectCodesAreEmpty();
      return false;
    } else {
      return true;
    }
  },
  nifFieldValidator: function nifFieldValidator(fieldValue, selected, activeTabFields) {
    if (
      fieldValue.toString() == "999999999" ||
      (selected.nonexistentNIFcheckbox &&
        selected.nonexistentNIFcheckbox[""] &&
        selected.nonexistentNIFcheckbox[""] == true)
    ) {
      return true;
    }
    let stringFieldValue = fieldValue.toString();
    let nifControlValue = 0;
    let multiplyIndex;

    if (stringFieldValue.length != 9) {
      return false;
    }
    if (!(stringFieldValue[0] == "1" || stringFieldValue[0] == "2" || stringFieldValue[0] == "3")) {
      return false;
    }

    for (let i = 0; i < 8; i++) {
      let el = stringFieldValue[i];
      multiplyIndex = 9 - i;
      nifControlValue = nifControlValue + Number(el) * multiplyIndex;
    }

    if (nifControlValue % 11 == 0 || nifControlValue % 11 == 1) {
      if (stringFieldValue[8] == "0") {
        return true;
      } else {
        return false;
      }
    } else {
      if (stringFieldValue[8] == 11 - (nifControlValue % 11)) {
        return true;
      } else {
        return false;
      }
    }
  },
  ibanFieldValidator: function ibanFieldValidator(fieldValue) {
    let stringFieldValue = fieldValue.toString();
    let countryCode = stringFieldValue.substring(0, 4);
    let firstLetterToNumber = countryCode.charCodeAt(0) - 65 + 10;
    let secondLetterToNumber = countryCode.charCodeAt(1) - 65 + 10;

    countryCode = firstLetterToNumber.toString() + secondLetterToNumber.toString() + countryCode.substring(2);

    let accountNumber = stringFieldValue.substring(4) + countryCode;

    if (modulo(accountNumber, 97) != 1) {
      return false;
    } else {
      return true;
    }
  },
  iptSalesPercentageCheck: function iptSalesPercentageCheck(fieldValue, selected) {
    let percentageTotal;
    if (selected.perPortfolio != null && selected.perRenewal != null) {
      percentageTotal = selected.perPortfolio + selected.perRenewal;
    }
    if (percentageTotal != null && percentageTotal != 100) {
      return false;
    } else {
      return true;
    }
  },
  vfxTransportsOnlyActSitFilter: function vfxTransportsOnlyActSitFilter(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.situation != "Ativo" || el.studentSituation != "Ativo") {
        data.splice(i, 1);
      } else {
        i++;
      }
    }
  },
  transportsCharges: function transportsCharges(scope, args, data) {
    scope.parentScope.$parent.showLoader();

    let moduleMode = "";

    //Check if CMS or KSTK module
    if (scope.module.collection == "Transp_CMS_Charges") {
      scope.genericFactory.setRouteName("Transp_CMS_Studs");
      moduleMode = "CMS";
    } else if (scope.module.collection == "KSTK_Transp_Charges") {
      scope.genericFactory.setRouteName("KSTK_Transp_Studs");
      moduleMode = "KSTK";
    }

    //scope.genericFactory.setRouteName("Transp_CMS_Studs");

    let map = {};
    map.year = getCurrentScholarYear();
    scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (transpStud) {
      transpStud = utilFunctions["vfxTransportsInfoSplit"](scope, transpStud);

      //Check if CMS or KSTK module
      if (moduleMode == "CMS") {
        scope.genericFactory.setRouteName("Transp_CMS_Charges");
      } else if (moduleMode == "KSTK") {
        scope.genericFactory.setRouteName("KSTK_Transp_Charges");
      }

      //scope.genericFactory.setRouteName("Transp_CMS_Charges");

      let rowsToUpdateOrCreate = [];
      let numUpdatedCreatedRows = 0;
      for (let i = 0; i < transpStud.length; ) {
        let el = transpStud[i];
        if (el.transportInfo[0].situation != "Ativo") {
          transpStud.splice(i, 1);
          continue;
        } else {
          i++;
        }

        if (el.cluster) {
          if (moduleMode == "CMS") {
            el.cluster = parseValue(
              el.cluster,
              "cluster",
              scope.deps,
              scope.depsData,
              scope.activeTab,
              "Transp_CMS_Clusters"
            );
          } else if (moduleMode == "KSTK") {
            el.cluster = parseValue(
              el.cluster,
              "cluster",
              scope.deps,
              scope.depsData,
              scope.activeTab,
              "Class_Plan_Clusters"
            );
          }
        }
        if (el.school) {
          if (moduleMode == "CMS") {
            el.school = parseValue(
              el.school,
              "school",
              scope.deps,
              scope.depsData,
              scope.activeTab,
              "Transp_CMS_Schos"
            );
          } else if (moduleMode == "KSTK") {
            el.school = parseValue(
              el.school,
              "school",
              scope.deps,
              scope.depsData,
              scope.activeTab,
              "Class_Plan_Schools"
            );
          }
        }
        if (el.schoolYear) {
          if (moduleMode == "CMS") {
            el.schoolYear = parseValue(
              el.schoolYear,
              "schoolYear",
              scope.deps,
              scope.depsData,
              scope.activeTab,
              "Transp_Scho_Years"
            );
          } else if (moduleMode == "KSTK") {
            el.schoolYear = parseValue(
              el.schoolYear,
              "schoolYear",
              scope.deps,
              scope.depsData,
              scope.activeTab,
              "Class_Plan_School_Years"
            );
          }
        }
        let parsedTransport;
        let parsedTicket;
        let parsedOrigin;
        let parsedDestination;
        if (el.transportInfo[0].transport) {
          parsedTransport = parseValue(
            el.transportInfo[0].transport,
            "transport",
            scope.deps,
            scope.depsData,
            scope.activeTab,
            "Transp_Trans"
          );
        }
        if (el.transportInfo[0].ticket) {
          parsedTicket = parseValue(
            el.transportInfo[0].ticket,
            "ticket",
            scope.deps,
            scope.depsData,
            scope.activeTab,
            "Transp_Tickets"
          );
        }
        if (el.transportInfo[0].origin) {
          parsedOrigin = parseValue(
            el.transportInfo[0].origin,
            "origin",
            scope.deps,
            scope.depsData,
            scope.activeTab,
            "Transp_Ori_Dests"
          );
        }
        if (el.transportInfo[0].destination) {
          parsedDestination = parseValue(
            el.transportInfo[0].destination,
            "destination",
            scope.deps,
            scope.depsData,
            scope.activeTab,
            "Transp_Ori_Dests"
          );
        }
        if (
          parsedTransport != null &&
          parsedTransport != "" &&
          parsedTicket != null &&
          parsedTicket != "" &&
          parsedOrigin != null &&
          parsedOrigin != "" &&
          parsedDestination != null &&
          parsedDestination != ""
        ) {
          el.parsedTransport = parsedTransport;
          el.transport = el.transportInfo[0].transport;
          el.parsedTicket = parsedTicket;
          el.parsedOrigin = parsedOrigin;
          el.parsedDestination = parsedDestination;
          el.ticketInfo =
            parsedTransport + " - " + parsedTicket + " (" + parsedOrigin + " - " + parsedDestination + ")";
        } else {
          transpStud.splice(i, 1);
          continue;
        }
        if (el.transportInfo[0].comparticipation != null) {
          el.comparticipation = el.transportInfo[0].comparticipation;
        }

        let chargedFlag = false;

        if (moduleMode == "CMS") {
          if (el.nif && el.ticketInfo) {
            for (let j = 0; j < data.length; j++) {
              let dataEl = data[j];
              if (el.year == dataEl.year && el.nif == dataEl.nif && el.transport == dataEl.transport) {
                delete el.id;
                delete dataEl.comparticipation;
                delete dataEl.school;
                delete dataEl.schoolYear;
                el.id = dataEl.id;
                el.updateRow = true;
                rowsToUpdateOrCreate.push(el);
                chargedFlag = true;
                break;
              }
            }
            if (!chargedFlag) {
              delete el.id;
              el.createRow = true;
              rowsToUpdateOrCreate.push(el);
            }
          }
        } else if (moduleMode == "KSTK") {
          if (el.name && el.ticketInfo) {
            for (let j = 0; j < data.length; j++) {
              let dataEl = data[j];
              if (el.year == dataEl.year && el.name == dataEl.name && el.transport == dataEl.transport) {
                delete el.id;
                delete dataEl.comparticipation;
                delete dataEl.school;
                delete dataEl.schoolYear;
                el.id = dataEl.id;
                el.updateRow = true;
                rowsToUpdateOrCreate.push(el);
                chargedFlag = true;
                break;
              }
            }
            if (!chargedFlag) {
              delete el.id;
              el.createRow = true;
              rowsToUpdateOrCreate.push(el);
            }
          }
        }
      }
      if (rowsToUpdateOrCreate.length > 0) {
        scope.parentScope.$parent.hideLoader();
        scope.parentScope.$parent.showDeterminateLoader(rowsToUpdateOrCreate.length);
        async.eachSeries(
          rowsToUpdateOrCreate,
          function iteratee(item, callback) {
            if (item.updateRow) {
              scope.genericFactory.modify(item.id, item).then((el) => {
                numUpdatedCreatedRows += 1;
                scope.parentScope.$parent.updateDeterminateLoader();
                callback();
              });
            } else if (item.createRow) {
              scope.genericFactory.create(item).then((el) => {
                numUpdatedCreatedRows += 1;
                scope.parentScope.$parent.updateDeterminateLoader();
                callback();
              });
            }
          },
          function done() {
            if (numUpdatedCreatedRows == rowsToUpdateOrCreate.length) {
              scope.parentScope.$parent.hideLoader();
              scope.getModuleData();
            }
          },
          function (err) {
            if (err) {
              console.log(err);
            } else {
              //Done
              console.log("done");
            }
          }
        );
      } else {
        scope.parentScope.$parent.hideLoader();
      }
    });
  },
  cmsOperatingResult: function cmsOperatingResult(scope, args, data) {
    scope.parentScope.$parent.showLoader();
    scope.genericFactory.setRouteName("CMS_Cluster_Charges");
    let refYear = [new Date().getFullYear() - 1 + "", new Date().getFullYear() + ""];
    let map = {};
    map.civilYear = refYear;
    scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (charges) {
      scope.genericFactory.setRouteName("CMS_Cluster_Revs");
      scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (revenues) {
        let map2 = {};
        if (new Date().getMonth() < 10) {
          map2.year = [new Date().getFullYear() - 2 + "", new Date().getFullYear() - 1 + ""];
        } else {
          map2.year = refYear;
        }
        scope.genericFactory.setRouteName("Non_Teach_Staff_Descriptions");
        scope.genericFactory.getByProperties(map2, scope.currentUser.organization).then(function (nonTeachStaff) {
          scope.genericFactory.setRouteName("CMS_Operating_Results");

          // Delete rows from the present and previous civilYear
          let rowsToDelete = data.length > 0 ? data.filter((el) => refYear.indexOf(el.civilYear) != -1) : [];
          if (rowsToDelete.length > 0) {
            let numDeletedRows = 0;
            async.eachSeries(
              rowsToDelete,
              function iteratee(item, callback) {
                scope.genericFactory.delete(item.id).then((el) => {
                  numDeletedRows += 1;
                  callback();
                });
              },
              function done() {
                if (numDeletedRows == rowsToDelete.length) {
                  insertNewOperatingResults();
                }
              },
              function (err) {
                if (err) {
                  console.log(err);
                } else {
                  //Done
                  console.log("done");
                }
              }
            );
          } else {
            insertNewOperatingResults();
          }

          // Insert operating results based on cluster charges, municipality reveneus and non teach staff charges
          function insertNewOperatingResults() {
            let chargesToInsert = [];
            let nonTeachStaffIndirectCostsToInsert = [];
            let nonTeachStaffRevenuesToInsert = [];
            let revenuesToInsert = [];

            // Parse charges
            charges.forEach((char) => {
              char.operationType = "Despesa";
              char.operationSubType = "Custos Diretos";
              if (char.educationLevel != null && char.educationLevel.length) {
                char.parsedEducationLevel = parseEducationLevel(char.educationLevel);
              } else {
                char.parsedEducationLevel = null;
              }
              cleanElement(char);
              chargesToInsert.push(char);
            });

            function parseChargeFieldsBySubChargeType(charge) {
              let parsedCharges = [];
              // Conservação art. 67º
              // Expenditure group
              if (charge.subType == "64a82abf2bbdd5007cf28d1c") {
                /* Apoio Financeiro – Equip. Cozinha
              facilitiesCanteenEquipmentFinSupport
             Aquisição de Equipamentos/Ferramentas/Material
              facilitiesMaintenanceMaterialPurchase
             Aquisição veículo - Equipa Móvel
              facilitiesMaintenanceVehiclePurchase
             Conservação/Manutenção - Várias
              facilitiesMaintenanceGeneral
             Fornecimento Contínuo
              facilitiesMaintenanceContinuousSupply
             Manutenção Elevadores
              facilitiesMaintenanceLifts */
              }
            }

            // Parse revenues
            revenues.forEach((rev) => {
              rev.operationType = "Receita";
              rev.operationSubType = "Receita";
              rev.value = JSON.parse(JSON.stringify(rev.revenue));
              if (rev.educationLevel != null && rev.educationLevel.length) {
                rev.parsedEducationLevel = parseEducationLevel(rev.educationLevel);
              } else {
                rev.parsedEducationLevel = null;
              }
              cleanElement(rev);
              revenuesToInsert.push(rev);
            });

            utilFunctions["kstkNonTeachingStaffManagementControlFilterRecords"](scope, nonTeachStaff);

            // Parse non teach staff indirect costs

            let nonTeachStaffIndirectCosts = JSON.parse(JSON.stringify(nonTeachStaff));
            utilFunctions["kstkNonTeachingStaffIndirectCostsSplitRecords"](scope, nonTeachStaffIndirectCosts);
            if (nonTeachStaffIndirectCosts != null && nonTeachStaffIndirectCosts.length) {
              let nonTeachStaffRevenuesMap = {};
              nonTeachStaffIndirectCosts.forEach((nonTeachIndCost) => {
                let key =
                  nonTeachIndCost.civilYear +
                  nonTeachIndCost.year +
                  nonTeachIndCost.month +
                  nonTeachIndCost.cluster +
                  nonTeachIndCost.school;
                if (
                  nonTeachIndCost.costsTotal != null &&
                  !isNaN(Number(nonTeachIndCost.costsTotal)) &&
                  nonTeachIndCost.indirectCostsPercentage != null &&
                  !isNaN(Number(nonTeachIndCost.indirectCostsPercentage))
                ) {
                  nonTeachIndCost.value = nonTeachIndCost.costsTotal * (nonTeachIndCost.indirectCostsPercentage / 100);
                } else if (nonTeachIndCost.ceiInsurance != null || nonTeachIndCost.ceiGrant) {
                  nonTeachIndCost.value = nonTeachIndCost.ceiInsurance + nonTeachIndCost.ceiGrant;
                }
                if (nonTeachStaffRevenuesMap[key] == null) {
                  nonTeachStaffRevenuesMap[key] = nonTeachIndCost;
                } else {
                  nonTeachStaffRevenuesMap[key].value += nonTeachIndCost.value;
                }
              });
              Object.values(nonTeachStaffRevenuesMap).forEach((nonTeachIndCostBySchool) => {
                nonTeachIndCostBySchool.operationType = "Despesa";
                nonTeachIndCostBySchool.operationSubType = "Custos Indiretos";
                nonTeachIndCostBySchool.supplyMonth = nonTeachIndCostBySchool.month.toLowerCase();

                if (nonTeachIndCostBySchool.educationLevel != null && nonTeachIndCostBySchool.educationLevel.length) {
                  nonTeachIndCostBySchool.parsedEducationLevel = parseEducationLevel(
                    nonTeachIndCostBySchool.educationLevel
                  );
                }
                nonTeachIndCostBySchool.chargeType = "64a829ba2bbdd5007cf28d11";

                if (nonTeachIndCostBySchool.parsedEducationLevel != null) {
                  // CEI
                  nonTeachIndCostBySchool.chargeSubType = "67d808fa4760ec0032f27ce0";

                  /* if (nonTeachIndCostBySchool.parsedEducationLevel == "PRE") {
                    nonTeachIndCostBySchool.chargeSubType = "64a82a052bbdd5007cf28d14";
                  } else if (nonTeachIndCostBySchool.parsedEducationLevel == "1º Ciclo") {
                    nonTeachIndCostBySchool.chargeSubType = "64a82a902bbdd5007cf28d18";
                  } else {
                    nonTeachIndCostBySchool.chargeSubType = "64a82aa02bbdd5007cf28d19";
                  } */
                } else {
                  // Escritório
                  nonTeachIndCostBySchool.chargeSubType = "67d809054760ec0032f27ce1";
                }

                nonTeachIndCostBySchool.value = Math.round(nonTeachIndCostBySchool.value * 100) / 100;

                cleanElement(nonTeachIndCostBySchool, "Receita Esperada");
                nonTeachStaffIndirectCostsToInsert.push(nonTeachIndCostBySchool);
              });
            }

            // Parse non teach staff revenues

            let nonTeachStaffRevenues = JSON.parse(JSON.stringify(nonTeachStaff));
            utilFunctions["kstkNonTeachingStaffExpectedRevenueSplitRecords"](scope, nonTeachStaffRevenues);
            nonTeachStaffRevenues = nonTeachStaffRevenues.filter(
              (el) => el.allocation == "Sala" || el.allocation == "Apoio Educativo"
            );
            if (nonTeachStaffRevenues != null && nonTeachStaffRevenues.length) {
              let nonTeachStaffRevenuesMap = {};
              nonTeachStaffRevenues.forEach((nonTeachRev) => {
                let key =
                  nonTeachRev.civilYear +
                  nonTeachRev.year +
                  nonTeachRev.month +
                  nonTeachRev.cluster +
                  nonTeachRev.school;
                if (nonTeachStaffRevenuesMap[key] == null) {
                  nonTeachRev.value = nonTeachRev.expectedRevTotal;
                  nonTeachStaffRevenuesMap[key] = nonTeachRev;
                } else {
                  nonTeachStaffRevenuesMap[key].value += nonTeachRev.expectedRevTotal;
                }
              });
              Object.values(nonTeachStaffRevenuesMap).forEach((nonTeachRevBySchool) => {
                nonTeachRevBySchool.operationType = "Receita Esperada";
                nonTeachRevBySchool.operationSubType = "Receita Esperada";
                nonTeachRevBySchool.supplyMonth = nonTeachRevBySchool.month.toLowerCase();

                if (nonTeachRevBySchool.educationLevel != null && nonTeachRevBySchool.educationLevel.length) {
                  nonTeachRevBySchool.parsedEducationLevel = parseEducationLevel(nonTeachRevBySchool.educationLevel);
                }
                nonTeachRevBySchool.chargeType = "64a829ba2bbdd5007cf28d11";

                if (nonTeachRevBySchool.parsedEducationLevel != null) {
                  if (nonTeachRevBySchool.parsedEducationLevel == "PRE") {
                    nonTeachRevBySchool.chargeSubType = "64a82a052bbdd5007cf28d14";
                  } else if (nonTeachRevBySchool.parsedEducationLevel == "1º Ciclo") {
                    nonTeachRevBySchool.chargeSubType = "64a82a902bbdd5007cf28d18";
                  } else {
                    nonTeachRevBySchool.chargeSubType = "64a82aa02bbdd5007cf28d19";
                  }
                }

                cleanElement(nonTeachRevBySchool, "Receita Esperada");
                nonTeachStaffRevenuesToInsert.push(nonTeachRevBySchool);
              });
            }

            function parseEducationLevel(eduLevel) {
              // PRE
              if (eduLevel.indexOf("5dfe2ad3d8954900740d6bed") != -1) {
                return "PRE";
              }
              // 1º ciclo
              else if (eduLevel.indexOf("5dfe2abfd8954900740d6bea") != -1) {
                return "1º Ciclo";
              }
              // 2º e 3º ciclo e SEC
              else {
                return "2º/3º Ciclo e SEC";
              }
            }

            function cleanElement(element, operationType) {
              if (element.id != null) {
                delete element.id;
              }
              if (element.createdBy != null) {
                delete element.createdBy;
              }
              if (element.modifiedBy != null) {
                delete element.modifiedBy;
              }
              if (element.year != null) {
                delete element.year;
              }
              if (element.revenue != null) {
                delete element.revenue;
              }
              if (element.cluster == null) {
                element.cluster = "Todos";
              }
              if (element.school == null) {
                element.school = "Todas";
              }
              if (element.parsedEducationLevel == null) {
                element.parsedEducationLevel = "Todos";
                delete element.codeIGEFE;
              }
              if (element.supplyMonth != null && operationType != "Receita Esperada") {
                element.supplyMonth = getMonthWithNumberBefore(element.supplyMonth);
              }

              element.updateDate = new Date().toLocaleDateString();
            }

            let rowsToInsert = chargesToInsert
              .concat(revenuesToInsert)
              .concat(nonTeachStaffIndirectCostsToInsert)
              .concat(nonTeachStaffRevenuesToInsert);
            let numInsertedRows = 0;
            async.eachSeries(
              rowsToInsert,
              function iteratee(item, callback) {
                scope.genericFactory.create(item).then((el) => {
                  numInsertedRows += 1;
                  callback();
                });
              },
              function done() {
                if (numInsertedRows == rowsToInsert.length) {
                  scope.parentScope.$parent.hideLoader();
                  scope.getModuleData();
                }
              },
              function (err) {
                if (err) {
                  console.log(err);
                  scope.parentScope.$parent.hideLoader();
                  scope.getModuleData();
                } else {
                  //Done
                  console.log("done");
                  scope.parentScope.$parent.hideLoader();
                  scope.getModuleData();
                }
              }
            );
          }
        });
      });
    });
  },
  vfxMealRecord: function vfxMealRecord(scope, args, data) {
    scope.parentScope.$parent.showLoader();
    scope.genericFactory.setRouteName("VFX_Meal_Records");
    let refYear;
    if (new Date().getMonth() < 9) {
      refYear = [new Date().getFullYear() - 2 + "", new Date().getFullYear() - 1 + ""];
    } else {
      refYear = [new Date().getFullYear() - 1 + "", new Date().getFullYear() + ""];
    }
    let map = {};
    map.year = refYear;
    scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (sigaMeals) {
      scope.genericFactory.setRouteName("VFX_Meal_Record_Schools");
      scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (ipssMeals) {
        scope.genericFactory.setRouteName("VFX_Meal_Record_Aggs");

        // Delete rows from the present and previous year
        let rowsToDelete = data.length > 0 ? data.filter((el) => refYear.indexOf(el.year) != -1) : [];
        if (rowsToDelete.length > 0) {
          let numDeletedRows = 0;
          async.eachSeries(
            rowsToDelete,
            function iteratee(item, callback) {
              scope.genericFactory.delete(item.id).then((el) => {
                numDeletedRows += 1;
                callback();
              });
            },
            function done() {
              if (numDeletedRows == rowsToDelete.length) {
                scope.parentScope.$parent.hideLoader();
                insertNewMealAggs();
              }
            },
            function (err) {
              if (err) {
                console.log(err);
              } else {
                //Done
                console.log("done");
              }
            }
          );
        } else {
          insertNewMealAggs();
        }

        // Insert meal aggs
        function insertNewMealAggs() {
          let sigaMealsToInsert = [];
          let ipssMealsToInsert = [];

          // SIGA meals
          let sigaMealsToInsertMap = {};

          sigaMeals.forEach((sigaMeal) => {
            let key =
              sigaMeal.year +
              sigaMeal.month +
              sigaMeal.day +
              sigaMeal.mealType +
              sigaMeal.cluster +
              sigaMeal.school +
              parseEducationLevel(sigaMeal.educationLevel) +
              sigaMeal.company;

            if (sigaMealsToInsertMap[key] == null) {
              sigaMeal.infoSource = "SIGA";
              if (
                sigaMeal.preparedMeals == null &&
                sigaMeal.requestedMeals != null &&
                sigaMeal.attendedMealsWithoutRequest != null
              ) {
                sigaMeal.preparedMeals = sigaMeal.requestedMeals + sigaMeal.attendedMealsWithoutRequest;
              }
              sigaMeal.educationLevel = parseEducationLevel(sigaMeal.educationLevel);
              sigaMeal.value = calculateMealPrice(sigaMeal.mealType, sigaMeal.preparedMeals);
              let newElSigaMeal = structuredClone(sigaMeal);
              delete newElSigaMeal.id;
              sigaMealsToInsertMap[key] = structuredClone(newElSigaMeal);
            } else {
              if (
                sigaMeal.preparedMeals == null &&
                sigaMeal.requestedMeals != null &&
                sigaMeal.attendedMealsWithoutRequest != null
              ) {
                sigaMeal.preparedMeals = sigaMeal.requestedMeals + sigaMeal.attendedMealsWithoutRequest;
              }
              sigaMealsToInsertMap[key].preparedMeals = Math.round(
                sigaMealsToInsertMap[key].preparedMeals + sigaMeal.preparedMeals
              );
              sigaMealsToInsertMap[key].value =
                Math.round(
                  (sigaMealsToInsertMap[key].value + calculateMealPrice(sigaMeal.mealType, sigaMeal.preparedMeals)) *
                    100
                ) / 100;
            }
          });

          // IPSS meals
          let ipssMealsToInsertMap = {};

          ipssMeals.forEach((ipssMeal) => {
            if (ipssMeal.mealRecords != null && ipssMeal.mealRecords.length > 0) {
              ipssMeal.mealRecords.forEach((mealRecordEl) => {
                for (let k = 0; k < 32; k++) {
                  let dayString = "day" + k;
                  if (
                    mealRecordEl[dayString] != null &&
                    mealRecordEl[dayString] != "" &&
                    !isNaN(Number(mealRecordEl[dayString]))
                  ) {
                    let ipssMealEl = structuredClone(ipssMeal);
                    delete ipssMealEl.id;
                    ipssMealEl.educationValue = mealRecordEl.educationValue;
                    ipssMealEl.mealType = mealRecordEl.mealType;
                    ipssMealEl.preparedMeals = mealRecordEl[dayString] != null ? mealRecordEl[dayString] : 0;
                    ipssMealEl.value = mealRecordEl.value != null ? mealRecordEl.value : 0;

                    let key =
                      ipssMealEl.year +
                      ipssMealEl.regMonth +
                      dayString +
                      ipssMealEl.mealType +
                      ipssMealEl.cluster +
                      ipssMealEl.school +
                      parseEducationLevel(mealRecordEl.educationLevel) +
                      ipssMealEl.company;

                    if (ipssMealsToInsertMap[key] == null) {
                      ipssMealEl.month = getMonthWithNumberBefore(ipssMealEl.regMonth);
                      ipssMealEl.infoSource = "IPSS";
                      ipssMealEl.day = k < 10 ? "0" + k : k;
                      if (mealRecordEl.educationLevel == null) {
                        if (mealRecordEl.mealType == "Adultos") {
                          ipssMealEl.educationLevel = "Adulto";
                        } else {
                          ipssMealEl.educationLevel = mealRecordEl.mealType;
                        }
                      } else {
                        ipssMealEl.educationLevel = parseEducationLevel(mealRecordEl.educationLevel);
                      }

                      ipssMealEl.value = calculateMealPrice(ipssMealEl.mealType, mealRecordEl[dayString]);
                      ipssMealsToInsertMap[key] = ipssMealEl;
                    } else {
                      ipssMealsToInsertMap[key].preparedMeals = Math.round(
                        ipssMealsToInsertMap[key].preparedMeals + mealRecordEl[dayString]
                      );
                      ipssMealsToInsertMap[key].value =
                        Math.round(
                          (ipssMealsToInsertMap[key].value +
                            calculateMealPrice(ipssMealEl.mealType, mealRecordEl[dayString])) *
                            100
                        ) / 100;
                    }
                  }
                }
              });
            }
          });

          function parseEducationLevel(eduLevel) {
            if (
              eduLevel == "61a7a015a9a2f600680ac179" ||
              eduLevel == "61a7a01ca9a2f600680ac17a" ||
              eduLevel == "Cursos de Educação Formação"
            ) {
              return "2º e 3º Ciclo";
            } else if (eduLevel == "Cursos Profissionais") {
              return "SEC";
            } else if (eduLevel == "Visitante Adulto") {
              return "Adulto";
            } else {
              return eduLevel;
            }
          }

          function calculateMealPrice(mealType, mealNumber) {
            if (mealType == "Alunos" || mealType == "Alunos ETI" || mealType == "Prova" || mealType == "Take-away") {
              return Math.round(2.35 * mealNumber * 100) / 100;
            } else if (mealType == "Lanches" || mealType == "Lanches ETI") {
              return Math.round(0.8 * mealNumber * 100) / 100;
            } else if (mealType == "Adultos") {
              return Math.round(4.34 * mealNumber * 100) / 100;
            }
          }

          sigaMealsToInsert = Object.values(sigaMealsToInsertMap);
          ipssMealsToInsert = Object.values(ipssMealsToInsertMap);

          let rowsToInsert = sigaMealsToInsert.concat(ipssMealsToInsert);
          if (rowsToInsert.length > 0) {
            scope.parentScope.$parent.showDeterminateLoader(rowsToInsert.length);
            let numInsertedRows = 0;
            async.eachSeries(
              rowsToInsert,
              function iteratee(item, callback) {
                scope.genericFactory.create(item).then((el) => {
                  scope.parentScope.$parent.updateDeterminateLoader();
                  numInsertedRows += 1;
                  callback();
                });
              },
              function done() {
                if (numInsertedRows == rowsToInsert.length) {
                  scope.parentScope.$parent.hideLoader();
                  scope.getModuleData();
                }
              },
              function (err) {
                if (err) {
                  console.log(err);
                  scope.parentScope.$parent.hideLoader();
                  scope.getModuleData();
                } else {
                  //Done
                  console.log("done");
                  scope.parentScope.$parent.hideLoader();
                  scope.getModuleData();
                }
              }
            );
          } else {
            scope.parentScope.$parent.hideLoader();
          }
        }
      });
    });
  },
  cmsUpdateTwelfths: function cmsUpdateTwelfths(scope, args, data) {
    scope.parentScope.$parent.showLoader();
    scope.genericFactory.setRouteName("CMS_Cluster_Charges");
    let refYear = [new Date().getFullYear() - 1 + "", new Date().getFullYear() + ""];
    if (refYear.indexOf("2024") != -1) {
      refYear.splice(refYear.indexOf("2024"), 1);
    }
    let map = {};
    map.civilYear = refYear;
    scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (charges) {
      scope.genericFactory.setRouteName("CMS_Management_Control_Twelfths");

      // Get the twelfths and extra payments that were already inserted
      let insertedTwelfths = {};
      data.forEach((dataEl) => {
        if (
          refYear.indexOf(dataEl.civilYear) != -1 &&
          ((dataEl.documentType == "Duodécimo" && dataEl.chargeSubType == "64a82a282bbdd5007cf28d16") ||
            (dataEl.documentType == "Duodécimo - Conservação" && dataEl.chargeSubType == "64a82ac92bbdd5007cf28d1d") ||
            (dataEl.documentType == "Duodécimo" && dataEl.chargeSubType == "656e0bfc8b37270084faf74a"))
        ) {
          insertedTwelfths[dataEl.civilYear + dataEl.cluster + dataEl.month + dataEl.chargeSubType] = dataEl;
        }
      });

      // Get the charge twelfths and extra payments
      let chargeTwelfths = {};
      charges.forEach((char) => {
        if (
          char.civilYear != null &&
          char.cluster != null &&
          char.supplyMonth != null &&
          (char.chargeSubType == "64a82a282bbdd5007cf28d16" ||
            char.chargeSubType == "64a82ac92bbdd5007cf28d1d" ||
            char.chargeSubType == "656e0bfc8b37270084faf74a")
        ) {
          if (chargeTwelfths[char.civilYear + char.cluster + char.supplyMonth + char.chargeSubType] != null) {
            chargeTwelfths[char.civilYear + char.cluster + char.supplyMonth + char.chargeSubType].value += char.value;
          } else {
            chargeTwelfths[char.civilYear + char.cluster + char.supplyMonth + char.chargeSubType] = char;
          }
        }
      });

      let chargesToInsert = [];
      // Parse twelfth charges and extra payments
      Object.values(chargeTwelfths).forEach((char) => {
        // Check if twelfth or extra payment was already inserted
        if (
          insertedTwelfths[char.civilYear + char.cluster + char.supplyMonth + char.chargeSubType] != null &&
          insertedTwelfths[char.civilYear + char.cluster + char.supplyMonth + char.chargeSubType].value !=
            Math.round(char.value * 100) / 100
        ) {
          insertedTwelfths[char.civilYear + char.cluster + char.supplyMonth + char.chargeSubType].twelfthUpdateType =
            "modify";
          insertedTwelfths[char.civilYear + char.cluster + char.supplyMonth + char.chargeSubType].value =
            Math.round(char.value * 100) / 100;
          parseElement(char, "modify");
          chargesToInsert.push(insertedTwelfths[char.civilYear + char.cluster + char.supplyMonth + char.chargeSubType]);
        } else if (insertedTwelfths[char.civilYear + char.cluster + char.supplyMonth + char.chargeSubType] == null) {
          char.twelfthUpdateType = "create";
          char.value = Math.round(char.value * 100) / 100;
          parseElement(char, "create");
          chargesToInsert.push(char);
        }
      });

      function parseElement(element, updateType) {
        if (element.chargeSubType == "64a82a282bbdd5007cf28d16") {
          element.documentType = "Duodécimo";
        } else if (element.chargeSubType == "64a82ac92bbdd5007cf28d1d") {
          element.documentType = "Duodécimo - Conservação";
        } else if (element.chargeSubType == "656e0bfc8b37270084faf74a") {
          element.documentType = "Duodécimo";
        }

        if (updateType == "modify") {
          element.modifiedBy = { name: scope.currentUser.name, surname: scope.currentUser.surname };
          element.modifiedAt = new Date().getTime();
        } else {
          delete element.id;
          if (element.supplyMonth != null) {
            element.month = JSON.parse(JSON.stringify(element.supplyMonth));
            delete element.supplyMonth;
          }
          if (element.createdBy != null) {
            element.createdBy = { name: scope.currentUser.name, surname: scope.currentUser.surname };
            element.createdAt = new Date().getTime();
          }
          if (element.modifiedBy != null) {
            delete element.modifiedBy;
          }
        }
      }

      let rowsToInsert = chargesToInsert;
      let numInsertedRows = 0;
      async.eachSeries(
        rowsToInsert,
        function iteratee(item, callback) {
          if (item.twelfthUpdateType == "modify") {
            scope.genericFactory.modify(item.id, item).then((el) => {
              numInsertedRows += 1;
              callback();
            });
          } else if (item.twelfthUpdateType == "create") {
            scope.genericFactory.create(item).then((el) => {
              numInsertedRows += 1;
              callback();
            });
          }
        },
        function done() {
          if (numInsertedRows == rowsToInsert.length) {
            scope.parentScope.$parent.hideLoader();
            scope.getModuleData();
          }
        },
        function (err) {
          if (err) {
            console.log(err);
            scope.parentScope.$parent.hideLoader();
            scope.getModuleData();
          } else {
            //Done
            console.log("done");
            scope.parentScope.$parent.hideLoader();
            scope.getModuleData();
          }
        }
      );
    });
  },
  cmsUpdateBalances: function cmsUpdateBalances(scope, args, data) {
    let balancesByPreviousCivilYearCluster = {};
    let balanceElsToBeDeleted = [];
    data.forEach((dataEl) => {
      if (dataEl.documentType == "Saldo Inicial" && dataEl.civilYear == new Date().getFullYear()) {
        balanceElsToBeDeleted.push(dataEl);
      }
      if (dataEl.civilYear == new Date().getFullYear() - 1) {
        // In 2023, case of the Lima, Encargos -> Conservacao
        if (
          dataEl.civilYear == "2023" &&
          dataEl.chargeType == "6495c47e9de1a0009670b0e0" &&
          dataEl.cluster == "5cb9fa219001bcb6568ae0bd"
        ) {
          dataEl.chargeType = "64a829902bbdd5007cf28d10";
        }

        // In 2024, Charge Sub Type Conservacao 67 -> Encargos
        if (dataEl.civilYear == "2024" && dataEl.chargeSubType == "64a82abf2bbdd5007cf28d1c") {
          dataEl.chargeType = "6495c47e9de1a0009670b0e0";
        }

        // In 2023, case of the ES Bocage, Encargos -> Conservacao
        /* else if (
          dataEl.civilYear == "2023" &&
          dataEl.chargeType == "6495c47e9de1a0009670b0e0" &&
          dataEl.cluster == "5e29c9f0d4ab6700506e2a61"
        ) {
          dataEl.chargeType = "64a829902bbdd5007cf28d10";
        } */
        // In 2023, case of the D. Joao II, Conservacao -> Encargos
        /* else if (
          dataEl.civilYear == "2023" &&
          dataEl.chargeType == "64a829902bbdd5007cf28d10" &&
          dataEl.cluster == "5e29c8b7d4ab6700506e2a5f"
        ) {
          dataEl.chargeType = "6495c47e9de1a0009670b0e0";
        } */

        let balanceKey = dataEl.civilYear + dataEl.cluster + dataEl.chargeType;
        if (balancesByPreviousCivilYearCluster[balanceKey] == null) {
          balancesByPreviousCivilYearCluster[balanceKey] = JSON.parse(JSON.stringify(dataEl));
          if (
            ((dataEl.documentType == "Fatura" || dataEl.documentType == "Nota Crédito") &&
              dataEl.paidDocument == "Sim") ||
            dataEl.documentType == "Nota Débito"
          ) {
            balancesByPreviousCivilYearCluster[balanceKey].value =
              -balancesByPreviousCivilYearCluster[balanceKey].value;
          }
        } else {
          if (dataEl.documentType == "Saldo Inicial") {
            balancesByPreviousCivilYearCluster[balanceKey].value += dataEl.value;
          } else if (dataEl.documentType == "Duodécimo" || dataEl.documentType == "Duodécimo - Conservação") {
            balancesByPreviousCivilYearCluster[balanceKey].value += dataEl.value;
          } else if (
            (dataEl.documentType == "Fatura" || dataEl.documentType == "Nota Crédito") &&
            dataEl.paidDocument == "Sim"
          ) {
            balancesByPreviousCivilYearCluster[balanceKey].value -= dataEl.value;
          } else if (dataEl.documentType == "Nota Débito") {
            balancesByPreviousCivilYearCluster[balanceKey].value -= dataEl.value;
          }
        }
      }
    });
    balanceElsToBeDeleted.forEach((elToDelete) => {
      scope.genericFactory.delete(elToDelete.id).then((el) => {});
    });
    let balanceElsToBeCreated = [];
    Object.values(balancesByPreviousCivilYearCluster).forEach((elToCreate) => {
      let newEl = JSON.parse(JSON.stringify(elToCreate));
      delete newEl.id;
      newEl.year = new Date().getFullYear() - 1 + "";
      newEl.civilYear = new Date().getFullYear() + "";
      newEl.month = "janeiro";
      newEl.documentType = "Saldo Inicial";
      // Encargos
      if (newEl.chargeType == "6495c47e9de1a0009670b0e0") {
        newEl.chargeType = "6495c47e9de1a0009670b0e0";
        newEl.chargeSubType = "64a82a282bbdd5007cf28d16";
      }
      // Conservação
      if (newEl.chargeType == "64a829902bbdd5007cf28d10") {
        newEl.chargeType = "64a829902bbdd5007cf28d10";
        newEl.chargeSubType = "64a82ac92bbdd5007cf28d1d";
      }
      newEl.economicClassification = null;
      newEl.economicClassificationDesc = null;
      newEl.document = null;
      newEl.paidDocument = null;
      if (newEl.value != null) {
        newEl.value = Math.round(newEl.value * 100) / 100;
      }
      delete newEl.modifiedAt;
      delete newEl.modifiedBy;
      newEl.createdBy = { name: scope.currentUser.name, surname: scope.currentUser.surname };
      newEl.createdAt = new Date().getTime();
      balanceElsToBeCreated.push(newEl);
    });
    let numCreatedRows = 0;
    async.eachSeries(
      balanceElsToBeCreated,
      function iteratee(item, callback) {
        scope.genericFactory.create(item).then(() => {
          numCreatedRows += 1;
          callback();
        });
      },
      function done() {
        if (numCreatedRows == balanceElsToBeCreated.length) {
          scope.getModuleData();
        }
      },
      function (err) {
        if (err) {
          console.log(err);
        } else {
          //Done
          scope.getModuleData();
        }
      }
    );
  },
  cmsUpdateEEMealComparticipations: function cmsUpdateEEMealComparticipations(scope, args, data) {
    // Delete current and previous civil year comparticipations
    let eeMealCompElsToBeDeleted = [];
    data.forEach((dataEl) => {
      if (
        (dataEl.chargeSubType == "64ad36cc0cc07f00717f3648" ||
          dataEl.chargeSubType == "64a82a0a2bbdd5007cf28d15" ||
          dataEl.chargeSubType == "64ad37020cc07f00717f3649") &&
        (dataEl.civilYear == new Date().getFullYear() - 1 || dataEl.civilYear == new Date().getFullYear())
      ) {
        eeMealCompElsToBeDeleted.push(dataEl);
      }
    });
    eeMealCompElsToBeDeleted.forEach((elToDelete) => {
      scope.genericFactory.delete(elToDelete.id).then((el) => {});
    });

    // Get comparticipations
    scope.parentScope.$parent.showLoader();
    scope.genericFactory.setRouteName("CMS_Meal_Records");
    let map = {};
    map.civilYear = [new Date().getFullYear() - 1 + "", new Date().getFullYear() + ""];
    scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (comparticipations) {
      scope.genericFactory.setRouteName("CMS_Cluster_Revs");

      let eeMealCompElsToBeCreated = {};

      comparticipations.forEach((el) => {
        if (el.eeComparticipation != null && el.eeComparticipation != "") {
          let newEl = {};
          newEl.organization = el.organization;
          newEl.civilYear = el.civilYear;
          newEl.supplyMonth = el.month;
          newEl.chargeType = "64a829f72bbdd5007cf28d12";
          newEl.revenue = el.eeComparticipation;

          // PRE
          if (el.educationLevel == "5dfe2ad3d8954900740d6bed") {
            newEl.chargeSubType = "64ad36cc0cc07f00717f3648";
            newEl.revSource = "Comparticipação Famílias";
            newEl.parsedEducationLevel = "PRE";
            newEl.codeIGEFE = 509;
          }

          // 1º Ciclo
          if (el.educationLevel == "5dfe2abfd8954900740d6bea") {
            newEl.chargeSubType = "64a82a0a2bbdd5007cf28d15";
            newEl.revSource = "Comparticipação Famílias";
            newEl.parsedEducationLevel = "1º Ciclo";
            newEl.codeIGEFE = 510;
          }

          // 2º Ciclo, 3º Ciclo and SEC
          if (
            el.educationLevel == "5dfe2ac6d8954900740d6beb" ||
            el.educationLevel == "5dfe2acbd8954900740d6bec" ||
            el.educationLevel == "5dfe2ad8d8954900740d6bee"
          ) {
            newEl.chargeSubType = "64ad37020cc07f00717f3649";
            newEl.revSource = "Comparticipação Famílias";
            newEl.parsedEducationLevel = "2º Ciclo,3º Ciclo,SEC";
            newEl.codeIGEFE = 510;
          }

          newEl.createdAt = new Date().getTime();
          newEl.createdBy = "admin";

          let key = newEl.civilYear + newEl.supplyMonth + newEl.chargeSubType;

          if (eeMealCompElsToBeCreated[key] == null) {
            eeMealCompElsToBeCreated[key] = newEl;
          } else {
            eeMealCompElsToBeCreated[key].revenue =
              Math.round((eeMealCompElsToBeCreated[key].revenue + newEl.revenue) * 100) / 100;
          }
        }
      });
      let numCreatedRows = 0;
      async.eachSeries(
        Object.values(eeMealCompElsToBeCreated),
        function iteratee(item, callback) {
          scope.genericFactory.create(item).then(() => {
            numCreatedRows += 1;
            callback();
          });
        },
        function done() {
          if (numCreatedRows == Object.values(eeMealCompElsToBeCreated).length) {
            scope.getModuleData();
          }
        },
        function (err) {
          if (err) {
            console.log(err);
          } else {
            //Done
            scope.getModuleData();
          }
        }
      );
    });
  },
  checkTwelfth: function checkTwelfth(scope, selected) {
    if (
      selected.documentType == "Duodécimo" ||
      selected.documentType == "Duodécimo - Conservação" ||
      selected.documentType == "Saldo Inicial" ||
      selected.documentType == "Guia Reposição"
    ) {
      if (selected.documentType == "Duodécimo - Conservação") {
        selected.chargeType = "64a829902bbdd5007cf28d10";
        selected.chargeSubType = "64a82ac92bbdd5007cf28d1d";
      }
      selected.economicClassification = null;
      selected.economicClassificationDesc = null;
      selected.document = null;
      selected.paidDocument = null;
      return true;
    } else {
      if (
        selected.chargeType == null ||
        selected.chargeSubType == null ||
        selected.economicClassification == null ||
        selected.economicClassificationDesc == null ||
        selected.document == null ||
        selected.chargeType == "" ||
        selected.chargeSubType == "" ||
        selected.economicClassification == "" ||
        selected.economicClassificationDesc == "" ||
        selected.document == "" ||
        (selected.paidDocument == null && scope.genericScope.module.name != "Controlo de Gestão AE/ENA") ||
        (selected.paidDocument == "" && scope.genericScope.module.name != "Controlo de Gestão AE/ENA")
      ) {
        scope.genericScope.$parent.alertInvalidTwelfth();
        return false;
      } else {
        // Check if Nº Doc is duplicated
        if (selected.documentType == "Fatura") {
          for (let w = 0; w < scope.data.length; w++) {
            let dataEl = scope.data[w];
            if (
              scope.mode == "update" &&
              selected.id == dataEl.id &&
              scope.originalSelected.civilYear == dataEl.civilYear &&
              scope.originalSelected.month == dataEl.month &&
              scope.originalSelected.document == dataEl.document
            ) {
              scope.genericScope.$parent.alertDuplicateInvoicedTwelfth();
              return false;
            }
            if (
              selected.civilYear == dataEl.civilYear &&
              selected.month == dataEl.month &&
              selected.document == dataEl.document &&
              selected.id != dataEl.id
            ) {
              scope.genericScope.$parent.alertDuplicateInvoicedTwelfth();
              return false;
            }
          }
          return true;
        } else {
          return true;
        }
      }
    }
  },
  cmsClusterChargeCheckInvoice: function cmsClusterChargeCheckInvoice(scope, selected) {
    // Check if Nº Doc is duplicated
    if (selected.documentType == "Fatura") {
      for (let w = 0; w < scope.data.length; w++) {
        let dataEl = scope.data[w];
        if (
          scope.mode == "update" &&
          selected.id == dataEl.id &&
          scope.originalSelected.civilYear == dataEl.civilYear &&
          scope.originalSelected.supplyMonth == dataEl.supplyMonth &&
          scope.originalSelected.invoice == dataEl.invoice
        ) {
          scope.genericScope.$parent.alertDuplicateInvoicedTwelfth();
          return false;
        }
        if (
          selected.civilYear == dataEl.civilYear &&
          selected.supplyMonth == dataEl.supplyMonth &&
          selected.invoice == dataEl.invoice &&
          selected.id != dataEl.id
        ) {
          scope.genericScope.$parent.alertDuplicateInvoicedTwelfth();
          return false;
        }
      }
      return true;
    } else {
      return true;
    }
  },
  getCMSLastYearContractTotalPrice: function getCMSLastYearContractTotalPrice(scope, selected, data) {
    if (
      selected.year != null &&
      selected.year != "" &&
      selected.cluster != null &&
      selected.cluster != "" &&
      selected.school != null &&
      selected.school != "" &&
      selected.provisionDesc != null &&
      selected.provisionDesc != ""
    ) {
      scope.parentScope.$parent.showLoader();

      if (selected.lastYearAvailableContractPlafondFlag == true) {
        selected.lastYearAvailableContractPlafondFlag = null;
      } else {
        let lastYear = (Number(selected.year) - 1).toString();
        let lastYearEl = data.filter(
          (el) =>
            el.year == lastYear &&
            el.cluster == selected.cluster &&
            el.school == selected.school &&
            el.provisionDesc == selected.provisionDesc
        );
        if (
          lastYearEl.length &&
          lastYearEl[0].availableContractPlafond != null &&
          lastYearEl[0].availableContractPlafond > 0
        ) {
          selected.lastYearAvailableContractPlafondFlag = true;
          selected.contractTotalPrice = lastYearEl[0].availableContractPlafond;
        }
      }

      scope.parentScope.$parent.hideLoader();
    }
  },
  transportsChargesMonthSplit: function transportsChargesMonthSplit(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.chargeHistory && el.chargeHistory.length > 0) {
        for (let k = 0; k < el.chargeHistory.length; k++) {
          let chargeHist = el.chargeHistory[k];
          let newElement = JSON.parse(JSON.stringify(el));
          newElement.chargeHistory = [];
          newElement.chargeHistory.push(chargeHist);
          for (let j = 0; j < el.chargeHistory.length; j++) {
            let chargeHist2 = el.chargeHistory[j];
            if (j != k) {
              newElement.chargeHistory.push(chargeHist2);
            }
          }
          data.unshift(newElement);
          i++;
        }
      }
      data.splice(i, 1);
    }
    return data;
  },
  ptInstrEvalFilter: function ptInstrEvalFilter(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.evalCritGridDesc || (el.studentFinalCritEvaluations && el.studentFinalCritEvaluations.length > 0)) {
        data.splice(i, 1);
      } else {
        i++;
      }
    }
  },
  ptFinalEvalFilter: function ptInstrEvalFilter(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.studentEvaluations && el.studentEvaluations.length > 0) {
        data.splice(i, 1);
      } else {
        i++;
      }
    }
  },
  ptQuestionEvalFilter: function ptInstrEvalFilter(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (
        el.studentEvaluations &&
        el.studentEvaluations.length > 0 &&
        el.studentEvaluations[0].essentialLearningEvaluation &&
        el.studentEvaluations[0].essentialLearningEvaluation.length > 0
      ) {
        data.splice(i, 1);
      } else {
        i++;
      }
    }
  },
  etlSucEvaluationEb: function etlSucEvaluationEb(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.ano_escolaridade >= 10) {
        data.splice(i, 1);
      } else {
        i++;
      }
    }
  },
  etlSucEvaluationSec: function etlSucEvaluationSec(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.ano_escolaridade < 10) {
        data.splice(i, 1);
      } else {
        i++;
      }
    }
  },
  etlSucEvaluation2019: function etlSucEvaluation2019(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.ano_lectivo == 2018) {
        data.splice(i, 1);
      } else {
        i++;
      }
    }
  },
  ptSplitInstrQuestionSchoolRecord: function ptSplitInstrQuestionSchoolRecord(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.studentEvaluations) {
        for (let k = 0; k < el.studentEvaluations.length; k++) {
          let studEval = el.studentEvaluations[k];
          let alreadySplitEvalsFlag = false;
          for (const essenLearn in studEval.qualitativeEvalMap) {
            if (studEval.qualitativeEvalMap.hasOwnProperty(essenLearn)) {
              let essenLearnEvals = studEval.qualitativeEvalMap[essenLearn];
              if (
                essenLearnEvals == null ||
                (studEval.completeQualitativeEvals != null && studEval.completeQualitativeEvals == false)
              ) {
                continue;
              }
              essenLearnEvals.map(function (essenLearnEval) {
                let newElement = JSON.parse(JSON.stringify(el));
                newElement.weight = essenLearnEval.weight;
                newElement.question = essenLearnEval.question;
                newElement.questionEval = essenLearnEval.essentialLearningEval;
                newElement.essentialLearningEval = essenLearnEval.essentialLearningEval;
                newElement.essentialLearning = essenLearn;
                newElement.name = studEval.studentID;
                newElement.student = studEval.student;
                newElement.splitFlag = true;
                newElement.qualitativeEvalFlag = true;
                if (newElement.module != null) {
                  newElement.parsedModule = scope.parseValue(newElement.module, "module", "Class_Plan_Prof_Modules");
                }
                if (newElement.student != null) {
                  delete newElement.student;
                }
                if (newElement.studentEvaluations != null) {
                  delete newElement.studentEvaluations;
                }
                if (newElement.createdBy != null) {
                  delete newElement.createdBy;
                }
                if (newElement.modifiedBy != null) {
                  delete newElement.modifiedBy;
                }
                data.push(newElement);
                alreadySplitEvalsFlag = true;
              });
            }
          }
          if (
            !alreadySplitEvalsFlag &&
            studEval.essentialLearningQuestionEvaluation != null &&
            studEval.essentialLearningQuestionEvaluation.length > 0
          ) {
            if (studEval.essenLearnFinalEvalMap && Object.keys(studEval.essenLearnFinalEvalMap).length > 0) {
              for (let w = 0; w < studEval.essentialLearningQuestionEvaluation.length; w++) {
                let newElement = JSON.parse(JSON.stringify(el));
                let essenLearnQuestionEval = studEval.essentialLearningQuestionEvaluation[w];

                newElement.weight = essenLearnQuestionEval.weight;
                newElement.question = essenLearnQuestionEval.question;
                newElement.questionEval = essenLearnQuestionEval.evaluation;
                newElement.essentialLearning = essenLearnQuestionEval.parsedEssentialLearning;
                newElement.performanceCrit = essenLearnQuestionEval.performanceCrit;

                if (essenLearnQuestionEval.evaluation == null) {
                  continue;
                }

                newElement.essentialLearningEval =
                  Math.round(
                    studEval.essenLearnFinalEvalMap[essenLearnQuestionEval.essentialLearning].evaluation * 100
                  ) / 100;
                //newElement.essentialLearningEval = studEval.essenLearnFinalEvalMap[essenLearnQuestionEval.essentialLearning].evaluation;

                newElement.name = studEval.studentID;
                newElement.splitFlag = true;
                newElement.quantitativeEvalFlag = true;
                if (newElement.module != null) {
                  newElement.parsedModule = scope.parseValue(newElement.module, "module", "Class_Plan_Prof_Modules");
                }

                if (newElement.student != null) {
                  delete newElement.student;
                }
                if (newElement.studentEvaluations != null) {
                  delete newElement.studentEvaluations;
                }
                if (newElement.createdBy != null) {
                  delete newElement.createdBy;
                }
                if (newElement.modifiedBy != null) {
                  delete newElement.modifiedBy;
                }
                data.push(newElement);
              }
            }
          }
        }
      }

      data.splice(0, 1);
    }

    let essenLearnMap = {};

    for (let j = 0; j < data.length; j++) {
      let el = data[j];
      if (el.qualitativeLevels != null && Object.keys(el.qualitativeLevels).length > 0 && el.qualitativeEvalFlag) {
        if (essenLearnMap[el.name] == null) {
          essenLearnMap[el.name] = {};
          essenLearnMap[el.name][el.essentialLearning] = [];
          let essenQualEval = {};
          essenQualEval.totalEvals = 1;
          essenQualEval.essentialLearningEval = el.essentialLearningEval;
          essenLearnMap[el.name][el.essentialLearning].push(essenQualEval);
        } else {
          if (essenLearnMap[el.name][el.essentialLearning] == null) {
            essenLearnMap[el.name][el.essentialLearning] = [];
            let essenQualEval = {};
            essenQualEval.totalEvals = 1;
            essenQualEval.essentialLearningEval = el.essentialLearningEval;
            essenLearnMap[el.name][el.essentialLearning].push(essenQualEval);
          } else {
            let alreadyAddedFlag = false;
            essenLearnMap[el.name][el.essentialLearning].map(function (qualEval) {
              if (qualEval.essentialLearningEval == el.essentialLearningEval) {
                alreadyAddedFlag = true;
                qualEval.totalEvals += 1;
              }
            });
            if (!alreadyAddedFlag) {
              let essenQualEval = {};
              essenQualEval.totalEvals = 1;
              essenQualEval.essentialLearningEval = el.essentialLearningEval;
              essenLearnMap[el.name][el.essentialLearning].push(essenQualEval);
            }
          }
        }
      } else {
        if (essenLearnMap[el.name] == null) {
          essenLearnMap[el.name] = {};
          essenLearnMap[el.name][el.essentialLearning] = {};
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal = el.essentialLearningEval;
          essenLearnMap[el.name][el.essentialLearning].totalEvals = 1;
        } else {
          if (essenLearnMap[el.name][el.essentialLearning] == null) {
            essenLearnMap[el.name][el.essentialLearning] = {};
            essenLearnMap[el.name][el.essentialLearning].essenLearnTotal = el.essentialLearningEval;
            essenLearnMap[el.name][el.essentialLearning].totalEvals = 1;
          } else {
            essenLearnMap[el.name][el.essentialLearning].essenLearnTotal += el.essentialLearningEval;
            essenLearnMap[el.name][el.essentialLearning].totalEvals += 1;
          }
        }
      }
    }

    for (let w = 0; w < data.length; w++) {
      let el = data[w];
      for (const studName in essenLearnMap) {
        if (essenLearnMap.hasOwnProperty(studName)) {
          let essLearn = essenLearnMap[studName];
          if (el.name == studName && essLearn[el.essentialLearning] != null) {
            if (
              el.qualitativeLevels != null &&
              Object.keys(el.qualitativeLevels).length > 0 &&
              el.qualitativeEvalFlag
            ) {
              el.essentialLearningEval = null;
              for (const essLearnQual in essLearn) {
                if (essLearn.hasOwnProperty(essLearnQual) && el.essentialLearning == essLearnQual) {
                  let essLearnQualEvals = essLearn[essLearnQual];
                  for (let m = 0; m < essLearnQualEvals.length; m++) {
                    let qualEval = essLearnQualEvals[m];

                    if (el.essentialLearningEval == null) {
                      el.essentialLearningEval = qualEval.essentialLearningEval + " (" + qualEval.totalEvals + ")";
                    } else {
                      el.essentialLearningEval +=
                        ", " + qualEval.essentialLearningEval + " (" + qualEval.totalEvals + ")";
                    }
                  }
                }
              }
            } else {
              el.essenLearnAvg =
                Math.round(
                  (essLearn[el.essentialLearning].essenLearnTotal / essLearn[el.essentialLearning].totalEvals) * 100
                ) / 100;
            }
          }
        }
      }
    }

    return data;
  },
  ptSplitAESchoolRecord: function ptSplitAESchoolRecord(scope, data, finalEvalFlag) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (finalEvalFlag && el.excludedFinalEvalFlag && el.excludedFinalEvalFlag.length > 0) {
        data.splice(i, 1);
        continue;
      }

      if (el.studentEvaluations) {
        for (let k = 0; k < el.studentEvaluations.length; k++) {
          let studEval = el.studentEvaluations[k];

          if (studEval.studEvalExcludedFinalEvalFlag && studEval.studEvalExcludedFinalEvalFlag.length > 0) {
            continue;
          }

          if (studEval.essentialLearningEvaluation != null && studEval.essentialLearningEvaluation.length > 0) {
            if (studEval.essentialLearningEvaluation && studEval.qualitativeEvalMap == null) {
              for (let w = 0; w < studEval.essentialLearningEvaluation.length; w++) {
                let newElement = JSON.parse(JSON.stringify(el));
                let essenLearn = studEval.essentialLearningEvaluation[w];

                newElement.essentialLearning = essenLearn.essentialLearning;
                if (essenLearn.evaluation == null) {
                  continue;
                }
                newElement.essentialLearningEval = essenLearn.evaluation;
                newElement.name = studEval.studentID;
                if (studEval.originalClass) {
                  newElement.class = studEval.originalClass;
                }
                if (studEval.student) {
                  newElement.classOrder = studEval.student.classOrder;
                } else {
                  newElement.classOrder = studEval.studentClassOrder;
                }
                if (newElement.module != null) {
                  newElement.parsedModule = scope.parseValue(newElement.module, "module", "Class_Plan_Prof_Modules");
                }
                newElement.splitFlag = true;
                cleanNewElement(newElement);
                data.push(newElement);
              }
            }
          }
          let studEvalWithQuantitativeEvals = false;
          if (
            studEval.essentialLearningQuestionEvaluation != null &&
            studEval.essentialLearningQuestionEvaluation.length > 0
          ) {
            for (const essenLearn in studEval.essenLearnFinalEvalMap) {
              if (studEval.essenLearnFinalEvalMap.hasOwnProperty(essenLearn)) {
                const essenLearnEval = studEval.essenLearnFinalEvalMap[essenLearn];
                let newElement = JSON.parse(JSON.stringify(el));

                newElement.essentialLearning = essenLearnEval.essentialLearning;
                if (essenLearnEval.evaluation == null) {
                  continue;
                }
                studEvalWithQuantitativeEvals = true;
                if (finalEvalFlag) {
                  newElement.essentialLearningEval = Math.round(essenLearnEval.evaluation * 100) / 100;
                } else {
                  newElement.essentialLearningEval = Math.round(essenLearnEval.evaluation);
                }
                newElement.name = studEval.studentID;
                if (studEval.originalClass) {
                  newElement.class = studEval.originalClass;
                }
                if (studEval.student) {
                  newElement.classOrder = studEval.student.classOrder;
                } else {
                  newElement.classOrder = studEval.studentClassOrder;
                }
                if (newElement.module != null) {
                  newElement.parsedModule = scope.parseValue(newElement.module, "module", "Class_Plan_Prof_Modules");
                }
                newElement.splitFlag = true;
                cleanNewElement(newElement);
                data.push(newElement);
              }
            }
          }
          if (studEval.qualitativeEvalMap != null && !studEvalWithQuantitativeEvals) {
            for (const essenLearn in studEval.qualitativeEvalMap) {
              if (studEval.qualitativeEvalMap.hasOwnProperty(essenLearn)) {
                let essenLearnEvals = studEval.qualitativeEvalMap[essenLearn];
                if (
                  essenLearnEvals == null ||
                  (studEval.completeQualitativeEvals != null && studEval.completeQualitativeEvals == false)
                ) {
                  continue;
                }
                if (finalEvalFlag) {
                  essenLearnEvals.map(function (essenLearnEval) {
                    let newElement = JSON.parse(JSON.stringify(el));
                    newElement.essentialLearningEval = essenLearnEval.essentialLearningEval;
                    newElement.essentialLearning = essenLearn;
                    newElement.name = studEval.studentID;
                    if (studEval.originalClass) {
                      newElement.class = studEval.originalClass;
                    }
                    if (studEval.student) {
                      newElement.classOrder = studEval.student.classOrder;
                    } else {
                      newElement.classOrder = studEval.studentClassOrder;
                    }
                    if (newElement.module != null) {
                      newElement.parsedModule = scope.parseValue(
                        newElement.module,
                        "module",
                        "Class_Plan_Prof_Modules"
                      );
                    }
                    newElement.splitFlag = true;
                    cleanNewElement(newElement);
                    data.push(newElement);
                  });
                } else {
                  let newElement = JSON.parse(JSON.stringify(el));
                  newElement.essentialLearningEval = "";
                  essenLearnEvals.map(function (essenLearnEval) {
                    if (newElement.essentialLearningEval == "") {
                      newElement.essentialLearningEval += essenLearnEval.essentialLearningEval;
                    } else {
                      newElement.essentialLearningEval += ", " + essenLearnEval.essentialLearningEval;
                    }
                  });
                  newElement.essentialLearning = essenLearn;
                  newElement.name = studEval.studentID;
                  if (studEval.originalClass) {
                    newElement.class = studEval.originalClass;
                  }
                  if (studEval.student) {
                    newElement.classOrder = studEval.student.classOrder;
                  } else {
                    newElement.classOrder = studEval.studentClassOrder;
                  }
                  if (newElement.module != null) {
                    newElement.parsedModule = scope.parseValue(newElement.module, "module", "Class_Plan_Prof_Modules");
                  }
                  newElement.splitFlag = true;
                  cleanNewElement(newElement);
                  data.push(newElement);
                }
              }
            }
          }
        }
      }

      data.splice(0, 1);
    }

    function cleanNewElement(element) {
      if (element.studentEvaluations != null) {
        delete element.studentEvaluations;
      }
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    let essenLearnMap = {};

    for (let j = 0; j < data.length; j++) {
      let el = data[j];
      /* if (el.qualitativeLevels != null && Object.keys(el.qualitativeLevels).length > 0) {
        if (essenLearnMap[el.name] == null) {
          essenLearnMap[el.name] = {};
          essenLearnMap[el.name][el.essentialLearning] = [];
          let essenQualEval = {};
          essenQualEval.totalEvals = 1;
          essenQualEval.essentialLearningEval = el.essentialLearningEval;
          essenLearnMap[el.name][el.essentialLearning].push(essenQualEval);
        } else {
          if (essenLearnMap[el.name][el.essentialLearning] == null) {
            essenLearnMap[el.name][el.essentialLearning] = [];
            let essenQualEval = {};
            essenQualEval.totalEvals = 1;
            essenQualEval.essentialLearningEval = el.essentialLearningEval;
            essenLearnMap[el.name][el.essentialLearning].push(essenQualEval);
          } else {
            let alreadyAddedFlag = false;
            essenLearnMap[el.name][el.essentialLearning].map(function (qualEval) {
              if (qualEval.essentialLearningEval == el.essentialLearningEval) {
                alreadyAddedFlag = true;
                qualEval.totalEvals += 1;
              }
            });
            if (!alreadyAddedFlag) {
              let essenQualEval = {};
              essenQualEval.totalEvals = 1;
              essenQualEval.essentialLearningEval = el.essentialLearningEval;
              essenLearnMap[el.name][el.essentialLearning].push(essenQualEval);
            }
          }
        }
      } else {
        if (essenLearnMap[el.name] == null) {
          essenLearnMap[el.name] = {};
          essenLearnMap[el.name][el.essentialLearning] = {};
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal = el.essentialLearningEval;
          essenLearnMap[el.name][el.essentialLearning].totalEvals = 1;
        } else {
          if (essenLearnMap[el.name][el.essentialLearning] == null) {
            essenLearnMap[el.name][el.essentialLearning] = {};
            essenLearnMap[el.name][el.essentialLearning].essenLearnTotal = el.essentialLearningEval;
            essenLearnMap[el.name][el.essentialLearning].totalEvals = 1;
          } else {
            essenLearnMap[el.name][el.essentialLearning].essenLearnTotal += el.essentialLearningEval;
            essenLearnMap[el.name][el.essentialLearning].totalEvals += 1;
          }
        }
      } */

      //Parse qualitative eval if necessary
      let parsedQuantitativeEval;
      if (typeof el.essentialLearningEval == "string" && el.evalInstrumentQualitativeLevels) {
        let parsedEvalInstrumentQualitativeLevels = JSON.parse(el.evalInstrumentQualitativeLevels);
        if (
          parsedEvalInstrumentQualitativeLevels.qualitativeLevels &&
          Array.isArray(parsedEvalInstrumentQualitativeLevels.qualitativeLevels) &&
          parsedEvalInstrumentQualitativeLevels.qualitativeLevels.length > 0
        ) {
          let quantitativeEval = classPlanGetQuantitativeEvalsFromQualitative(
            new Array(el.essentialLearningEval),
            parsedEvalInstrumentQualitativeLevels.qualitativeLevels
          );
          if (!isNaN(Number(quantitativeEval))) {
            parsedQuantitativeEval = quantitativeEval;
          } else {
            continue;
          }
        } else {
          continue;
        }
      }

      if (essenLearnMap[el.name] == null) {
        essenLearnMap[el.name] = {};
      }
      if (essenLearnMap[el.name][el.essentialLearning] == null) {
        essenLearnMap[el.name][el.essentialLearning] = {};
      }
      if (essenLearnMap[el.name][el.essentialLearning].essenLearnTotal != null) {
        if (parsedQuantitativeEval != null) {
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal += parsedQuantitativeEval;
        } else {
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal += el.essentialLearningEval;
        }
        essenLearnMap[el.name][el.essentialLearning].totalEvals += 1;
      } else {
        if (parsedQuantitativeEval != null) {
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal = parsedQuantitativeEval;
        } else {
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal = el.essentialLearningEval;
        }
        essenLearnMap[el.name][el.essentialLearning].totalEvals = 1;
      }
    }

    for (let w = 0; w < data.length; w++) {
      let el = data[w];
      for (const studName in essenLearnMap) {
        if (essenLearnMap.hasOwnProperty(studName)) {
          let essLearn = essenLearnMap[studName];
          if (el.name == studName && essLearn[el.essentialLearning] != null) {
            /* if (el.qualitativeLevels != null && Object.keys(el.qualitativeLevels).length > 0) {
              if (essLearn.hasOwnProperty(el.essentialLearning)) {
                for (let m = 0; m < essLearn[el.essentialLearning].length; m++) {
                  let qualEval = essLearn[el.essentialLearning][m];
                  if (el.essenLearnAvg == null) {
                    el.essenLearnAvg = qualEval.essentialLearningEval + " (" + qualEval.totalEvals + ")";
                  } else {
                    el.essenLearnAvg += ", " + qualEval.essentialLearningEval + " (" + qualEval.totalEvals + ")"
                  }
                  if (!el.qualitativeTotalEvals) {
                    el.qualitativeTotalEvals = [];
                  }
                  for (let w = 0; w < qualEval.totalEvals; w++) {
                    el.qualitativeTotalEvals.push(qualEval.essentialLearningEval);
                  }
                }
              }
            } else {
              if (finalEvalFlag) {
                el.essenLearnAvg = Math.round((essLearn[el.essentialLearning].essenLearnTotal / essLearn[el.essentialLearning].totalEvals) * 100) / 100;
              } else {
                el.essenLearnAvg = Math.round((essLearn[el.essentialLearning].essenLearnTotal / essLearn[el.essentialLearning].totalEvals));
              }
            } */
            if (essLearn[el.essentialLearning].essenLearnTotal != null) {
              if (finalEvalFlag) {
                el.essenLearnAvg =
                  Math.round(
                    (essLearn[el.essentialLearning].essenLearnTotal / essLearn[el.essentialLearning].totalEvals) * 100
                  ) / 100;
              } else {
                el.essenLearnAvg = Math.round(
                  essLearn[el.essentialLearning].essenLearnTotal / essLearn[el.essentialLearning].totalEvals
                );
              }
            }
          }
        }
      }
    }

    return data;
  },
  ptEvalInstrumentByStudentSimplified: function ptEvalInstrumentByStudentSimplified(scope, data, reportConfig) {
    let evalInstrumentQualitativeLevels = scope
      .getFromTableData("Class_Plan_Eval_Qualitative_Levels")
      .filter(
        (el) =>
          el.year == scope.selectedRow.year &&
          el.schoolYear.indexOf(scope.selectedRow.schoolYear) != -1 &&
          el.subject.indexOf(scope.selectedRow.subject) != -1
      );

    let selectedRowEvalInstrumentQualitativeLevels;
    // Get eval instrument's rubrica
    if (scope.selectedRow.evalInstrumentQualitativeLevels != null) {
      selectedRowEvalInstrumentQualitativeLevels = evalInstrumentQualitativeLevels.filter(
        (el) => el.id == JSON.parse(scope.selectedRow.evalInstrumentQualitativeLevels).id
      );
      if (selectedRowEvalInstrumentQualitativeLevels.length) {
        selectedRowEvalInstrumentQualitativeLevels = selectedRowEvalInstrumentQualitativeLevels[0];
      }
    }
    // Set that as the module selected row
    let finalEvalFlag = false;
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (finalEvalFlag && el.excludedFinalEvalFlag && el.excludedFinalEvalFlag.length > 0) {
        data.splice(i, 1);
        continue;
      }

      if (el.studentEvaluations) {
        for (let k = 0; k < el.studentEvaluations.length; k++) {
          let studEval = el.studentEvaluations[k];

          if (studEval.studEvalExcludedFinalEvalFlag && studEval.studEvalExcludedFinalEvalFlag.length > 0) {
            continue;
          }
          // Get evals when the instrument has no questions
          if (studEval.essentialLearningEvaluation != null && studEval.essentialLearningEvaluation.length > 0) {
            if (studEval.essentialLearningEvaluation && studEval.qualitativeEvalMap == null) {
              for (let w = 0; w < studEval.essentialLearningEvaluation.length; w++) {
                let newElement = JSON.parse(JSON.stringify(el));
                let essenLearn = studEval.essentialLearningEvaluation[w];

                newElement.essentialLearning = essenLearn.essentialLearning;
                if (essenLearn.evaluation == null) {
                  continue;
                }
                newElement.essentialLearningEval = essenLearn.evaluation;
                newElement.name = studEval.studentID;
                if (studEval.student) {
                  newElement.classOrder = studEval.student.classOrder;
                } else {
                  newElement.classOrder = studEval.studentClassOrder;
                }
                newElement.feedbackStud = studEval.feedbackStud;
                newElement.splitFlag = true;
                newElement.finalEssentialLearningEvalFlag = true;
                prepareNewElementToBePushed(newElement);
                data.push(newElement);
              }
            }
          }
          // Get evals from questions
          let studEvalWithQuantitativeEvals = false;
          if (
            studEval.essentialLearningQuestionEvaluation != null &&
            studEval.essentialLearningQuestionEvaluation.length > 0
          ) {
            studEval.essentialLearningQuestionEvaluation.forEach((questionEval) => {
              if (questionEval.evaluation != null) {
                let newElement = JSON.parse(JSON.stringify(el));
                newElement.question = questionEval.question;
                if (
                  el.correctionByEssenLearnWeightcheckbox &&
                  el.correctionByEssenLearnWeightcheckbox[""] &&
                  el.correctionByEssenLearnWeightcheckbox[""] == true &&
                  questionEval.weight
                ) {
                  newElement.evaluation = questionEval.evaluation + "/" + questionEval.weight;
                } else {
                  newElement.evaluation = questionEval.evaluation;
                }
                if (questionEval.evaluationQualitativeLevel) {
                  /* newElement.evaluation += " (" + questionEval.evaluationQualitativeLevel + ")"; */
                  newElement.evaluation = questionEval.evaluationQualitativeLevel;
                }
                if (
                  selectedRowEvalInstrumentQualitativeLevels &&
                  selectedRowEvalInstrumentQualitativeLevels.performanceCritDesc &&
                  questionEval.performanceCrit &&
                  questionEval.evaluationQualitativeLevel
                ) {
                  selectedRowEvalInstrumentQualitativeLevels.performanceCritDesc.forEach((performanceCritDesc) => {
                    if (
                      performanceCritDesc.performanceCrit == questionEval.performanceCrit &&
                      performanceCritDesc.qualitativeLevel == questionEval.evaluationQualitativeLevel
                    ) {
                      newElement.performanceDesc = performanceCritDesc.performanceDesc;
                    }
                  });
                }
                newElement.essentialLearning = questionEval.essentialLearning;
                newElement.performanceCrit = questionEval.performanceCrit;
                newElement.name = studEval.studentID;
                if (studEval.student) {
                  newElement.classOrder = studEval.student.classOrder;
                } else {
                  newElement.classOrder = studEval.studentClassOrder;
                }
                newElement.feedbackStud = studEval.feedbackStud;
                newElement.questionEvalFlag = true;
                newElement.splitFlag = true;
                prepareNewElementToBePushed(newElement);
                data.push(newElement);
              }
            });
            // Get essential learning instrument evals
            for (const essenLearn in studEval.essenLearnFinalEvalMap) {
              if (studEval.essenLearnFinalEvalMap.hasOwnProperty(essenLearn)) {
                const essenLearnEval = studEval.essenLearnFinalEvalMap[essenLearn];
                let newElement = JSON.parse(JSON.stringify(el));

                newElement.essentialLearning = essenLearnEval.essentialLearning;
                if (essenLearnEval.evaluation == null) {
                  continue;
                }
                studEvalWithQuantitativeEvals = true;
                if (finalEvalFlag) {
                  newElement.essentialLearningEval = Math.round(essenLearnEval.evaluation * 100) / 100;
                } else {
                  newElement.essentialLearningEval = Math.round(essenLearnEval.evaluation);
                }
                newElement.name = studEval.studentID;
                if (studEval.student) {
                  newElement.classOrder = studEval.student.classOrder;
                } else {
                  newElement.classOrder = studEval.studentClassOrder;
                }
                newElement.feedbackStud = studEval.feedbackStud;
                newElement.splitFlag = true;
                newElement.finalEssentialLearningEvalFlag = true;
                prepareNewElementToBePushed(newElement);
                data.push(newElement);
              }
            }
          }
          // Get qualitative evals
          if (studEval.qualitativeEvalMap != null && !studEvalWithQuantitativeEvals) {
            for (const essenLearn in studEval.qualitativeEvalMap) {
              if (studEval.qualitativeEvalMap.hasOwnProperty(essenLearn)) {
                let essenLearnEvals = studEval.qualitativeEvalMap[essenLearn];
                if (
                  essenLearnEvals == null ||
                  (studEval.completeQualitativeEvals != null && studEval.completeQualitativeEvals == false)
                ) {
                  continue;
                }
                if (finalEvalFlag) {
                  essenLearnEvals.map(function (essenLearnEval) {
                    let newElement = JSON.parse(JSON.stringify(el));
                    newElement.essentialLearningEval = essenLearnEval.essentialLearningEval;
                    newElement.essentialLearning = essenLearn;
                    newElement.name = studEval.studentID;
                    if (studEval.student) {
                      newElement.classOrder = studEval.student.classOrder;
                    } else {
                      newElement.classOrder = studEval.studentClassOrder;
                    }
                    newElement.feedbackStud = studEval.feedbackStud;
                    newElement.splitFlag = true;
                    prepareNewElementToBePushed(newElement);
                    data.push(newElement);
                  });
                } else {
                  let newElement = JSON.parse(JSON.stringify(el));
                  newElement.essentialLearningEval = "";
                  essenLearnEvals.map(function (essenLearnEval) {
                    if (newElement.essentialLearningEval == "") {
                      newElement.essentialLearningEval += essenLearnEval.essentialLearningEval;
                    } else {
                      newElement.essentialLearningEval += ", " + essenLearnEval.essentialLearningEval;
                    }
                  });
                  newElement.essentialLearning = essenLearn;
                  newElement.name = studEval.studentID;
                  if (studEval.student) {
                    newElement.classOrder = studEval.student.classOrder;
                  } else {
                    newElement.classOrder = studEval.studentClassOrder;
                  }
                  newElement.feedbackStud = studEval.feedbackStud;
                  newElement.splitFlag = true;
                  newElement.finalEssentialLearningEvalFlag = true;
                  prepareNewElementToBePushed(newElement);
                  data.push(newElement);
                }
              }
            }
          }
        }
      }

      data.splice(0, 1);
    }

    // Parse and clean new element to be pushed
    function prepareNewElementToBePushed(element) {
      if (element.name != null) {
        element.studentID = JSON.parse(JSON.stringify(element.name));
        element.name = scope.parseValue(element.name, "name", "Class_Plan_Students");
      }
      if (element.essentialLearning != null) {
        if (element.module == null) {
          element.essentialLearning = scope.parseValue(
            element.essentialLearning,
            "essentialLearning",
            "Class_Plan_Essential_Learnings"
          );
        } else {
          element.essentialLearning = scope.parseValue(
            element.essentialLearning,
            "essentialLearning",
            "Class_Plan_Module_Essential_Learnings"
          );
        }
      }
      if (element.performanceCrit != null) {
        element.performanceCrit = scope.parseValue(
          element.performanceCrit,
          "performanceCrit",
          "Class_Plan_Performance_Crits"
        );
      }
      if (element.studentEvaluations != null) {
        delete element.studentEvaluations;
      }
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    let essenLearnMap = {};

    for (let j = 0; j < data.length; j++) {
      let el = data[j];

      //Parse qualitative eval if necessary
      let parsedQuantitativeEval;
      if (typeof el.essentialLearningEval == "string" && el.evalInstrumentQualitativeLevels) {
        let parsedEvalInstrumentQualitativeLevels = JSON.parse(el.evalInstrumentQualitativeLevels);
        if (
          parsedEvalInstrumentQualitativeLevels.qualitativeLevels &&
          Array.isArray(parsedEvalInstrumentQualitativeLevels.qualitativeLevels) &&
          parsedEvalInstrumentQualitativeLevels.qualitativeLevels.length > 0
        ) {
          let quantitativeEval = classPlanGetQuantitativeEvalsFromQualitative(
            new Array(el.essentialLearningEval),
            parsedEvalInstrumentQualitativeLevels.qualitativeLevels
          );
          if (!isNaN(Number(quantitativeEval))) {
            parsedQuantitativeEval = quantitativeEval;
          } else {
            continue;
          }
        } else {
          continue;
        }
      }

      if (essenLearnMap[el.name] == null) {
        essenLearnMap[el.name] = {};
      }
      if (essenLearnMap[el.name][el.essentialLearning] == null) {
        essenLearnMap[el.name][el.essentialLearning] = {};
      }
      if (essenLearnMap[el.name][el.essentialLearning].essenLearnTotal != null) {
        if (parsedQuantitativeEval != null) {
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal += parsedQuantitativeEval;
        } else {
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal += el.essentialLearningEval;
        }
        essenLearnMap[el.name][el.essentialLearning].totalEvals += 1;
      } else {
        if (parsedQuantitativeEval != null) {
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal = parsedQuantitativeEval;
        } else {
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal = el.essentialLearningEval;
        }
        essenLearnMap[el.name][el.essentialLearning].totalEvals = 1;
      }
    }

    for (let w = 0; w < data.length; w++) {
      let el = data[w];
      for (const studName in essenLearnMap) {
        if (essenLearnMap.hasOwnProperty(studName)) {
          let essLearn = essenLearnMap[studName];
          if (el.name == studName && essLearn[el.essentialLearning] != null) {
            if (essLearn[el.essentialLearning].essenLearnTotal != null) {
              if (finalEvalFlag) {
                el.essenLearnAvg =
                  Math.round(
                    (essLearn[el.essentialLearning].essenLearnTotal / essLearn[el.essentialLearning].totalEvals) * 100
                  ) / 100;
              } else {
                el.essenLearnAvg = Math.round(
                  essLearn[el.essentialLearning].essenLearnTotal / essLearn[el.essentialLearning].totalEvals
                );
                el.essentialLearningEvalLevel = ptConvertEvalToFinalEvalLevel(el, "essentialLearningEval");
              }
            }
          }
        }
      }
    }
    if (scope.selectedRow.module == null) {
      scope.genericFactory.setRouteName("Class_Plan_School_Records");
    } else {
      scope.genericFactory.setRouteName("Class_Plan_Prof_School_Records");
    }

    data = genericReportExport(data, scope, reportConfig);
    data = parseData(scope, data);

    return data;
  },
  ptEvalInstrumentByStudent: function ptEvalInstrumentByStudent(
    scope,
    functionArgs,
    data,
    slRow,
    sendCommunicationMode
  ) {
    let evalInstrumentEvaluationCrit;
    if (scope.deps.indexOf("Class_Plan_Evaluation_Crits") != -1) {
      evalInstrumentEvaluationCrit = scope
        .getFromTableData("Class_Plan_Evaluation_Crits")
        .filter(
          (el) =>
            el.id ==
            scope
              .getFromTableData("Class_Plan_Evaluation_Instruments")
              .filter((el2) => el2.id == scope.selectedRow.evalInstrument)[0].evalCritGridDesc
        );
      if (evalInstrumentEvaluationCrit != null && evalInstrumentEvaluationCrit.length > 0) {
        evalInstrumentEvaluationCrit = evalInstrumentEvaluationCrit[0];
      }
    } else {
      evalInstrumentEvaluationCrit = scope
        .getFromTableData("Class_Plan_Prof_Evaluation_Crits")
        .filter(
          (el) =>
            el.id ==
            scope
              .getFromTableData("Class_Plan_Prof_Evaluation_Instruments")
              .filter((el2) => el2.id == scope.selectedRow.evalInstrument)[0].evalCritGridDesc
        );
      if (evalInstrumentEvaluationCrit != null && evalInstrumentEvaluationCrit.length > 0) {
        evalInstrumentEvaluationCrit = evalInstrumentEvaluationCrit[0];
      }
    }

    let evalInstrumentQualitativeLevels = scope
      .getFromTableData("Class_Plan_Eval_Qualitative_Levels")
      .filter(
        (el) =>
          el.year == scope.selectedRow.year &&
          el.schoolYear.indexOf(scope.selectedRow.schoolYear) != -1 &&
          el.subject.indexOf(scope.selectedRow.subject) != -1
      );
    let selectedRowEvalInstrumentQualitativeLevels;
    // Get eval instrument's rubrica
    if (scope.selectedRow.evalInstrumentQualitativeLevels != null) {
      selectedRowEvalInstrumentQualitativeLevels = evalInstrumentQualitativeLevels.filter(
        (el) => el.id == JSON.parse(scope.selectedRow.evalInstrumentQualitativeLevels).id
      );
      if (selectedRowEvalInstrumentQualitativeLevels.length) {
        selectedRowEvalInstrumentQualitativeLevels = selectedRowEvalInstrumentQualitativeLevels[0];
      }
    }
    // Set that as the module selected row
    data = [scope.selectedRow];
    let finalEvalFlag = false;
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (finalEvalFlag && el.excludedFinalEvalFlag && el.excludedFinalEvalFlag.length > 0) {
        data.splice(i, 1);
        continue;
      }

      if (el.studentEvaluations) {
        for (let k = 0; k < el.studentEvaluations.length; k++) {
          let studEval = el.studentEvaluations[k];
          let evalInsertedFlag = false;

          if (studEval.studEvalExcludedFinalEvalFlag && studEval.studEvalExcludedFinalEvalFlag.length > 0) {
            continue;
          }
          // Get evals when the instrument has no questions
          if (studEval.essentialLearningEvaluation != null && studEval.essentialLearningEvaluation.length > 0) {
            if (studEval.essentialLearningEvaluation && studEval.qualitativeEvalMap == null) {
              for (let w = 0; w < studEval.essentialLearningEvaluation.length; w++) {
                let newElement = JSON.parse(JSON.stringify(el));
                let essenLearn = studEval.essentialLearningEvaluation[w];

                newElement.essentialLearning = essenLearn.essentialLearning;
                if (essenLearn.evaluation == null) {
                  continue;
                }
                newElement.essentialLearningEval = essenLearn.evaluation;
                newElement.name = studEval.studentID;
                if (studEval.student) {
                  newElement.classOrder = studEval.student.classOrder;
                } else {
                  newElement.classOrder = studEval.studentClassOrder;
                }
                newElement.feedbackStud = studEval.feedbackStud;
                newElement.splitFlag = true;
                newElement.finalEssentialLearningEvalFlag = true;
                prepareNewElementToBePushed(newElement);
                evalInsertedFlag = true;
                data.push(newElement);
              }
            }
          }
          // Get evals from questions
          let studEvalWithQuantitativeEvals = false;
          if (
            studEval.essentialLearningQuestionEvaluation != null &&
            studEval.essentialLearningQuestionEvaluation.length > 0
          ) {
            studEval.essentialLearningQuestionEvaluation.forEach((questionEval) => {
              if (questionEval.evaluation != null) {
                let newElement = JSON.parse(JSON.stringify(el));
                newElement.question = questionEval.question;
                if (
                  el.correctionByEssenLearnWeightcheckbox &&
                  el.correctionByEssenLearnWeightcheckbox[""] &&
                  el.correctionByEssenLearnWeightcheckbox[""] == true &&
                  questionEval.weight
                ) {
                  newElement.evaluation = questionEval.evaluation + "/" + questionEval.weight;
                } else {
                  newElement.evaluation = questionEval.evaluation;
                }
                if (questionEval.evaluationQualitativeLevel) {
                  newElement.evaluation += " (" + questionEval.evaluationQualitativeLevel + ")";
                }
                if (
                  selectedRowEvalInstrumentQualitativeLevels &&
                  selectedRowEvalInstrumentQualitativeLevels.performanceCritDesc &&
                  questionEval.performanceCrit &&
                  questionEval.evaluationQualitativeLevel
                ) {
                  selectedRowEvalInstrumentQualitativeLevels.performanceCritDesc.forEach((performanceCritDesc) => {
                    if (
                      performanceCritDesc.performanceCrit == questionEval.performanceCrit &&
                      performanceCritDesc.qualitativeLevel == questionEval.evaluationQualitativeLevel
                    ) {
                      newElement.performanceDesc = performanceCritDesc.performanceDesc;
                    }
                  });
                }
                newElement.essentialLearning = questionEval.essentialLearning;
                newElement.performanceCrit = questionEval.performanceCrit;
                newElement.name = studEval.studentID;
                if (studEval.student) {
                  newElement.classOrder = studEval.student.classOrder;
                } else {
                  newElement.classOrder = studEval.studentClassOrder;
                }
                newElement.feedbackStud = studEval.feedbackStud;
                newElement.questionEvalFlag = true;
                newElement.splitFlag = true;
                prepareNewElementToBePushed(newElement);
                evalInsertedFlag = true;
                data.push(newElement);
              }
            });
            // Get essential learning instrument evals
            for (const essenLearn in studEval.essenLearnFinalEvalMap) {
              if (studEval.essenLearnFinalEvalMap.hasOwnProperty(essenLearn)) {
                const essenLearnEval = studEval.essenLearnFinalEvalMap[essenLearn];
                let newElement = JSON.parse(JSON.stringify(el));

                newElement.essentialLearning = essenLearnEval.essentialLearning;
                if (essenLearnEval.evaluation == null) {
                  continue;
                }
                studEvalWithQuantitativeEvals = true;
                if (finalEvalFlag) {
                  newElement.essentialLearningEval = Math.round(essenLearnEval.evaluation * 100) / 100;
                } else {
                  newElement.essentialLearningEval = Math.round(essenLearnEval.evaluation);
                }
                newElement.name = studEval.studentID;
                if (studEval.student) {
                  newElement.classOrder = studEval.student.classOrder;
                } else {
                  newElement.classOrder = studEval.studentClassOrder;
                }
                newElement.feedbackStud = studEval.feedbackStud;
                newElement.splitFlag = true;
                newElement.finalEssentialLearningEvalFlag = true;
                prepareNewElementToBePushed(newElement);
                evalInsertedFlag = true;
                data.push(newElement);
              }
            }
          }
          // Get qualitative evals
          if (studEval.qualitativeEvalMap != null && !studEvalWithQuantitativeEvals) {
            for (const essenLearn in studEval.qualitativeEvalMap) {
              if (studEval.qualitativeEvalMap.hasOwnProperty(essenLearn)) {
                let essenLearnEvals = studEval.qualitativeEvalMap[essenLearn];
                if (
                  essenLearnEvals == null ||
                  (studEval.completeQualitativeEvals != null && studEval.completeQualitativeEvals == false)
                ) {
                  continue;
                }
                if (finalEvalFlag) {
                  essenLearnEvals.map(function (essenLearnEval) {
                    let newElement = JSON.parse(JSON.stringify(el));
                    newElement.essentialLearningEval = essenLearnEval.essentialLearningEval;
                    newElement.essentialLearning = essenLearn;
                    newElement.name = studEval.studentID;
                    if (studEval.student) {
                      newElement.classOrder = studEval.student.classOrder;
                    } else {
                      newElement.classOrder = studEval.studentClassOrder;
                    }
                    newElement.feedbackStud = studEval.feedbackStud;
                    newElement.splitFlag = true;
                    prepareNewElementToBePushed(newElement);
                    evalInsertedFlag = true;
                    data.push(newElement);
                  });
                } else {
                  let newElement = JSON.parse(JSON.stringify(el));
                  newElement.essentialLearningEval = "";
                  essenLearnEvals.map(function (essenLearnEval) {
                    if (newElement.essentialLearningEval == "") {
                      newElement.essentialLearningEval += essenLearnEval.essentialLearningEval;
                    } else {
                      newElement.essentialLearningEval += ", " + essenLearnEval.essentialLearningEval;
                    }
                  });
                  newElement.essentialLearning = essenLearn;
                  newElement.name = studEval.studentID;
                  if (studEval.student) {
                    newElement.classOrder = studEval.student.classOrder;
                  } else {
                    newElement.classOrder = studEval.studentClassOrder;
                  }
                  newElement.feedbackStud = studEval.feedbackStud;
                  newElement.splitFlag = true;
                  newElement.finalEssentialLearningEvalFlag = true;
                  prepareNewElementToBePushed(newElement);
                  evalInsertedFlag = true;
                  data.push(newElement);
                }
              }
            }
          }
          // Insert eval instrument final eval
          if (evalInsertedFlag) {
            let newElement = JSON.parse(JSON.stringify(el));
            newElement.essentialLearning = null;
            newElement.name = studEval.studentID;
            if (studEval.student) {
              newElement.classOrder = studEval.student.classOrder;
            } else {
              newElement.classOrder = studEval.studentClassOrder;
            }
            newElement.splitFlag = true;
            newElement.finalEvalInstrumentEvalFlag = true;
            prepareNewElementToBePushed(newElement);
            data.push(newElement);
          }
        }
      }

      data.splice(0, 1);
    }

    // Parse and clean new element to be pushed
    function prepareNewElementToBePushed(element) {
      if (element.name != null) {
        // Get students from the kstk students collection in case of municipalities
        if (scope.currentUser.organization == "60006e108b7f480098c8234c") {
          element.studentID = JSON.parse(JSON.stringify(element.name));
          element.name = scope.parseValue(element.name, "name", "KSTK_Students");
        } else {
          element.studentID = JSON.parse(JSON.stringify(element.name));
          element.name = scope.parseValue(element.name, "name", "Class_Plan_Students");
        }
      }
      if (element.essentialLearning != null) {
        if (element.module == null) {
          element.essentialLearningId = JSON.parse(JSON.stringify(element.essentialLearning));
          element.essentialLearning = scope.parseValue(
            element.essentialLearning,
            "essentialLearning",
            "Class_Plan_Essential_Learnings"
          );
        } else {
          element.essentialLearningId = JSON.parse(JSON.stringify(element.essentialLearning));
          element.parsedModule = scope.parseValue(element.module, "module", "Class_Plan_Prof_Modules");
          element.essentialLearning = scope.parseValue(
            element.essentialLearning,
            "essentialLearning",
            "Class_Plan_Module_Essential_Learnings"
          );
        }
      }
      if (element.performanceCrit != null) {
        element.performanceCrit = scope.parseValue(
          element.performanceCrit,
          "performanceCrit",
          "Class_Plan_Performance_Crits"
        );
      }
      if (element.studentEvaluations != null) {
        delete element.studentEvaluations;
      }
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    let essenLearnMap = {};

    for (let j = 0; j < data.length; j++) {
      let el = data[j];

      //Parse qualitative eval if necessary
      let parsedQuantitativeEval;
      if (typeof el.essentialLearningEval == "string" && el.evalInstrumentQualitativeLevels) {
        let parsedEvalInstrumentQualitativeLevels = JSON.parse(el.evalInstrumentQualitativeLevels);
        if (
          parsedEvalInstrumentQualitativeLevels.qualitativeLevels &&
          Array.isArray(parsedEvalInstrumentQualitativeLevels.qualitativeLevels) &&
          parsedEvalInstrumentQualitativeLevels.qualitativeLevels.length > 0
        ) {
          let quantitativeEval = classPlanGetQuantitativeEvalsFromQualitative(
            new Array(el.essentialLearningEval),
            parsedEvalInstrumentQualitativeLevels.qualitativeLevels
          );
          if (!isNaN(Number(quantitativeEval))) {
            parsedQuantitativeEval = quantitativeEval;
          } else {
            continue;
          }
        } else {
          continue;
        }
      }

      if (essenLearnMap[el.name] == null) {
        essenLearnMap[el.name] = {};
      }
      if (essenLearnMap[el.name][el.essentialLearning] == null) {
        essenLearnMap[el.name][el.essentialLearning] = {};
        essenLearnMap[el.name][el.essentialLearning].id = el.essentialLearningId;
      }
      if (essenLearnMap[el.name][el.essentialLearning].essenLearnTotal != null) {
        if (parsedQuantitativeEval != null) {
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal += parsedQuantitativeEval;
        } else {
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal += el.essentialLearningEval;
        }
        essenLearnMap[el.name][el.essentialLearning].totalEvals += 1;
      } else {
        if (parsedQuantitativeEval != null) {
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal = parsedQuantitativeEval;
        } else {
          essenLearnMap[el.name][el.essentialLearning].essenLearnTotal = el.essentialLearningEval;
        }
        essenLearnMap[el.name][el.essentialLearning].totalEvals = 1;
      }
    }

    for (let w = 0; w < data.length; w++) {
      let el = data[w];

      // Get final instrument eval
      if (
        evalInstrumentEvaluationCrit != null &&
        evalInstrumentEvaluationCrit.essentialLearningDomainWeight != null &&
        el.finalEvalInstrumentEvalFlag
      ) {
        // Prepare object to get final instrument eval through existing ptGetAEEvalLevel function

        let obj = {};
        obj.studentFinalCritEvaluations = [];
        let essenLearnObj = { studentFinalEssenLearnCritEvals: [] };

        evalInstrumentEvaluationCrit.essentialLearningDomainWeight.forEach((essenLearn) => {
          let essenLearnAuxObj = {};

          for (const essenLearnName in essenLearnMap[el.name]) {
            if (Object.hasOwnProperty.call(essenLearnMap[el.name], essenLearnName)) {
              let essenLearnInfo = essenLearnMap[el.name][essenLearnName];
              if (essenLearnInfo.id == essenLearn.essentialLearning) {
                essenLearnAuxObj.essentialLearningAvg = essenLearnInfo.essenLearnAvg;
              }
            }
          }
          essenLearnAuxObj.essentialLearningWeight = essenLearn.essentialLearningWeight;
          essenLearnObj.studentFinalEssenLearnCritEvals.push(essenLearnAuxObj);
        });

        obj.studentFinalCritEvaluations.push(essenLearnObj);

        /* essentialLearningAvg
        essentialLearningWeight
        (); */

        el.finalEvalInstrumentEval = ptGetAEEvalLevel(obj, 0);
      }

      for (const studName in essenLearnMap) {
        if (essenLearnMap.hasOwnProperty(studName)) {
          let essLearn = essenLearnMap[studName];
          if (el.name == studName && essLearn[el.essentialLearning] != null) {
            if (essLearn[el.essentialLearning].essenLearnTotal != null) {
              if (finalEvalFlag) {
                el.essenLearnAvg =
                  Math.round(
                    (essLearn[el.essentialLearning].essenLearnTotal / essLearn[el.essentialLearning].totalEvals) * 100
                  ) / 100;
              } else {
                el.essenLearnAvg = Math.round(
                  essLearn[el.essentialLearning].essenLearnTotal / essLearn[el.essentialLearning].totalEvals
                );
                essenLearnMap[studName][el.essentialLearning].essenLearnAvg = el.essenLearnAvg;
                el.essentialLearningEvalLevel = ptConvertEvalToFinalEvalLevel(el, "essentialLearningEval");
              }
            }
          }
        }
      }
    }
    if (scope.selectedRow.module == null) {
      scope.genericFactory.setRouteName("Class_Plan_School_Records");
    } else {
      scope.genericFactory.setRouteName("Class_Plan_Prof_School_Records");
    }
    // Add header template with logo
    if (scope.parentScope.$parent.clientHasOrganizationLogo) {
      functionArgs.headerTemplate = "portraitHeaderOrgLogo";
      functionArgs.marginTop = "120px";
    }
    if (sendCommunicationMode != null && sendCommunicationMode) {
      return data;
    } else {
      exportReport(scope, scope.$mdPanel, functionArgs, true, data);
    }
  },
  ptSplitProfAESchoolRecord: function ptSplitProfAESchoolRecord(scope, data, finalEvalFlag) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (finalEvalFlag && el.excludedFinalEvalFlag && el.excludedFinalEvalFlag.length > 0) {
        data.splice(i, 1);
        continue;
      }

      if (el.studentEvaluations) {
        for (let k = 0; k < el.studentEvaluations.length; k++) {
          let studEval = el.studentEvaluations[k];

          if (studEval.studEvalExcludedFinalEvalFlag && studEval.studEvalExcludedFinalEvalFlag.length > 0) {
            continue;
          }

          if (studEval.essentialLearningEvaluation != null && studEval.essentialLearningEvaluation.length > 0) {
            if (studEval.essentialLearningEvaluation && studEval.qualitativeEvalMap == null) {
              for (let w = 0; w < studEval.essentialLearningEvaluation.length; w++) {
                let newElement = JSON.parse(JSON.stringify(el));
                let essenLearn = studEval.essentialLearningEvaluation[w];

                newElement.essentialLearning = essenLearn.essentialLearning;
                if (essenLearn.evaluation == null) {
                  continue;
                }
                newElement.essentialLearningEval = essenLearn.evaluation;
                newElement.name = studEval.studentID;
                newElement.student = studEval.student;
                newElement.splitFlag = true;
                cleanNewElement(newElement);
                data.push(newElement);
              }
            }
          }
          if (
            studEval.essentialLearningQuestionEvaluation != null &&
            studEval.essentialLearningQuestionEvaluation.length > 0 &&
            studEval.qualitativeEvalMap == null
          ) {
            for (const essenLearn in studEval.essenLearnFinalEvalMap) {
              if (studEval.essenLearnFinalEvalMap.hasOwnProperty(essenLearn)) {
                const essenLearnEval = studEval.essenLearnFinalEvalMap[essenLearn];
                let newElement = JSON.parse(JSON.stringify(el));

                newElement.essentialLearning = essenLearnEval.essentialLearning;
                if (essenLearnEval.evaluation == null) {
                  continue;
                }
                if (finalEvalFlag) {
                  newElement.essentialLearningEval = Math.round(essenLearnEval.evaluation * 100) / 100;
                } else {
                  newElement.essentialLearningEval = Math.round(essenLearnEval.evaluation);
                }
                newElement.name = studEval.studentID;
                newElement.student = studEval.student;
                newElement.splitFlag = true;
                cleanNewElement(newElement);
                data.push(newElement);
              }
            }
          }
        }
      }

      data.splice(0, 1);
    }

    function cleanNewElement(element) {
      if (element.studentEvaluations != null) {
        delete element.studentEvaluations;
      }
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    let essenLearnMap = {};

    for (let j = 0; j < data.length; j++) {
      let el = data[j];
      if (essenLearnMap[el.name] == null) {
        essenLearnMap[el.name] = {};
        essenLearnMap[el.name][el.module + el.essentialLearning] = {};
        essenLearnMap[el.name][el.module + el.essentialLearning].essenLearnTotal = el.essentialLearningEval;
        essenLearnMap[el.name][el.module + el.essentialLearning].totalEvals = 1;
      } else {
        if (essenLearnMap[el.name][el.module + el.essentialLearning] == null) {
          essenLearnMap[el.name][el.module + el.essentialLearning] = {};
          essenLearnMap[el.name][el.module + el.essentialLearning].essenLearnTotal = el.essentialLearningEval;
          essenLearnMap[el.name][el.module + el.essentialLearning].totalEvals = 1;
        } else {
          essenLearnMap[el.name][el.module + el.essentialLearning].essenLearnTotal += el.essentialLearningEval;
          essenLearnMap[el.name][el.module + el.essentialLearning].totalEvals += 1;
        }
      }
    }

    for (let w = 0; w < data.length; w++) {
      let el = data[w];
      for (const studName in essenLearnMap) {
        if (essenLearnMap.hasOwnProperty(studName)) {
          let essLearn = essenLearnMap[studName];
          if (el.name == studName && essLearn[el.module + el.essentialLearning] != null) {
            if (el.qualitativeLevels != null && Object.keys(el.qualitativeLevels).length > 0) {
              for (const essLearnQual in essLearn) {
                if (essLearn.hasOwnProperty(essLearnQual)) {
                  let essLearnQualEvals = essLearn[essLearnQual];
                  for (let m = 0; m < essLearnQualEvals.length; m++) {
                    let qualEval = essLearnQualEvals[m];
                    if (el.essenLearnAvg == null) {
                      el.essenLearnAvg = qualEval.essentialLearningEval + " (" + qualEval.totalEvals + ")";
                    } else {
                      el.essenLearnAvg += ", " + qualEval.essentialLearningEval + " (" + qualEval.totalEvals + ")";
                    }
                  }
                }
              }
            } else {
              if (finalEvalFlag) {
                el.essenLearnAvg =
                  Math.round(
                    (essLearn[el.module + el.essentialLearning].essenLearnTotal /
                      essLearn[el.module + el.essentialLearning].totalEvals) *
                      100
                  ) / 100;
              } else {
                el.essenLearnAvg = Math.round(
                  essLearn[el.module + el.essentialLearning].essenLearnTotal /
                    essLearn[el.module + el.essentialLearning].totalEvals
                );
              }
            }
          }
        }
      }
    }

    return data;
  },
  ptSplitAttSchoolRecord: function ptSplitAttSchoolRecord(scope, data, finalEvalFlag) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (finalEvalFlag && el.excludedFinalEvalFlag && el.excludedFinalEvalFlag.length > 0) {
        data.splice(i, 1);
        continue;
      }

      if (el.studentEvaluations) {
        for (let k = 0; k < el.studentEvaluations.length; k++) {
          let studEval = el.studentEvaluations[k];

          if (studEval.studEvalExcludedFinalEvalFlag && studEval.studEvalExcludedFinalEvalFlag.length > 0) {
            continue;
          }
          if (studEval.attitudesEvaluation != null && studEval.attitudesEvaluation.length > 0) {
            if (studEval.attitudesEvaluation && studEval.qualitativeEvalMap == null) {
              for (let w = 0; w < studEval.attitudesEvaluation.length; w++) {
                let newElement = JSON.parse(JSON.stringify(el));
                let attEval = studEval.attitudesEvaluation[w];

                newElement.attitudeCrit = attEval.attitudeCrit;
                if (attEval.evaluation == null) {
                  continue;
                }
                newElement.attitudeEval = attEval.evaluation;
                newElement.name = studEval.studentID;
                newElement.student = studEval.student;
                newElement.splitFlag = true;
                data.push(newElement);
              }
            }
          }
        }
      }

      data.splice(0, 1);
    }

    let attitudeEvalMap = {};

    for (let j = 0; j < data.length; j++) {
      let el = data[j];
      if (el.attitudeCrit && el.attitudeEval) {
        if (attitudeEvalMap[el.name] == null) {
          attitudeEvalMap[el.name] = {};
          attitudeEvalMap[el.name][el.attitudeCrit] = {};
          attitudeEvalMap[el.name][el.attitudeCrit].attTotal = el.attitudeEval;
          attitudeEvalMap[el.name][el.attitudeCrit].totalEvals = 1;
        } else {
          if (attitudeEvalMap[el.name][el.attitudeCrit] == null) {
            attitudeEvalMap[el.name][el.attitudeCrit] = {};
            attitudeEvalMap[el.name][el.attitudeCrit].attTotal = el.attitudeEval;
            attitudeEvalMap[el.name][el.attitudeCrit].totalEvals = 1;
          } else {
            attitudeEvalMap[el.name][el.attitudeCrit].attTotal += el.attitudeEval;
            attitudeEvalMap[el.name][el.attitudeCrit].totalEvals += 1;
          }
        }
      }
    }

    for (let w = 0; w < data.length; w++) {
      let el = data[w];
      if (el.attitudeCrit != null) {
        for (const studName in attitudeEvalMap) {
          let studAttCritTotalEvals = 0;
          let studAttCritNumEvals = 0;
          if (attitudeEvalMap.hasOwnProperty(studName)) {
            let studAttCritEvals = attitudeEvalMap[studName];
            for (const attCrit in studAttCritEvals) {
              if (studAttCritEvals.hasOwnProperty(attCrit)) {
                let attCritEvals = studAttCritEvals[attCrit];
                if (el.name == studName) {
                  studAttCritTotalEvals += attCritEvals.attTotal;
                  studAttCritNumEvals += attCritEvals.totalEvals;
                }
              }
            }
            if (studAttCritTotalEvals > 0 && studAttCritNumEvals > 0) {
              el.autoCritEvalAvg = Math.round((studAttCritTotalEvals / studAttCritNumEvals) * 100) / 100;
              el.autoCritFlag = true;
              el.studAttCritEvals = studAttCritEvals;
            }
          }
        }
      }
    }

    return data;
  },
  ptSplitACSchoolRecord: function ptSplitACSchoolRecord(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.studentEvaluations) {
        for (let k = 0; k < el.studentEvaluations.length; k++) {
          let studEval = el.studentEvaluations[k];
          let studFullfilledExpFields = [];

          /* if (studEval.completeQualitativeEvals) { */
          for (let w = 0; w < studEval.expertiseFieldDesc.length; w++) {
            let newElement = JSON.parse(JSON.stringify(el));

            let expertiseDesc = studEval.expertiseFieldDesc[w];
            studFullfilledExpFields.push(expertiseDesc);

            newElement.expertiseFieldDesc = expertiseDesc;
            newElement.fulfilled = 1;
            newElement.fulfilledAggregate = "Sim";

            newElement.name = studEval.studentID;

            newElement.splitFlag = true;
            cleanNewElement(newElement);
            data.push(newElement);
          }

          for (const expField in studEval.expertiseFieldDesccheckbox) {
            if (studEval.expertiseFieldDesccheckbox.hasOwnProperty(expField)) {
              let fulfilled = studEval.expertiseFieldDesccheckbox[expField];
              if (studFullfilledExpFields.indexOf(expField) == -1) {
                let newElement = JSON.parse(JSON.stringify(el));

                let expertiseDesc = expField;
                studFullfilledExpFields.push(expertiseDesc);

                newElement.expertiseFieldDesc = expertiseDesc;
                if (fulfilled) {
                  newElement.fulfilled = 1;
                  newElement.fulfilledAggregate = "Sim";
                } else {
                  newElement.notFulfilled = 1;
                  newElement.fulfilledAggregate = "Não";
                }

                newElement.name = studEval.studentID;

                newElement.splitFlag = true;
                cleanNewElement(newElement);
                data.push(newElement);
              }
            }
          }

          for (let j = 0; j < el.loadedExpertiseDescs.length; j++) {
            let loadDesc = el.loadedExpertiseDescs[j];

            if (studFullfilledExpFields.indexOf(loadDesc) == -1) {
              let newElement = JSON.parse(JSON.stringify(el));

              newElement.expertiseFieldDesc = loadDesc;
              newElement.notFulfilled = 1;
              newElement.fulfilledAggregate = "Não";

              newElement.name = studEval.studentID;

              newElement.splitFlag = true;
              cleanNewElement(newElement);
              data.push(newElement);
            }
          }
          /* } */
        }
      }

      function cleanNewElement(element) {
        if (element.studentEvaluations != null) {
          delete element.studentEvaluations;
        }
        if (element.createdBy != null) {
          delete element.createdBy;
        }
        if (element.modifiedBy != null) {
          delete element.modifiedBy;
        }
      }

      data.splice(0, 1);
    }
  },
  ptSplitFinalEvalsSchoolRecord: function ptSplitFinalEvalsSchoolRecord(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.studentFinalCritEvaluations && el.studentFinalCritEvaluations.length > 0) {
        for (let k = 0; k < el.studentFinalCritEvaluations.length; k++) {
          let studEval = el.studentFinalCritEvaluations[k];

          let newElement = JSON.parse(JSON.stringify(el));

          newElement.name = studEval.name;
          newElement.studentID = studEval.studentID;
          if (studEval.originalClass) {
            newElement.class = studEval.originalClass;
          }
          newElement.crit = studEval.essenLearnCrit;
          newElement.student = studEval.student;
          newElement.studCritEval = true;
          newElement.studAECritEval = true;

          if (studEval.finalEssenLearnCritEvalGrade != null) {
            newElement.evalCrit = Math.round(Number(studEval.finalEssenLearnCritEvalGrade));
          } else {
            newElement.evalCrit = Math.round(Number(studEval.autoFinalEssenLearnCritEvalGrade));
          }

          if (studEval.finalGrade == null) {
            if (studEval.autoFinalGrade != null) {
              /* newElement.finalEval = studEval.autoFinalGrade; */
              newElement.splitFlag = true;
            }
          } else {
            /* newElement.finalEval = studEval.finalGrade; */
            newElement.splitFlag = true;
          }

          /* if (studEval.finalEvalLevel != null) {
            newElement.finalEvalLevel = studEval.finalEvalLevel;
          } */

          if (newElement.splitFlag) {
            if (newElement.student != null) {
              delete newElement.student;
            }
            if (newElement.studentFinalCritEvaluations != null) {
              delete newElement.studentFinalCritEvaluations;
            }
            if (newElement.subjectCriteria != null) {
              delete newElement.subjectCriteria;
            }
            if (newElement.createdBy != null) {
              delete newElement.createdBy;
            }
            if (newElement.modifiedBy != null) {
              delete newElement.modifiedBy;
            }
            data.push(newElement);

            for (let j = 0; j < studEval.studentFinalEssenLearnCritEvals.length; j++) {
              let essenLearnStudEval = studEval.studentFinalEssenLearnCritEvals[j];
              let newElementEssenLearnEval = JSON.parse(JSON.stringify(el));
              newElementEssenLearnEval.name = studEval.name;
              newElementEssenLearnEval.studentID = studEval.studentID;
              if (studEval.originalClass) {
                newElementEssenLearnEval.class = studEval.originalClass;
              }
              newElementEssenLearnEval.student = studEval.student;
              newElementEssenLearnEval.crit = studEval.essenLearnCrit;
              if (
                essenLearnStudEval.newEssentialLearningWeight != null &&
                essenLearnStudEval.newEssentialLearningWeight != ""
              ) {
                newElementEssenLearnEval.essenLearn =
                  essenLearnStudEval.parsedEssentialLearning +
                  " -> (" +
                  essenLearnStudEval.newEssentialLearningWeight +
                  ")";
              } else {
                newElementEssenLearnEval.essenLearn = essenLearnStudEval.parsedEssentialLearning;
              }
              if (essenLearnStudEval.learningProfile != null) {
                newElementEssenLearnEval.learningProfile = essenLearnStudEval.learningProfile;
              }
              if (
                essenLearnStudEval.essentialLearningAvg != null &&
                !isNaN(Number(essenLearnStudEval.essentialLearningAvg))
              ) {
                newElementEssenLearnEval.evalCrit = Math.round(Number(essenLearnStudEval.essentialLearningAvg));
              }
              newElementEssenLearnEval.studAEEval = true;
              newElementEssenLearnEval.splitFlag = true;

              if (newElementEssenLearnEval.student != null) {
                delete newElementEssenLearnEval.student;
              }
              if (newElementEssenLearnEval.studentFinalCritEvaluations != null) {
                delete newElementEssenLearnEval.studentFinalCritEvaluations;
              }
              if (newElementEssenLearnEval.subjectCriteria != null) {
                delete newElementEssenLearnEval.subjectCriteria;
              }
              if (newElementEssenLearnEval.createdBy != null) {
                delete newElementEssenLearnEval.createdBy;
              }
              if (newElementEssenLearnEval.modifiedBy != null) {
                delete newElementEssenLearnEval.modifiedBy;
              }
              data.push(newElementEssenLearnEval);
            }
          }

          for (let w = 0; w < studEval.studentFinalCritEvals.length; w++) {
            let newElement = JSON.parse(JSON.stringify(el));
            let crit = studEval.studentFinalCritEvals[w];

            newElement.name = studEval.name;
            newElement.studentID = studEval.studentID;
            if (studEval.originalClass) {
              newElement.class = studEval.originalClass;
            }
            newElement.crit = crit.criterion;
            newElement.student = studEval.student;
            newElement.studCritEval = true;

            if (crit.autoCritEvalAvg != null) {
              newElement.evalCrit = Math.round(Number(crit.autoCritEvalAvg));
            } else {
              newElement.evalCrit = Math.round(Number(crit.evaluation));
            }

            /* if (studEval.finalEvalLevel != null) {
              newElement.finalEvalLevel = studEval.finalEvalLevel;
            } */

            if (newElement.student != null) {
              delete newElement.student;
            }
            if (newElement.studentFinalCritEvaluations != null) {
              delete newElement.studentFinalCritEvaluations;
            }
            if (newElement.subjectCriteria != null) {
              delete newElement.subjectCriteria;
            }
            if (newElement.createdBy != null) {
              delete newElement.createdBy;
            }
            if (newElement.modifiedBy != null) {
              delete newElement.modifiedBy;
            }

            if (studEval.finalGrade == null) {
              if (studEval.autoFinalGrade != null) {
                /* newElement.finalEval = studEval.autoFinalGrade; */
                newElement.splitFlag = true;
                data.push(newElement);
              }
            } else {
              /* newElement.finalEval = studEval.finalGrade; */
              newElement.splitFlag = true;
              data.push(newElement);
            }
          }

          if (newElement.splitFlag) {
            let newElementStudFinalEval = JSON.parse(JSON.stringify(el));
            newElementStudFinalEval.name = studEval.name;
            newElementStudFinalEval.studentID = studEval.studentID;
            if (studEval.originalClass) {
              newElementStudFinalEval.class = studEval.originalClass;
            }
            newElementStudFinalEval.student = studEval.student;
            if (studEval.student) {
              newElementStudFinalEval.classOrder = studEval.student.classOrder;
            } else {
              newElementStudFinalEval.classOrder = studEval.studentClassOrder;
            }
            if (studEval.profRecoveryInstrEvalsFlag) {
              newElementStudFinalEval.profRecoveryInstrEvalsFlag = studEval.profRecoveryInstrEvalsFlag;
            }

            if (studEval.finalGrade == null) {
              if (studEval.autoFinalGrade != null) {
                newElementStudFinalEval.finalEval = studEval.autoFinalGrade;
                newElementStudFinalEval.splitFlag = true;
              }
            } else {
              newElementStudFinalEval.finalEval = studEval.finalGrade;
              newElementStudFinalEval.splitFlag = true;
            }

            if (studEval.feedbackStud != null) {
              newElementStudFinalEval.feedbackStud = studEval.feedbackStud;
            }

            if (studEval.finalEvalLevel != null) {
              newElementStudFinalEval.finalEvalLevel = studEval.finalEvalLevel;
            }
            newElementStudFinalEval.studFinalEval = true;

            if (newElementStudFinalEval.student != null) {
              delete newElementStudFinalEval.student;
            }
            if (newElementStudFinalEval.studentFinalCritEvaluations != null) {
              delete newElementStudFinalEval.studentFinalCritEvaluations;
            }
            if (newElementStudFinalEval.subjectCriteria != null) {
              delete newElementStudFinalEval.subjectCriteria;
            }
            if (newElementStudFinalEval.createdBy != null) {
              delete newElementStudFinalEval.createdBy;
            }
            if (newElementStudFinalEval.modifiedBy != null) {
              delete newElementStudFinalEval.modifiedBy;
            }
            data.push(newElementStudFinalEval);
          }
        }
      }

      data.splice(0, 1);
    }

    return data;
  },
  ptSimplifiedSplitFinalEvalsSchoolRecord: function ptSimplifiedSplitFinalEvalsSchoolRecord(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.studentFinalCritEvaluations && el.studentFinalCritEvaluations.length > 0) {
        for (let k = 0; k < el.studentFinalCritEvaluations.length; k++) {
          let studEval = el.studentFinalCritEvaluations[k];

          if (studEval.finalEvalLevel) {
            let newElementStudFinalEval = JSON.parse(JSON.stringify(el));

            if (studEval.student) {
              newElementStudFinalEval.classOrder = studEval.student.classOrder;
            } else {
              newElementStudFinalEval.classOrder = studEval.studentClassOrder;
            }

            newElementStudFinalEval.name = studEval.name;
            newElementStudFinalEval.studentID = studEval.studentID;

            if (studEval.finalEvalLevel != null) {
              newElementStudFinalEval.finalEvalLevel = studEval.finalEvalLevel;
            }

            if (newElementStudFinalEval.student != null) {
              delete newElementStudFinalEval.student;
            }
            if (newElementStudFinalEval.studentFinalCritEvaluations != null) {
              delete newElementStudFinalEval.studentFinalCritEvaluations;
            }
            if (newElementStudFinalEval.subjectCriteria != null) {
              delete newElementStudFinalEval.subjectCriteria;
            }
            if (newElementStudFinalEval.createdBy != null) {
              delete newElementStudFinalEval.createdBy;
            }
            if (newElementStudFinalEval.modifiedBy != null) {
              delete newElementStudFinalEval.modifiedBy;
            }
            newElementStudFinalEval.splitFlag = true;
            data.push(newElementStudFinalEval);
          }
        }
      }

      data.splice(0, 1);
    }

    return data;
  },
  ptSplitFinalEvalInstrDetails: function ptSplitFinalEvalInstrDetails(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }
      let evaluatedEssenLearnsAllStudents = [];
      if (el.studentFinalCritEvaluations && el.studentFinalCritEvaluations.length > 0) {
        let studentSubjectAvalWithoutInstrDescFlag = false;
        for (let k = 0; k < el.studentFinalCritEvaluations.length; k++) {
          let studEval = el.studentFinalCritEvaluations[k];
          let newElement = JSON.parse(JSON.stringify(el));
          newElement.name = studEval.name;
          newElement.studentID = studEval.studentID;
          newElement.student = studEval.student;
          newElement.classOrder = studEval.classOrder;

          if (studEval.finalGrade != null || studEval.autoFinalGrade != null) {
            let evaluatedEssenLearns = [];
            for (let j = 0; j < studEval.studentFinalEssenLearnCritEvals.length; j++) {
              let essenLearnStudEval = studEval.studentFinalEssenLearnCritEvals[j];

              if (essenLearnStudEval.parsedEssentialLearning.indexOf("%") != -1) {
                essenLearnStudEval.parsedEssentialLearning = essenLearnStudEval.parsedEssentialLearning
                  .split("(")[0]
                  .slice(0, -1);
              }

              if (essenLearnStudEval.essentialLearningEvalDesc != null) {
                if (evaluatedEssenLearnsAllStudents.indexOf(essenLearnStudEval.parsedEssentialLearning) == -1) {
                  evaluatedEssenLearnsAllStudents.push(essenLearnStudEval.parsedEssentialLearning);
                }
                evaluatedEssenLearns.push(essenLearnStudEval.parsedEssentialLearning);
                newElement[essenLearnStudEval.parsedEssentialLearning] =
                  essenLearnStudEval.essentialLearningEvalDesc +
                  (!isNaN(Number(essenLearnStudEval.essentialLearningAvg))
                    ? ". Aval Domínio: " + Math.round(Number(essenLearnStudEval.essentialLearningAvg))
                    : "");
              }
              /* else {
                studentSubjectAvalWithoutInstrDescFlag = true;
                break;
              } */
            }
            /* if (studentSubjectAvalWithoutInstrDescFlag) {
              continue;
            } */

            if (evaluatedEssenLearnsAllStudents.length > 0) {
              evaluatedEssenLearnsAllStudents = evaluatedEssenLearnsAllStudents.sort();
            }
            newElement.evaluatedEssenLearns = evaluatedEssenLearnsAllStudents;

            let evaluatedSecondaryCrits = [];
            for (let w = 0; w < studEval.studentFinalCritEvals.length; w++) {
              let crit = studEval.studentFinalCritEvals[w];
              evaluatedSecondaryCrits.push(crit.criterion);
              if (crit.autoCritEvalAvg != null) {
                newElement[crit.criterion] = Math.round(Number(crit.autoCritEvalAvg));
              } else {
                newElement[crit.criterion] = Math.round(Number(crit.evaluation));
              }
            }
            newElement.evaluatedSecondaryCrits = evaluatedSecondaryCrits;

            if (studEval.profRecoveryInstrEvalsFlag) {
              newElement.profRecoveryInstrEvalsFlag = studEval.profRecoveryInstrEvalsFlag;
            }

            if (studEval.finalGrade == null) {
              if (studEval.autoFinalGrade != null) {
                newElement.finalEval = studEval.autoFinalGrade;
              }
            } else {
              newElement.finalEval = studEval.finalGrade;
            }

            if (studEval.finalEvalLevel != null) {
              newElement.finalEvalLevel = studEval.finalEvalLevel;
            }
            newElement.studFinalEval = true;

            newElement.splitFlag = true;
            cleanElement(newElement);
            data.push(newElement);
          }
        }
      }

      data.splice(0, 1);
    }

    function cleanElement(element) {
      if (element.student != null) {
        delete element.student;
      }
      if (element.studentFinalCritEvaluations != null) {
        delete element.studentFinalCritEvaluations;
      }
      if (element.subjectCriteria != null) {
        delete element.subjectCriteria;
      }
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  ptSplitExpertiseFieldEvals: function ptSplitExpertiseFieldEvals(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.studentFinalCritEvaluations && el.studentFinalCritEvaluations.length > 0) {
        for (let k = 0; k < el.studentFinalCritEvaluations.length; k++) {
          let studEval = el.studentFinalCritEvaluations[k];

          if (
            studEval.studentExpertiseFieldEvals &&
            Array.isArray(studEval.studentExpertiseFieldEvals) &&
            studEval.studentExpertiseFieldEvals.length > 0
          ) {
            for (let j = 0; j < studEval.studentExpertiseFieldEvals.length; j++) {
              let expertiseFieldStudEval = studEval.studentExpertiseFieldEvals[j];
              let newElementEssenLearnEval = JSON.parse(JSON.stringify(el));
              newElementEssenLearnEval.name = studEval.name;
              newElementEssenLearnEval.studentID = studEval.studentID;
              if (studEval.student) {
                newElementEssenLearnEval.classOrder = studEval.student.classOrder;
              } else {
                newElementEssenLearnEval.classOrder = studEval.studentClassOrder;
              }
              if (newElementEssenLearnEval.module != null) {
                newElementEssenLearnEval.parsedModule = scope.parseValue(
                  newElementEssenLearnEval.module,
                  "module",
                  "Class_Plan_Prof_Modules"
                );
              }

              newElementEssenLearnEval.parsedExpertiseField = expertiseFieldStudEval.parsedExpertiseField;
              newElementEssenLearnEval.expertiseFieldEval = expertiseFieldStudEval.expertiseFieldEval;
              newElementEssenLearnEval.associatedEssentialLearning = expertiseFieldStudEval.associatedEssentialLearning;

              newElementEssenLearnEval.splitFlag = true;

              if (newElementEssenLearnEval.student != null) {
                delete newElementEssenLearnEval.student;
              }
              if (newElementEssenLearnEval.studentFinalCritEvaluations != null) {
                delete newElementEssenLearnEval.studentFinalCritEvaluations;
              }
              if (newElementEssenLearnEval.subjectCriteria != null) {
                delete newElementEssenLearnEval.subjectCriteria;
              }
              if (newElementEssenLearnEval.createdBy != null) {
                delete newElementEssenLearnEval.createdBy;
              }
              if (newElementEssenLearnEval.modifiedBy != null) {
                delete newElementEssenLearnEval.modifiedBy;
              }
              data.push(newElementEssenLearnEval);
            }
          }
        }
      }
      data.splice(0, 1);
    }
    return data;
  },
  ptSplitProfSubjectEvals: function ptSplitProfSubjectEvals(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.studentProfSubjectsEvals && el.studentProfSubjectsEvals.length > 0) {
        for (let k = 0; k < el.studentProfSubjectsEvals.length; k++) {
          let subjectEval = el.studentProfSubjectsEvals[k];
          let newElement = JSON.parse(JSON.stringify(el));
          if (subjectEval.evalSubject == null) {
            break;
          }

          if (subjectEval.studProfSubModulesEvals) {
            if (
              subjectEval.studProfSubModulesEvals.length > 1 ||
              (subjectEval.missingProfCoursePlanModuleEval && subjectEval.missingProfCoursePlanModuleEval.length > 0)
            ) {
              for (let w = 0; w < subjectEval.studProfSubModulesEvals.length; w++) {
                if (
                  subjectEval.studProfSubModulesEvals.length > 1 ||
                  (subjectEval.missingProfCoursePlanModuleEval &&
                    subjectEval.missingProfCoursePlanModuleEval.length > 0)
                ) {
                  let newSubElement = JSON.parse(JSON.stringify(el));
                  newSubElement.name = subjectEval.studProfSubModulesEvals[w].name;
                  newSubElement.evalModule = subjectEval.studProfSubModulesEvals[w].evalModule;
                  newSubElement.parsedModule = subjectEval.studProfSubModulesEvals[w].parsedModule;
                  newSubElement.studentID = subjectEval.studProfSubModulesEvals[w].studentID;
                  cleanNewElement(newSubElement);
                  newSubElement.splitFlag = true;
                  data.push(newSubElement);
                }
              }
            } else {
              newElement.evalModule = subjectEval.studProfSubModulesEvals[0].evalModule;
              newElement.parsedModule = subjectEval.studProfSubModulesEvals[0].parsedModule;
            }
          }

          if (subjectEval.missingProfCoursePlanModuleEval) {
            if (
              subjectEval.missingProfCoursePlanModuleEval.length > 1 ||
              (subjectEval.studProfSubModulesEvals && subjectEval.studProfSubModulesEvals.length > 0)
            ) {
              for (let j = 0; j < subjectEval.missingProfCoursePlanModuleEval.length; j++) {
                let newSubElement = JSON.parse(JSON.stringify(el));
                newSubElement.evalModule = "NA";
                newSubElement.name = subjectEval.name;
                newSubElement.parsedModule = subjectEval.missingProfCoursePlanModuleEval[j];
                cleanNewElement(newSubElement);
                newSubElement.splitFlag = true;
                data.push(newSubElement);
              }
            } else {
              newElement.evalModule = "NA";
              newElement.parsedModule = subjectEval.parsedMissingProfCoursePlanModuleEval;
            }
          }

          newElement.name = subjectEval.name;
          newElement.evalLevelSubject = subjectEval.evalLevelSubject;
          newElement.studentID = subjectEval.studentID;

          if (subjectEval.evalSubjectManual != null) {
            newElement.evalSubject = subjectEval.evalSubjectManual;
          } else {
            newElement.evalSubject = subjectEval.evalSubject;
          }

          cleanNewElement(newElement);

          newElement.splitFlag = true;
          data.push(newElement);
        }
      }

      data.splice(0, 1);
    }

    function cleanNewElement(element) {
      if (element.finalEvalLevels != null) {
        delete element.finalEvalLevels;
      }
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  ptSplitProfCourseEvals: function ptSplitProfCourseEvals(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.studentProfCourseEvals && el.studentProfCourseEvals.length > 0) {
        for (let k = 0; k < el.studentProfCourseEvals.length; k++) {
          let subjectEval = el.studentProfCourseEvals[k];
          if (subjectEval.evalSubject == null) {
            break;
          }
          for (let w = 0; w < subjectEval.courseSubjectsEvals.length; w++) {
            let newElement = JSON.parse(JSON.stringify(el));
            newElement.studyYear = subjectEval.courseSubjectsEvals[w].studyYear;
            newElement.name = subjectEval.courseSubjectsEvals[w].name;
            newElement.evalSubject = subjectEval.courseSubjectsEvals[w].evalSubject;
            newElement.parsedSubject = subjectEval.courseSubjectsEvals[w].parsedSubject;
            newElement.studentID = subjectEval.courseSubjectsEvals[w].studentID;

            cleanNewElement(newElement);

            newElement.splitFlag = true;
            data.push(newElement);
          }
          let newElement = JSON.parse(JSON.stringify(el));
          newElement.name = subjectEval.name;
          newElement.avgEvalSubject = subjectEval.evalSubject;
          newElement.evalCourse = subjectEval.evalCourse;
          newElement.studentID = subjectEval.studentID;

          cleanNewElement(newElement);

          newElement.splitFlag = true;
          data.push(newElement);
        }
      }

      data.splice(0, 1);
    }

    function cleanNewElement(element) {
      if (element.finalEvalLevels != null) {
        delete element.finalEvalLevels;
      }
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  ptSplitMeasures: function ptSplitMeasures(scope, data) {
    let measures = scope.getFromTableData("Class_Plan_Measures");
    let measureTypes = scope.getFromTableData("Class_Plan_Measure_Types");
    let studsWithSpecialMeasuresArr = [];
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.studentMeasures) {
        for (let k = 0; k < el.studentMeasures.length; k++) {
          let studMesures = el.studentMeasures[k];
          let studMesureTypes = ["universalMeasures", "selectiveMeasures", "additionalMeasures", "adaptationsMeasures"];

          if (
            studsWithSpecialMeasuresArr.indexOf(studMesures.studentID) == -1 &&
            ((studMesures["selectiveMeasures"] != null && studMesures["selectiveMeasures"].length > 0) ||
              (studMesures["additionalMeasures"] != null && studMesures["additionalMeasures"].length > 0) ||
              (studMesures["adaptationsMeasures"] != null && studMesures["adaptationsMeasures"].length > 0))
          ) {
            studsWithSpecialMeasuresArr.push(studMesures.studentID);
          }

          for (let j = 0; j < studMesureTypes.length; j++) {
            const studMesType = studMesureTypes[j];
            if (studMesures[studMesType] != null && studMesures[studMesType].length > 0) {
              let parsedMeasureType;
              for (let w = 0; w < studMesures[studMesType].length; w++) {
                const studSelectedMeasure = studMesures[studMesType][w];
                let newElement = JSON.parse(JSON.stringify(el));
                let fromTableMeasure = measures.filter((el) => el.id == studSelectedMeasure)[0];
                if (fromTableMeasure != null) {
                  newElement.name = studMesures.name;
                  newElement.studentID = studMesures.studentID;
                  newElement.parsedCreatedBy = el.createdBy.name + " " + el.createdBy.surname;
                  newElement.measureType = measureTypes.filter(
                    (el) => el.id == fromTableMeasure.measureType
                  )[0].measureType;
                  if (!parsedMeasureType) {
                    parsedMeasureType = newElement.measureType;
                  }
                  newElement.measure = fromTableMeasure.measure;
                  newElement.splitFlag = true;
                  /* if (j > 0 || w > 0) {
                    if (newElement.subject) {
                      delete newElement.subject;
                    }
                  } */
                  cleanNewElement(newElement);
                  data.push(newElement);
                }
              }
              if (parsedMeasureType) {
                let newElement = JSON.parse(JSON.stringify(el));
                switch (studMesType) {
                  case "universalMeasures":
                    newElement.comment = studMesures.universalCom;
                    break;
                  case "selectiveMeasures":
                    newElement.comment = studMesures.selectiveCom;
                    break;
                  case "additionalMeasures":
                    newElement.comment = studMesures.additionalCom;
                    break;
                  case "adaptationsMeasures":
                    newElement.comment = studMesures.adaptationsCom;
                    break;
                }
                if (newElement.comment) {
                  newElement.name = studMesures.name;
                  newElement.studentID = studMesures.studentID;
                  newElement.parsedCreatedBy = el.createdBy.name + " " + el.createdBy.surname;
                  newElement.measureType = parsedMeasureType;
                  //delete newElement.subject;
                  newElement.splitFlag = true;
                  cleanNewElement(newElement);
                  data.push(newElement);
                }
              }
            } else {
              // Measure comment withouts the application of measures

              let newElement = JSON.parse(JSON.stringify(el));
              switch (studMesType) {
                case "universalMeasures":
                  newElement.comment = studMesures.universalCom;
                  newElement.measureType = "Universal";
                  break;
                case "selectiveMeasures":
                  newElement.comment = studMesures.selectiveCom;
                  newElement.measureType = "Seletiva";
                  break;
                case "additionalMeasures":
                  newElement.comment = studMesures.additionalCom;
                  newElement.measureType = "Adicional";
                  break;
                case "adaptationsMeasures":
                  newElement.comment = studMesures.adaptationsCom;
                  newElement.measureType = "Adaptações Processo Avaliação";
                  break;
              }
              if (newElement.comment) {
                newElement.name = studMesures.name;
                newElement.studentID = studMesures.studentID;
                newElement.parsedCreatedBy = el.createdBy.name + " " + el.createdBy.surname;
                //delete newElement.subject;
                newElement.splitFlag = true;
                cleanNewElement(newElement);
                data.push(newElement);
              }
            }
          }
        }
      }

      data.splice(0, 1);
    }

    data.forEach((el) => {
      if (studsWithSpecialMeasuresArr.indexOf(el.studentID) != -1) {
        el.specialMeasures = true;
      }
    });

    function cleanNewElement(element) {
      if (element.studentMeasures != null) {
        delete element.studentMeasures;
      }
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  ptSplitAFCRecords: function ptSplitAFCRecords(scope, data, pdfMode) {
    let subjects = scope.getFromTableData("Class_Plan_Subjects");
    let modules = scope.getFromTableData("Class_Plan_Prof_Modules");
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      let numMissingSubjectModule = 0;
      let subjectModuleArr = [];
      let newElement = JSON.parse(JSON.stringify(el));

      if (el.dacSubjects && Array.isArray(el.dacSubjects) && el.dacSubjects.length > 0) {
        for (let j = 0; j < el.dacSubjects.length; j++) {
          let dacSubject = el.dacSubjects[j];
          if (dacSubject.subject != null) {
            if (pdfMode != null && pdfMode == true) {
              subjectModuleArr.push(dacSubject.subject);
            } else {
              let parsedSubjectModule = subjects.filter((el) => el.id == dacSubject.subject)[0];
              if (parsedSubjectModule != null && parsedSubjectModule.subject != null) {
                subjectModuleArr.push(parsedSubjectModule.subject);
              } else {
                numMissingSubjectModule += 1;
              }
            }
          } else if (dacSubject.module != null) {
            if (pdfMode != null && pdfMode == true) {
              subjectModuleArr.push(dacSubject.module);
            } else {
              let parsedSubjectModule = modules.filter((el) => el.id == dacSubject.module)[0];
              if (parsedSubjectModule != null && parsedSubjectModule.module != null) {
                subjectModuleArr.push(parsedSubjectModule.module);
              } else {
                numMissingSubjectModule += 1;
              }
            }
          }
        }
        if (numMissingSubjectModule > 0) {
          subjectModuleArr.push("Disciplina/módulo em falta (" + numMissingSubjectModule + ")");
        }

        if (subjectModuleArr.length > 0) {
          newElement.parsedSubjectModule = subjectModuleArr.sort().toString();
        }
      } else {
        newElement.parsedSubjectModule = "DACs não específicados";
      }

      if (newElement.schoolYear != null && newElement.schoolYear != []) {
        if (pdfMode != null && pdfMode == true) {
          newElement.schoolYear = newElement.schoolYear;
        } else {
          newElement.schoolYear = scope.parseValue(newElement.schoolYear, "schoolYear", "Class_Plan_School_Years");
        }
      }

      if (newElement.articulationState == null || newElement.articulationState == "") {
        newElement.articulationState = "Sem estado";
      }

      newElement.splitFlag = true;
      cleanNewElement(newElement);
      data.push(newElement);

      data.splice(0, 1);
    }

    function cleanNewElement(element) {
      if (element.dacSubjects != null) {
        delete element.dacSubjects;
      }
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  ptSplitAFCRecordsByClass: function ptSplitAFCRecordsByClass(scope, data, pdfMode) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      let classSpecificDACFlag = false;

      if (el.educationLevel == null) {
        el.educationLevel = "Todos";
      }

      if (el.temporalOrganization && Array.isArray(el.temporalOrganization) && el.temporalOrganization.length > 0) {
        for (let j = 0; j < el.temporalOrganization.length; j++) {
          let temporalOrg = el.temporalOrganization[j];
          if (temporalOrg.class != null && Array.isArray(temporalOrg.class) && temporalOrg.class.length > 0) {
            classSpecificDACFlag = true;
            for (let k = 0; k < temporalOrg.class.length; k++) {
              let cl = temporalOrg.class[k];
              let newElement = JSON.parse(JSON.stringify(el));
              newElement.classSpecificDAC = "Turma específica";
              newElement.class = cl;
              newElement.splitFlag = true;
              cleanNewElement(newElement);
              data.push(newElement);
            }
          }
        }
      }
      if (!classSpecificDACFlag) {
        let newElement = JSON.parse(JSON.stringify(el));
        newElement.classSpecificDAC = "Transversal";
        newElement.class = "Todas";
        newElement.splitFlag = true;
        cleanNewElement(newElement);
        data.push(newElement);
      }

      data.splice(0, 1);
    }

    function cleanNewElement(element) {
      if (element.dacSubjects != null) {
        delete element.dacSubjects;
      }
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  ptSplitAnnualActivityPlanRecordsBySchool: function ptSplitAFCRecords(scope, data, pdfMode) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      for (let j = 0; j < el.school.length; j++) {
        const activitySchool = el.school[j];
        let newElement = JSON.parse(JSON.stringify(el));
        newElement.school = activitySchool;
        newElement.envolvedSchools = scope.parseValue(el.school, "school", "Class_Plan_Schools");
        if (el.class != null && Array.isArray(el.class) && el.class.length > 0) {
          newElement.envolvedClasses = scope.parseValue(el.class, "class", "Class_Plan_Classes");
        } else {
          newElement.envolvedClasses = null;
        }
        if (el.subject != null && Array.isArray(el.subject) && el.subject.length > 0) {
          let parsedActivitySubjects = [...new Set(el.subject)];
          newElement.parsedActivitySubjects = parsedActivitySubjects
            .map((sub) => getParsedSubject(sub))
            .sort((a, b) => {
              if (a.localeCompare(b, "pt") == -1) {
                return -1;
              }
              if (a.localeCompare(b, "pt") == 1) {
                return 1;
              }
            })
            .toString()
            .replace(/\,/g, ", ");
        }

        if (newElement.activityStartDateMs != null && newElement.activityStartDateMs != "") {
          newElement.createdAtStart = JSON.parse(JSON.stringify(newElement.activityStartDateMs));
          newElement.activityStartDateMs = new Date(newElement.activityStartDateMs).toLocaleDateString();
        }
        if (newElement.activityEndDateMs != null && newElement.activityEndDateMs != "") {
          newElement.createdAtEnd = JSON.parse(JSON.stringify(newElement.activityEndDateMs));
          newElement.activityEndDateMs = new Date(newElement.activityEndDateMs).toLocaleDateString();
        }
        if (
          newElement.activityStartDateMs != null &&
          newElement.activityStartDateMs != "" &&
          newElement.activityEndDateMs != null &&
          newElement.activityEndDateMs != "" &&
          newElement.activityStartDateMs != newElement.activityEndDateMs
        ) {
          newElement.distinctActivityDates = true;
        }
        newElement.splitFlag = true;

        cleanNewElement(newElement);
        data.push(newElement);
      }

      data.splice(0, 1);
    }

    function cleanNewElement(element) {
      /* if (element.modifiedBy != null) {
        delete element.modifiedBy;
      } */
    }

    function getParsedSubject(subject) {
      let subs = scope.getFromTableData("Class_Plan_Subjects");
      let filteredSub = subs.filter((el) => el.id == subject);
      if (filteredSub.length) {
        return (
          scope.parseValue(filteredSub[0].schoolYear, "schoolYear", "Class_Plan_School_Years") +
          "º ano - " +
          scope.parseValue(subject, "subject", "Class_Plan_Subjects")
        );
      }
    }

    return data;
  },
  ptSplitAnnualActivityPlanRecordsByEducationPlanObjetives:
    function ptSplitAnnualActivityPlanRecordsByEducationPlanObjetives(scope, data, pdfMode) {
      let objectives = scope.getFromTableData("Class_Plan_Education_Plan_Op_Objectives");
      for (let i = 0; i < data.length; ) {
        let el = data[i];

        if (el.splitFlag) {
          break;
        }

        let schoolElement = JSON.parse(JSON.stringify(el));
        if (el.class != null && Array.isArray(el.class) && el.class.length > 0) {
          schoolElement.envolvedClasses = scope.parseValue(el.class, "class", "Class_Plan_Classes");
        } else {
          schoolElement.envolvedClasses = null;
        }

        /*  for (let j = 0; j < el.school.length; j++) {
          const activitySchool = el.school[j];
          let schoolElement = JSON.parse(JSON.stringify(el));
          schoolElement.school = activitySchool;
          schoolElement.envolvedSchools = scope.parseValue(el.school, "school", "Class_Plan_Schools");
          if (el.class != null && Array.isArray(el.class) && el.class.length > 0) {
            schoolElement.envolvedClasses = scope.parseValue(el.class, "class", "Class_Plan_Classes");
          }
          if (el.subject != null && Array.isArray(el.subject) && el.subject.length > 0) {
            let parsedActivitySubjects = scope.parseValue(el.subject, "subject", "Class_Plan_Subjects");
            if (parsedActivitySubjects.length > 0) {
              parsedActivitySubjects = [...new Set(parsedActivitySubjects.split(","))];
              schoolElement.parsedActivitySubjects = parsedActivitySubjects.sort();
            }
          } */
        if (
          schoolElement.stratObjective != null &&
          schoolElement.stratObjective.length > 0 &&
          schoolElement.opObjective != null &&
          schoolElement.opObjective.length > 0
        ) {
          for (let w = 0; w < schoolElement.stratObjective.length; w++) {
            let stratObjective = schoolElement.stratObjective[w];
            let filteredOpObjectives = objectives
              .filter((obj) => obj.stratObjective == stratObjective)
              .filter((obj) => schoolElement.opObjective.indexOf(obj.id) != -1);
            if (filteredOpObjectives.length > 0) {
              for (let k = 0; k < filteredOpObjectives.length; k++) {
                let opObjective = filteredOpObjectives[k];
                let objElement = JSON.parse(JSON.stringify(schoolElement));
                objElement.stratObjective = stratObjective;
                objElement.opObjective = opObjective.id;
                objElement.splitFlag = true;
                cleanNewElement(objElement);
                data.push(objElement);
              }
            } else {
              let objElement = JSON.parse(JSON.stringify(schoolElement));
              objElement.stratObjective = stratObjective;
              objElement.opObjective = null;
              objElement.splitFlag = true;
              cleanNewElement(objElement);
              data.push(objElement);
            }
          }
        } else {
          /* schoolElement.splitFlag = true;
            cleanNewElement(schoolElement);
            data.push(schoolElement); */
        }
        /* } */

        data.splice(0, 1);
      }

      function cleanNewElement(element) {
        if (element.modifiedBy != null) {
          delete element.modifiedBy;
        }
      }

      return data;
    },
  ptSplitAnnualActivityPlanRecordsByClass: function ptSplitAFCRecords(scope, data, pdfMode) {
    let classes = scope.getFromTableData("Class_Plan_Classes");

    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      for (let j = 0; j < el.class.length; j++) {
        const activityClass = el.class[j];
        let classRecord = classes.filter((cls) => cls.id == activityClass)[0];
        let newElement = JSON.parse(JSON.stringify(el));

        newElement.school = classRecord.school;
        newElement.educationLevel = classRecord.educationLevel;
        newElement.schoolYear = scope.parseValue(classRecord.schoolYear, "schoolYear", "Class_Plan_School_Years");
        newElement.class = activityClass;

        newElement.splitFlag = true;
        cleanNewElement(newElement);
        data.push(newElement);
      }

      data.splice(0, 1);
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  ptSplitIndisciplineOccurrences: function ptSplitIndisciplineOccurrences(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      for (let w = 0; w < el.name.length; w++) {
        let stud = el.name[w];
        let newElement = JSON.parse(JSON.stringify(el));
        newElement.name = stud;
        newElement.studentID = stud;
        newElement.splitFlag = true;
        cleanNewElement(newElement);
        data.push(newElement);
      }

      data.splice(i, 1);
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  ptSplitGeneralSchoolRecord: function ptSplitGeneralSchoolRecord(scope, data) {
    let generalData = [];
    let dataCopy = JSON.parse(JSON.stringify(data));
    generalData = generalData.concat(utilFunctions["ptSplitAESchoolRecord"](scope, dataCopy));
    let dataCopy2 = JSON.parse(JSON.stringify(data));
    generalData = generalData.concat(utilFunctions["ptSplitFinalEvalsSchoolRecord"](scope, dataCopy2));
    for (let i = 0; i < data.length; ) {
      data.splice(0, 1);
    }
    for (let j = 0; j < generalData.length; j++) {
      data.push(generalData[j]);
    }
  },
  ptSplitSchoolRecordACPreviewTable: function ptSplitSchoolRecordACPreviewTable(scope, data) {
    let columnDefs = [];

    if (scope.selectedRow != null) {
      scope.selected = scope.selectedRow;
    }

    let expertiseFields = scope.getFromTableData("Class_Plan_Expertise_Field_Descs");
    let filteredExpertiseFields = [];
    if (
      scope.selected.loadedExpertiseDescs != null &&
      scope.selected.loadedExpertiseDescs.length > 0 &&
      expertiseFields != null &&
      expertiseFields.length > 0
    ) {
      expertiseFields.forEach((expField) => {
        scope.selected.loadedExpertiseDescs.forEach((loadedExpField) => {
          if (expField.id == loadedExpField) {
            filteredExpertiseFields.push(expField);
          }
        });
      });
    }

    if (filteredExpertiseFields.length > 0) {
      for (let i = 0; i < data.length; ) {
        let el = data[i];

        if (el.name || el.Nome) {
          break;
        }

        if (el.studentEvaluations && el.studentEvaluations.length > 0) {
          columnDefs = [
            {
              name: "name",
              displayName: "Nome",
              enableCellEdit: false,
            },
            {
              name: "expertiseFieldDesc",
              displayName: "Área de Competência",
              enableCellEdit: false,
            },
            {
              name: "expertiseFieldDescValue",
              displayName: "Cumprido",
              cellClass: "ptAvalColumnToEdit",
              type: "string",
              editableCellTemplate: "ui-grid/dropdownEditor",
              editDropdownOptionsArray: [
                {
                  id: 1,
                  value: "S",
                },
                {
                  id: 2,
                  value: "N",
                },
              ],
              editDropdownIdLabel: "value",
              checkAfterEditCell: "if(rowEntity.expertiseFieldDescValue == null) {true}",
              checkBeforeCalcFunc:
                "if(}{.dynamicIndex != rowEntity.dynamicIndex || }{.subDynamicIndex != rowEntity.subDynamicIndex) {true} else {false}",
              calculatedFunction:
                "if({}.studentEvaluations[>>].expertiseFieldDesccheckbox == null) {{}.studentEvaluations[>>].expertiseFieldDesccheckbox = { }} if(rowEntity.expertiseFieldDescValue == 'S') {{}.studentEvaluations[>>].expertiseFieldDesccheckbox[rowEntity.id] = true} if (rowEntity.expertiseFieldDescValue == 'N') {{}.studentEvaluations[>>].expertiseFieldDesccheckbox[rowEntity.id] = false} rowEntity.expertiseFieldDescValue;",
            },
          ];

          for (let k = 0; k < el.studentEvaluations.length; k++) {
            let studEval = JSON.parse(JSON.stringify(el.studentEvaluations[k]));
            studEval.dynamicIndex = k;

            /* if (filteredExpertiseFields.length > 0 && (studEval.expertiseFieldDesc == null || (studEval.expertiseFieldDesc != null && studEval.expertiseFieldDesc.length == 0))) {
              studEval.expertiseFieldDesc = filteredExpertiseFields;
            } */
            studEval.expertiseFieldDesc = filteredExpertiseFields;

            for (let w = 0; w < studEval.expertiseFieldDesc.length; w++) {
              let expertiseField = JSON.parse(JSON.stringify(studEval.expertiseFieldDesc[w]));

              expertiseField.dynamicIndex = k;
              expertiseField.subDynamicIndex = w;

              expertiseField.name = studEval.name;
              if (studEval.expertiseFieldDesccheckbox != null) {
                if (studEval.expertiseFieldDesccheckbox[expertiseField.id] == true) {
                  expertiseField.expertiseFieldDescValue = "S";
                } else if (studEval.expertiseFieldDesccheckbox[expertiseField.id] == false) {
                  expertiseField.expertiseFieldDescValue = "N";
                }
              }
              data.push(expertiseField);
            }
          }
        }
        data.splice(0, 1);
      }
    } else {
      return [];
    }
    if (columnDefs.length > 0) {
      return {
        data: data,
        columnDefs: columnDefs,
      };
    } else {
      return data;
    }
  },
  ptSplitSchoolRecordPreviewTable: function ptSplitSchoolRecordPreviewTable(scope, data, panelConfig) {
    let columnDefs = [];
    let lastName;
    let rowBackgroundColorID = 1;

    if (scope.selectedRow != null) {
      scope.selected = scope.selectedRow;
    }

    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.name || el.Nome) {
        break;
      }

      if (el.studentEvaluations && el.studentEvaluations.length > 0) {
        for (let k = 0; k < el.studentEvaluations.length; k++) {
          let studEval = el.studentEvaluations[k];
          studEval.dynamicIndex = k;

          //Qualitative Eval

          if (scope.selected.qualitativeLevels != null && Object.keys(scope.selected.qualitativeLevels).length > 0) {
            let alreadyAdaptedForMixedEvalGrid = false;
            let qualitativeEvalWithQuantitativeLevelsFlag = false;
            let performanceLevelDescs = [];

            //Check if there are quantitative levels associated
            if (scope.selected.evalInstrumentQualitativeLevels) {
              let qualitativeEvalLevels = JSON.parse(scope.selected.evalInstrumentQualitativeLevels);
              if (
                qualitativeEvalLevels &&
                qualitativeEvalLevels.qualitativeLevels &&
                Array.isArray(qualitativeEvalLevels.qualitativeLevels) &&
                qualitativeEvalLevels.qualitativeLevels.length > 0
              ) {
                //Check if a certain qualitative level doesnt have a quantitative level associated
                for (let j = 0; j < qualitativeEvalLevels.qualitativeLevels.length; j++) {
                  const qualitativeLevel = qualitativeEvalLevels.qualitativeLevels[j];
                  if (
                    qualitativeLevel.quantitativeLevel != null &&
                    !isNaN(Number(qualitativeLevel.quantitativeLevel))
                  ) {
                    qualitativeEvalWithQuantitativeLevelsFlag = true;
                  } else {
                    qualitativeEvalWithQuantitativeLevelsFlag = false;
                    break;
                  }
                }
              }
              //Check if it is possible to show performance crit descs
              let fromTablePerformanceLevelDesc;
              fromTablePerformanceLevelDesc = scope
                .getFromTableData("Class_Plan_Eval_Qualitative_Levels")
                .filter((el) => el.id == qualitativeEvalLevels.id);
              if (
                Array.isArray(fromTablePerformanceLevelDesc) &&
                fromTablePerformanceLevelDesc.length > 0 &&
                fromTablePerformanceLevelDesc[0].performanceCritDesc != null &&
                fromTablePerformanceLevelDesc[0].performanceCritDesc.length > 0
              ) {
                performanceLevelDescs = fromTablePerformanceLevelDesc[0].performanceCritDesc;
              }
            }

            //Qualitative Essential Learning Eval
            if (studEval.essentialLearningEvaluation) {
              columnDefs = [
                {
                  name: "classOrder",
                  displayName: "Nº",
                  enableCellEdit: false,
                  enableFiltering: false,
                  width: 70,
                },
                {
                  name: "name",
                  displayName: "Nome",
                  enableCellEdit: false,
                },
                {
                  name: "parsedEssentialLearning",
                  displayName: "Domínio",
                  enableCellEdit: false,
                },
                {
                  name: "evaluationQualitativeLevel",
                  displayName: "Aval Dom (Qual)",
                  cellClass: "ptAvalColumnToEdit",
                  type: "string",
                  editableCellTemplate: "ui-grid/dropdownEditor",
                  editDropdownRowEntityOptionsArrayPath: "qualitativeLevels",
                  editDropdownIdLabel: "value",
                  checkAfterEditCell:
                    "if(rowEntity.qualitativeEval == false) {rowEntity.evaluationQualitativeLevel = null; true}",
                },
              ];

              if (qualitativeEvalWithQuantitativeLevelsFlag) {
                columnDefs[columnDefs.length - 1] = {
                  name: "evaluationQualitativeLevel",
                  displayName: "Aval Dom (Qual)",
                  cellClass: "ptAvalColumnToEdit",
                  type: "string",
                  editableCellTemplate: "ui-grid/dropdownEditor",
                  editDropdownRowEntityOptionsArrayPath: "qualitativeLevels",
                  editDropdownIdLabel: "value",
                  calculatedFunction:
                    "updateQuantitaveEvalFromQualitative({},}{," + JSON.stringify(performanceLevelDescs) + ")",
                };

                columnDefs = columnDefs.concat([
                  {
                    name: "evaluation",
                    displayName: "Aval Dom (Quant)",
                    type: "number",
                    enableCellEdit: false,
                  },
                ]);
                if (performanceLevelDescs.length > 0) {
                  columnDefs = columnDefs.concat([
                    {
                      name: "performanceLevelDesc",
                      displayName: "Descritor Desempenho",
                      type: "string",
                      enableCellEdit: false,
                      width: 700,
                    },
                  ]);
                  columnDefs.map(function (el) {
                    if (!el.width) {
                      el.width = 150;
                    }
                  });
                }
                if (panelConfig.importer != null) {
                  columnDefs = columnDefs.concat([
                    {
                      name: "rowID",
                      displayName: "ID",
                      enableCellEdit: false,
                      enableFiltering: false,
                      width: 5,
                      type: "number",
                      sort: {
                        priority: 0,
                        direction: "asc",
                      },
                    },
                  ]);
                }
              }

              for (let w = 0; w < studEval.essentialLearningEvaluation.length; w++) {
                let essenLearn = studEval.essentialLearningEvaluation[w];

                essenLearn.dynamicIndex = k;
                essenLearn.subDynamicIndex = w;
                essenLearn.name = studEval.name;
                essenLearn.qualitativeLevels = [];
                essenLearn.rowID = (essenLearn.dynamicIndex + 1) * 1000 + (essenLearn.subDynamicIndex + 1);

                if (
                  scope.selected.qualitativeLevels[essenLearn.parsedEssentialLearning] == null &&
                  alreadyAdaptedForMixedEvalGrid == false
                ) {
                  columnDefs = columnDefs.concat([
                    {
                      name: "evaluation",
                      displayName: "Aval Dom (Quant)",
                      type: "number",
                      cellClass: "ptAvalColumnToEdit",
                      checkAfterEditCell: "if(rowEntity.qualitativeEval == true) {rowEntity.evaluation = null; true}",
                    },
                  ]);
                  essenLearn.qualitativeEval = false;
                  alreadyAdaptedForMixedEvalGrid = true;
                } else {
                  essenLearn.qualitativeLevels.push({
                    value: "(vazio)",
                  });
                  scope.selected.qualitativeLevels[essenLearn.parsedEssentialLearning].map((level) =>
                    essenLearn.qualitativeLevels.push({
                      value: level,
                    })
                  );
                  essenLearn.qualitativeEval = true;
                }

                cleanElementAndSetupRowBackground(essenLearn, studEval);
                data.push(essenLearn);
              }
            }

            //Qualitative Question Eval
            else if (studEval.essentialLearningQuestionEvaluation) {
              columnDefs = [
                {
                  name: "classOrder",
                  displayName: "Nº",
                  enableCellEdit: false,
                  enableFiltering: false,
                  width: 70,
                },
                {
                  name: "name",
                  displayName: "Nome",
                  enableCellEdit: false,
                },
                {
                  name: "parsedEssentialLearning",
                  displayName: "Domínio",
                  enableCellEdit: false,
                },
                {
                  name: "weight",
                  displayName: "Ponderação Domínio",
                  enableCellEdit: false,
                },
                {
                  name: "question",
                  displayName: "Questão",
                  enableCellEdit: false,
                },
                {
                  name: "evaluationQualitativeLevel",
                  displayName: "Aval Quest (Qual)",
                  cellClass: "ptAvalColumnToEdit",
                  type: "string",
                  editableCellTemplate: "ui-grid/dropdownEditor",
                  editDropdownRowEntityOptionsArrayPath: "qualitativeLevels",
                  editDropdownIdLabel: "value",
                },
              ];

              if (qualitativeEvalWithQuantitativeLevelsFlag) {
                columnDefs[columnDefs.length - 1] = {
                  name: "evaluationQualitativeLevel",
                  displayName: "Aval Quest (Qual)",
                  cellClass: "ptAvalColumnToEdit",
                  type: "string",
                  editableCellTemplate: "ui-grid/dropdownEditor",
                  editDropdownRowEntityOptionsArrayPath: "qualitativeLevels",
                  editDropdownIdLabel: "value",
                  calculatedFunction:
                    "updateQuantitaveEvalFromQualitative({},}{," + JSON.stringify(performanceLevelDescs) + ")",
                };

                columnDefs = columnDefs.concat([
                  {
                    name: "evaluation",
                    displayName: "Aval Quest (Quant)",
                    type: "number",
                    calculatedFunction: "updateEssenLearnAvgMap({},}{,'evaluation')",
                    enableCellEdit: false,
                  },
                  {
                    name: "essentiaLearningEval",
                    displayName: "Aval Domínio",
                    enableCellEdit: false,
                    calculatedFunction:
                      "Math.round(({}.studentEvaluations[}{.dynamicIndex].essenLearnFinalEvalMap[{}.studentEvaluations[}{.dynamicIndex].essentialLearningQuestionEvaluation[}{.subDynamicIndex].essentialLearning].evaluation) * 100) / 100",
                    checkBeforeCalcFunc:
                      "if({}.studentEvaluations[}{.dynamicIndex].essenLearnFinalEvalMap == null) {if (}{.essentiaLearningEval != null) { }{.essentiaLearningEval = null;} true} else {false}",
                  },
                ]);
                if (performanceLevelDescs.length > 0) {
                  columnDefs = columnDefs.concat([
                    {
                      name: "performanceLevelDesc",
                      displayName: "Descritor Desempenho",
                      type: "string",
                      enableCellEdit: false,
                      width: 700,
                    },
                  ]);
                  columnDefs.map(function (el) {
                    if (!el.width) {
                      el.width = 150;
                    }
                  });
                }
                if (panelConfig.importer != null) {
                  columnDefs = columnDefs.concat([
                    {
                      name: "rowID",
                      displayName: "ID",
                      enableCellEdit: false,
                      enableFiltering: false,
                      width: 5,
                      type: "number",
                      sort: {
                        priority: 0,
                        direction: "asc",
                      },
                    },
                  ]);
                }
              }

              for (let w = 0; w < studEval.essentialLearningQuestionEvaluation.length; w++) {
                let essenLearnQuestionEval = studEval.essentialLearningQuestionEvaluation[w];

                //Aux values for calculated cells
                essenLearnQuestionEval.dynamicIndex = k;
                essenLearnQuestionEval.subDynamicIndex = w;
                essenLearnQuestionEval.name = studEval.name;
                essenLearnQuestionEval.qualitativeLevels = [];
                essenLearnQuestionEval.rowID =
                  (essenLearnQuestionEval.dynamicIndex + 1) * 1000 + (essenLearnQuestionEval.subDynamicIndex + 1);

                if (
                  scope.selected.qualitativeLevels[essenLearnQuestionEval.parsedEssentialLearning] == null &&
                  alreadyAdaptedForMixedEvalGrid == false
                ) {
                  columnDefs = columnDefs.concat([
                    {
                      name: "evaluation",
                      displayName: "Aval Quest (Quant)",
                      type: "number",
                      cellClass: "ptAvalColumnToEdit",
                      checkAfterEditCell: "if(rowEntity.qualitativeEval == true) {rowEntity.evaluation = null; true}",
                    },
                  ]);
                  essenLearnQuestionEval.qualitativeEval = false;
                  alreadyAdaptedForMixedEvalGrid = true;
                } else {
                  essenLearnQuestionEval.qualitativeLevels.push({
                    value: "(vazio)",
                  });
                  scope.selected.qualitativeLevels[essenLearnQuestionEval.parsedEssentialLearning].map((level) =>
                    essenLearnQuestionEval.qualitativeLevels.push({
                      value: level,
                    })
                  );
                  essenLearnQuestionEval.qualitativeEval = true;
                }

                cleanElementAndSetupRowBackground(essenLearnQuestionEval, studEval);
                data.push(essenLearnQuestionEval);
              }
            }
          }
          //Quatitative Eval

          //Quatitative Essential Learning Eval
          else if (studEval.essentialLearningEvaluation) {
            for (let w = 0; w < studEval.essentialLearningEvaluation.length; w++) {
              let essenLearn = studEval.essentialLearningEvaluation[w];

              essenLearn.dynamicIndex = k;
              essenLearn.subDynamicIndex = w;
              essenLearn.name = studEval.name;
              essenLearn.rowID = (essenLearn.dynamicIndex + 1) * 1000 + (essenLearn.subDynamicIndex + 1);

              cleanElementAndSetupRowBackground(essenLearn, studEval);
              data.push(essenLearn);
            }

            columnDefs = [
              {
                name: "classOrder",
                displayName: "Nº",
                enableCellEdit: false,
                enableFiltering: false,
                width: 70,
              },
              {
                name: "name",
                displayName: "Nome",
                enableCellEdit: false,
              },
              {
                name: "evaluation",
                displayName: "Aval Domínio",
                type: "number",
                cellClass: "ptAvalColumnToEdit",
              },
              {
                name: "parsedEssentialLearning",
                displayName: "Domínio",
                enableCellEdit: false,
              },
            ];
            if (panelConfig.importer != null) {
              columnDefs = columnDefs.concat([
                {
                  name: "rowID",
                  displayName: "ID",
                  enableCellEdit: false,
                  enableFiltering: false,
                  width: 5,
                  type: "number",
                  sort: {
                    priority: 0,
                    direction: "asc",
                  },
                },
              ]);
            }
          }

          //Quatitative Question Eval
          else if (studEval.essentialLearningQuestionEvaluation) {
            for (let w = 0; w < studEval.essentialLearningQuestionEvaluation.length; w++) {
              let essenLearnQuestionEval = studEval.essentialLearningQuestionEvaluation[w];

              //Aux values for calculated cells
              essenLearnQuestionEval.dynamicIndex = k;
              essenLearnQuestionEval.subDynamicIndex = w;
              essenLearnQuestionEval.name = studEval.name;
              essenLearnQuestionEval.rowID =
                (essenLearnQuestionEval.dynamicIndex + 1) * 1000 + (essenLearnQuestionEval.subDynamicIndex + 1);

              if (studEval.essenLearnFinalEvalMap && Object.keys(studEval.essenLearnFinalEvalMap).length > 0) {
                essenLearnQuestionEval.essentiaLearningEval =
                  Math.round(
                    studEval.essenLearnFinalEvalMap[essenLearnQuestionEval.essentialLearning].evaluation * 100
                  ) / 100;
              }

              cleanElementAndSetupRowBackground(essenLearnQuestionEval, studEval);
              data.push(essenLearnQuestionEval);
            }

            columnDefs = [
              {
                name: "classOrder",
                displayName: "Nº",
                enableCellEdit: false,
                enableFiltering: false,
                width: 70,
              },
              {
                name: "name",
                displayName: "Nome",
                enableCellEdit: false,
              },
              {
                name: "question",
                displayName: "Questão",
                enableCellEdit: false,
              },
              {
                name: "evaluation",
                displayName: "Aval Questão",
                type: "number",
                cellClass: "ptAvalColumnToEdit",
                calculatedFunction: "updateEssenLearnAvgMap({},}{,'evaluation')",
              },
              {
                name: "parsedEssentialLearning",
                displayName: "Domínio",
                enableCellEdit: false,
              },
              {
                name: "weight",
                displayName: "Ponderação Domínio",
                enableCellEdit: false,
              },
              {
                name: "essentiaLearningEval",
                displayName: "Aval Domínio",
                enableCellEdit: false,
                calculatedFunction:
                  "Math.round(({}.studentEvaluations[}{.dynamicIndex].essenLearnFinalEvalMap[{}.studentEvaluations[}{.dynamicIndex].essentialLearningQuestionEvaluation[}{.subDynamicIndex].essentialLearning].evaluation) * 100) / 100",
                checkBeforeCalcFunc:
                  "if({}.studentEvaluations[}{.dynamicIndex].essenLearnFinalEvalMap == null) {if (}{.essentiaLearningEval != null) { }{.essentiaLearningEval = null;} true} else {false}",
              },
            ];
            if (panelConfig.importer != null) {
              columnDefs = columnDefs.concat([
                {
                  name: "rowID",
                  displayName: "ID",
                  enableCellEdit: false,
                  enableFiltering: false,
                  width: 5,
                  type: "number",
                  sort: {
                    priority: 0,
                    direction: "asc",
                  },
                },
              ]);
            }
          } //Attitudes eval
          else if (studEval.attitudesEvaluation && studEval.attitudesEvaluation.length > 0) {
            for (let w = 0; w < studEval.attitudesEvaluation.length; w++) {
              let attCrit = studEval.attitudesEvaluation[w];

              attCrit.dynamicIndex = k;
              attCrit.subDynamicIndex = w;
              attCrit.name = studEval.name;

              cleanElementAndSetupRowBackground(attCrit, studEval);
              data.push(attCrit);
            }

            columnDefs = [
              {
                name: "name",
                displayName: "Nome",
                enableCellEdit: false,
              },
              {
                name: "parsedAttCrit",
                displayName: "Critério Atitudes",
                enableCellEdit: false,
              },
              {
                name: "evaluation",
                displayName: "Aval Crit",
                type: "number",
                cellClass: "ptAvalColumnToEdit",
              },
            ];
          }
        }
        // Student Final Evaluations
      } else if (el.studentFinalCritEvaluations && el.studentFinalCritEvaluations.length > 0) {
        // Student Final Evaluations With Qualitative
        if (scope.selected.hasQualitativeEvalEssenLearns) {
          for (let k = 0; k < el.studentFinalCritEvaluations.length; k++) {
            let studEval = el.studentFinalCritEvaluations[k];

            for (let n = 0; n < studEval.studentFinalEssenLearnCritEvals.length; n++) {
              let essen = studEval.studentFinalEssenLearnCritEvals[n];

              essen.essenLearn = true;
              essen.dynamicIndex = k;
              essen.subDynamicIndex = n;

              essen.criterion = studEval.essenLearnCrit;
              essen.criterionWeight = studEval.essenLearnCritWeight;
              essen.name = studEval.name;

              if (essen.qualitativeEval) {
                essen.evaluationCrit = essen.essentialLearningQuantEval;
                essen.essentialLearningQualtitativeResults = essen.essentialLearningAvg;
              } else {
                essen.evaluationCrit = Number(essen.essentialLearningAvg);
              }

              if (studEval.finalGrade == null) {
                essen.finalGradeCrit = studEval.autoFinalGrade;
              } else {
                essen.finalGradeCrit = studEval.finalGrade;
              }
              data.push(essen);
            }

            for (let w = 0; w < studEval.studentFinalCritEvals.length; w++) {
              let crit = studEval.studentFinalCritEvals[w];

              crit.essenLearn = false;
              crit.dynamicIndex = k;
              crit.subDynamicIndex = w;

              crit.name = studEval.name;
              crit.evaluationCrit = studEval.studentFinalCritEvals[w].evaluation;

              if (studEval.finalGrade == null) {
                crit.finalGradeCrit = studEval.autoFinalGrade;
              } else {
                crit.finalGradeCrit = studEval.finalGrade;
              }

              if (studEval.finalEvalLevel != null) {
                crit.finalEvalLevel = studEval.finalEvalLevel;
              }

              data.push(crit);
            }
          }

          columnDefs = [
            {
              name: "name",
              displayName: "Nome",
              enableCellEdit: false,
            },
            {
              name: "criterion",
              displayName: "Critério",
              enableCellEdit: false,
            },
            {
              name: "criterionWeight",
              displayName: "Ponderação Critério",
              enableCellEdit: false,
            },
            {
              name: "parsedEssentialLearning",
              displayName: "Domínio",
              enableCellEdit: false,
            },
            {
              name: "evaluationCrit",
              displayName: "Aval Dom/Crit",
              cellClass: "ptAvalColumnToEdit",
              type: "number",
              checkAfterEditCell:
                "if(!rowEntity.qualitativeEval && rowEntity.essenLearn) {{}.studentFinalCritEvaluations[rowEntity.dynamicIndex].studentFinalEssenLearnCritEvals[rowEntity.subDynamicIndex].evaluationCrit = oldValue; true}",
              checkBeforeCalcFunc:
                "if(}{.dynamicIndex != rowEntity.dynamicIndex || }{.subDynamicIndex != rowEntity.subDynamicIndex || }{.essenLearn != rowEntity.essenLearn) {true} else {false}",
              calculatedFunction:
                "if(}{.essenLearn == false){{}.studentFinalCritEvaluations[>>].studentFinalCritEvals[rowEntity.subDynamicIndex].evaluation = rowEntity.evaluationCrit; rowEntity.evaluationCrit;} else if(}{.qualitativeEval != null && }{.qualitativeEval == true && }{.essenLearn == true){{}.studentFinalCritEvaluations[>>].studentFinalEssenLearnCritEvals[rowEntity.subDynamicIndex].essentialLearningQuantEval = rowEntity.evaluationCrit; rowEntity.evaluationCrit;}",
            },
            {
              name: "essentialLearningQualtitativeResults",
              displayName: "Aval Qualitativas",
              enableCellEdit: false,
            },
            {
              name: "finalGradeCrit",
              displayName: "Aval Final",
              enableCellEdit: false,
              calculatedFunction: "ptGetFinalGrade($scope.selected, index)",
            },
            {
              name: "finalEvalLevel",
              displayName: "Nível Aval Final",
              enableCellEdit: false,
              calculatedFunction:
                "ptGetFinalEvalLevel($scope.genericScope, $scope.selected, index, $scope.genericScope.getFromTableData('Class_Plan_Final_Evaluation_Levels'), }{.subDynamicIndex, }{.finalGradeCrit)",
            },
          ];
        }
        // Student Final Evaluations Only Quantitative
        else {
          let autoEvalsOnlyFlag = false;

          for (let k = 0; k < el.studentFinalCritEvaluations.length; k++) {
            let studEval = el.studentFinalCritEvaluations[k];

            if (studEval.autoFinalEssenLearnCritEvalGrade != null || studEval.autoFinalGrade != null) {
              studEval.essenLearnCritFlag = true;
              studEval.dynamicIndex = k;

              studEval.criterion = studEval.essenLearnCrit;
              studEval.criterionWeight = studEval.essenLearnCritWeight;

              if (studEval.finalEssenLearnCritEvalGrade != null) {
                studEval.evaluationCrit = studEval.finalEssenLearnCritEvalGrade;
              } else {
                studEval.evaluationCrit = studEval.autoFinalEssenLearnCritEvalGrade;
              }

              if (studEval.finalGrade == null) {
                studEval.finalGradeCrit = studEval.autoFinalGrade;
              } else {
                studEval.finalGradeCrit = studEval.finalGrade;
              }
              data.push(studEval);

              for (let w = 0; w < studEval.studentFinalCritEvals.length; w++) {
                let crit = studEval.studentFinalCritEvals[w];

                /* let newElement = {};

                if (crit.evaluation != null) {
                  newElement["Nome"] = studEval.name;
                  newElement["Critério"] = crit.criterion;
                  newElement["Avaliação Critério"] = crit.evaluation;
                  if (studEval.finalGrade == null) {
                    newElement["Avaliação Final"] = studEval.autoFinalGrade;
                  } else {
                    newElement["Avaliação Final"] = studEval.finalGrade;
                  }
                  if (studEval.finalEvalLevel != null) {
                    newElement["Nível Aval Final"] = studEval.finalEvalLevel;
                  }
                  data.push(newElement);

                  
                } */

                crit.essenLearnCritFlag = false;
                crit.dynamicIndex = k;
                crit.subDynamicIndex = w;

                crit.name = studEval.name;
                if (studEval.studentFinalCritEvals[w].autoCritEvalAvg) {
                  crit.evaluationCrit = studEval.studentFinalCritEvals[w].autoCritEvalAvg;
                  autoEvalsOnlyFlag = true;
                } else {
                  crit.evaluationCrit = studEval.studentFinalCritEvals[w].evaluation;
                }

                if (studEval.finalGrade == null) {
                  crit.finalGradeCrit = studEval.autoFinalGrade;
                } else {
                  crit.finalGradeCrit = studEval.finalGrade;
                }

                if (studEval.finalEvalLevel != null) {
                  crit.finalEvalLevel = studEval.finalEvalLevel;
                }

                data.push(crit);
              }
            }
          }

          if (!autoEvalsOnlyFlag) {
            columnDefs = [
              {
                name: "name",
                displayName: "Nome",
                enableCellEdit: false,
              },
              {
                name: "criterion",
                displayName: "Critério",
                enableCellEdit: false,
              },
              {
                name: "criterionWeight",
                displayName: "Ponderação Critério",
                enableCellEdit: false,
              },
              {
                name: "evaluationCrit",
                displayName: "Aval Crit",
                cellClass: "ptAvalColumnToEdit",
                treeAggregationType: scope.uiGridGroupingConstants.aggregation.AVG,
                customTreeAggregationFinalizerFn: function (aggregation) {
                  aggregation.rendered = String(aggregation.value).substr(0, 4);
                },
                checkAfterEditCell:
                  "if(rowEntity.essenLearnCritFlag) {{}.studentFinalCritEvaluations[rowEntity.dynamicIndex].evaluationCrit = oldValue; true}",
                checkBeforeCalcFunc:
                  "if(}{.dynamicIndex != rowEntity.dynamicIndex || }{.subDynamicIndex != rowEntity.subDynamicIndex) {true} else {false}",
                calculatedFunction:
                  "{}.studentFinalCritEvaluations[>>].studentFinalCritEvals[rowEntity.subDynamicIndex].evaluation = rowEntity.evaluationCrit; rowEntity.evaluationCrit;",
              },
              {
                name: "finalGradeCrit",
                displayName: "Aval Final",
                enableCellEdit: false,
                treeAggregationType: scope.uiGridGroupingConstants.aggregation.AVG,
                customTreeAggregationFinalizerFn: function (aggregation) {
                  aggregation.rendered = String(aggregation.value).substr(0, 4);
                },
                checkBeforeCalcFunc: "if(rowEntity.essenLearnCritFlag) {true} else {false}",
                calculatedFunction:
                  "if ({}.studentFinalCritEvaluations[>>].studentFinalEssenLearnCritEvals != null && {}.studentFinalCritEvaluations[>>].studentFinalEssenLearnCritEvals.length > 0) { let autoFinalGrade = 0; if ({}.studentFinalCritEvaluations[>>].finalEssenLearnCritEvalGrade != null) { autoFinalGrade += (Number({}.studentFinalCritEvaluations[>>].finalEssenLearnCritEvalGrade) * ({}.studentFinalCritEvaluations[>>].essenLearnCritWeight / 100)); } else { autoFinalGrade += (Number({}.studentFinalCritEvaluations[>>].autoFinalEssenLearnCritEvalGrade) * ({}.studentFinalCritEvaluations[>>].essenLearnCritWeight / 100)); } if ({}.studentFinalCritEvaluations[>>].studentFinalCritEvals != null && {}.studentFinalCritEvaluations[>>].studentFinalCritEvals.length > 0) { {}.studentFinalCritEvaluations[>>].studentFinalCritEvals.map(function (ev) { if (ev.evaluation == null) { autoFinalGrade = null; } else { autoFinalGrade += (Number(ev.evaluation) * (ev.criterionWeight / 100)); } });} if (autoFinalGrade != null && !isNaN(autoFinalGrade) ) { Math.round(autoFinalGrade * 100) / 100 } }",
              },
              {
                name: "finalEvalLevel",
                displayName: "Nível Aval Final",
                enableCellEdit: false,
                treeAggregationType: scope.uiGridGroupingConstants.aggregation.AVG,
                customTreeAggregationFinalizerFn: function (aggregation) {
                  aggregation.rendered = String(aggregation.value).substr(0, 4);
                },
                calculatedFunction:
                  "ptGetFinalEvalLevel($scope.genericScope, $scope.selected, index, $scope.genericScope.getFromTableData('Class_Plan_Final_Evaluation_Levels'), }{.subDynamicIndex, }{.finalGradeCrit)",
              },
            ];
          } else {
            columnDefs = [
              {
                name: "name",
                displayName: "Nome",
                enableCellEdit: false,
              },
              {
                name: "criterion",
                displayName: "Critério",
                enableCellEdit: false,
              },
              {
                name: "criterionWeight",
                displayName: "Ponderação Critério",
                enableCellEdit: false,
              },
              {
                name: "evaluationCrit",
                displayName: "Aval Crit",
                enableCellEdit: false,
                treeAggregationType: scope.uiGridGroupingConstants.aggregation.AVG,
                customTreeAggregationFinalizerFn: function (aggregation) {
                  aggregation.rendered = String(aggregation.value).substr(0, 4);
                },
              },
              {
                name: "finalGradeCrit",
                displayName: "Aval Final",
                enableCellEdit: false,
                treeAggregationType: scope.uiGridGroupingConstants.aggregation.AVG,
                customTreeAggregationFinalizerFn: function (aggregation) {
                  aggregation.rendered = String(aggregation.value).substr(0, 4);
                },
              },
              {
                name: "finalEvalLevel",
                displayName: "Nível Aval Final",
                enableCellEdit: false,
                treeAggregationType: scope.uiGridGroupingConstants.aggregation.AVG,
                customTreeAggregationFinalizerFn: function (aggregation) {
                  aggregation.rendered = String(aggregation.value).substr(0, 4);
                },
              },
            ];
          }
        }
      }
      data.splice(0, 1);
    }

    function cleanElementAndSetupRowBackground(studRecord, studEval) {
      // Insert aditional info in first row of each student
      if (studRecord.name + studRecord.dynamicIndex != lastName) {
        rowBackgroundColorID == 0 ? (rowBackgroundColorID = 1) : (rowBackgroundColorID = 0);
        studRecord.rowBackgroundColorID = rowBackgroundColorID;
        lastName = studRecord.name + studRecord.dynamicIndex;
        if (studEval.studentClassOrder) {
          studRecord.classOrder = studEval.studentClassOrder;
        } else if (studEval.student != null && studEval.student.classOrder != null) {
          studRecord.classOrder = studEval.student.classOrder;
        }
      } else {
        studRecord.rowBackgroundColorID = rowBackgroundColorID;
      }
    }

    if (columnDefs.length > 0) {
      return {
        data: data,
        columnDefs: columnDefs,
      };
    } else {
      return data;
    }
  },
  ptSplitExpertiseFields: function ptSplitExpertiseFields(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      //Loops until letter 'j' of the expertise fields
      for (let k = 0; k < 10; k++) {
        let field = (k + 10).toString(36);

        if (el[field] && el[field].length > 0) {
          for (let j = 0; j < el[field].length; j++) {
            const expertiseField = el[field][j];
            let newElement = JSON.parse(JSON.stringify(el));
            newElement["expertiseFieldDesc"] = expertiseField;
            newElement["expertiseFieldDescFulfilled"] = el[field].length;
            let fromTableData = scope.getFromTableData(scope.activeTab.fields["expertiseFieldDesc"].fromTable);
            let filteredFieldDescs = fromTableData.filter(
              (fieldDesc) =>
                fieldDesc.expertiseField ==
                fromTableData.filter((fieldDescAux) => fieldDescAux.id == newElement["expertiseFieldDesc"])[0]
                  .expertiseField
            );
            newElement["totalExpertiseFieldDesc"] = filteredFieldDescs.length;
            newElement["expertiseField"] = filteredFieldDescs[0].expertiseField;
            newElement["expertiseFieldDescFulfilledPer"] = (
              (newElement["expertiseFieldDescFulfilled"] / newElement["totalExpertiseFieldDesc"]) *
              100
            ).toFixed(1);
            data.unshift(newElement);
            i++;
          }
        }
      }

      data.splice(i, 1);
    }
  },
  ptCheckEssentialLearning: function ptCheckEssentialLearning(scope, selected) {
    if (selected.essentialLearning) {
      if ([";", ".", " "].indexOf(selected.essentialLearning[selected.essentialLearning.length - 1]) != -1) {
        scope.genericScope.$parent.alertPTEssentialLearningInvalidName();
        return false;
      }
    }
    return true;
  },
  ptCheckEvalInstr: function ptCheckEvalInstr(scope, selected) {
    let selectedEssenLearns = [];
    let expertFieldFlag = false;

    // Blank expertise fields, verification not necessary

    for (let k = 0; k < 10; k++) {
      let field = (k + 10).toString(36);
      if (selected[field]) {
        if (selected[field].length > 0) {
          expertFieldFlag = true;
        }
      }
    }

    if (selected.attitudesEvalInstrFlag && selected.attitudesEvalInstrFlag.length > 0) {
      if (
        selected["attitudeCrits"] == null ||
        (selected["attitudeCrits"] != null &&
          Array.isArray(selected["attitudeCrits"]) &&
          selected["attitudeCrits"].length == 0)
      ) {
        scope.genericScope.$parent.alertPTEvalInstrNoAttCrit();
        return false;
      }
    } else {
      if (
        !expertFieldFlag &&
        (selected["essentialLearningDomainWeight"] == null ||
          (selected["essentialLearningDomainWeight"] != null &&
            Array.isArray(selected["essentialLearningDomainWeight"]) &&
            selected["essentialLearningDomainWeight"].length == 0))
      ) {
        scope.genericScope.$parent.alertPTEvalInstrNoExptFieldAndEssenLearn();
        return false;
      }
    }

    let essenLearns = scope.getFromTableData("Class_Plan_Essential_Learnings");

    let professionalFlag = false;

    if (essenLearns == null) {
      essenLearns = scope.getFromTableData("Class_Plan_Module_Essential_Learnings");
      if (essenLearns != null) {
        professionalFlag = true;
      }
    }

    // Essential Learning check

    if (selected["essentialLearningDomainWeight"] && selected["essentialLearningDomainWeight"].length > 0) {
      for (let i = 0; i < selected["essentialLearningDomainWeight"].length; i++) {
        const essenLearnDomainWeight = selected["essentialLearningDomainWeight"][i];

        // Duplicated essential learning

        if (selectedEssenLearns.indexOf(essenLearnDomainWeight.essentialLearning) != -1) {
          scope.genericScope.$parent.alertPTDupliEssenLearn();
          return false;
        } else {
          selectedEssenLearns.push(essenLearnDomainWeight.essentialLearning);
        }

        //Invalid/blank essential learning

        let filteredEssenLearn;

        if (!professionalFlag) {
          filteredEssenLearn = essenLearns.filter(
            (el) =>
              el.id == essenLearnDomainWeight.essentialLearning &&
              el.schoolYear == selected.schoolYear &&
              el.subject == selected.subject
          )[0];
        } else {
          filteredEssenLearn = essenLearns.filter(
            (el) => el.id == essenLearnDomainWeight.essentialLearning && el.module.indexOf(selected.module) != -1
          )[0];
        }

        if (filteredEssenLearn == null) {
          scope.genericScope.$parent.alertPTInvalidBlankEssenLearn();
          return false;
        }
      }
    }

    // Instrument Questions check

    if (selected["instrumentQuestions"] && selected["instrumentQuestions"].length > 0) {
      let questionEssenLearnWeightMap = {};
      let essentialLearningsWithInvalidQuestionsDomainTotalWeight = [];

      for (let i = 0; i < selected["instrumentQuestions"].length; i++) {
        const instrEvalLearnQuestions = selected["instrumentQuestions"][i];

        // Not selected essential learning

        if (selectedEssenLearns.indexOf(instrEvalLearnQuestions.essentialLearning) == -1) {
          scope.genericScope.$parent.alertPTNotSelectedEssenLearn();
          return false;
        }

        let filteredEssenLearn;

        if (!professionalFlag) {
          filteredEssenLearn = essenLearns.filter(
            (el) =>
              el.id == instrEvalLearnQuestions.essentialLearning &&
              el.schoolYear == selected.schoolYear &&
              el.subject == selected.subject
          )[0];
        } else {
          filteredEssenLearn = essenLearns.filter(
            (el) => el.id == instrEvalLearnQuestions.essentialLearning && el.module.indexOf(selected.module) != -1
          )[0];
        }

        if (filteredEssenLearn == null) {
          scope.genericScope.$parent.alertPTInvalidBlankEssenLearn();
          return false;
        }

        if (questionEssenLearnWeightMap[instrEvalLearnQuestions.essentialLearning] == null) {
          questionEssenLearnWeightMap[instrEvalLearnQuestions.essentialLearning] = {};
          questionEssenLearnWeightMap[instrEvalLearnQuestions.essentialLearning].totalWeight =
            instrEvalLearnQuestions.weight;
          questionEssenLearnWeightMap[instrEvalLearnQuestions.essentialLearning].parsedEssentialLearning =
            filteredEssenLearn.essentialLearning;
        } else {
          questionEssenLearnWeightMap[instrEvalLearnQuestions.essentialLearning].totalWeight +=
            instrEvalLearnQuestions.weight;
        }
      }

      for (const key in questionEssenLearnWeightMap) {
        if (questionEssenLearnWeightMap.hasOwnProperty(key)) {
          const essenLearn = questionEssenLearnWeightMap[key];
          if (essenLearn.totalWeight != 100) {
            if (
              essentialLearningsWithInvalidQuestionsDomainTotalWeight.indexOf(
                essenLearn.parsedEssentialLearning + " (" + essenLearn.totalWeight + "/100)"
              ) == -1
            ) {
              essentialLearningsWithInvalidQuestionsDomainTotalWeight.push(
                essenLearn.parsedEssentialLearning + " (" + essenLearn.totalWeight + "/100)"
              );
            }
          }
        }
      }

      if (
        selected.freeQuotationEvalInstrFlag == null ||
        (Array.isArray(selected.freeQuotationEvalInstrFlag) && selected.freeQuotationEvalInstrFlag.length == 0) ||
        (selected.freeQuotationEvalInstrFlagcheckbox &&
          selected.freeQuotationEvalInstrFlagcheckbox[""] &&
          selected.freeQuotationEvalInstrFlagcheckbox[""] == false)
      ) {
        if (essentialLearningsWithInvalidQuestionsDomainTotalWeight.length > 0) {
          scope.genericScope.$parent.alertPTInstrInvalidQuestionsDomainTotalWeight(
            essentialLearningsWithInvalidQuestionsDomainTotalWeight.sort()
          );
          return false;
        }
      }

      for (let j = 0; j < selectedEssenLearns.length; j++) {
        const selectedEssennLearn = selectedEssenLearns[j];
        if (Object.keys(questionEssenLearnWeightMap).indexOf(selectedEssennLearn) == -1) {
          scope.genericScope.$parent.alertPTMissingEssenLearnQuestions();
          return false;
        }
      }
    }

    // Missing questions with free questions checkbox selected
    else if (
      selected.freeQuotationEvalInstrFlagcheckbox &&
      selected.freeQuotationEvalInstrFlagcheckbox[""] &&
      selected.freeQuotationEvalInstrFlagcheckbox[""] == true
    ) {
      scope.genericScope.$parent.alertPTFreeQuotationMissingEssenLearnQuestions();
      return false;
    }

    // Invalid instrument performance level desc and no questions
    else if (
      selected.performanceLevelDesc != null &&
      (selected["instrumentQuestions"] == null ||
        (selected["instrumentQuestions"] != null && selected["instrumentQuestions"].length == 0))
    ) {
      scope.genericScope.$parent.alertPTMissingEssenLearnQuestionsWithPerformanceDesc();
      return false;
    }

    return true;
  },
  ptCheckEvalCrit: function ptCheckEvalCrit(scope, selected) {
    let domainWeight = 0;
    let essenLearnCrit = false;

    // Eval Crit check

    if (selected["evaluationCriteria"] && selected["evaluationCriteria"].length > 0) {
      for (let i = 0; i < selected["evaluationCriteria"].length; i++) {
        const evalCriteria = selected["evaluationCriteria"][i];
        domainWeight += evalCriteria.weight;
        if (essenLearnCrit && evalCriteria.essenLearnCrit && evalCriteria.essenLearnCrit.length > 0) {
          scope.genericScope.$parent.alertPTEvalCritEssenLearn();
          return false;
        }
        if (evalCriteria.essenLearnCrit && evalCriteria.essenLearnCrit.length > 0) {
          essenLearnCrit = true;
        }
      }

      if (!essenLearnCrit) {
        scope.genericScope.$parent.alertPTEvalCritEssenLearn();
        return false;
      }

      // Total domain weight check

      if (domainWeight != 100) {
        scope.genericScope.$parent.alertPTInvaTotalWeight();
        return false;
      }
    } else {
      scope.genericScope.$parent.alertPTEvalCritNoDomains();
      return false;
    }

    let selectedEssenLearns = [];
    let essenLearnTotalWeight = 0;

    // Essential Learning check

    if (selected["essentialLearningDomainWeight"] && selected["essentialLearningDomainWeight"].length > 0) {
      let essenLearns = scope.getFromTableData("Class_Plan_Essential_Learnings");

      for (let i = 0; i < selected["essentialLearningDomainWeight"].length; i++) {
        const essenLearnDomainWeight = selected["essentialLearningDomainWeight"][i];

        essenLearnTotalWeight += essenLearnDomainWeight.essentialLearningWeight;

        // Invalid essential learning weight

        if (essenLearnTotalWeight > 100 || essenLearnTotalWeight < 1) {
          scope.genericScope.$parent.alertPTInvaTotalWeight();
          return false;
        }

        // Duplicated essential learning

        if (selectedEssenLearns.indexOf(essenLearnDomainWeight.essentialLearning) != -1) {
          scope.genericScope.$parent.alertPTDupliEssenLearn();
          return false;
        } else {
          selectedEssenLearns.push(essenLearnDomainWeight.essentialLearning);
        }

        // Invalid/blank essential learning

        let essenLearns = scope.getFromTableData("Class_Plan_Essential_Learnings");
        let professionalFlag = false;

        if (essenLearns == null) {
          essenLearns = scope.getFromTableData("Class_Plan_Module_Essential_Learnings");
          if (essenLearns != null) {
            professionalFlag = true;
          }
        }

        if (essenLearns != null) {
          if (!professionalFlag) {
            let filteredEssenLearn = essenLearns.filter(
              (el) =>
                el.id == essenLearnDomainWeight.essentialLearning &&
                el.schoolYear == selected.schoolYear &&
                el.subject == selected.subject
            )[0];

            if (filteredEssenLearn == null) {
              scope.genericScope.$parent.alertPTInvalidBlankEssenLearn();
              return false;
            }
          } else {
            let filteredEssenLearn = essenLearns.filter(
              (el) => el.id == essenLearnDomainWeight.essentialLearning && el.module.indexOf(selected.module) != -1
            )[0];

            if (filteredEssenLearn == null) {
              scope.genericScope.$parent.alertPTInvalidBlankEssenLearn();
              return false;
            }
          }
        }
      }

      if (essenLearnTotalWeight != 100) {
        scope.genericScope.$parent.alertPTInvaTotalWeight();
        return false;
      }
    } else {
      scope.genericScope.$parent.alertPTNoSelectedEssenLearn();
      return false;
    }

    return true;
  },
  ptCheckFinalEvalLevel: function ptCheckFinalEvalLevel(scope, selected) {
    // Eval Level check

    if (selected["finalEvalLevel"] && selected["finalEvalLevel"].length > 0) {
      let evalLimitArray = [];
      let evalLevelArray = [];

      for (let i = 0; i < selected["finalEvalLevel"].length; i++) {
        let finalEvalLevel = selected["finalEvalLevel"][i];

        if (finalEvalLevel.bottomEvalLimit > finalEvalLevel.topEvalLimit) {
          scope.genericScope.$parent.alertPTBottomGTTopEvalLimit(
            finalEvalLevel.topEvalLimit,
            finalEvalLevel.bottomEvalLimit
          );
          return false;
        }

        if (
          finalEvalLevel.bottomEvalLimit < 0 ||
          finalEvalLevel.bottomEvalLimit > 100 ||
          finalEvalLevel.topEvalLimit < 0 ||
          finalEvalLevel.topEvalLimit > 100
        ) {
          scope.genericScope.$parent.alertPTExceedingEvalLimits();
          return false;
        }

        /* if (finalEvalLevel.evalLevel < 0 || finalEvalLevel.evalLevel > 5) {
          scope
            .genericScope
            .$parent
            .alertPTExceedingEvalLevel();
          return false;
        } */

        /* if (Object.keys(lastFinalEvalLevel).length > 0) {
          if (lastFinalEvalLevel.bottomEvalLimit > finalEvalLevel.bottomEvalLimit || lastFinalEvalLevel.bottomEvalLimit > finalEvalLevel.topEvalLimit || lastFinalEvalLevel.topEvalLimit > finalEvalLevel.bottomEvalLimit || lastFinalEvalLevel.topEvalLimit > finalEvalLevel.topEvalLimit) {

            return;
          }
        } */

        /* if (evalLimitArray.length > 0 && (evalLimitArray.indexOf(finalEvalLevel.bottomEvalLimit) != -1 || evalLimitArray.indexOf(finalEvalLevel.topEvalLimit) != -1)) {
          scope
            .genericScope
            .$parent
            .alertPTWrongEvalLimitDefinition();
          return false;
        } */

        if (evalLevelArray.length > 0 && evalLevelArray.indexOf(finalEvalLevel.evalLevel) != -1) {
          scope.genericScope.$parent.alertPTDuplicateLevel();
          return false;
        }

        evalLimitArray.push({
          botLimit: finalEvalLevel.bottomEvalLimit,
          topLimit: finalEvalLevel.topEvalLimit,
        });
        evalLevelArray.push(finalEvalLevel.evalLevel);
      }

      evalLimitArray.sort(function (a, b) {
        if (a["botLimit"] < b["botLimit"]) {
          return -1;
        }
        if (a["botLimit"] > b["botLimit"]) {
          return 1;
        }
      });

      let lastLimit;
      for (let j = 0; j < evalLimitArray.length; j++) {
        let limit = evalLimitArray[j];

        if (lastLimit != null) {
          if (Math.floor(lastLimit.topLimit) != Math.floor(limit.botLimit) - 1) {
            scope.genericScope.$parent.alertPTWrongEvalLimitDefinition();
            return false;
          }
        }

        if (j == 0) {
          if (limit.botLimit != 0) {
            scope.genericScope.$parent.alertPTWrongEvalLimitDefinition();
            return false;
          }
          lastLimit = limit;
          continue;
        }

        /* if (j == evalLimitArray.length - 1) {
          if (limit.topLimit != 100) {
            scope
              .genericScope
              .$parent
              .alertPTWrongEvalLimitDefinition();
            return false;
          }
        } */

        lastLimit = limit;
      }
    }

    return true;
  },
  ptEvalInstrumentSchoolRecordCheck: function ptEvalInstrumentSchoolRecordCheck(scope, selected) {
    if (scope.mode == "modify") {
      return new Promise((resolve, reject) => {
        var confirm = scope.$mdDialog
          .confirm()
          .title("Alteração de Instrumento")
          .textContent(
            "Ao alterar este Instrumento de Avaliação vai remover todas as avaliações (no módulo 'Avaliação') lançadas com base neste. Deseja continuar?"
          )
          .ariaLabel("Confirmar alteração")
          .ok("Não")
          .cancel("Sim, apagar avaliações")
          .clickOutsideToClose(true);

        scope.$mdDialog.show(confirm).then(
          function () {
            resolve(false);
          },
          function () {
            scope.parentScope.$parent.showLoader();

            scope.genericFactory.setRouteName("Class_Plan_School_Records");

            let map = {};
            map.schoolYear = selected.schoolYear;
            map.subject = selected.subject;
            map.period = selected.period;

            scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (data) {
              for (let i = 0; i < data.length; i++) {
                const evalInstrSchoolRecord = data[i];
                if (evalInstrSchoolRecord.evalInstrument == selected.id) {
                  evalInstrSchoolRecord.studentEvaluations = [];
                  evalInstrSchoolRecord["evalInstrResetAt"] = Date.now();
                  evalInstrSchoolRecord["evalInstrResetBy"] = scope.currentUser;
                  scope.genericFactory.modify(evalInstrSchoolRecord.id, evalInstrSchoolRecord).then(() => {});
                }
              }
              scope.genericFactory.setRouteName("Class_Plan_Evaluation_Instruments");
              scope.parentScope.$parent.hideLoader();
              resolve(true);
            });
          }
        );
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    }
  },
  ptCritSchoolRecordCheck: function ptCritSchoolRecordCheck(scope, selected) {
    if (scope.mode == "modify") {
      return new Promise((resolve, reject) => {
        var confirm = scope.$mdDialog
          .confirm()
          .title("Alteração de Critério de Avaliação")
          .textContent(
            "Ao alterar este Critério de Avaliação vai remover todas as Avaliações Finais (no módulo 'Avaliação') lançadas com base neste. Deseja continuar?"
          )
          .ariaLabel("Confirmar alteração")
          .ok("Não")
          .cancel("Sim, apagar avaliações")
          .clickOutsideToClose(true);

        scope.$mdDialog.show(confirm).then(
          function () {
            resolve(false);
          },
          function () {
            scope.parentScope.$parent.showLoader();

            scope.genericFactory.setRouteName("Class_Plan_School_Records");

            let map = {};
            map.schoolYear = selected.schoolYear;
            map.subject = selected.subject;

            scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (data) {
              for (let i = 0; i < data.length; i++) {
                const critSchoolRecord = data[i];
                if (critSchoolRecord.subjectCriteria && critSchoolRecord.subjectCriteria.id == selected.id) {
                  console.log(critSchoolRecord);
                  critSchoolRecord.studentFinalCritEvaluations = [];
                  critSchoolRecord["critResetAt"] = Date.now();
                  critSchoolRecord["critResetBy"] = scope.currentUser;
                  scope.genericFactory.modify(critSchoolRecord.id, critSchoolRecord).then(() => {});
                }
              }
              scope.genericFactory.setRouteName("Class_Plan_Evaluation_Crits");
              scope.parentScope.$parent.hideLoader();
              resolve(true);
            });
          }
        );
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    }
  },
  ptUpdateEvalInstrumentQualitativeLevels: function ptUpdateEvalInstrumentQualitativeLevels(scope, selected, data) {
    return new Promise((resolve, reject) => {
      scope.parentScope.$parent.showLoader();

      // Load selected eval instrument
      let evalInstrument = scope
        .getFromTableData(scope.activeTab.fields["evalInstrument"].fromTable)
        .filter((el) => el.id == selected["evalInstrument"])[0];

      let professionalFlag = false;
      if (scope.deps.indexOf("Class_Plan_Prof_Evaluation_Instruments") == -1) {
        scope.genericFactory.setRouteName("Class_Plan_Essential_Learnings");
      } else {
        scope.genericFactory.setRouteName("Class_Plan_Module_Essential_Learnings");
        professionalFlag = true;
      }
      scope.genericFactory
        .getByProperty("organization", scope.currentUser.organization)
        .then(function (essentialLearnings) {
          //Get performance crits to parse them on the question descriptions
          scope.genericFactory.setRouteName("Class_Plan_Performance_Crits");
          scope.genericFactory
            .getByProperty("organization", scope.currentUser.organization)
            .then(function (performanceCrits) {
              if (evalInstrument.instrumentQuestions && evalInstrument.instrumentQuestions.length > 0) {
                evalInstrument.instrumentQuestions = evalInstrument.instrumentQuestions.map(function (
                  instrEvalLearnQuestions
                ) {
                  let newEl = {};
                  newEl = instrEvalLearnQuestions;
                  newEl.parsedEssentialLearning = essentialLearnings.filter(
                    (el) => el.id == newEl.essentialLearning
                  )[0].essentialLearning;
                  //Parse performance crit if there are any
                  if (newEl.performanceCrit) {
                    newEl.parsedPerformanceCrit = performanceCrits.filter(
                      (el) => el.id == newEl.performanceCrit
                    )[0].performanceCrit;
                  }
                  return newEl;
                });
              } else {
                evalInstrument.essentialLearningDomainWeight = evalInstrument.essentialLearningDomainWeight.map(
                  function (essenLearnDomainWeight) {
                    let newEl = {};
                    newEl = essenLearnDomainWeight;
                    newEl.parsedEssentialLearning = essentialLearnings.filter(
                      (el) => el.id == newEl.essentialLearning
                    )[0].essentialLearning;
                    //Parse performance crit if there are any
                    if (newEl.performanceCrit) {
                      newEl.parsedPerformanceCrit = performanceCrits.filter(
                        (el) => el.id == newEl.performanceCrit
                      )[0].performanceCrit;
                    }
                    return newEl;
                  }
                );
              }

              let map = {};
              map.year = selected.year;
              map.schoolYear = new Array(selected.schoolYear);
              map.subject = new Array(selected.subject);
              scope.genericFactory.setRouteName("Class_Plan_Eval_Qualitative_Levels");
              scope.genericFactory
                .getByProperties(map, scope.currentUser.organization)
                .then(function (evalInstrumentQualitativeLevels) {
                  //Prepare qualitative levels according to performance levels associated with the eval instrument
                  if (
                    evalInstrument.performanceLevelDesc &&
                    evalInstrumentQualitativeLevels &&
                    evalInstrumentQualitativeLevels.length > 0
                  ) {
                    evalInstrumentQualitativeLevels = evalInstrumentQualitativeLevels.filter(
                      (el) => el.id == evalInstrument.performanceLevelDesc
                    )[0];
                    if (evalInstrumentQualitativeLevels) {
                      if (evalInstrumentQualitativeLevels.performanceCritDesc != null) {
                        delete evalInstrumentQualitativeLevels.performanceCritDesc;
                      }
                      selected.evalInstrumentQualitativeLevels = JSON.stringify(evalInstrumentQualitativeLevels);
                      if (
                        evalInstrumentQualitativeLevels.qualitativeLevels &&
                        Array.isArray(evalInstrumentQualitativeLevels.qualitativeLevels) &&
                        evalInstrumentQualitativeLevels.qualitativeLevels.length > 0
                      ) {
                        selected.qualitativeLevels = null;
                        if (evalInstrument.instrumentQuestions && evalInstrument.instrumentQuestions.length > 0) {
                          for (let n = 0; n < evalInstrument.instrumentQuestions.length; n++) {
                            let evalQuestion = evalInstrument.instrumentQuestions[n];
                            evalQuestion.qualitativeEval = true;
                            if (selected.qualitativeLevels == null) {
                              selected.qualitativeLevels = {};
                            }
                            if (selected.qualitativeLevels[evalQuestion.parsedEssentialLearning] == null) {
                              selected.qualitativeLevels[evalQuestion.parsedEssentialLearning] = [];
                            } else {
                              continue;
                            }
                            evalInstrumentQualitativeLevels.qualitativeLevels.forEach((qualLevel) => {
                              selected.qualitativeLevels[evalQuestion.parsedEssentialLearning].push(
                                qualLevel.qualitativeLevel
                              );
                            });
                          }
                        } else if (
                          evalInstrument.essentialLearningDomainWeight &&
                          evalInstrument.essentialLearningDomainWeight.length > 0
                        ) {
                          for (let n = 0; n < evalInstrument.essentialLearningDomainWeight.length; n++) {
                            let evalDomain = evalInstrument.essentialLearningDomainWeight[n];
                            evalDomain.qualitativeEval = true;
                            if (selected.qualitativeLevels == null) {
                              selected.qualitativeLevels = {};
                            }
                            if (selected.qualitativeLevels[evalDomain.parsedEssentialLearning] == null) {
                              selected.qualitativeLevels[evalDomain.parsedEssentialLearning] = [];
                            } else {
                              continue;
                            }
                            evalInstrumentQualitativeLevels.qualitativeLevels.forEach((qualLevel) => {
                              selected.qualitativeLevels[evalDomain.parsedEssentialLearning].push(
                                qualLevel.qualitativeLevel
                              );
                            });
                          }
                        }
                      }
                    }
                  }
                  if (professionalFlag) {
                    scope.genericFactory.setRouteName("Class_Plan_Prof_School_Records");
                  } else {
                    scope.genericFactory.setRouteName("Class_Plan_School_Records");
                  }
                  resolve(true);
                  scope.genericScope.$parent.alertPTUpdateEvalInstrumentQualitativeLevels();
                  scope.parentScope.$parent.hideLoader();
                });
            });
        });
    });
  },
  ptLoadSchoolRecordInstrEval: function ptLoadSchoolRecordInstrEval(scope, selected, data) {
    return new Promise((resolve, reject) => {
      if (selected.studentFinalCritEvaluations && selected.studentFinalCritEvaluations.length > 0) {
        scope.genericScope.$parent.alertPTAlreadyFinalEval();
        resolve(false);
      }

      if (selected.studentEvaluations && selected.studentEvaluations.length > 0) {
        let resetEvals = scope.$mdDialog
          .confirm()
          .title("Atualizar Informação de Avaliações de Instrumento")
          .textContent(
            "Já tem registos carregados. Deseja manter esta informação? Se responder 'NÃO' o sistema irá eliminar as avaliações previamente efetuadas."
          )
          .ariaLabel("Confirmar atualização")
          .ok("Sim")
          .cancel("Não")
          .clickOutsideToClose(false);
        scope.$mdDialog.show(resetEvals).then(
          function () {
            resolve(true);
          },
          function () {
            ptLoadSchoolRecordInstrEval(scope, selected, data);
          }
        );
      } else {
        ptLoadSchoolRecordInstrEval(scope, selected, data);
      }

      function ptLoadSchoolRecordInstrEval(scope, selected, data) {
        scope.parentScope.$parent.showLoader();
        selected.studentEvaluations = [];
        // Load selected eval instrument
        let evalInstrument = scope
          .getFromTableData(scope.activeTab.fields["evalInstrument"].fromTable)
          .filter((el) => el.id == selected["evalInstrument"])[0];
        // Load eval instrument expertise fields
        let loadedExpertiseDescs = [];
        for (let k = 0; k < 10; k++) {
          let field = (k + 10).toString(36);
          if (evalInstrument[field]) {
            if (evalInstrument[field].length > 0) {
              loadedExpertiseDescs = loadedExpertiseDescs.concat(evalInstrument[field]);
            }
          }
        }
        selected["loadedExpertiseDescs"] = loadedExpertiseDescs;
        // Setup free quotation flag
        if (
          evalInstrument.freeQuotationEvalInstrFlagcheckbox &&
          evalInstrument.freeQuotationEvalInstrFlagcheckbox[""] &&
          evalInstrument.freeQuotationEvalInstrFlagcheckbox[""] == true
        ) {
          selected.freeQuotationEvalInstrFlag = true;
          selected.correctionByEssenLearnWeightcheckbox = { "": true };
        } else {
          selected.freeQuotationEvalInstrFlag = false;
        }
        // Parse eval instruments essential learnings if there is any
        if (
          evalInstrument.essentialLearningDomainWeight != null &&
          evalInstrument.essentialLearningDomainWeight.length > 0
        ) {
          let professionalFlag = false;
          if (scope.deps.indexOf("Class_Plan_Prof_Evaluation_Instruments") == -1) {
            scope.genericFactory.setRouteName("Class_Plan_Essential_Learnings");
          } else {
            scope.genericFactory.setRouteName("Class_Plan_Module_Essential_Learnings");
            professionalFlag = true;
          }
          scope.genericFactory
            .getByProperty("organization", scope.currentUser.organization)
            .then(function (essentialLearnings) {
              //Get performance crits to parse them on the question descriptions
              scope.genericFactory.setRouteName("Class_Plan_Performance_Crits");
              scope.genericFactory
                .getByProperty("organization", scope.currentUser.organization)
                .then(function (performanceCrits) {
                  if (evalInstrument.instrumentQuestions && evalInstrument.instrumentQuestions.length > 0) {
                    evalInstrument.instrumentQuestions = evalInstrument.instrumentQuestions.map(function (
                      instrEvalLearnQuestions
                    ) {
                      let newEl = {};
                      newEl = instrEvalLearnQuestions;
                      newEl.parsedEssentialLearning = essentialLearnings.filter(
                        (el) => el.id == newEl.essentialLearning
                      )[0].essentialLearning;
                      //Parse performance crit if there are any
                      if (newEl.performanceCrit) {
                        newEl.parsedPerformanceCrit = performanceCrits.filter(
                          (el) => el.id == newEl.performanceCrit
                        )[0].performanceCrit;
                      }
                      return newEl;
                    });
                  } else {
                    evalInstrument.essentialLearningDomainWeight = evalInstrument.essentialLearningDomainWeight.map(
                      function (essenLearnDomainWeight) {
                        let newEl = {};
                        newEl = essenLearnDomainWeight;
                        newEl.parsedEssentialLearning = essentialLearnings.filter(
                          (el) => el.id == newEl.essentialLearning
                        )[0].essentialLearning;
                        //Parse performance crit if there are any
                        if (newEl.performanceCrit) {
                          newEl.parsedPerformanceCrit = performanceCrits.filter(
                            (el) => el.id == newEl.performanceCrit
                          )[0].performanceCrit;
                        }
                        return newEl;
                      }
                    );
                  }

                  let map = {};
                  map.year = selected.year;
                  map.schoolYear = new Array(selected.schoolYear);
                  map.subject = new Array(selected.subject);
                  scope.genericFactory.setRouteName("Class_Plan_Eval_Qualitative_Levels");
                  scope.genericFactory
                    .getByProperties(map, scope.currentUser.organization)
                    .then(function (evalInstrumentQualitativeLevels) {
                      //Prepare qualitative levels according to performance levels associated with the eval instrument
                      if (
                        evalInstrument.performanceLevelDesc &&
                        evalInstrumentQualitativeLevels &&
                        evalInstrumentQualitativeLevels.length > 0
                      ) {
                        evalInstrumentQualitativeLevels = evalInstrumentQualitativeLevels.filter(
                          (el) => el.id == evalInstrument.performanceLevelDesc
                        )[0];
                        if (evalInstrumentQualitativeLevels) {
                          if (evalInstrumentQualitativeLevels.performanceCritDesc != null) {
                            delete evalInstrumentQualitativeLevels.performanceCritDesc;
                          }
                          selected.evalInstrumentQualitativeLevels = JSON.stringify(evalInstrumentQualitativeLevels);
                          if (
                            evalInstrumentQualitativeLevels.qualitativeLevels &&
                            Array.isArray(evalInstrumentQualitativeLevels.qualitativeLevels) &&
                            evalInstrumentQualitativeLevels.qualitativeLevels.length > 0
                          ) {
                            selected.qualitativeLevels = null;
                            if (evalInstrument.instrumentQuestions && evalInstrument.instrumentQuestions.length > 0) {
                              for (let n = 0; n < evalInstrument.instrumentQuestions.length; n++) {
                                let evalQuestion = evalInstrument.instrumentQuestions[n];
                                evalQuestion.qualitativeEval = true;
                                if (selected.qualitativeLevels == null) {
                                  selected.qualitativeLevels = {};
                                }
                                if (selected.qualitativeLevels[evalQuestion.parsedEssentialLearning] == null) {
                                  selected.qualitativeLevels[evalQuestion.parsedEssentialLearning] = [];
                                } else {
                                  continue;
                                }
                                evalInstrumentQualitativeLevels.qualitativeLevels.forEach((qualLevel) => {
                                  selected.qualitativeLevels[evalQuestion.parsedEssentialLearning].push(
                                    qualLevel.qualitativeLevel
                                  );
                                });
                              }
                            } else if (
                              evalInstrument.essentialLearningDomainWeight &&
                              evalInstrument.essentialLearningDomainWeight.length > 0
                            ) {
                              for (let n = 0; n < evalInstrument.essentialLearningDomainWeight.length; n++) {
                                let evalDomain = evalInstrument.essentialLearningDomainWeight[n];
                                evalDomain.qualitativeEval = true;
                                if (selected.qualitativeLevels == null) {
                                  selected.qualitativeLevels = {};
                                }
                                if (selected.qualitativeLevels[evalDomain.parsedEssentialLearning] == null) {
                                  selected.qualitativeLevels[evalDomain.parsedEssentialLearning] = [];
                                } else {
                                  continue;
                                }
                                evalInstrumentQualitativeLevels.qualitativeLevels.forEach((qualLevel) => {
                                  selected.qualitativeLevels[evalDomain.parsedEssentialLearning].push(
                                    qualLevel.qualitativeLevel
                                  );
                                });
                              }
                            }
                          }
                        }
                      }

                      //Get eval instrument specific expertise fields
                      if (evalInstrument.expertiseFields && evalInstrument.expertiseFields.length > 0) {
                        selected.expertiseFields = evalInstrument.expertiseFields;
                      } else if (selected.expertiseFields) {
                        selected.expertiseFields = null;
                      }

                      /* //Prepare qualitative levels according to qualitative levels associated with essentiallearnings
                selected.qualitativeLevels = null;
                if (evalInstrument.instrumentQuestions && evalInstrument.instrumentQuestions.length > 0) {
                  for (let n = 0; n < evalInstrument.instrumentQuestions.length; n++) {
                    let evalQuestion = evalInstrument.instrumentQuestions[n];
                    evalQuestion.qualitativeEval = false;
                    for (let k = 0; k < qualitativeLevels.length; k++) {
                      let qualitativeLevel = qualitativeLevels[k];
                      if (evalQuestion.essentialLearning && qualitativeLevel.essentialLearning.indexOf(evalQuestion.essentialLearning) != -1) {
                        evalQuestion.qualitativeEval = true;
                        if (selected.qualitativeLevels == null) {
                          selected.qualitativeLevels = {};
                        }
                        if (selected.qualitativeLevels[evalQuestion.parsedEssentialLearning] == null) {
                          selected.qualitativeLevels[evalQuestion.parsedEssentialLearning] = [];
                        } else {
                          continue;
                        }
                        qualitativeLevel.qualitativeLevels.forEach(qualLevel => {
                          selected.qualitativeLevels[evalQuestion.parsedEssentialLearning].push(qualLevel.qualitativeLevel);
                        });
                      }
                    }
                  }
                } else if (evalInstrument.essentialLearningDomainWeight && evalInstrument.essentialLearningDomainWeight.length > 0) {
                  for (let n = 0; n < evalInstrument.essentialLearningDomainWeight.length; n++) {
                    let evalDomain = evalInstrument.essentialLearningDomainWeight[n];
                    evalDomain.qualitativeEval = false;
                    for (let k = 0; k < qualitativeLevels.length; k++) {
                      let qualitativeLevel = qualitativeLevels[k];
                      if (evalDomain.essentialLearning && qualitativeLevel.essentialLearning.indexOf(evalDomain.essentialLearning) != -1) {
                        evalDomain.qualitativeEval = true;
                        if (selected.qualitativeLevels == null) {
                          selected.qualitativeLevels = {};
                        }
                        if (selected.qualitativeLevels[evalDomain.parsedEssentialLearning] == null) {
                          selected.qualitativeLevels[evalDomain.parsedEssentialLearning] = [];
                        } else {
                          continue;
                        }
                        qualitativeLevel.qualitativeLevels.forEach(qualLevel => {
                          selected.qualitativeLevels[evalDomain.parsedEssentialLearning].push(qualLevel.qualitativeLevel);
                        });
                      }
                    }
                  }
                } */

                      let schoolYears = scope.getFromTableData("Class_Plan_School_Years");
                      scope.genericFactory.setRouteName("Class_Plan_Final_Evaluation_Levels");
                      let map = {};
                      map.year = selected.year;
                      scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (data) {
                        if (selected.optionalFinalEvalLevels != null) {
                          selected.finalEvalLevels = data.filter(
                            (finalLevels) => finalLevels.id == selected.optionalFinalEvalLevels
                          )[0].finalEvalLevel;
                        } else {
                          let calculatedEduLevel = schoolYears.filter(
                            (schoYear) => schoYear.id == selected.schoolYear
                          )[0].educationLevel;
                          let finalEvalLevels = data.filter(
                            (finalLevels) => finalLevels.educationLevel == calculatedEduLevel
                          );
                          if (finalEvalLevels != null && finalEvalLevels.length) {
                            selected.finalEvalLevels = finalEvalLevels[0].finalEvalLevel;
                            // Setup maxTopEvalLimit to future calculations
                            let maxTopEvalLimit = 0;
                            selected.finalEvalLevels.forEach((limit) => {
                              if (limit.topEvalLimit > maxTopEvalLimit) {
                                maxTopEvalLimit = limit.topEvalLimit;
                              }
                            });
                            if (maxTopEvalLimit > 0) {
                              selected.maxTopEvalLimit = maxTopEvalLimit;
                            }
                          }
                        }

                        // Load students
                        let students = [];
                        // Get students from the kstk students collection in case of municipalities
                        if (scope.currentUser.organization == "60006e108b7f480098c8234c") {
                          scope.genericFactory.setRouteName("KSTK_Students");
                        } else {
                          scope.genericFactory.setRouteName("Class_Plan_Students");
                        }
                        let map = {};
                        map.year = selected.year;
                        map.schoolYear = selected.schoolYear;
                        map.class = selected.class;
                        scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (data) {
                          if (data != null && Array.isArray(data) && data.length > 0) {
                            students = data.filter((stud) => stud.status_matricula == "Matriculado");
                          }
                          let studentsWithClassOrder = [];
                          let studentWithoutClassOrder = [];

                          studentsWithClassOrder = students.filter((stud) => stud.classOrder != null);
                          studentWithoutClassOrder = students.filter((stud) => stud.classOrder == null);

                          studentsWithClassOrder = studentsWithClassOrder.sort(function compareModules(a, b) {
                            if (a.classOrder < b.classOrder) {
                              return -1;
                            }
                            if (a.classOrder > b.classOrder) {
                              return 1;
                            }

                            return 0;
                          });

                          studentWithoutClassOrder = studentWithoutClassOrder.sort(function compareModules(a, b) {
                            if (
                              a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
                              b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                            ) {
                              return -1;
                            }

                            if (
                              a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
                              b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                            ) {
                              return 1;
                            }

                            return 0;
                          });

                          students = studentsWithClassOrder.concat(studentWithoutClassOrder);

                          for (let i = 0; i < students.length; i++) {
                            let student = students[i];
                            let uniqueEvalInstrument = {};
                            let studentSplitName = student.name.split(" ");
                            if (studentSplitName && studentSplitName.length > 1) {
                              student.name = studentSplitName[0] + " " + studentSplitName[studentSplitName.length - 1];
                            }

                            if (evalInstrument.instrumentQuestions && evalInstrument.instrumentQuestions.length > 0) {
                              uniqueEvalInstrument = JSON.parse(JSON.stringify(evalInstrument.instrumentQuestions));

                              selected.studentEvaluations.push({
                                studentID: student.id,
                                studentClassOrder: student.classOrder,
                                name: student.name,
                                essentialLearningQuestionEvaluation: uniqueEvalInstrument,
                              });
                            } else {
                              uniqueEvalInstrument = JSON.parse(
                                JSON.stringify(evalInstrument.essentialLearningDomainWeight)
                              );

                              selected.studentEvaluations.push({
                                studentID: student.id,
                                studentClassOrder: student.classOrder,
                                name: student.name,
                                essentialLearningEvaluation: uniqueEvalInstrument,
                              });
                            }
                          }

                          scope.parentScope.$parent.hideLoader();
                          if (professionalFlag) {
                            scope.genericFactory.setRouteName("Class_Plan_Prof_School_Records");
                          } else {
                            scope.genericFactory.setRouteName("Class_Plan_School_Records");
                          }
                          resolve(true);
                        });
                      });
                    });
                });
            });
        } else if (evalInstrument.attitudeCrits != null && evalInstrument.attitudeCrits.length > 0) {
          // Load students for atts evals
          scope.genericFactory.setRouteName("Class_Plan_Attitude_Crits");
          scope.genericFactory.getByProperty("organization", scope.currentUser.organization).then(function (attCrits) {
            evalInstrument.attitudeCrits = evalInstrument.attitudeCrits.map(function (instrEvalAtts) {
              let newEl = {};
              newEl = instrEvalAtts;
              newEl.parsedAttCrit = attCrits.filter((el) => el.id == newEl.attitudeCrit)[0].attitudeCrit;
              return newEl;
            });

            let students = [];
            scope.genericFactory.setRouteName("Class_Plan_Students");
            let map = {};
            map.schoolYear = selected.schoolYear;
            map.class = selected.class;
            scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (data) {
              if (data != null && Array.isArray(data) && data.length > 0) {
                students = data.filter((stud) => stud.status_matricula == "Matriculado");
              }

              let studentsWithClassOrder = [];
              let studentWithoutClassOrder = [];

              studentsWithClassOrder = students.filter((stud) => stud.classOrder != null);
              studentWithoutClassOrder = students.filter((stud) => stud.classOrder == null);

              studentsWithClassOrder = studentsWithClassOrder.sort(function compareModules(a, b) {
                if (a.classOrder < b.classOrder) {
                  return -1;
                }
                if (a.classOrder > b.classOrder) {
                  return 1;
                }

                return 0;
              });

              studentWithoutClassOrder = studentWithoutClassOrder.sort(function compareModules(a, b) {
                if (
                  a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
                  b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                ) {
                  return -1;
                }

                if (
                  a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
                  b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                ) {
                  return 1;
                }

                return 0;
              });

              students = studentsWithClassOrder.concat(studentWithoutClassOrder);

              for (let i = 0; i < students.length; i++) {
                let student = students[i];
                let uniqueEvalInstrument = {};

                uniqueEvalInstrument = JSON.parse(JSON.stringify(evalInstrument.attitudeCrits));

                selected.studentEvaluations.push({
                  studentID: student.id,
                  studentClassOrder: student.classOrder,
                  name: student.name,
                  attitudesEvaluation: uniqueEvalInstrument,
                });
              }

              scope.parentScope.$parent.hideLoader();
              if (scope.deps.indexOf("Class_Plan_Prof_Evaluation_Instruments") == -1) {
                scope.genericFactory.setRouteName("Class_Plan_School_Records");
              } else {
                scope.genericFactory.setRouteName("Class_Plan_Prof_School_Records");
              }
              resolve(true);
            });
          });
        } else {
          // Load only students in case of no essential learnings
          let students = [];
          scope.genericFactory.setRouteName("Class_Plan_Students");
          let map = {};
          map.schoolYear = selected.schoolYear;
          map.class = selected.class;
          scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (data) {
            if (data != null && Array.isArray(data) && data.length > 0) {
              students = data.filter((stud) => stud.status_matricula == "Matriculado");
            }

            let studentsWithClassOrder = [];
            let studentWithoutClassOrder = [];

            studentsWithClassOrder = students.filter((stud) => stud.classOrder != null);
            studentWithoutClassOrder = students.filter((stud) => stud.classOrder == null);

            studentsWithClassOrder = studentsWithClassOrder.sort(function compareModules(a, b) {
              if (a.classOrder < b.classOrder) {
                return -1;
              }
              if (a.classOrder > b.classOrder) {
                return 1;
              }

              return 0;
            });

            studentWithoutClassOrder = studentWithoutClassOrder.sort(function compareModules(a, b) {
              if (
                a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
              ) {
                return -1;
              }

              if (
                a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
              ) {
                return 1;
              }

              return 0;
            });

            students = studentsWithClassOrder.concat(studentWithoutClassOrder);

            for (let i = 0; i < students.length; i++) {
              let student = students[i];
              selected.studentEvaluations.push({
                studentID: student.id,
                studentClassOrder: student.classOrder,
                name: student.name,
              });
            }
            scope.parentScope.$parent.hideLoader();
            if (professionalFlag) {
              scope.genericFactory.setRouteName("Class_Plan_Prof_School_Records");
            } else {
              scope.genericFactory.setRouteName("Class_Plan_School_Records");
            }
            resolve(true);
          });
        }
      }
    });
  },
  ptShowEvalOptions: function ptShowEvalOptions(scope, selected, data) {
    if (selected.evalOptionsFlag == null) {
      selected.evalOptionsFlag = true;
    } else if (selected.evalOptionsFlag != null && selected.evalOptionsFlag == false) {
      selected.evalOptionsFlag = true;
    } else {
      selected.evalOptionsFlag = false;
    }
  },
  showHidePanelFields: function showHidePanelFields(scope, selected, data, functionArgs) {
    if (functionArgs.showHideField != null) {
      if (selected[functionArgs.showHideField] == null) {
        selected[functionArgs.showHideField] = true;
      } else if (selected[functionArgs.showHideField] != null && selected[functionArgs.showHideField] == false) {
        selected[functionArgs.showHideField] = true;
      } else {
        selected[functionArgs.showHideField] = false;
      }
    }
  },
  ptLoadSchoolRecordFinalCritEval: function ptLoadSchoolRecordFinalCritEval(scope, selected, data) {
    return new Promise((resolve, reject) => {
      if (selected.studentEvaluations && selected.studentEvaluations.length > 0) {
        scope.genericScope.$parent.alertPTAlreadyInstrEval();
        resolve(false);
      }

      if (selected.studentFinalCritEvaluations && selected.studentFinalCritEvaluations.length > 0) {
        let resetEvals = scope.$mdDialog
          .confirm()
          .title("Atualizar Informação de Avaliações Finais")
          .textContent(
            "Já tem registos carregados. Deseja manter esta informação? Se responder 'NÃO' o sistema irá eliminar as avaliações previamente efetuadas."
          )
          .ariaLabel("Confirmar atualização")
          .ok("Sim")
          .cancel("Não")
          .clickOutsideToClose(false);

        scope.$mdDialog.show(resetEvals).then(
          function () {
            resolve(true);
          },
          function () {
            ptLoadSchoolRecordFinalCritEval(scope, selected, data);
          }
        );
      } else {
        ptLoadSchoolRecordFinalCritEval(scope, selected, data);
      }

      function ptLoadSchoolRecordFinalCritEval(scope, selected, data) {
        scope.parentScope.$parent.showLoader();
        let dataSplitByAE = [];
        let dataSplitByAtt = [];
        let qualitativeEssenLearnFlag = false;
        let schoolRecordRowFilterMap = {};
        schoolRecordRowFilterMap.year = selected.year;
        schoolRecordRowFilterMap.schoolYear = selected.schoolYear;
        schoolRecordRowFilterMap.class = selected.class;

        // Professional eval flag
        let professionalFinalEvalFlag = false;
        if (scope.module.collection == "Class_Plan_Prof_School_Records") {
          professionalFinalEvalFlag = true;
        }

        scope.genericFactory
          .getByProperties(schoolRecordRowFilterMap, scope.currentUser.organization)
          .then(function (schoolRecordUnfilteredData) {
            if (!professionalFinalEvalFlag) {
              // Evals by period
              if (
                selected.evalsByPeriodFlagcheckbox &&
                selected.evalsByPeriodFlagcheckbox[""] &&
                selected.evalsByPeriodFlagcheckbox[""] == true
              ) {
                dataSplitByAE = utilFunctions["ptSplitAESchoolRecord"](
                  scope,
                  JSON.parse(JSON.stringify(schoolRecordUnfilteredData)).filter(
                    (el) => el.period == selected.period && el.subject == selected.subject
                  ),
                  true
                );
              } else {
                dataSplitByAE = utilFunctions["ptSplitAESchoolRecord"](
                  scope,
                  JSON.parse(JSON.stringify(schoolRecordUnfilteredData)).filter(
                    (el) => el.period == el.period && el.subject == selected.subject
                  ),
                  true
                );
              }
            } else {
              dataSplitByAE = utilFunctions["ptSplitProfAESchoolRecord"](
                scope,
                JSON.parse(JSON.stringify(schoolRecordUnfilteredData)).filter(
                  (el) => el.period == el.period && el.subject == selected.subject && el.module == selected.module
                ),
                true
              );
            }

            // Atts eval by class and not by subject
            if (
              selected.ignoreSubjectAttEvalsFlagcheckbox &&
              selected.ignoreSubjectAttEvalsFlagcheckbox[""] &&
              selected.ignoreSubjectAttEvalsFlagcheckbox[""] == true
            ) {
              dataSplitByAtt = utilFunctions["ptSplitAttSchoolRecord"](
                scope,
                JSON.parse(JSON.stringify(schoolRecordUnfilteredData)).filter((el) => el.period == el.period),
                true
              );
            } else {
              dataSplitByAtt = utilFunctions["ptSplitAttSchoolRecord"](
                scope,
                JSON.parse(JSON.stringify(schoolRecordUnfilteredData)).filter(
                  (el) => el.period == el.period && el.subject == selected.subject
                ),
                true
              );
            }

            if (professionalFinalEvalFlag && dataSplitByAtt.length > 0) {
              dataSplitByAtt.filter((el) => el.module == selected.module);
            }

            // Initiate student final crit evaluations
            selected.studentFinalCritEvaluations = [];

            // Initiate essential learnings with fixed eval weight
            selected.essenLearnsWithFixedEvalWeight = [];

            // Load selected subject or module criteria
            let subjectCriteria;
            let map = {};
            if (professionalFinalEvalFlag) {
              map.module = selected.module;
              scope.genericFactory.setRouteName("Class_Plan_Prof_Evaluation_Crits");
            } else {
              scope.genericFactory.setRouteName("Class_Plan_Evaluation_Crits");
            }
            map.subject = selected.subject;
            map.schoolYear = selected.schoolYear;
            map.year = selected.year;
            scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (criteria) {
              criteria = criteria.filter((criteria) => criteria.id == selected.evalCritGridDesc);
              // Missing subject criteria

              if (criteria.length == 0) {
                scope.genericScope.$parent.alertPTMissingEvalCriteria();
                if (professionalFinalEvalFlag) {
                  scope.genericFactory.setRouteName("Class_Plan_Prof_School_Records");
                } else {
                  scope.genericFactory.setRouteName("Class_Plan_School_Records");
                }
                scope.parentScope.$parent.hideLoader();
                resolve(false);
              }

              subjectCriteria = JSON.parse(JSON.stringify(criteria[0]));
              selected.subjectCriteria = subjectCriteria;
              scope.genericFactory.setRouteName("Class_Plan_Evaluation_Crit_Domains");
              scope.genericFactory
                .getByProperty("organization", scope.currentUser.organization)
                .then(function (critDomains) {
                  scope.genericFactory.setRouteName("Class_Plan_Attitude_Crits");
                  scope.genericFactory
                    .getByProperty("organization", scope.currentUser.organization)
                    .then(function (attCritDomains) {
                      let evalCriteriaNoEssenLearn = [];
                      let evalEssenLearnDomains = [];
                      let essenLearnCrit = "";
                      let essenLearnCritWeight = 0;

                      // Load and parse evaluation criteria without essential learning mapping

                      subjectCriteria.evaluationCriteria = subjectCriteria.evaluationCriteria.map(function (
                        evaluationCriteria
                      ) {
                        if (evaluationCriteria.essenLearnCrit && evaluationCriteria.essenLearnCrit.length > 0) {
                          essenLearnCrit = evaluationCriteria.criterion
                            .map((crit) => critDomains.filter((evalCrit) => evalCrit.id == crit)[0].criterion)
                            .toString()
                            .replace(/\,/gi, ", ");
                          essenLearnCritWeight = evaluationCriteria.weight;
                          return evaluationCriteria;
                        } else {
                          let newEl = {};
                          for (let j = 0; j < evaluationCriteria.criterion.length; j++) {
                            let criterion = evaluationCriteria.criterion[j];
                            let criterionDomainDetails = critDomains.filter((evalCrit) => evalCrit.id == criterion)[0];
                            if (
                              criterionDomainDetails &&
                              criterionDomainDetails.attitudesCritFlag &&
                              criterionDomainDetails.attitudesCritFlag.length > 0
                            ) {
                              newEl.autoCritFlag = true;
                              selected.autoCritFlag = true;
                            } else {
                              selected.autoCritFlag = false;
                            }
                          }
                          newEl.criterion = evaluationCriteria.criterion
                            .map((crit) => critDomains.filter((evalCrit) => evalCrit.id == crit)[0].criterion)
                            .toString()
                            .replace(/\,/gi, ", ");
                          newEl.criterionWeight = evaluationCriteria.weight;
                          evalCriteriaNoEssenLearn.push(newEl);
                          return evaluationCriteria;
                        }
                      });

                      // Load and parse evaluation criteria with essential learning mapping. Get domain avg eval by splitting it with ptSplitAESchoolRecord function

                      let map = {};
                      if (professionalFinalEvalFlag) {
                        scope.genericFactory.setRouteName("Class_Plan_Module_Essential_Learnings");
                        map.module = selected.module;
                      } else {
                        scope.genericFactory.setRouteName("Class_Plan_Essential_Learnings");
                        map.subject = selected.subject;
                      }
                      scope.genericFactory
                        .getByProperties(map, scope.currentUser.organization)
                        .then(function (essentialLearnings) {
                          subjectCriteria.essentialLearningDomainWeight =
                            subjectCriteria.essentialLearningDomainWeight.map(function (essentialLearningDomainWeight) {
                              let newEl = {};
                              newEl.essentialLearningWeight = essentialLearningDomainWeight.essentialLearningWeight;
                              newEl.essentialLearning = essentialLearningDomainWeight.essentialLearning;
                              let filteredEssentialLearning = essentialLearnings.filter(
                                (el) => el.id == essentialLearningDomainWeight.essentialLearning
                              )[0];
                              if (
                                filteredEssentialLearning.essentialLearningWithFixedEvalWeightcheckbox &&
                                filteredEssentialLearning.essentialLearningWithFixedEvalWeightcheckbox[""] &&
                                filteredEssentialLearning.essentialLearningWithFixedEvalWeightcheckbox[""] == true
                              ) {
                                selected.essenLearnsWithFixedEvalWeight.push(filteredEssentialLearning.id);
                              }
                              newEl.parsedEssentialLearning =
                                filteredEssentialLearning.essentialLearning +
                                " (" +
                                newEl.essentialLearningWeight +
                                "%)";
                              evalEssenLearnDomains.push(newEl);
                              return essentialLearningDomainWeight;
                            });

                          // Load qualitative eval levels in case they are necessary

                          let map = {};
                          map.year = selected.year;
                          map.schoolYear = selected.schoolYear;
                          map.subject = selected.subject;
                          scope.genericFactory.setRouteName("Class_Plan_Eval_Qualitative_Levels");
                          scope.genericFactory
                            .getByProperties(map, scope.currentUser.organization)
                            .then(function (qualitativeLevelsData) {
                              /* let qualitativeEvalLevels = {};

                              // Filter qualitative levels by the subject criteria
                              if (qualitativeLevelsData.length > 0) {
                                subjectCriteria.essentialLearningDomainWeight.forEach(essentialLearningDomainWeight => {
                                  let filteredQualitativeLevels = qualitativeLevelsData.filter(el => el.essentialLearning.indexOf(essentialLearningDomainWeight.essentialLearning) != -1)[0];
                                  if (filteredQualitativeLevels) {
                                    if (qualitativeEvalLevels[essentialLearningDomainWeight.essentialLearning] == null) {
                                      qualitativeEvalLevels[essentialLearningDomainWeight.essentialLearning] = filteredQualitativeLevels.qualitativeLevels;
                                    }
                                  }
                                });
                              } */

                              // Load learning profiles in case they are necessary

                              let map = {};
                              map.year = selected.year;
                              map.schoolYear = selected.schoolYear;
                              map.subject = selected.subject;
                              scope.genericFactory.setRouteName("Class_Plan_Learning_Profiles");
                              scope.genericFactory
                                .getByProperties(map, scope.currentUser.organization)
                                .then(function (learningProfilesData) {
                                  let selectedLearningProfiles;
                                  if (selected.learningProfileDesc && learningProfilesData.length) {
                                    selectedLearningProfiles = learningProfilesData.filter(
                                      (el) => el.id == selected.learningProfileDesc
                                    )[0];
                                  }

                                  // Load eval instruments

                                  let map = {};
                                  map.year = selected.year;
                                  map.schoolYear = selected.schoolYear;
                                  map.subject = selected.subject;
                                  if (professionalFinalEvalFlag) {
                                    scope.genericFactory.setRouteName("Class_Plan_Prof_Evaluation_Instruments");
                                  } else {
                                    scope.genericFactory.setRouteName("Class_Plan_Evaluation_Instruments");
                                  }
                                  scope.genericFactory
                                    .getByProperties(map, scope.currentUser.organization)
                                    .then(function (evalInstruments) {
                                      // Load expertise fields in case they are necessary

                                      scope.genericFactory.setRouteName("Class_Plan_Expertise_Fields");
                                      scope.genericFactory
                                        .getByProperty("organization", scope.currentUser.organization)
                                        .then(function (expertiseFields) {
                                          // Load students

                                          let students = [];
                                          // Get students from the kstk students collection in case of municipalities
                                          if (scope.currentUser.organization == "60006e108b7f480098c8234c") {
                                            scope.genericFactory.setRouteName("KSTK_Students");
                                          } else {
                                            scope.genericFactory.setRouteName("Class_Plan_Students");
                                          }
                                          let map = {};
                                          map.schoolYear = selected.schoolYear;
                                          map.class = selected.class;
                                          map.year = selected.year;
                                          scope.genericFactory
                                            .getByProperties(map, scope.currentUser.organization)
                                            .then(function (data) {
                                              if (data != null && Array.isArray(data) && data.length > 0) {
                                                students = data.filter(
                                                  (stud) =>
                                                    stud.status_matricula == "Matriculado" &&
                                                    (stud.retained == null || stud.retained == "Não")
                                                );
                                              }
                                              let studentsWithClassOrder = [];
                                              let studentWithoutClassOrder = [];

                                              studentsWithClassOrder = students.filter(
                                                (stud) => stud.classOrder != null
                                              );
                                              studentWithoutClassOrder = students.filter(
                                                (stud) => stud.classOrder == null
                                              );

                                              studentsWithClassOrder = studentsWithClassOrder.sort(
                                                function compareModules(a, b) {
                                                  if (a.classOrder < b.classOrder) {
                                                    return -1;
                                                  }
                                                  if (a.classOrder > b.classOrder) {
                                                    return 1;
                                                  }

                                                  return 0;
                                                }
                                              );

                                              studentWithoutClassOrder = studentWithoutClassOrder.sort(
                                                function compareModules(a, b) {
                                                  if (
                                                    a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
                                                    b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                                  ) {
                                                    return -1;
                                                  }

                                                  if (
                                                    a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
                                                    b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                                  ) {
                                                    return 1;
                                                  }

                                                  return 0;
                                                }
                                              );

                                              students = studentsWithClassOrder.concat(studentWithoutClassOrder);
                                              selected.evaluationsInAScaleOf200Flag = false;
                                              for (let i = 0; i < students.length; i++) {
                                                let student = students[i];
                                                let uniqueEvalEssenLearnDomains = JSON.parse(
                                                  JSON.stringify(evalEssenLearnDomains)
                                                );
                                                let uniqueEvalCriteriaNoEssenLearn = JSON.parse(
                                                  JSON.stringify(evalCriteriaNoEssenLearn)
                                                );
                                                let studDataSplitByAE = JSON.parse(
                                                  JSON.stringify(dataSplitByAE.filter((el) => el.name == student.id))
                                                );
                                                let studDataSplitByAtt = JSON.parse(
                                                  JSON.stringify(dataSplitByAtt.filter((el) => el.name == student.id))
                                                );

                                                let profRecoveryInstrEvalsFlag;
                                                if (professionalFinalEvalFlag) {
                                                  //Filter professional recovery instrument evals
                                                  let filteredRecoveryInstrEvals = [];
                                                  filteredRecoveryInstrEvals = studDataSplitByAE.filter(
                                                    (el) =>
                                                      el.evalInstrumentDesc &&
                                                      (el.evalInstrumentDesc == "Recuperação" ||
                                                        el.evalInstrumentDesc == "Prova Final")
                                                  );
                                                  if (filteredRecoveryInstrEvals.length > 0) {
                                                    profRecoveryInstrEvalsFlag =
                                                      filteredRecoveryInstrEvals[0].evalInstrumentDesc;
                                                    studDataSplitByAE = JSON.parse(
                                                      JSON.stringify(filteredRecoveryInstrEvals)
                                                    );
                                                  } else {
                                                    profRecoveryInstrEvalsFlag = "Normal";
                                                  }
                                                }
                                                uniqueEvalEssenLearnDomains = uniqueEvalEssenLearnDomains.map(function (
                                                  evalEssenLearn
                                                ) {
                                                  let newEl = {};
                                                  newEl = evalEssenLearn;
                                                  let missingQualitativeEvalLevelsFlag = false;
                                                  let studDataSplitByAEFilteredByAE = studDataSplitByAE.filter(
                                                    (el) => el.essentialLearning == newEl.essentialLearning
                                                  );
                                                  let essentialLearningEvalDesc = classPlanGetEvalDescByEvalInstrument(
                                                    studDataSplitByAEFilteredByAE,
                                                    evalInstruments
                                                  );
                                                  let essenLearnEval = studDataSplitByAEFilteredByAE[0];
                                                  if (essenLearnEval != null) {
                                                    let qualitativeEvalLevels;
                                                    //Get the qualitative levels from the string field
                                                    if (essenLearnEval.evalInstrumentQualitativeLevels) {
                                                      qualitativeEvalLevels = JSON.parse(
                                                        essenLearnEval.evalInstrumentQualitativeLevels
                                                      ).qualitativeLevels;
                                                    }
                                                    if (essentialLearningEvalDesc != null) {
                                                      newEl.essentialLearningEvalDesc = essentialLearningEvalDesc;
                                                    }
                                                    if (
                                                      profRecoveryInstrEvalsFlag != null &&
                                                      profRecoveryInstrEvalsFlag != "Normal"
                                                    ) {
                                                      newEl.essentialLearningAvg = essenLearnEval.essentialLearningEval;
                                                    } else {
                                                      newEl.essentialLearningAvg = essenLearnEval.essenLearnAvg;
                                                      if (selectedLearningProfiles) {
                                                        newEl.learningProfile =
                                                          getLearningProfileFromEssentialLearningEval(
                                                            selectedLearningProfiles,
                                                            newEl.essentialLearning,
                                                            essenLearnEval.essenLearnAvg
                                                          );
                                                      }
                                                    }
                                                    //Get evaluationsInAScaleOf200Flag from essentialLearningAvg
                                                    if (
                                                      selected.evaluationsInAScaleOf200Flag == false &&
                                                      newEl.essentialLearningAvg != null &&
                                                      !isNaN(Number(newEl.essentialLearningAvg))
                                                    ) {
                                                      if (newEl.essentialLearningAvg > 100) {
                                                        selected.evaluationsInAScaleOf200Flag = true;
                                                      }
                                                    }

                                                    if (essenLearnEval.essenLearnAvg == null) {
                                                      newEl.qualitativeEval = true;
                                                      if (qualitativeEssenLearnFlag == false) {
                                                        qualitativeEssenLearnFlag = true;
                                                      }
                                                    } else {
                                                      newEl.qualitativeEval = false;
                                                    }
                                                  } else {
                                                    newEl.essentialLearningAvg = null;
                                                  }
                                                  return newEl;
                                                });

                                                if (selected.autoCritFlag) {
                                                  uniqueEvalCriteriaNoEssenLearn = uniqueEvalCriteriaNoEssenLearn.map(
                                                    function (attCriteria) {
                                                      let newEl = {};
                                                      newEl = attCriteria;
                                                      let studAttEval = studDataSplitByAtt.filter(
                                                        (el) => el.autoCritFlag == true
                                                      )[0];
                                                      if (studAttEval != null) {
                                                        newEl.autoCritEvalAvg = studAttEval.autoCritEvalAvg;
                                                        for (const attCrit in studAttEval.studAttCritEvals) {
                                                          if (studAttEval.studAttCritEvals.hasOwnProperty(attCrit)) {
                                                            const attCriteriaEvalValues =
                                                              studAttEval.studAttCritEvals[attCrit];
                                                            let parsedAttCrit = attCritDomains.filter(
                                                              (attC) => attC.id == attCrit
                                                            )[0].attitudeCrit;
                                                            let attCritAvg =
                                                              Math.round(
                                                                (attCriteriaEvalValues.attTotal /
                                                                  attCriteriaEvalValues.totalEvals) *
                                                                  100
                                                              ) / 100;
                                                            if (newEl.autoCritEvalDetails == null) {
                                                              newEl.autoCritEvalDetails =
                                                                parsedAttCrit + " (" + attCritAvg + "%)";
                                                            } else {
                                                              newEl.autoCritEvalDetails +=
                                                                ", " + parsedAttCrit + " (" + attCritAvg + "%)";
                                                            }
                                                          }
                                                        }
                                                      } else {
                                                        newEl.autoCritEvalAvg = null;
                                                      }
                                                      return newEl;
                                                    }
                                                  );
                                                }

                                                //Get student expertise field evals if there is any

                                                let studentExpertiseFieldEvals;
                                                if (uniqueEvalEssenLearnDomains.length > 0) {
                                                  studentExpertiseFieldEvals = classPlanGetStudentExpertiseFieldEvals(
                                                    uniqueEvalEssenLearnDomains,
                                                    studDataSplitByAE,
                                                    essentialLearnings,
                                                    expertiseFields
                                                  );
                                                }
                                                selected.studentFinalCritEvaluations.push({
                                                  studentID: student.id,
                                                  studentClassOrder: student.classOrder,
                                                  originalClass: student.originalClass ? student.originalClass : null,
                                                  name: student.name,
                                                  essenLearnCrit: essenLearnCrit,
                                                  essenLearnCritWeight: essenLearnCritWeight,
                                                  studentFinalEssenLearnCritEvals: uniqueEvalEssenLearnDomains,
                                                  studentFinalCritEvals: uniqueEvalCriteriaNoEssenLearn,
                                                  studentExpertiseFieldEvals: studentExpertiseFieldEvals,
                                                  profRecoveryInstrEvalsFlag: profRecoveryInstrEvalsFlag,
                                                });
                                              }

                                              selected.hasQualitativeEvalEssenLearns = qualitativeEssenLearnFlag;

                                              scope.genericFactory.setRouteName("Class_Plan_Final_Evaluation_Levels");
                                              if (selected.optionalFinalEvalLevels) {
                                                scope.genericFactory
                                                  .get(selected.optionalFinalEvalLevels)
                                                  .then(function (data) {
                                                    if (data != null && Object.keys(data).length > 0) {
                                                      selected.finalEvalLevels = data.finalEvalLevel;
                                                    }
                                                    scope.parentScope.$parent.hideLoader();
                                                    if (professionalFinalEvalFlag) {
                                                      scope.genericFactory.setRouteName(
                                                        "Class_Plan_Prof_School_Records"
                                                      );
                                                    } else {
                                                      scope.genericFactory.setRouteName("Class_Plan_School_Records");
                                                    }
                                                    /* ptClassPlanSchoolRecordCalculatedStudEvals(
                                                      scope,
                                                      selected,
                                                      selected.studentFinalCritEvaluations,
                                                      professionalFinalEvalFlag
                                                    ); */
                                                    resolve(true);
                                                  });
                                              } else {
                                                let map = {};
                                                let schoolYears = scope.getFromTableData("Class_Plan_School_Years");
                                                map.year = selected.year;
                                                map.educationLevel = schoolYears.filter(
                                                  (schoYear) => schoYear.id == selected.schoolYear
                                                )[0].educationLevel;
                                                scope.genericFactory
                                                  .getByProperties(map, scope.currentUser.organization)
                                                  .then(function (data) {
                                                    if (data.length > 0) {
                                                      selected.finalEvalLevels = data[0].finalEvalLevel;
                                                    }
                                                    scope.parentScope.$parent.hideLoader();
                                                    if (professionalFinalEvalFlag) {
                                                      scope.genericFactory.setRouteName(
                                                        "Class_Plan_Prof_School_Records"
                                                      );
                                                    } else {
                                                      scope.genericFactory.setRouteName("Class_Plan_School_Records");
                                                    }
                                                    /* ptClassPlanSchoolRecordCalculatedStudEvals(
                                                      scope,
                                                      selected,
                                                      selected.studentFinalCritEvaluations,
                                                      professionalFinalEvalFlag
                                                    ); */
                                                    resolve(true);
                                                  });
                                              }
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
          });
      }
    });
  },
  ptLoadProfSubjectsEvals: function ptLoadProfSubjectsEvals(scope, selected, data) {
    return new Promise((resolve, reject) => {
      if (selected.studentProfSubjectsEvals && selected.studentProfSubjectsEvals.length > 0) {
        let resetEvals = scope.$mdDialog
          .confirm()
          .title("Atualizar Informação de Avaliação de Disciplina")
          .textContent(
            "Já tem registos carregados. Deseja manter esta informação? Se responder 'NÃO' o sistema irá eliminar as avaliações previamente efetuadas."
          )
          .ariaLabel("Confirmar atualização")
          .ok("Sim")
          .cancel("Não")
          .clickOutsideToClose(false);

        scope.$mdDialog.show(resetEvals).then(
          function () {
            resolve(true);
          },
          function () {
            ptLoadProfSubjectsEvals(scope, selected, data);
          }
        );
      } else {
        ptLoadProfSubjectsEvals(scope, selected, data);
      }

      function ptLoadProfSubjectsEvals(scope, selected, data) {
        scope.parentScope.$parent.showLoader();
        let profSubModulesData = [];

        scope.genericFactory.setRouteName("Class_Plan_Prof_School_Records");
        let profModuleEvalsRowFilterMap = {};
        profModuleEvalsRowFilterMap.year = selected.year;
        profModuleEvalsRowFilterMap.schoolYear = selected.schoolYear;
        profModuleEvalsRowFilterMap.class = selected.class;
        profModuleEvalsRowFilterMap.subject = selected.subject;
        scope.genericFactory
          .getByProperties(profModuleEvalsRowFilterMap, scope.currentUser.organization)
          .then(function (profSubModulesUnfilteredData) {
            // Evals by period
            if (
              selected.evalsByPeriodFlagcheckbox &&
              selected.evalsByPeriodFlagcheckbox[""] &&
              selected.evalsByPeriodFlagcheckbox[""] == true
            ) {
              profSubModulesData = utilFunctions["ptSplitFinalEvalsSchoolRecord"](
                scope,
                JSON.parse(JSON.stringify(profSubModulesUnfilteredData)).filter((el) => el.period == selected.period),
                true
              ).filter((el) => el.studFinalEval && el.evalDesc == selected.evalDesc);
            } else {
              profSubModulesData = utilFunctions["ptSplitFinalEvalsSchoolRecord"](
                scope,
                JSON.parse(JSON.stringify(profSubModulesUnfilteredData)),
                true
              ).filter((el) => el.studFinalEval && el.evalDesc == selected.evalDesc);
            }

            if (profSubModulesData.length == 0) {
              scope.genericScope.$parent.alertPTProfNoModuleEvalInfo();
              scope.genericFactory.setRouteName("Class_Plan_Prof_Subject_Evals");
              scope.parentScope.$parent.hideLoader();
              resolve(false);
            } else {
              // Initiate student final crit evaluations
              selected.studentProfSubjectsEvals = [];

              scope.genericFactory.setRouteName("Class_Plan_Prof_Modules");
              scope.genericFactory
                .getByProperty("organization", scope.currentUser.organization)
                .then(function (profModules) {
                  scope.genericFactory.setRouteName("Class_Plan_Prof_Course_Plans");
                  scope.genericFactory
                    .getByProperty("organization", scope.currentUser.organization)
                    .then(function (profCoursePlans) {
                      // Load students

                      let students = [];
                      scope.genericFactory.setRouteName("Class_Plan_Students");
                      let map = {};
                      map.schoolYear = selected.schoolYear;
                      map.class = selected.class;
                      map.year = selected.year;
                      scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (data) {
                        if (data != null && Array.isArray(data) && data.length > 0) {
                          students = data.filter((stud) => stud.status_matricula == "Matriculado");
                        }
                        let studentsWithClassOrder = [];
                        let studentWithoutClassOrder = [];

                        studentsWithClassOrder = students.filter((stud) => stud.classOrder != null);
                        studentWithoutClassOrder = students.filter((stud) => stud.classOrder == null);

                        studentsWithClassOrder = studentsWithClassOrder.sort(function compareModules(a, b) {
                          if (a.classOrder < b.classOrder) {
                            return -1;
                          }
                          if (a.classOrder > b.classOrder) {
                            return 1;
                          }

                          return 0;
                        });

                        studentWithoutClassOrder = studentWithoutClassOrder.sort(function compareModules(a, b) {
                          if (
                            a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
                            b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                          ) {
                            return -1;
                          }

                          if (
                            a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
                            b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                          ) {
                            return 1;
                          }

                          return 0;
                        });

                        students = studentsWithClassOrder.concat(studentWithoutClassOrder);

                        let filteredCoursePlan = getFilteredCoursePlan(selected, profCoursePlans);

                        if (!filteredCoursePlan) {
                          scope.genericScope.$parent.alertPTProfSubsMissingCoursePlan();
                          scope.genericFactory.setRouteName("Class_Plan_Prof_Subject_Evals");
                          scope.parentScope.$parent.hideLoader();
                          resolve(false);
                        } else {
                          for (let i = 0; i < students.length; i++) {
                            let student = students[i];
                            let studProfSubModulesEvals = JSON.parse(
                              JSON.stringify(profSubModulesData.filter((el) => el.studentID == student.id))
                            );
                            let modSumEvals = 0;
                            let modNumEvals = 0;
                            let evaluatedModules = [];
                            let failedProfCoursePlanModule = 0;

                            studProfSubModulesEvals = studProfSubModulesEvals.map(function (studModEval) {
                              let newEl = {};
                              newEl.studentID = studModEval.studentID;
                              newEl.evalModule = studModEval.finalEvalLevel;
                              newEl.name = studModEval.name;
                              newEl.module = studModEval.module;
                              evaluatedModules.push(newEl.module);
                              if (newEl.evalModule && !isNaN(Number(newEl.evalModule))) {
                                modNumEvals += 1;
                                modSumEvals += newEl.evalModule;
                              } else {
                                failedProfCoursePlanModule += 1;
                              }
                              let module = profModules.filter((el) => el.id == studModEval.module)[0].module;
                              newEl.parsedModule = module;
                              return newEl;
                            });

                            let missingProfCoursePlanModuleEval = [];

                            if (filteredCoursePlan.module) {
                              filteredCoursePlan.module.forEach((mod) => {
                                if (evaluatedModules.indexOf(mod) == -1) {
                                  missingProfCoursePlanModuleEval.push(
                                    profModules.filter((el) => el.id == mod)[0].module
                                  );
                                }
                              });
                            }

                            let evalSubject;
                            let parsedMissingProfCoursePlanModuleEval;

                            if (missingProfCoursePlanModuleEval.length > 0) {
                              parsedMissingProfCoursePlanModuleEval = missingProfCoursePlanModuleEval
                                .sort()
                                .toString()
                                .replace(",", ", ");
                              evalSubject = "NA";
                            } else if (failedProfCoursePlanModule > 0) {
                              evalSubject = "NA";
                            } else {
                              evalSubject = Math.round((modSumEvals / modNumEvals) * 10) / 10;
                            }

                            selected.studentProfSubjectsEvals.push({
                              studentID: student.id,
                              studentClassOrder: student.classOrder,
                              name: student.name,
                              studProfSubModulesEvals: studProfSubModulesEvals,
                              missingProfCoursePlanModuleEval: missingProfCoursePlanModuleEval,
                              parsedMissingProfCoursePlanModuleEval: parsedMissingProfCoursePlanModuleEval,
                              evalSubject: evalSubject,
                            });
                          }
                        }

                        let schoolYears = scope.getFromTableData("Class_Plan_School_Years");
                        scope.genericFactory.setRouteName("Class_Plan_Final_Evaluation_Levels");
                        let map = {};
                        map.year = selected.year;
                        map.educationLevel = schoolYears.filter(
                          (schoYear) => schoYear.id == selected.schoolYear
                        )[0].educationLevel;
                        scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (data) {
                          if (data.length > 0) {
                            selected.finalEvalLevels = data[0].finalEvalLevel;
                          }
                          scope.parentScope.$parent.hideLoader();
                          scope.genericFactory.setRouteName("Class_Plan_Prof_Subject_Evals");
                          resolve(true);
                        });
                      });
                    });
                });
            }
          });
      }
    });
  },
  ptLoadStudentsProfCourseEvals: function ptLoadStudentsProfCourseEvals(scope, selected, data) {
    return new Promise((resolve, reject) => {
      if (selected.studentProfCourseEvals && selected.studentProfCourseEvals.length > 0) {
        let resetEvals = scope.$mdDialog
          .confirm()
          .title("Atualizar Informação de Avaliação de Curso Profissional")
          .textContent(
            "Já tem registos carregados. Deseja manter esta informação? Se responder 'NÃO' o sistema irá eliminar as avaliações previamente efetuadas."
          )
          .ariaLabel("Confirmar atualização")
          .ok("Sim")
          .cancel("Não")
          .clickOutsideToClose(false);

        scope.$mdDialog.show(resetEvals).then(
          function () {
            resolve(true);
          },
          function () {
            ptLoadStudentsProfCourseEvals(scope, selected, data);
          }
        );
      } else {
        ptLoadStudentsProfCourseEvals(scope, selected, data);
      }

      function ptLoadStudentsProfCourseEvals(scope, selected, data) {
        scope.parentScope.$parent.showLoader();
        let profSubModulesData = [];

        scope.genericFactory.setRouteName("Class_Plan_Prof_Subject_Evals");
        let profModuleEvalsRowFilterMap = {};
        profModuleEvalsRowFilterMap.course = selected.course;
        profModuleEvalsRowFilterMap.coursePlanRef = selected.coursePlanRef;
        //profModuleEvalsRowFilterMap.schoolYear = selected.schoolYear;
        //profModuleEvalsRowFilterMap.class = selected.year;
        scope.genericFactory
          .getByProperties(profModuleEvalsRowFilterMap, scope.currentUser.organization)
          .then(function (profSubModulesUnfilteredData) {
            // Evals by period
            if (
              selected.evalsByPeriodFlagcheckbox &&
              selected.evalsByPeriodFlagcheckbox[""] &&
              selected.evalsByPeriodFlagcheckbox[""] == true
            ) {
              profSubModulesData = utilFunctions["ptSplitProfSubjectEvals"](
                scope,
                JSON.parse(JSON.stringify(profSubModulesUnfilteredData)).filter((el) => el.period == selected.period),
                true
              ).filter((el) => el.evalSubject && el.evalDesc == "Final");
            } else {
              profSubModulesData = utilFunctions["ptSplitProfSubjectEvals"](
                scope,
                JSON.parse(JSON.stringify(profSubModulesUnfilteredData)),
                true
              ).filter((el) => el.evalSubject && el.evalDesc == "Final");
            }

            if (profSubModulesData.length == 0) {
              scope.genericScope.$parent.alertPTProfNoSubjectEvalInfo();
              scope.genericFactory.setRouteName("Class_Plan_Prof_Course_Evals");
              scope.parentScope.$parent.hideLoader();
              resolve(false);
            } else {
              // Initiate student final crit evaluations
              selected.studentProfCourseEvals = [];

              scope.genericFactory.setRouteName("Class_Plan_Subjects");
              scope.genericFactory
                .getByProperty("organization", scope.currentUser.organization)
                .then(function (subjects) {
                  scope.genericFactory.setRouteName("Class_Plan_Prof_Course_Plans");
                  scope.genericFactory
                    .getByProperty("organization", scope.currentUser.organization)
                    .then(function (profCoursePlans) {
                      // Load students

                      let students = [];
                      scope.genericFactory.setRouteName("Class_Plan_Students");
                      let map = {};
                      //map.year = selected.year;
                      map.course = selected.course;
                      scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (data) {
                        /* if (data != null && Array.isArray(data) && data.length > 0) {
                    students = data.filter(stud => stud.status_matricula == "Matriculado");
                  } */

                        students = data.filter((stud) => selected.name.indexOf(stud.id) != -1);

                        students = students.sort(function compareNames(a, b) {
                          if (
                            a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
                            b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                          ) {
                            return -1;
                          }

                          if (
                            a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
                            b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                          ) {
                            return 1;
                          }

                          return 0;
                        });

                        for (let m = 0; m < students.length; m++) {
                          let selectedStud = students[m];
                          selectedStud.refIDs = [];
                          selectedStud.refIDs.push(selectedStud.id);
                          data.forEach((stud) => {
                            if (stud.nif == selectedStud.nif && selectedStud.refIDs.indexOf(stud.id) == -1) {
                              selectedStud.refIDs.push(stud.id);
                            }
                          });
                        }

                        let filteredCoursePlan = getFilteredCoursePlan(selected, profCoursePlans, true);

                        filteredCoursePlan = filteredCoursePlan.sort(function compareCoursePlans(a, b) {
                          if (a.studyYear < b.studyYear) {
                            return -1;
                          }
                          if (a.studyYear > b.studyYear) {
                            return 1;
                          }

                          return 0;
                        });

                        if (!filteredCoursePlan) {
                          scope.genericScope.$parent.alertPTProfCourseMissingCoursePlan();
                          scope.genericFactory.setRouteName("Class_Plan_Prof_Course_Evals");
                          scope.parentScope.$parent.hideLoader();
                          resolve(false);
                        } else {
                          for (let i = 0; i < students.length; i++) {
                            let student = students[i];
                            let studProfSubjectsEvals = JSON.parse(
                              JSON.stringify(
                                profSubModulesData.filter((el) => student.refIDs.indexOf(el.studentID) != -1)
                              )
                            );
                            let nonEvaluatedSubjectFlag = false;
                            let subSumEvals = 0;
                            let subNumEvals = 0;

                            let fctEval = 0;
                            let papEval = 0;

                            let courseSubjectsEvals = [];
                            filteredCoursePlan.forEach((subjectCoursePlan) => {
                              let subjectEval = studProfSubjectsEvals.filter(
                                (studSubEval) =>
                                  studSubEval.studyYear == subjectCoursePlan.studyYear &&
                                  studSubEval.schoolYear == subjectCoursePlan.schoolYear &&
                                  studSubEval.subject == subjectCoursePlan.subject &&
                                  student.refIDs.indexOf(studSubEval.studentID) != -1
                              )[0];
                              let newEl = {};
                              if (!subjectEval) {
                                nonEvaluatedSubjectFlag = true;
                                newEl.studyYear = subjectCoursePlan.studyYear;
                                newEl.evalSubject = "NA";
                                newEl.subject = subjectCoursePlan.subject;
                                newEl.name = student.name;
                                newEl.studentID = student.studentID;
                                let subject = subjects.filter((el) => el.id == subjectCoursePlan.subject)[0].subject;
                                newEl.parsedSubject = subject;
                              } else {
                                newEl.studyYear = subjectEval.studyYear;
                                newEl.studentID = subjectEval.studentID;
                                newEl.evalSubject = subjectEval.evalSubject;
                                newEl.name = subjectEval.name;
                                newEl.subject = subjectEval.subject;
                                let subject = subjects.filter((el) => el.id == subjectEval.subject)[0].subject;
                                newEl.parsedSubject = subject;
                                if (newEl.evalSubject == "NA") {
                                  nonEvaluatedSubjectFlag = true;
                                } else if (newEl.parsedSubject.indexOf("FCT") != -1) {
                                  fctEval = newEl.evalSubject;
                                } else if (newEl.parsedSubject.indexOf("PAP") != -1) {
                                  papEval = newEl.evalSubject;
                                } else if (newEl.evalSubject && !isNaN(newEl.evalSubject)) {
                                  subNumEvals += 1;
                                  subSumEvals += newEl.evalSubject;
                                }
                              }
                              courseSubjectsEvals.push(newEl);
                            });

                            /* studProfSubjectsEvals = studProfSubjectsEvals.map(function (studModEval) {
                        let newEl = {};
                        newEl.studentID = studModEval.studentID;
                        newEl.evalSubject = studModEval.evalSubject;
                        newEl.name = studModEval.name;
                        newEl.subject = studModEval.subject;
                        let subject = subjects.filter(el => el.id == studModEval.subject)[0].subject;
                        newEl.parsedSubject = subject;
                        if (newEl.parsedSubject == "FCT") {
                          fctEval = newEl.evalSubject;
                          return newEl;
                        }
                        if (newEl.parsedSubject == "PAP") {
                          papEval = newEl.evalSubject;
                          return newEl;
                        }
                        if (newEl.evalSubject && !isNaN(newEl.evalSubject)) {
                          subNumEvals += 1;
                          subSumEvals += newEl.evalSubject;
                        }
                        return newEl;
                      }); */

                            let evalSubject, evalCourse;
                            if (nonEvaluatedSubjectFlag) {
                              evalSubject = "NA";
                              evalCourse = "NA";
                            } else {
                              evalSubject = Math.round((subSumEvals / subNumEvals) * 10) / 10;
                              evalCourse = Math.round(
                                ((2 * Math.round((subSumEvals / subNumEvals) * 100)) / 100 +
                                  (0.3 * Math.round(fctEval) + 0.7 * Math.round(papEval))) /
                                  3
                              );
                            }

                            selected.studentProfCourseEvals.push({
                              studentID: student.id,
                              studentClassOrder: student.classOrder,
                              name: student.name,
                              courseSubjectsEvals: courseSubjectsEvals,
                              evalSubject: evalSubject,
                              evalCourse: evalCourse,
                            });
                          }
                        }

                        scope.parentScope.$parent.hideLoader();
                        scope.genericFactory.setRouteName("Class_Plan_Prof_Course_Evals");
                        resolve(true);

                        /* let schoolYears = scope.getFromTableData("Class_Plan_School_Years");
                  scope.genericFactory.setRouteName("Class_Plan_Final_Evaluation_Levels");
                  let map = {};
                  map.year = selected.year;
                  map.educationLevel = schoolYears.filter(schoYear => schoYear.id == selected.schoolYear)[0].educationLevel;
                  scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (data) {
                    if (data.length > 0) {
                      selected.finalEvalLevels = data[0].finalEvalLevel;
                    }
                    scope.parentScope.$parent.hideLoader();
                    scope.genericFactory.setRouteName("Class_Plan_Prof_Course_Evals");
                    resolve(true);
                  }) */
                      });
                    });
                });
            }
          });
      }
    });
  },
  ptLoadMeasures: function ptLoadMeasures(scope, selected, data) {
    if (selected.studentMeasures && selected.studentMeasures.length > 0) {
      let resetEvals = scope.$mdDialog
        .confirm()
        .title("Recarregar Medidas Atribuídas")
        .textContent("Já tem medidas atribuídas a alunos, deseja recarregar estas?")
        .ariaLabel("Confirmar recarregamento")
        .ok("Sim")
        .cancel("Não")
        .clickOutsideToClose(true);

      scope.$mdDialog.show(resetEvals).then(
        function () {
          ptLoadMeasures(scope, selected, data);
          scope.genericScope.$parent.ptMeasureResetWarning();
        },
        function () {
          return;
        }
      );
    } else {
      ptLoadMeasures(scope, selected, data);
    }

    function ptLoadMeasures(scope, selected, data) {
      scope.parentScope.$parent.showLoader();
      selected.studentMeasures = [];

      // Load students

      let students = [];
      scope.genericFactory.setRouteName("Class_Plan_Students");
      let map = {};
      map.class = selected.class;
      scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (data) {
        if (data != null && Array.isArray(data) && data.length > 0) {
          students = data.filter((stud) => stud.status_matricula == "Matriculado");
        }
        let studentsWithClassOrder = [];
        let studentWithoutClassOrder = [];

        studentsWithClassOrder = students.filter((stud) => stud.classOrder != null);
        studentWithoutClassOrder = students.filter((stud) => stud.classOrder == null);

        studentsWithClassOrder = studentsWithClassOrder.sort(function compareModules(a, b) {
          if (a.classOrder < b.classOrder) {
            return -1;
          }
          if (a.classOrder > b.classOrder) {
            return 1;
          }

          return 0;
        });

        studentWithoutClassOrder = studentWithoutClassOrder.sort(function compareModules(a, b) {
          if (
            a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
            b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ) {
            return -1;
          }

          if (
            a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
            b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ) {
            return 1;
          }

          return 0;
        });

        students = studentsWithClassOrder.concat(studentWithoutClassOrder);
        for (let i = 0; i < students.length; i++) {
          let student = students[i];

          selected.studentMeasures.push({
            studentID: student.id,
            name: student.name,
            student: student,
          });
        }
        scope.parentScope.$parent.hideLoader();
        scope.genericFactory.setRouteName("Class_Plan_Action_Plans");
      });
    }
  },
  ptEvalInstrMap: function ptEvalInstrMap(scope, selected) {
    if (selected.qualitativeLevels != null && Object.keys(selected.qualitativeLevels).length > 0) {
      if (selected.studentEvaluations) {
        for (let k = 0; k < selected.studentEvaluations.length; k++) {
          let studEval = selected.studentEvaluations[k];

          if (studEval.qualitativeEvalMap != null) {
            delete studEval.qualitativeEvalMap;
          }

          if (
            studEval.essentialLearningQuestionEvaluation != null &&
            studEval.essentialLearningQuestionEvaluation.length > 0
          ) {
            studEval.completeQualitativeEvals = false;
            let questsWithEval = 0;
            for (let w = 0; w < studEval.essentialLearningQuestionEvaluation.length; w++) {
              let studEvalQuest = studEval.essentialLearningQuestionEvaluation[w];
              if (studEvalQuest.evaluationQualitativeLevel != null) {
                questsWithEval += 1;
                if (studEval.qualitativeEvalMap == null) {
                  studEval.qualitativeEvalMap = {};
                }
                if (studEval.qualitativeEvalMap[studEvalQuest.essentialLearning] == null) {
                  studEval.qualitativeEvalMap[studEvalQuest.essentialLearning] = [];
                }
                let qualEval = {};
                qualEval.essentialLearning = studEvalQuest.essentialLearning;
                qualEval.essentialLearningEval = studEvalQuest.evaluationQualitativeLevel;
                qualEval.question = studEvalQuest.question;
                qualEval.weight = studEvalQuest.weight;
                studEval.qualitativeEvalMap[studEvalQuest.essentialLearning].push(qualEval);
              }
            }
            if (questsWithEval == studEval.essentialLearningQuestionEvaluation.length) {
              studEval.completeQualitativeEvals = true;
            }
          } else if (studEval.essentialLearningEvaluation != null && studEval.essentialLearningEvaluation.length > 0) {
            studEval.completeQualitativeEvals = false;
            let domainsWithEval = 0;
            for (let w = 0; w < studEval.essentialLearningEvaluation.length; w++) {
              let studEvalDomain = studEval.essentialLearningEvaluation[w];
              if (studEvalDomain.evaluationQualitativeLevel != null) {
                domainsWithEval += 1;
                if (studEval.qualitativeEvalMap == null) {
                  studEval.qualitativeEvalMap = {};
                }
                if (studEval.qualitativeEvalMap[studEvalDomain.essentialLearning] == null) {
                  studEval.qualitativeEvalMap[studEvalDomain.essentialLearning] = [];
                }
                let qualEval = {};
                qualEval.essentialLearning = studEvalDomain.essentialLearning;
                qualEval.essentialLearningEval = studEvalDomain.evaluationQualitativeLevel;
                studEval.qualitativeEvalMap[studEvalDomain.essentialLearning].push(qualEval);
              }
            }
            if (domainsWithEval == studEval.essentialLearningEvaluation.length) {
              studEval.completeQualitativeEvals = true;
            }
          }
        }
      }
    }
    return true;
  },
  showHidePanelField: function showHidePanelField(scope, args) {
    if (args != null && args.field != null) {
      if (scope.hiddenFieldsShownInPanel[args.field] == null) {
        scope.hiddenFieldsShownInPanel[args.field] = true;
      } else {
        scope.hiddenFieldsShownInPanel[args.field] = !scope.hiddenFieldsShownInPanel[args.field];
      }
    }
  },
  ptCheckClassDirMeasure: function ptCheckClassDirMeasure(scope, selected) {
    //Check if transversal measures are being applied to class director's classes
    if (
      scope.currentUser.classDirector != null &&
      selected.class != null &&
      (selected.subject == null || (Array.isArray(selected.subject) && selected.subject.length == 0))
    ) {
      if (scope.currentUser.classDirector.indexOf(selected.class) != -1) {
        return true;
      } else {
        scope.genericScope.$parent.alertPTTransversalMeasureClassNotDir();
        return false;
      }
    }
  },
  ptSplitParentCommunicationHistory: function ptSplitParentCommunicationHistory(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.sentMailHistory) {
        for (let k = 0; k < el.sentMailHistory.length; k++) {
          let sentMail = el.sentMailHistory[k];

          for (const studID in sentMail.sentMailInfo) {
            if (sentMail.sentMailInfo.hasOwnProperty(studID)) {
              const studMailInfo = sentMail.sentMailInfo[studID];
              let mailDuplicate = JSON.parse(JSON.stringify(sentMail));

              mailDuplicate.sentState = studMailInfo.sentState;

              if (studMailInfo.exportedStudDocs) {
                mailDuplicate.exportedStudDocs = studMailInfo.exportedStudDocs.toString();
              }

              mailDuplicate.name = studMailInfo.studName;

              mailDuplicate.splitFlag = true;
              data.push(mailDuplicate);
            }
          }

          /* for (let j = 0; j < sentMail.name.length; j++) {
            let sentStudID = sentMail.name[j];

            let mailDuplicate = JSON.parse(JSON.stringify(sentMail));

            let studMailInfo = sentMail.sentMailInfo[sentStudID];
            if (studMailInfo != null) {
              if (sentMail.failedToSendEmails.indexOf(studMailInfo.studName) != -1) {
                mailDuplicate.sentState = "Erro ao Enviar"
              } else {
                mailDuplicate.sentState = "Enviado"
              }

              if (studMailInfo.exportedStudDocs) {
                mailDuplicate.exportedStudDocs = studMailInfo.exportedStudDocs.toString();
              }
            } else {
              mailDuplicate.sentState = "Erro ao Enviar"
            }

            mailDuplicate.name = sentStudID;

            mailDuplicate.splitFlag = true;
            data.push(mailDuplicate);
          } */
        }
      }

      data.splice(0, 1);
    }
  },
  ptValidateDocsToBeSent: function ptValidateDocsToBeSent(scope, selected, tableData) {
    utilFunctions["ptSendCommunication"](scope, selected, tableData, true);
  },
  ptSendCommunication: function ptSendCommunication(scope, selected, tableData, validateDocsFlag) {
    return new Promise((resolve, reject) => {
      let infoForMails = {};

      let students = scope.getFromTableData("Class_Plan_Students");
      let studentsWithMissingEmails = [];
      let studentsWithMissingorInvalidParentEmails = [];
      let studentsMatched = 0;
      let toSendTotalEmails = 0;
      let sentEmails = 0;
      let failedToSendEmails = [];

      //List of studs IDs to validate docs before sending

      let studsToSendCommunications = [];

      //Check if message is also to students and setup flag

      let messageAlsoToStudentsFlag = false;
      if (
        selected.messageAlsoToStudentsFlagcheckbox &&
        selected.messageAlsoToStudentsFlagcheckbox[""] &&
        selected.messageAlsoToStudentsFlagcheckbox[""] == true
      ) {
        messageAlsoToStudentsFlag = true;
      }

      //Check if there are docs to be attached

      let docsToBeAttachedFlag = false;
      if (
        selected.docsToBeAttached != null &&
        Array.isArray(selected.docsToBeAttached) &&
        selected.docsToBeAttached.length > 0
      ) {
        docsToBeAttachedFlag = true;
      }

      //Check if there will be sent evaluations to more than one class

      if (docsToBeAttachedFlag) {
        for (let j = 0; j < selected.docsToBeAttached.length; j++) {
          let docName = selected.docsToBeAttached[j];
          if (docName.indexOf("Pauta de Aval") != -1 && selected.class.length && selected.class.length > 1) {
            scope.genericScope.$parent.alertPTCantSendEvalsToManyClasses();
            resolve(false);
            return;
          }
        }
      }

      //Custom message parsing

      let customMessage = "";

      let docFilteredData = {};

      customMessage = selected.customMessage.replace(/\r?\n/g, "<br />");
      customMessage +=
        "<br /><br /> Este e-mail foi-lhe enviado automaticamente pela plataforma KSTK Predictive Mindset.";
      /* customMessage +=
        "<br /><br /> Para responder ao rementente deste e-mail, utilize o seguinte endereço: " +
        selected.institucionalFromEmail; */

      //Filter students first by class and status matricula = Matriculado

      let studentsFilteredByClass = students.filter(
        (el) =>
          selected.class.indexOf(el.class) != -1 &&
          el.status_matricula == "Matriculado" &&
          (el.retained == null || el.retained == "Não")
      );

      //Get parent and student (if necessary) emails. Creating necessary info blocks for each email

      if (
        selected.name == null ||
        (selected.name != null && Array.isArray(selected.name) && selected.name.length == 0)
      ) {
        for (let n = 0; n < studentsFilteredByClass.length; n++) {
          let stud = studentsFilteredByClass[n];
          createStudMailInfo(stud);
          studsToSendCommunications.push(stud.id);
        }
      } else {
        for (let k = 0; k < studentsFilteredByClass.length; k++) {
          let stud = studentsFilteredByClass[k];
          if (studentsMatched == selected.name.length) {
            break;
          }
          if (selected.name.indexOf(stud.id) != -1) {
            studentsMatched += 1;
            createStudMailInfo(stud);
            studsToSendCommunications.push(stud.id);
          }
        }
      }

      function createStudMailInfo(stud) {
        if (stud.e_mail_ee != null && stud.e_mail_ee != "" && validateEmail(stud.e_mail_ee)) {
          toSendTotalEmails += 1;
          let mailInfo = {};
          mailInfo.institucionalFromEmail = selected.institucionalFromEmail;
          mailInfo.fromName = scope.currentUser.name + " " + scope.currentUser.surname;
          mailInfo.studID = stud.id;
          mailInfo.studName = stud.name;
          mailInfo.customMessage = customMessage;

          // Prepare email for stud
          if (messageAlsoToStudentsFlag) {
            if (stud.e_mail_stud != null && stud.e_mail_stud != "" && validateEmail(stud.e_mail_stud)) {
              toSendTotalEmails += 1;
              let studMailInfo = JSON.parse(JSON.stringify(mailInfo));
              studMailInfo.to = stud.e_mail_stud;
              infoForMails[stud.id + "stud"] = studMailInfo;
            } else {
              studentsWithMissingEmails.push(stud.name);
            }
          }

          // Prepare email for EE
          if (stud.e_mail_ee != null && stud.e_mail_ee != "" && validateEmail(stud.e_mail_ee)) {
            toSendTotalEmails += 1;
            let eeMailInfo = JSON.parse(JSON.stringify(mailInfo));
            eeMailInfo.to = stud.e_mail_ee;
            infoForMails[stud.id + "ee"] = eeMailInfo;
          } else {
            studentsWithMissingEmails.push(stud.name);
          }

          // Prepare email for professor
          let professorMailInfo = JSON.parse(JSON.stringify(mailInfo));
          professorMailInfo.to = mailInfo.institucionalFromEmail;
          infoForMails[stud.id + "professor"] = professorMailInfo;
        } else {
          let mailInfo = {};
          mailInfo.studID = stud.id;
          mailInfo.studName = stud.name;
          mailInfo.sentState = "Erro ao enviar";
          infoForMails[stud.id] = mailInfo;
          studentsWithMissingorInvalidParentEmails.push(stud.name);
        }
      }

      //Check if it is necessary to validate docs. If it is not, validation dialogs need to be executed
      if (!validateDocsFlag) {
        if (studentsWithMissingorInvalidParentEmails.length == Object.keys(infoForMails).length) {
          scope.genericScope.$parent.alertPTNoValidParentEmailsToSend();
          resolve(false);
          return;
        }

        if (
          (messageAlsoToStudentsFlag && studentsWithMissingEmails.length > 0) ||
          studentsWithMissingorInvalidParentEmails.length > 0
        ) {
          // Missing emails
          let missingMailsConfirm = scope.$mdDialog
            .confirm()
            .clickOutsideToClose(false)
            .title("Aluno(s) com E-mail em Falta")
            .textContent(
              "O(s) seguinte(s) aluno(s) (" +
                studentsWithMissingEmails.concat(studentsWithMissingorInvalidParentEmails).length +
                ") tem(têm) o e-mail de EE ou pessoal (necessário se mensagem também for endereçada a alunos) em falta ou inválidos: " +
                studentsWithMissingEmails
                  .concat(studentsWithMissingorInvalidParentEmails)
                  .sort()
                  .toString()
                  .replace(/\,/gi, ", ") +
                ". Por favor corrija esta situação no Módulo de Apoio 'Alunos'. Deseja continuar com o envio da mensagem?"
            )
            .ariaLabel(
              "O(s) seguinte(s) aluno(s) (" +
                studentsWithMissingEmails.concat(studentsWithMissingorInvalidParentEmails).length +
                ") tem(têm) o e-mail de EE ou pessoal (necessário se mensagem também for endereçada a alunos) em falta ou inválidos: " +
                studentsWithMissingEmails
                  .concat(studentsWithMissingorInvalidParentEmails)
                  .sort()
                  .toString()
                  .replace(/\,/gi, ", ") +
                ". Por favor corrija esta situação no Módulo de Apoio 'Alunos'. Deseja continuar com o envio da mensagem?"
            )
            .ok("Sim")
            .cancel("Não");

          scope.$mdDialog.show(missingMailsConfirm).then(
            function () {
              confirmSendCommunication();
            },
            function () {
              resolve(false);
            }
          );
        } else {
          // No missing emails
          confirmSendCommunication();
        }
      } else {
        scope.genericScope.$parent.showLoader();
        prepareDocsToBeAttached();
      }

      function confirmSendCommunication() {
        // Confirm send process
        let finishSendCommunicationConfirm = scope.$mdDialog
          .confirm()
          .clickOutsideToClose(false)
          .title("Confirmação de Envio")
          .textContent(
            "Confirma o envio de " +
              toSendTotalEmails +
              " e-mail(s) para a(s) turma(s) " +
              scope.genericScope.parseValue(selected.class, "class") +
              ", com os documentos " +
              selected.docsToBeAttached.sort().toString().replace(/\,/gi, ", ") +
              "?"
          )
          .ariaLabel(
            "Confirma o envio de " +
              toSendTotalEmails +
              " e-mail(s) para a(s) turma(s) " +
              scope.genericScope.parseValue(selected.class, "class") +
              ", com os documentos " +
              selected.docsToBeAttached.sort().toString().replace(/\,/gi, ", ") +
              "?"
          )
          .ok("Sim")
          .cancel("Não");

        scope.$mdDialog.show(finishSendCommunicationConfirm).then(
          function () {
            scope.genericScope.$parent.showLoader();
            if (!docsToBeAttachedFlag) {
              sendMessages();
            } else {
              prepareDocsToBeAttached();
            }
          },
          function () {
            resolve(false);
          }
        );
      }

      function prepareDocsToBeAttached() {
        let necessaryDocsDataPromises = [];
        let docsDoneFlag = false;

        let newDeps = [];
        let depsDoneFlag = false;
        let newDepsData = [];

        let customFunctions = {};

        let docsToBeAttachedString = selected.docsToBeAttached.toString();

        //Caracterização
        if (docsToBeAttachedString.indexOf("Caracterização") != -1) {
          // Get new fromTable data from Class Descriptions deps
          newDeps = newDeps.concat(["Class_Plan_Class_Strats", "Class_Plan_Class_Classifications"]);

          scope.genericFactory.setRouteName("Class_Plan_Class_Descriptions");
          let map = {};
          map.year = selected.year;
          map.schoolYear = selected.schoolYear;
          if (selected.class != null) {
            map.class = selected.class;
          }

          necessaryDocsDataPromises.push(
            scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (data) {
              docFilteredData["Caracterização de Turma"] = data;
            })
          );
        }

        //Medidas
        if (docsToBeAttachedString.indexOf("Medidas de Suporte") != -1) {
          // Get new fromTable data from Stud Measures deps
          newDeps = newDeps.concat(["Class_Plan_Measures", "Class_Plan_Measure_Types"]);
          customFunctions["Medidas de Suporte - Turma e Aluno"] = ["ptSplitMeasures"];

          scope.genericFactory.setRouteName("Class_Plan_Action_Plans");
          let map = {};
          map.year = selected.year;
          map.schoolYear = selected.schoolYear;
          //Sends the history of all applied measures since the beginning of the scholar year
          //map.period = selected.period;
          if (selected.subject != null) {
            map.subject = selected.subject;
          }
          if (selected.class != null) {
            map.class = selected.class;
          }

          necessaryDocsDataPromises.push(
            scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (data) {
              docFilteredData["Medidas de Suporte - Turma e Aluno"] = data;
            })
          );
        }

        //Indisciplina Ocorrências
        if (docsToBeAttachedString.indexOf("Indisciplina Ocorrências") != -1) {
          // Get new fromTable data from Stud Measures deps
          newDeps = newDeps.concat([
            "Class_Plan_Indiscipline_Occurrence_Types",
            "Class_Plan_Indiscipline_Occurrence_Types",
            "Class_Plan_Indiscipline_Measure_Types",
            "Class_Plan_Indiscipline_Measures",
          ]);
          customFunctions["Indisciplina Ocorrências - Turma e Aluno"] = ["ptSplitIndisciplineOccurrences"];

          scope.genericFactory.setRouteName("Class_Plan_Indisciplines");
          let map = {};
          map.year = selected.year;
          map.schoolYear = selected.schoolYear;
          //Sends the history of all occurrences since the beginning of the scholar year
          if (selected.subject != null) {
            map.subject = selected.subject;
          }
          if (selected.class != null) {
            map.class = selected.class;
          }

          necessaryDocsDataPromises.push(
            scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (data) {
              docFilteredData["Indisciplina Ocorrências - Turma e Aluno"] = data;
            })
          );
        }

        //Avaliação
        if (docsToBeAttachedString.indexOf("Aval") != -1) {
          customFunctions["Pauta de Avaliação Final - Turma e Aluno"] = ["ptSplitFinalEvalsSchoolRecord"];
          customFunctions["Pauta de Avaliação Final Domínios - Turma e Aluno"] = ["ptSplitFinalEvalsSchoolRecord"];
          customFunctions["Pauta de Avaliação Final Domínios - Perfis de Aprendizagem - Turma e Aluno"] = [
            "ptSplitFinalEvalsSchoolRecord",
          ];
          customFunctions["Pauta de Avaliação Intercalar - Turma e Aluno"] = ["ptSplitFinalEvalsSchoolRecord"];
          customFunctions["Pauta de Avaliação Intercalar Domínios - Turma e Aluno"] = ["ptSplitFinalEvalsSchoolRecord"];
          //let customFunctions1 = ["ptSplitFinalEvalsSchoolRecord"];
          scope.genericFactory.setRouteName("Class_Plan_School_Records");
          let map = {};
          map.year = selected.year;
          map.schoolYear = selected.schoolYear;
          map.period = selected.period;
          if (selected.subject != null) {
            map.subject = selected.subject;
          }
          if (selected.class != null) {
            map.class = selected.class;
          }

          necessaryDocsDataPromises.push(
            scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (data) {
              docFilteredData["Pauta de Avaliação Final - Turma e Aluno"] = data.filter(
                (el) => el.evalDesc == "Final" || el.evalDesc == "Período/Semestre"
              );
              docFilteredData["Pauta de Avaliação Final Domínios - Turma e Aluno"] = data.filter(
                (el) => el.evalDesc == "Final" || el.evalDesc == "Período/Semestre"
              );
              docFilteredData["Pauta de Avaliação Final Domínios - Perfis de Aprendizagem - Turma e Aluno"] =
                data.filter((el) => el.evalDesc == "Final" || el.evalDesc == "Período/Semestre");
              docFilteredData["Pauta de Avaliação Intercalar - Turma e Aluno"] = data.filter(
                (el) => el.evalDesc == "Intercalar"
              );
              docFilteredData["Pauta de Avaliação Intercalar Domínios - Turma e Aluno"] = data.filter(
                (el) => el.evalDesc == "Intercalar"
              );
            })
          );
        }

        if (newDeps.length > 0) {
          newDepsData = scope.genericFactory.getAllList(newDeps, scope.currentUser.organization);

          //If new deps are necessary. Deps -> Docs data -> Custom Functions
          Promise.all(newDepsData).then(function (depsData) {
            for (let i = 0; i < depsData.length; i++) {
              newDepsData[i] = depsData[i];
            }
            scope.genericScope.deps = scope.deps.concat(newDeps);
            scope.genericScope.depsData = scope.depsData.concat(newDepsData);
            depsDoneFlag = true;

            Promise.all(necessaryDocsDataPromises).then(function (docsData) {
              let docsWithNoData = [];

              //Check if it impossible to get data from a certain doc given the chosen filters
              for (const docName in docFilteredData) {
                if (docFilteredData.hasOwnProperty(docName)) {
                  const dData = docFilteredData[docName];
                  if (dData.length == 0 && selected.docsToBeAttached.indexOf(docName) != -1) {
                    docsWithNoData.push(docName);
                  }
                }
              }

              //Remove docs with no data from lists
              for (let j = 0; j < selected.docsToBeAttached.length; ) {
                let docName = selected.docsToBeAttached[j];
                if (docsWithNoData.indexOf(docName) != -1) {
                  delete docFilteredData[docName];
                  selected.docsToBeAttached.splice(j, 1);
                } else {
                  j++;
                }
              }

              //Exit if there are empty datasets for doc export
              if (docsWithNoData.length > 0) {
                scope.genericFactory.setRouteName("Class_Plan_Parent_Communications");
                scope.genericScope.$parent.alertPTDocsWithNoData(docsWithNoData);
                scope.genericScope.$parent.hideLoader();
                resolve(false);
              } else {
                docsDoneFlag = true;

                //Run custom functions
                for (const doc in customFunctions) {
                  if (customFunctions.hasOwnProperty(doc)) {
                    const docCustomFunctions = customFunctions[doc];
                    for (let i = 0; i < docCustomFunctions.length; i++) {
                      const element = docCustomFunctions[i];
                      if (
                        utilFunctions[element] !== null &&
                        utilFunctions[element] !== undefined &&
                        docFilteredData[doc] != null
                      ) {
                        utilFunctions[element](scope.genericScope, docFilteredData[doc]);
                      }
                    }
                  }
                }

                let map = {};
                map.collection = [
                  "Class_Plan_School_Records",
                  "Class_Plan_Action_Plans",
                  "Class_Plan_Class_Descriptions",
                  "Class_Plan_Indisciplines",
                ];
                scope.genericScope.metaModuleFactory.getByProperties(map, null, true).then(function (modules) {
                  scope.genericFactory.setRouteName("Class_Plan_Parent_Communications");
                  sendMessages(modules);
                });
              }
            });
          });
        } else {
          //If no new deps are necessary. Docs data -> Custom Functions
          Promise.all(necessaryDocsDataPromises).then(function (docsData) {
            let docsWithNoData = [];

            //Check if it impossible to get data from a certain doc given the chosen filters
            for (const docName in docFilteredData) {
              if (docFilteredData.hasOwnProperty(docName)) {
                const dData = docFilteredData[docName];
                if (dData.length == 0 && selected.docsToBeAttached.indexOf(docName) != -1) {
                  docsWithNoData.push(docName);
                }
              }
            }

            //Remove docs with no data from lists
            for (let j = 0; j < selected.docsToBeAttached.length; ) {
              let docName = selected.docsToBeAttached[j];
              if (docsWithNoData.indexOf(docName) != -1) {
                delete docFilteredData[docName];
                selected.docsToBeAttached.splice(j, 1);
              } else {
                j++;
              }
            }

            //Exit if there are empty datasets for doc export
            if (docsWithNoData.length > 0) {
              scope.genericFactory.setRouteName("Class_Plan_Parent_Communications");
              scope.genericScope.$parent.alertPTDocsWithNoData(docsWithNoData);
              scope.genericScope.$parent.hideLoader();
              resolve(false);
            } else {
              docsDoneFlag = true;

              //Run custom functions
              for (const doc in customFunctions) {
                if (customFunctions.hasOwnProperty(doc)) {
                  const docCustomFunctions = customFunctions[doc];
                  for (let i = 0; i < docCustomFunctions.length; i++) {
                    const element = docCustomFunctions[i];
                    if (
                      utilFunctions[element] !== null &&
                      utilFunctions[element] !== undefined &&
                      docFilteredData[doc] != null
                    ) {
                      utilFunctions[element](scope.genericScope, docFilteredData[doc]);
                    }
                  }
                }
              }

              if (newDeps.length == 0 || depsDoneFlag) {
                let map = {};
                map.collection = [
                  "Class_Plan_School_Records",
                  "Class_Plan_Action_Plans",
                  "Class_Plan_Class_Descriptions",
                  "Class_Plan_Indisciplines",
                ];
                scope.genericScope.metaModuleFactory.getByProperties(map, null, true).then(function (modules) {
                  scope.genericFactory.setRouteName("Class_Plan_Parent_Communications");
                  sendMessages(modules);
                });
              }
            }
          });
        }
      }

      function sendMessages(modules) {
        if (validateDocsFlag) {
          infoForMails = {};
          infoForMails.validateDocsFlag = {
            studID: studsToSendCommunications,
          };
        }
        async.eachSeries(
          Object.values(infoForMails),
          function (info, callback) {
            let noDocsSuccessfullyAttachedFlag = false;
            let exportedDocs = [];
            let numDocsExportAttempt = 0;

            if (docsToBeAttachedFlag && info.sentState == null) {
              info.attachments = [];
              for (let j = 0; j < selected.docsToBeAttached.length; j++) {
                let docName = selected.docsToBeAttached[j];
                let docDataName = "";
                let reportItemConfig = {};

                if (docName.indexOf("Pauta de Aval") != -1) {
                  docDataName = docName;
                  let evaluationsExternalActiveTab = modules.filter(
                    (el) => el.collection === "Class_Plan_School_Records"
                  )[0].tabs["Grelha Avaliação Disciplina"];
                  reportItemConfig = modules
                    .filter((el) => el.collection === "Class_Plan_School_Records")[0]
                    .tabs["Grelha Avaliação Disciplina"].gridMenuCustomItems.filter(
                      (el) => el.exportTitle === docName
                    )[0];
                  reportItemConfig.activeTab = evaluationsExternalActiveTab;
                } else if (docName == "Caracterização de Turma") {
                  docDataName = docName;
                  let classDescriptionsExternalActiveTab = modules.filter(
                    (el) => el.collection === "Class_Plan_Class_Descriptions"
                  )[0].tabs["Caracterização de Turma"];
                  reportItemConfig = classDescriptionsExternalActiveTab.gridMenuCustomItems.filter(
                    (el) => el.exportTitle === docName
                  )[0];
                  reportItemConfig.activeTab = classDescriptionsExternalActiveTab;
                } else if (docName == "Medidas de Suporte - Turma e Aluno") {
                  docDataName = docName;
                  let measuresExternalActiveTab = modules.filter((el) => el.collection === "Class_Plan_Action_Plans")[0]
                    .tabs["Consulta de Medidas"];
                  reportItemConfig = measuresExternalActiveTab.gridMenuCustomItems.filter(
                    (el) => el.exportTitle === docName
                  )[0];
                  reportItemConfig.activeTab = measuresExternalActiveTab;
                } else if (docName == "Indisciplina Ocorrências - Turma e Aluno") {
                  docDataName = docName;
                  let indisciplineOccurrencesExternalActiveTab = modules.filter(
                    (el) => el.collection === "Class_Plan_Indisciplines"
                  )[0].tabs["Grelha de Ocorrências"];
                  reportItemConfig = indisciplineOccurrencesExternalActiveTab.gridMenuCustomItems.filter(
                    (el) => el.exportTitle === docName
                  )[0];
                  reportItemConfig.activeTab = indisciplineOccurrencesExternalActiveTab;
                }

                let genericExportConfig = {
                  reportTemplate: "generic",
                  targetData: "filtered",
                  dataTransformFunction: "genericParentCommunicationReportExport",
                  reportFileName: "Relatório_",
                  footerTemplate: "portraitDefault",
                  marginTop: "30px",
                  marginBottom: "125px",
                };
                if (reportItemConfig.reportFileName) {
                  genericExportConfig.reportFileName = reportItemConfig.reportFileName;
                } else {
                  genericExportConfig.reportFileName =
                    scope.genericScope.module.name + "_" + scope.genericScope.activeTabName + "_";
                }
                if (reportItemConfig.exportTitle) {
                  genericExportConfig.exportTitle = reportItemConfig.exportTitle;
                }
                if (reportItemConfig.exportTemplate) {
                  genericExportConfig.reportTemplate = reportItemConfig.exportTemplate;
                }
                if (reportItemConfig.footerTemplate) {
                  genericExportConfig.footerTemplate = reportItemConfig.footerTemplate;
                }
                if (reportItemConfig.marginTop) {
                  genericExportConfig.marginTop = reportItemConfig.marginTop;
                }
                if (reportItemConfig.marginBottom) {
                  genericExportConfig.marginBottom = reportItemConfig.marginBottom;
                }
                if (reportItemConfig.exportDataTransformFunction) {
                  genericExportConfig.dataTransformFunction = reportItemConfig.exportDataTransformFunction;
                }
                if (reportItemConfig.activeTab) {
                  genericExportConfig.activeTab = reportItemConfig.activeTab;
                }

                if (docFilteredData[docDataName][0].name != null) {
                  let docFilteredDataByStud = docFilteredData[docDataName].filter(
                    (el) => el.studentID === info.studID || info.studID.indexOf(el.studentID) != -1
                  );
                  if (docFilteredDataByStud.length > 0) {
                    exportReport(scope.genericScope, null, genericExportConfig, true, docFilteredDataByStud)
                      .then(function (response) {
                        return response.json();
                      })
                      .then(function (response) {
                        let responseData = response.$data;
                        numDocsExportAttempt += 1;
                        if (!validateDocsFlag) {
                          info.attachments.push({
                            filename: docName + " (" + info.studName + ").pdf",
                            path: `data:application/pdf;base64,${responseData}`,
                          });
                          exportedDocs.push(docName);
                          docExportConcluded();
                        } else {
                          const linkSource = `data:application/pdf;base64,${responseData}`;
                          const downloadLink = document.createElement("a");
                          const fileName = docName + " " + new Date().toLocaleDateString() + ".pdf";
                          downloadLink.href = linkSource;
                          downloadLink.download = fileName;
                          downloadLink.click();
                          validateDocExportConcluded();
                        }
                      }),
                      function errorCallback(responseData) {
                        console.log(responseData);
                        return null;
                      };
                  } else {
                    numDocsExportAttempt += 1;
                    docExportConcluded();
                  }
                } else {
                  exportReport(scope.genericScope, null, genericExportConfig, true, docFilteredData[docName])
                    .then(function (response) {
                      return response.json();
                    })
                    .then(function (response) {
                      let responseData = response.$data;
                      numDocsExportAttempt += 1;
                      if (!validateDocsFlag) {
                        info.attachments.push({
                          filename: docName + " (" + info.studName + ").pdf",
                          path: `data:application/pdf;base64,${responseData}`,
                        });
                        exportedDocs.push(docName);
                        docExportConcluded();
                      } else {
                        const linkSource = `data:application/pdf;base64,${responseData}`;
                        const downloadLink = document.createElement("a");
                        const fileName = docName + " " + new Date().toLocaleDateString() + ".pdf";
                        downloadLink.href = linkSource;
                        downloadLink.download = fileName;
                        downloadLink.click();
                        validateDocExportConcluded();
                      }
                    }),
                    function errorCallback(responseData) {
                      console.log(responseData);
                      return null;
                    };
                }
              }
            } else {
              callback();
            }

            function validateDocExportConcluded() {
              if (numDocsExportAttempt == selected.docsToBeAttached.length) {
                callback();
              }
            }

            function docExportConcluded() {
              if (numDocsExportAttempt == selected.docsToBeAttached.length) {
                if (info.attachments.length == 0) {
                  failedToSendEmails.push(info.studName);
                  callback();
                }
                if (exportedDocs.length > 0) {
                  scope.genericFactory.ptSendCommunication(info).then(function (response) {
                    if (sentEmails == 0) {
                      scope.genericScope.$parent.hideLoader();

                      //Start determinate loader when the first email is sent
                      scope.genericScope.$parent.showDeterminateLoader(toSendTotalEmails);
                    }
                    scope.genericScope.$parent.updateDeterminateLoader();
                    if (!response) {
                      failedToSendEmails.push(info.studName);
                    } else {
                      sentEmails += 1;
                      info.exportedStudDocs = exportedDocs;
                      info.sentState = "Enviado";
                    }
                    callback();
                  });
                }
              }
            }
          },
          function (err) {
            if (validateDocsFlag) {
              scope.genericScope.$parent.hideLoader();
              scope.genericScope.$parent.alertPTDownloadedDocs();
              resolve(true);
              return;
            }
            scope.genericScope.$parent.hideLoader();
            scope.genericScope.$parent.hideDeterminateLoader();
            if (err) {
              console.log(err);

              resolve(false);
            } else {
              if (toSendTotalEmails - failedToSendEmails.length > 0) {
                // Update sent mail history. Only do this when at least one email was sent with success
                updateSentMailHistoryAndSave();
                if (failedToSendEmails.length > 0) {
                  scope.genericScope.$parent.alertPTInvalidEmailAfterSend(
                    failedToSendEmails.toString().replace(/\,/gi, ", ")
                  );
                }
                scope.genericScope.$parent.alertPTSentEmailsWithSuccess(sentEmails, toSendTotalEmails);
              } else {
                //In case of some problems with sent e-mails
                if (toSendTotalEmails - failedToSendEmails.length == 0) {
                  scope.genericScope.$parent.alertPTNoEmailsWereSentErrors();
                } else if (failedToSendEmails.length > 0) {
                  scope.genericScope.$parent.alertPTInvalidEmailAfterSend(
                    failedToSendEmails.toString().replace(/\,/gi, ", ")
                  );
                }
                scope.genericScope.closePanel();
                resolve(true);
              }
            }
          }
        );
      }

      function updateSentMailHistoryAndSave() {
        if (selected.sentMailHistory == null) {
          selected.sentMailHistory = [];
        }
        if (!selected.id) {
          selected.createdBy = scope.currentUser;
          selected.createdAt = new Date().getTime();
          selected.organization = scope.currentUser.organization;
        }

        let selectedDuplicate = JSON.parse(JSON.stringify(selected));

        let simplifiedCreatedBy = {};
        simplifiedCreatedBy.name = selected.createdBy.name;
        simplifiedCreatedBy.surname = selected.createdBy.surname;
        selectedDuplicate.createdBy = simplifiedCreatedBy;
        delete selectedDuplicate.customMessage;
        delete selectedDuplicate.modifiedBy;
        delete selectedDuplicate.modifiedAt;
        delete selectedDuplicate.sentMailHistory;
        selectedDuplicate.communicationDate = new Date().getTime();
        selectedDuplicate.failedToSendEmails = failedToSendEmails;

        //Info of all sent mails
        for (const stud in infoForMails) {
          if (infoForMails.hasOwnProperty(stud)) {
            let mail = infoForMails[stud];
            delete mail.attachments;
            delete mail.customMessage;
          }
        }
        selectedDuplicate.sentMailInfo = infoForMails;

        selected.sentMailHistory.push(selectedDuplicate);

        if (selected.id) {
          scope.genericFactory.modify(selected.id, selected).then(() => {
            scope.genericScope.closePanel();
            resolve(true);
          });
        } else {
          scope.genericFactory.create(selected).then(() => {
            scope.genericScope.closePanel();
            resolve(true);
          });
        }
      }
    });
  },
  ptSendEvalInstrumentCommunicationCheck: function ptSendEvalInstrumentCommunicationCheck(
    scope,
    functionArgs,
    gridData,
    selected
  ) {
    if (selected.communicationDate) {
      var confirm = scope.$mdDialog
        .confirm()
        .title("Avaliações já enviadas")
        .textContent(
          "As avaliações selecionadas já foram enviadas na seguinte data:" +
            " " +
            new Date(selected.communicationDate).toLocaleString() +
            ". Deseja enviá-las de novo?"
        )
        .ariaLabel("Avaliações já enviadas")
        .ok("Sim")
        .cancel("Não");

      scope.$mdDialog.show(confirm).then(
        function () {
          utilFunctions["ptSendEvalInstrumentCommunication"](scope, functionArgs, gridData, selected);
        },
        function () {
          return new Promise((resolve, reject) => {
            resolve(true);
          });
        }
      );
    } else {
      utilFunctions["ptSendEvalInstrumentCommunication"](scope, functionArgs, gridData, selected);
    }
  },
  ptSendEvalInstrumentCommunication: function ptSendEvalInstrumentCommunication(
    scope,
    functionArgs,
    gridData,
    selected
  ) {
    return new Promise((resolve, reject) => {
      let infoForMails = {};

      let students = scope.getFromTableData("Class_Plan_Students");
      let evalInstruments = scope.getFromTableData("Class_Plan_Evaluation_Instruments");
      let studentsWithMissingEmails = [];
      let studentsWithMissingorInvalidParentEmails = [];
      let studentsMatched = 0;
      let toSendTotalEmails = 0;
      let sentEmails = 0;
      let failedToSendEmails = [];
      let validateDocsFlag = false;

      //List of studs IDs to validate docs before sending

      let studsToSendCommunications = [];

      //Check if message is also to students and setup flag

      let messageAlsoToStudentsFlag = true;

      //Check if there are docs to be attached

      let docsToBeAttachedFlag = true;

      //Create message

      let customMessage = "";

      let docFilteredData = {};

      customMessage = "Este e-mail foi-lhe enviado automaticamente pela plataforma KSTK Predictive Mindset.";
      /* customMessage +=
        "<br /><br /> Para responder ao rementente deste e-mail, utilize o seguinte endereço: " +
        (scope.currentUser.institucionalMail != null && scope.currentUser.institucionalMail != ""
          ? scope.currentUser.institucionalMail
          : scope.currentUser.email); */

      //Filter students first by class and status matricula = Matriculado

      let studentsFilteredByClass = students.filter(
        (el) =>
          selected.class == el.class &&
          el.status_matricula == "Matriculado" &&
          (el.retained == null || el.retained == "Não")
      );

      //Filter selected evaluation instrument

      let evalInstrument = evalInstruments.filter((el) => selected.evalInstrument == el.id);

      if (evalInstrument.length) {
        evalInstrument = evalInstrument[0].evalInstrument;
      } else {
        evalInstrument = null;
      }

      //Get parent and student emails. Creating necessary info blocks for each email

      for (let n = 0; n < studentsFilteredByClass.length; n++) {
        let stud = studentsFilteredByClass[n];
        createStudMailInfo(stud);
        studsToSendCommunications.push(stud.id);
      }

      function createStudMailInfo(stud) {
        if (
          (stud.e_mail_ee != null && stud.e_mail_ee != "" && validateEmail(stud.e_mail_ee)) ||
          (stud.e_mail_stud != null && stud.e_mail_stud != "" && validateEmail(stud.e_mail_stud))
        ) {
          toSendTotalEmails += 1;
          let mailInfo = {};
          mailInfo.institucionalFromEmail =
            scope.currentUser.institucionalMail != null && scope.currentUser.institucionalMail != ""
              ? scope.currentUser.institucionalMail
              : scope.currentUser.email;
          mailInfo.fromName = scope.currentUser.name + " " + scope.currentUser.surname;
          mailInfo.studID = stud.id;
          mailInfo.studName = stud.name + (evalInstrument != null ? " - " + evalInstrument : "");
          mailInfo.customMessage = customMessage;

          // Prepare email for EE
          if (stud.e_mail_ee != null && stud.e_mail_ee != "" && validateEmail(stud.e_mail_ee)) {
            toSendTotalEmails += 1;
            let eeMailInfo = JSON.parse(JSON.stringify(mailInfo));
            eeMailInfo.to = stud.e_mail_ee;
            infoForMails[stud.id + "ee"] = eeMailInfo;
          } else {
            studentsWithMissingEmails.push(stud.name);
          }

          // Prepare email for stud
          if (stud.e_mail_stud != null && stud.e_mail_stud != "" && validateEmail(stud.e_mail_stud)) {
            toSendTotalEmails += 1;
            let studMailInfo = JSON.parse(JSON.stringify(mailInfo));
            studMailInfo.to = stud.e_mail_stud;
            infoForMails[stud.id + "stud"] = studMailInfo;
          } else {
            studentsWithMissingEmails.push(stud.name);
          }

          // Prepare email for professor
          let professorMailInfo = JSON.parse(JSON.stringify(mailInfo));
          professorMailInfo.to = mailInfo.institucionalFromEmail;
          infoForMails[stud.id + "professor"] = professorMailInfo;
        } else {
          let mailInfo = {};
          mailInfo.studID = stud.id;
          mailInfo.studName = stud.name;
          mailInfo.sentState = "Erro ao enviar";
          infoForMails[stud.id] = mailInfo;
          studentsWithMissingorInvalidParentEmails.push(stud.name);
        }
      }

      //Validation dialog
      if (studentsWithMissingorInvalidParentEmails.length == Object.keys(infoForMails).length) {
        scope.$parent.alertPTNoValidParentEmailsToSend();
        resolve(false);
        return;
      }

      if (
        (messageAlsoToStudentsFlag && studentsWithMissingEmails.length > 0) ||
        studentsWithMissingorInvalidParentEmails.length > 0
      ) {
        // Missing emails
        let missingMailsConfirm = scope.$mdDialog
          .confirm()
          .clickOutsideToClose(false)
          .title("Aluno(s) com E-mail em Falta")
          .textContent(
            "O(s) seguinte(s) aluno(s) (" +
              studentsWithMissingEmails.concat(studentsWithMissingorInvalidParentEmails).length +
              ") tem(têm) o e-mail de EE ou pessoal (necessário se mensagem também for endereçada a alunos) em falta ou inválidos: " +
              studentsWithMissingEmails
                .concat(studentsWithMissingorInvalidParentEmails)
                .sort()
                .toString()
                .replace(/\,/gi, ", ") +
              ". Deseja continuar com o envio da mensagem?"
          )
          .ariaLabel(
            "O(s) seguinte(s) aluno(s) (" +
              studentsWithMissingEmails.concat(studentsWithMissingorInvalidParentEmails).length +
              ") tem(têm) o e-mail de EE ou pessoal (necessário se mensagem também for endereçada a alunos) em falta ou inválidos: " +
              studentsWithMissingEmails
                .concat(studentsWithMissingorInvalidParentEmails)
                .sort()
                .toString()
                .replace(/\,/gi, ", ") +
              ". Deseja continuar com o envio da mensagem?"
          )
          .ok("Sim")
          .cancel("Não");

        scope.$mdDialog.show(missingMailsConfirm).then(
          function () {
            confirmSendCommunication();
          },
          function () {
            resolve(false);
          }
        );
      } else {
        // No missing emails
        confirmSendCommunication();
      }

      function confirmSendCommunication() {
        // Confirm send process
        let finishSendCommunicationConfirm = scope.$mdDialog
          .confirm()
          .clickOutsideToClose(false)
          .title("Confirmação de Envio")
          .textContent("Deseja enviar as avaliações aos respetivos alunos e encarregados de educação?")
          .ariaLabel("Deseja enviar as avaliações aos respetivos alunos e encarregados de educação?")
          .ok("Enviar pauta")
          .cancel("Cancelar");

        scope.$mdDialog.show(finishSendCommunicationConfirm).then(
          function () {
            scope.$parent.showLoader();
            if (!docsToBeAttachedFlag) {
              sendMessages();
            } else {
              prepareDocsToBeAttached();
            }
          },
          function () {
            resolve(false);
          }
        );
      }

      function prepareDocsToBeAttached() {
        let customFunctions = {};

        // Instrumento de avaliação
        // Not AEAA
        if (scope.currentUser.organization != "59f069bdb0c8f16d4e0a527c") {
          docFilteredData["Pauta de Instrumento de Avaliação"] = utilFunctions["ptEvalInstrumentByStudent"](
            scope,
            {
              reportTemplate: "ptEvalInstrumentByStudent",
              targetData: "selected",
              dataTransformFunction: "ptEvalInstrumentByStudent",
              promiseDataTransfFunctiona: true,
              exportTitle: "Pauta de Instrumento de Avaliação",
              reportFileName: "Pauta_De_Instrumento_",
              footerTemplate: "portraitDefault",
              marginTop: "30px",
              marginBottom: "150px",
            },
            [selected],
            true,
            true
          );
        }

        // Instrumento de avaliação simplificada
        // AEAA
        if (scope.currentUser.organization == "59f069bdb0c8f16d4e0a527c") {
          docFilteredData["Pauta de Instrumento de Avaliação"] = utilFunctions["ptEvalInstrumentByStudentSimplified"](
            scope,
            [selected]
          );
        }

        if (
          docFilteredData["Pauta de Instrumento de Avaliação"] != null &&
          docFilteredData["Pauta de Instrumento de Avaliação"].length > 0
        ) {
          let map = {};
          map.collection = ["Class_Plan_School_Records"];
          scope.metaModuleFactory.getByProperties(map, null, true).then(function (modules) {
            scope.genericFactory.setRouteName("Class_Plan_Parent_Communications");
            sendMessages(modules);
          });
        } else {
          scope.$parent.alertPTNoDataToSend();
          scope.$parent.hideLoader();
          resolve(false);
          return;
        }
      }

      function sendMessages(modules) {
        async.eachSeries(
          Object.values(infoForMails),
          function (info, callback) {
            let noDocsSuccessfullyAttachedFlag = false;
            let exportedDocs = [];
            let numDocsExportAttempt = 0;

            if (docsToBeAttachedFlag && info.sentState == null) {
              info.attachments = [];
              for (let j = 0; j < 1; j++) {
                let docName = "Pauta de Instrumento de Avaliação";
                let docDataName = "";
                let reportItemConfig = {};

                docDataName = docName;
                let evaluationsExternalActiveTab = modules.filter(
                  (el) => el.collection === "Class_Plan_School_Records"
                )[0].tabs["Avaliação Instrumento"];
                reportItemConfig = modules.filter((el) => el.collection === "Class_Plan_School_Records")[0].tabs[
                  "Avaliação Instrumento"
                ].customFunctionButtons["exportInstrumentEvalsByStudent"].functionArgs;
                reportItemConfig.activeTab = evaluationsExternalActiveTab;

                let genericExportConfig = {
                  reportTemplate:
                    scope.currentUser.organization == "59f069bdb0c8f16d4e0a527c"
                      ? "ptEvalInstrumentByStudentSimplified"
                      : "ptEvalInstrumentByStudent",
                  targetData: "selected",
                  dataTransformFunction: "ptEvalInstrumentByStudent",
                  sendCommunicationMode: true,
                  exportTitle: "Pauta de Instrumento de Avaliação",
                  reportFileName: "Pauta_De_Instrumento_",
                  footerTemplate: "portraitDefault",
                  marginTop: "30px",
                  marginBottom: "150px",
                  activeTab: reportItemConfig.activeTab,
                };

                if (docFilteredData[docDataName][0].name != null) {
                  let docFilteredDataByStud = docFilteredData[docDataName].filter(
                    (el) => el.studentID === info.studID || info.studID.indexOf(el.studentID) != -1
                  );
                  if (docFilteredDataByStud.length > 0) {
                    exportReport(scope, null, genericExportConfig, true, docFilteredDataByStud)
                      .then(function (response) {
                        return response.json();
                      })
                      .then(function (response) {
                        let responseData = response.$data;
                        numDocsExportAttempt += 1;
                        if (!validateDocsFlag) {
                          info.attachments.push({
                            filename: docName + " (" + info.studName + ").pdf",
                            path: `data:application/pdf;base64,${responseData}`,
                          });
                          exportedDocs.push(docName);
                          docExportConcluded();
                        } else {
                          const linkSource = `data:application/pdf;base64,${responseData}`;
                          const downloadLink = document.createElement("a");
                          const fileName = docName + " " + new Date().toLocaleDateString() + ".pdf";
                          downloadLink.href = linkSource;
                          downloadLink.download = fileName;
                          downloadLink.click();
                          validateDocExportConcluded();
                        }
                      }),
                      function errorCallback(responseData) {
                        console.log(responseData);
                        return null;
                      };
                  } else {
                    numDocsExportAttempt += 1;
                    docExportConcluded();
                  }
                }
              }
            } else {
              callback();
            }

            function validateDocExportConcluded() {
              if (numDocsExportAttempt == 1) {
                callback();
              }
            }

            function docExportConcluded() {
              if (numDocsExportAttempt == 1) {
                if (info.attachments.length == 0) {
                  /* failedToSendEmails.push(info.studName); */
                  callback();
                }
                if (exportedDocs.length > 0) {
                  scope.genericFactory.ptSendCommunication(info).then(function (response) {
                    if (sentEmails == 0) {
                      scope.$parent.hideLoader();

                      //Start determinate loader when the first email is sent
                      scope.$parent.showDeterminateLoader(toSendTotalEmails);
                    }
                    scope.$parent.updateDeterminateLoader();
                    if (!response) {
                      failedToSendEmails.push(info.studName);
                    } else {
                      sentEmails += 1;
                      info.exportedStudDocs = exportedDocs;
                      info.sentState = "Enviado";
                    }
                    callback();
                  });
                }
              }
            }
          },
          function (err) {
            if (validateDocsFlag) {
              scope.$parent.hideLoader();
              scope.$parent.alertPTDownloadedDocs();
              resolve(true);
              return;
            }
            scope.$parent.hideLoader();
            scope.$parent.hideDeterminateLoader();
            if (err) {
              console.log(err);

              resolve(false);
            } else {
              scope.genericFactory.setRouteName("Class_Plan_School_Records");
              updateSentMailHistoryAndSave();
              scope.$parent.alertPTEvalInstrumentSentEmails(sentEmails, toSendTotalEmails);
            }
          }
        );
      }

      function updateSentMailHistoryAndSave() {
        selected.communicationDate = new Date().getTime();

        scope.genericFactory.modify(selected.id, selected).then(() => {
          resolve(true);
        });
      }
    });
  },
  ptLoadEssentialLearnings: function ptLoadEssentialLearnings(scope, selected, data) {
    if (selected.evalCritGridDesc) {
      scope.parentScope.$parent.showLoader();
      let professionalFlag;
      let originalRouteName = scope.module.collection;
      if (scope.deps.indexOf("Class_Plan_Prof_Modules") != -1 && selected.module != null) {
        professionalFlag = true;
      }

      // Load performance level crits
      if (!professionalFlag) {
        scope.genericFactory.setRouteName("Class_Plan_Evaluation_Crits");
      } else {
        scope.genericFactory.setRouteName("Class_Plan_Prof_Evaluation_Crits");
      }
      scope.genericFactory.get(selected.evalCritGridDesc).then(function (evalCritGridDesc) {
        if (evalCritGridDesc) {
          if (
            evalCritGridDesc.schoolYear.indexOf(selected.schoolYear) == -1 ||
            evalCritGridDesc.subject.indexOf(selected.subject) == -1 ||
            evalCritGridDesc.year != selected.year ||
            evalCritGridDesc.essentialLearningDomainWeight == null ||
            (Array.isArray(evalCritGridDesc.essentialLearningDomainWeight) &&
              evalCritGridDesc.essentialLearningDomainWeight.length == 0)
          ) {
            selected.essentialLearnings = null;
            scope.genericScope.$parent.alertCPInvalidSelectedEvaluationCrits();
          } else if (professionalFlag && evalCritGridDesc.module.indexOf(selected.module) == -1) {
            selected.essentialLearnings = null;
            scope.genericScope.$parent.alertCPInvalidSelectedEvaluationCrits();
          } else {
            selected.essentialLearnings = evalCritGridDesc.essentialLearningDomainWeight;
            scope.genericScope.$parent.alertCPValidSelectedEvaluationCrits();
          }
        }
        scope.parentScope.$parent.hideLoader();
        scope.genericFactory.setRouteName(originalRouteName);
      });
    }
  },
  ptLoadPerformanceLevelCrits: function ptLoadPerformanceLevelCrits(scope, selected, data) {
    if (selected.performanceLevelDesc) {
      scope.parentScope.$parent.showLoader();

      // Load performance level crits
      scope.genericFactory.setRouteName("Class_Plan_Eval_Qualitative_Levels");
      scope.genericFactory.get(selected.performanceLevelDesc).then(function (performanceLevelDetails) {
        if (performanceLevelDetails) {
          if (
            performanceLevelDetails.schoolYear.indexOf(selected.schoolYear) == -1 ||
            performanceLevelDetails.subject.indexOf(selected.subject) == -1 ||
            performanceLevelDetails.year != selected.year
          ) {
            selected.performanceCrits = null;
            scope.genericScope.$parent.alertCPInvalidSelectedPerformanceLevelDesc();
          } else {
            selected.performanceCrits = performanceLevelDetails.performanceCrits;
            scope.genericScope.$parent.alertCPValidSelectedPerformanceLevelDesc();
          }
        }
        scope.parentScope.$parent.hideLoader();
        scope.genericFactory.setRouteName("Class_Plan_Evaluation_Instruments");
      });
    }
  },
  ptRefreshEvalInstrument: function ptRefreshEvalInstrument(scope, selected, data) {
    if (selected.evalCritGridDesc) {
      scope.parentScope.$parent.showLoader();

      let professionalFlag = false;

      if (scope.deps.indexOf("Class_Plan_Module_Essential_Learnings") != -1) {
        professionalFlag = true;
      }

      // Validate selected subject

      let validSubjectFlag = scope
        .getFromTableData("Class_Plan_Subjects")
        .filter((el) => el.id == selected.subject && el.schoolYear == selected.schoolYear);

      if (!validSubjectFlag.length) {
        // Invalid subject
        scope.genericScope.$parent.alertCPInvalidSelectedSubject();
        scope.parentScope.$parent.hideLoader();
        return;
      }

      let validEvalCritGridDesc;
      if (!professionalFlag) {
        validEvalCritGridDesc = scope
          .getFromTableData("Class_Plan_Evaluation_Crits")
          .filter(
            (el) =>
              el.id == selected.evalCritGridDesc &&
              el.schoolYear == selected.schoolYear &&
              el.subject == selected.subject
          );
      } else {
        validEvalCritGridDesc = scope
          .getFromTableData("Class_Plan_Prof_Evaluation_Crits")
          .filter(
            (el) =>
              el.id == selected.evalCritGridDesc &&
              el.schoolYear == selected.schoolYear &&
              el.subject == selected.subject &&
              el.module == selected.module
          );
      }

      if (!validEvalCritGridDesc.length) {
        // Invalid eval crit grid desc
        scope.genericScope.$parent.alertCPEvalInstrumentRefreshInvalidSelectedEvalCritGridDesc();
        scope.parentScope.$parent.hideLoader();
        return;
      }

      // Load correct essentialLearnings
      selected.essentialLearnings = validEvalCritGridDesc[0].essentialLearningDomainWeight;

      let newEssentialLearnings;

      if (!professionalFlag) {
        newEssentialLearnings = scope
          .getFromTableData("Class_Plan_Essential_Learnings")
          .filter((el) => el.subject == selected.subject);
      } else {
        newEssentialLearnings = scope
          .getFromTableData("Class_Plan_Module_Essential_Learnings")
          .filter((el) => el.module.indexOf(selected.module) != -1);
      }

      // Translate essentialLearningDomainWeight essential learnings

      for (let i = 0; i < selected.essentialLearningDomainWeight.length; i++) {
        let oldEssentialLearning = selected.essentialLearningDomainWeight[i];
        let newEssentialLearningFromOldId = parseOldEssentialLearning(oldEssentialLearning);
        if (newEssentialLearningFromOldId) {
          oldEssentialLearning.essentialLearning = newEssentialLearningFromOldId;
        }
      }

      // Translate instrumentQuestions essential learnings

      for (let i = 0; i < selected.instrumentQuestions.length; i++) {
        let oldEssentialLearning = selected.instrumentQuestions[i];
        let newEssentialLearningFromOldId = parseOldEssentialLearning(oldEssentialLearning);
        if (newEssentialLearningFromOldId) {
          oldEssentialLearning.essentialLearning = newEssentialLearningFromOldId;
        }
      }

      function parseOldEssentialLearning(oldEssentialLearning) {
        let parsedOldEssentialLearning;
        if (!professionalFlag) {
          parsedOldEssentialLearning = scope
            .getFromTableData("Class_Plan_Essential_Learnings")
            .filter((el) => el.id == oldEssentialLearning.essentialLearning);
        } else {
          parsedOldEssentialLearning = scope
            .getFromTableData("Class_Plan_Module_Essential_Learnings")
            .filter((el) => el.id == oldEssentialLearning.essentialLearning);
        }
        if (parsedOldEssentialLearning.length) {
          parsedOldEssentialLearning = parsedOldEssentialLearning[0].essentialLearning;
          // Get new essential learning based on parsed old essential learning, 'Escrita' == 'Escrita'
          let newEssentialLearningFromOld = newEssentialLearnings.filter(
            (el) => el.essentialLearning == parsedOldEssentialLearning
          );
          if (newEssentialLearningFromOld.length) {
            return newEssentialLearningFromOld[0].id;
          } else {
            return null;
          }
        } else {
          return null;
        }
      }

      scope.genericScope.$parent.alertCPEssentialLearningRefresh();
      scope.parentScope.$parent.hideLoader();
    }
  },
  ptRefreshEssentialLearnings: function ptRefreshEssentialLearnings(scope, selected, data) {
    if (selected.evalCritGridDesc) {
      scope.parentScope.$parent.showLoader();

      let professionalFlag = false;

      if (selected.module != null) {
        professionalFlag = true;
      }

      // Validate selected subject

      let validSubjectFlag = scope
        .getFromTableData("Class_Plan_Subjects")
        .filter((el) => el.id == selected.subject && el.schoolYear == selected.schoolYear);

      if (!validSubjectFlag.length) {
        // Invalid subject
        scope.genericScope.$parent.alertCPInvalidSelectedSubject();
        scope.parentScope.$parent.hideLoader();
        return;
      }

      let validEvalCritGridDesc;
      if (!professionalFlag) {
        validEvalCritGridDesc = scope
          .getFromTableData("Class_Plan_Evaluation_Crits")
          .filter(
            (el) =>
              el.id == selected.evalCritGridDesc &&
              el.schoolYear == selected.schoolYear &&
              el.subject == selected.subject
          );
      } else {
        validEvalCritGridDesc = scope
          .getFromTableData("Class_Plan_Prof_Evaluation_Crits")
          .filter(
            (el) =>
              el.id == selected.evalCritGridDesc &&
              el.schoolYear == selected.schoolYear &&
              el.subject == selected.subject &&
              el.module == selected.module
          );
      }

      if (!validEvalCritGridDesc.length) {
        // Invalid eval crit grid desc
        scope.genericScope.$parent.alertCPInvalidSelectedEvalCritGridDes();
        scope.parentScope.$parent.hideLoader();
        return;
      }

      // Load correct essentialLearnings
      selected.essentialLearnings = validEvalCritGridDesc[0].essentialLearningDomainWeight;

      let newEssentialLearnings;

      if (!professionalFlag) {
        newEssentialLearnings = scope
          .getFromTableData("Class_Plan_Essential_Learnings")
          .filter((el) => el.subject == selected.subject);
      } else {
        newEssentialLearnings = scope
          .getFromTableData("Class_Plan_Module_Essential_Learnings")
          .filter((el) => el.module.indexOf(selected.module) != -1);
      }

      // Translate fields with essential learnings

      let targetFields = scope.activeTab.fields["refreshEssentialLearnings"].targetFields;

      if (targetFields.length) {
        targetFields.forEach((targetField) => {
          for (let i = 0; i < selected[targetField].length; i++) {
            let oldEssentialLearning = selected[targetField][i];
            let newEssentialLearningFromOldId = parseOldEssentialLearning(oldEssentialLearning);
            if (newEssentialLearningFromOldId) {
              oldEssentialLearning.essentialLearning = newEssentialLearningFromOldId;
            }
          }
        });
      }

      function parseOldEssentialLearning(oldEssentialLearning) {
        let parsedOldEssentialLearning;
        if (!professionalFlag) {
          parsedOldEssentialLearning = scope
            .getFromTableData("Class_Plan_Essential_Learnings")
            .filter((el) => el.id == oldEssentialLearning.essentialLearning);
        } else {
          parsedOldEssentialLearning = scope
            .getFromTableData("Class_Plan_Module_Essential_Learnings")
            .filter((el) => el.id == oldEssentialLearning.essentialLearning);
        }
        if (parsedOldEssentialLearning.length) {
          parsedOldEssentialLearning = parsedOldEssentialLearning[0].essentialLearning;
          // Get new essential learning based on parsed old essential learning, 'Escrita' == 'Escrita'
          let newEssentialLearningFromOld = newEssentialLearnings.filter(
            (el) => el.essentialLearning == parsedOldEssentialLearning
          );
          if (newEssentialLearningFromOld.length) {
            return newEssentialLearningFromOld[0].id;
          } else {
            return null;
          }
        } else {
          return null;
        }
      }

      scope.genericScope.$parent.alertCPEssentialLearningRefresh();
      scope.parentScope.$parent.hideLoader();
    }
  },
  ptLoadEssenLearnExpertiseFields: function ptLoadEssenLearnExpertiseFields(scope, selected, data) {
    scope.parentScope.$parent.showLoader();

    // Load expertiseFields
    scope.genericFactory.setRouteName("Class_Plan_Expertise_Fields");
    scope.genericFactory.getByProperty("organization", scope.currentUser.organization).then(function (expertiseFields) {
      let essentialLearnings = null;
      let professionalFlag = false;

      if (scope.deps.indexOf("Class_Plan_Module_Essential_Learnings") != -1) {
        professionalFlag = true;
      }

      if (
        expertiseFields &&
        expertiseFields.length > 0 &&
        selected.essentialLearningDomainWeight &&
        selected.essentialLearningDomainWeight.length > 0 &&
        Object.keys(selected.essentialLearningDomainWeight[0]).length > 0
      ) {
        let selectedEssentialLearnings = [];

        selected.essentialLearningDomainWeight.forEach((selecEssenLearn) => {
          if (selecEssenLearn.essentialLearning) {
            selectedEssentialLearnings.push(selecEssenLearn.essentialLearning);
          }
        });

        if (scope.deps.indexOf("Class_Plan_Module_Essential_Learnings") == -1) {
          essentialLearnings = scope
            .getFromTableData("Class_Plan_Essential_Learnings")
            .filter((el) => selectedEssentialLearnings.indexOf(el.id) != -1);
        } else {
          essentialLearnings = scope
            .getFromTableData("Class_Plan_Module_Essential_Learnings")
            .filter((el) => selectedEssentialLearnings.indexOf(el.id) != -1);
        }

        if (selected.expertiseFields) {
          delete selected.expertiseFields;
        }
        if (selected.expertiseFieldscheckbox) {
          delete selected.expertiseFieldscheckbox;
        }

        let expertiseFilteredByEssenLearn = [];

        essentialLearnings.forEach((essenLearn) => {
          if (
            essenLearn.expertiseField &&
            Array.isArray(essenLearn.expertiseField) &&
            essenLearn.expertiseField.length > 0
          ) {
            essenLearn.expertiseField.forEach((essenLearnExpertiseField) => {
              let filteredExpertiseField = expertiseFields.filter((el) => el.id === essenLearnExpertiseField);
              if (Array.isArray(filteredExpertiseField) && filteredExpertiseField.length > 0) {
                if (!selected["expertiseFieldscheckbox"]) {
                  selected["expertiseFieldscheckbox"] = {};
                }
                if (!selected["expertiseFieldscheckbox"][essenLearnExpertiseField]) {
                  selected["expertiseFieldscheckbox"][essenLearnExpertiseField] = true;
                  expertiseFilteredByEssenLearn.push(filteredExpertiseField[0]);
                }
              }
            });
          }
        });

        if (expertiseFilteredByEssenLearn.length > 0) {
          expertiseFilteredByEssenLearn.sort(function (a, b) {
            if (a.expertiseField < b.expertiseField) {
              return -1;
            }
            if (a.expertiseField > b.expertiseField) {
              return 1;
            }
          });

          selected.auxExpertiseFields = expertiseFilteredByEssenLearn;
        }
      }

      if (professionalFlag) {
        scope.genericFactory.setRouteName("Class_Plan_Prof_Evaluation_Instruments");
      } else {
        scope.genericFactory.setRouteName("Class_Plan_Evaluation_Instruments");
      }

      scope.parentScope.$parent.hideLoader();
    });
  },
  generateAndSetUniqueProcessNumber: function generateAndSetUniqueProcessNumber(scope, selected, data) {
    let id = generateId();
    scope.genericFactory.getAll().then(function (updatedModuleData) {
      if (validateGeneratedId(id, updatedModuleData)) {
        selected.uniqueProcessNumber = id;
      } else {
        generateAndSetUniqueProcessNumber(scope, selected, data);
      }
    });

    function generateId() {
      // start by the year
      let id = new Date().getFullYear();
      // add two random letters
      id =
        id +
        String.fromCharCode(65 + Math.floor(Math.random() * 26)) +
        String.fromCharCode(65 + Math.floor(Math.random() * 26));
      // add 4 random numbers
      id = id + Math.floor(1000 + Math.random() * 9000);
      return id;
    }

    function validateGeneratedId(id, refData) {
      for (let k = 0; k < data.length; k++) {
        const el = data[k];
        if (el.uniqueProcessNumber == id) {
          return false;
        }
      }
      return true;
    }
  },
  iptClientCheckPercentages: function iptClientCheckPercentages(scope, selected, data) {
    if (selected.lisbonPer != null || selected.viseuPer != null || selected.portoPer != null) {
      let totalPer = 0;
      let perArray = ["lisbonPer", "viseuPer", "portoPer"];
      perArray.forEach((perEl) => {
        if (selected[perEl] != null && !isNaN(Number(selected[perEl]))) {
          totalPer += Math.round(selected[perEl] * 100) / 100;
        }
      });
      if (totalPer != 100) {
        scope.genericScope.$parent.alertIPTClientInvalidPercentages();
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  },
  checkNIFFieldIsEmpty: function checkNIFFieldIsEmpty(scope, selected, data) {
    if (
      (selected.nif == null || selected.nif == "") &&
      ((selected.nonexistentNIFcheckbox &&
        selected.nonexistentNIFcheckbox[""] &&
        selected.nonexistentNIFcheckbox[""] == false) ||
        selected.nonexistentNIFcheckbox == null ||
        Object.keys(selected.nonexistentNIFcheckbox).length == 0)
    ) {
      scope.genericScope.$parent.alertNIFFieldIsEmpty();
      return false;
    } else {
      return true;
    }
  },
  checkFamilyRecordIdDocNISS: function checkFamilyRecordIdDocNISS(scope, selected, data) {
    let selectedIdDocNISS = [];
    let selectedIdDocNISSHouseholdNumbers = [];

    if (selected.idDocumentNumber != null && selected.idDocumentNumber != "") {
      selectedIdDocNISS.push(selected.idDocumentNumber);
      selectedIdDocNISSHouseholdNumbers.push(1);
    }
    if (selected.niss != null && selected.niss != "") {
      selectedIdDocNISS.push(selected.niss);
      selectedIdDocNISSHouseholdNumbers.push(1);
    }
    if (selected.household != null && selected.household.length) {
      selected.household.forEach((householdEl, index) => {
        if (householdEl.idDocumentNumber != null && householdEl.idDocumentNumber != "") {
          selectedIdDocNISS.push(householdEl.idDocumentNumber);
          selectedIdDocNISSHouseholdNumbers.push(index + 2);
        }
        if (householdEl.niss != null && householdEl.niss != "") {
          selectedIdDocNISS.push(householdEl.niss);
          selectedIdDocNISSHouseholdNumbers.push(index + 2);
        }
      });
    }

    for (let i = 0; i < data.length; i++) {
      let dataEl = data[i];
      if (dataEl.id != selected.id) {
        if (
          dataEl.idDocumentNumber != null &&
          dataEl.idDocumentNumber != "" &&
          selectedIdDocNISS.indexOf(dataEl.idDocumentNumber) != -1
        ) {
          scope.genericScope.$parent.alertIdDocNISSAlreadyInserted(
            selectedIdDocNISSHouseholdNumbers[selectedIdDocNISS.indexOf(dataEl.idDocumentNumber)],
            1,
            dataEl.uniqueProcessNumber
          );
          return false;
        }
        if (dataEl.niss != null && dataEl.niss != "" && selectedIdDocNISS.indexOf(dataEl.niss) != -1) {
          scope.genericScope.$parent.alertIdDocNISSAlreadyInserted(
            selectedIdDocNISSHouseholdNumbers[selectedIdDocNISS.indexOf(dataEl.niss)],
            1,
            dataEl.uniqueProcessNumber
          );
          return false;
        }

        if (dataEl.household != null && dataEl.household.length) {
          for (let j = 0; j < dataEl.household.length; j++) {
            let householdEl = dataEl.household[j];
            if (
              householdEl.idDocumentNumber != null &&
              householdEl.idDocumentNumber != "" &&
              selectedIdDocNISS.indexOf(householdEl.idDocumentNumber) != -1
            ) {
              scope.genericScope.$parent.alertIdDocNISSAlreadyInserted(
                selectedIdDocNISSHouseholdNumbers[selectedIdDocNISS.indexOf(householdEl.idDocumentNumber)],
                householdEl.householdNumber,
                dataEl.uniqueProcessNumber
              );
              return false;
            }
            if (
              householdEl.niss != null &&
              householdEl.niss != "" &&
              selectedIdDocNISS.indexOf(householdEl.niss) != -1
            ) {
              scope.genericScope.$parent.alertIdDocNISSAlreadyInserted(
                selectedIdDocNISSHouseholdNumbers[selectedIdDocNISS.indexOf(householdEl.niss)],
                householdEl.householdNumber,
                dataEl.uniqueProcessNumber
              );
              return false;
            }
          }
        }
      }
    }

    return true;
  },
  setFamilyRecordProcessNumberSearchField: function setFamilyRecordProcessNumberSearchField(scope, selected, data) {
    if (selected.caseServices != null && selected.caseServices.length) {
      let processNumberSearchField = "";
      if (selected.uniqueProcessNumber != null) {
        processNumberSearchField += selected.uniqueProcessNumber;
      }
      selected.caseServices.forEach((service) => {
        if (processNumberSearchField != null) {
          processNumberSearchField =
            processNumberSearchField +
            "-" +
            (service.service != null
              ? scope.genericScope.parseValue(service.service, "service", "KSTK_Social_Assessment_Family_Services")
              : "") +
            service.internalProcessNumber;
        }
      });
      selected.processNumberSearchField = processNumberSearchField;
    }
  },
  setFamilyRecordHouseholdNamesSearchField: function setFamilyRecordHouseholdNamesSearchField(scope, selected, data) {
    if (selected.household != null && selected.household.length) {
      let householdNamesSearchField = "";
      selected.household.forEach((householdEl) => {
        if (householdNamesSearchField == "") {
          householdNamesSearchField = householdEl.name;
        } else if (householdNamesSearchField != null) {
          householdNamesSearchField = householdNamesSearchField + "-" + householdEl.name;
        }
      });
      selected.householdNamesSearchField = householdNamesSearchField;
    }
  },
  setFamilyRecordParsedName: function setFamilyRecordParsedName(scope, selected, data) {
    if (selected.name != null && selected.name != "") {
      selected.name = parseName(selected.name);
    }

    if (selected.household != null && selected.household.length) {
      selected.household.forEach((householdEl) => {
        if (householdEl.name != null && householdEl.name != "") {
          householdEl.name = parseName(householdEl.name);
        }
      });
    }

    function parseName(name) {
      let parsedName = "";

      name.split(" ").forEach((nameEl) => {
        let newNameEl = "";
        newNameEl = nameEl.toLowerCase();
        newNameEl = newNameEl.charAt(0).toUpperCase() + newNameEl.slice(1);
        if (parsedName == "") {
          parsedName = newNameEl;
        } else {
          parsedName = parsedName + " " + newNameEl;
        }
      });

      return parsedName;
    }
  },
  getParsedLogs: function getParsedLogs(scope, data) {
    let refDate = new Date().getTime();
    data.map((el) => {
      if (
        el.who.indexOf("carolina.frias") != -1 ||
        el.who.indexOf("jose.veiga") != -1 ||
        el.who.indexOf("andre.veiga") != -1 ||
        el.who.indexOf("pedro.veiga") != -1 ||
        el.who.indexOf("admin@kstk.pt") != -1
      ) {
        el.orgName = "KSTK";
      } else {
        el.orgName = getOrgName(el.organization);
      }

      let elDateMs = new Date(el.date).getTime();

      // Last 24 hours
      if (elDateMs > refDate - 24 * 60 * 60 * 1000) {
        el.last24Hours = "Sim";
      }

      // Last 7 days
      if (elDateMs > refDate - 7 * 24 * 60 * 60 * 1000) {
        el.last7Days = "Sim";
      }

      // Last 30 days
      if (elDateMs > refDate - 30 * 24 * 60 * 60 * 1000) {
        el.last30Days = "Sim";
      }

      return el;
    });

    function getOrgName(orgId) {
      var filteredOrg = scope.getFromTableData("organizations").filter((e) => e.id === orgId);

      if (filteredOrg.length) {
        return filteredOrg[0].name;
      } else {
        return "KSTK";
      }
    }
  },
  checkFamilyRecordContactsRecord: function checkFamilyRecordContactsRecord(scope, selected, data) {
    return new Promise((resolve, reject) => {
      let contactRecordAddedFlag = false;
      let refDate = new Date(
        new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate()
      ).getTime();

      if (
        scope.originalSelected == null ||
        (selected.contacts != null && scope.originalSelected.contacts == null) ||
        (selected.contacts != null &&
          scope.originalSelected.contacts != null &&
          selected.contacts.length > scope.originalSelected.contacts.length)
      ) {
        contactRecordAddedFlag = true;
      }

      if (selected.contacts != null && selected.contacts.length) {
        for (let i = 0; i < selected.contacts.length; i++) {
          const contact = selected.contacts[i];
          if (contact.contactDateMs.getTime() > refDate) {
            scope.$mdDialog.show(
              scope.$mdDialog
                .alert()
                .clickOutsideToClose(false)
                .title(
                  "Por favor valide a data de contacto inserida (" +
                    contact.contactDateMs.toLocaleDateString() +
                    ") pois é posterior à presente data."
                )
                .ok("Ok")
            );
            resolve(false);
          }
        }
      }

      if (!contactRecordAddedFlag) {
        let contactRecordRequiredDialog = scope.$mdDialog
          .confirm()
          .title("Pretende sair sem registar atendimento?")
          .ariaLabel("Registo de Contacto")
          .ok("Sim")
          .cancel("Não")
          .clickOutsideToClose(false);
        scope.$mdDialog.show(contactRecordRequiredDialog).then(
          function () {
            utilFunctions["kstkSetRecordHistoryByChange"](scope, selected, data);
            resolve(true);
          },
          function () {
            resolve(false);
          }
        );
      } else {
        utilFunctions["kstkSetRecordHistoryByChange"](scope, selected, data);
        resolve(true);
      }
    });
  },
  familyRecordSplitByServices: function familyRecordSplitByServices(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.splitFlag) {
        break;
      }
      if (el.caseServices != null && Array.isArray(el.caseServices) && el.caseServices.length > 0) {
        for (let w = 0; w < el.caseServices.length; w++) {
          let service = el.caseServices[w];
          let newElement = JSON.parse(JSON.stringify(el));
          newElement.service = service.service;
          newElement.internalProcessNumber = service.internalProcessNumber;
          newElement.serviceDateMs = service.serviceDateMs;
          newElement.serviceEndDateMs = service.serviceEndDateMs;
          newElement.splitFlag = true;
          cleanNewElement(newElement);
          data.push(newElement);
        }
      }
      data.splice(i, 1);
    }
    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }
    return data;
  },
  familyRecordSplitByContacts: function familyRecordSplitByContacts(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.splitFlag) {
        break;
      }
      if (el.contacts != null && Array.isArray(el.contacts) && el.contacts.length > 0) {
        for (let w = 0; w < el.contacts.length; w++) {
          let contact = el.contacts[w];
          let newElement = JSON.parse(JSON.stringify(el));
          newElement.technician = contact.technician;
          newElement.service = contact.service;
          newElement.contactDateMs = contact.contactDateMs;
          newElement.contactType = contact.contactType;
          newElement.processType = contact.processType;
          newElement.serviceComment = contact.serviceComment;
          newElement.splitFlag = true;
          cleanNewElement(newElement);
          data.push(newElement);
        }
      }
      data.splice(i, 1);
    }
    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }
    return data;
  },
  checkHonorBoard: function checkHonorBoard(scope, selected, data) {
    // AEPA
    if (
      scope.currentUser.organization == "5d9be46d32273900a0187100" &&
      selected.honorBoard != null &&
      selected.honorBoard.length > 0 &&
      Number(selected.year) > 2022
    ) {
      if (selected.honorBoard.length > 1) {
        scope.genericScope.$parent.alertInvalidAEPAHonorBoard();
        return false;
      }
      if (selected.honorBoard[0].period == "5d9be4a232273900a018770c") {
        scope.genericScope.$parent.alertInvalidAEPAHonorBoard();
        return false;
      }
      return true;
    } else {
      return true;
    }
  },
  classPlanGetStudentInfo: function classPlanGetStudentInfo(scope, selected, data) {
    if (selected.name != null && selected.name != "") {
      scope.parentScope.$parent.showLoader();
      let stud = scope.getFromTableData("Class_Plan_Students").filter((s) => s.id == selected.name)[0];
      if (stud != null) {
        if (stud.cluster != null) {
          selected.cluster = stud.cluster;
        }
        if (stud.school != null) {
          selected.school = stud.school;
        }
        if (stud.course != null) {
          selected.course = stud.course;
        }
        if (stud.class != null) {
          selected.class = stud.class;
        }
        if (stud.classOrder != null) {
          selected.classOrder = stud.classOrder;
        }
        if (stud.nr_processo != null) {
          selected.aluno = stud.nr_processo;
        } else if (stud.aluno != null) {
          selected.aluno = stud.aluno;
        }
        if (stud.nif != null) {
          selected.nif = stud.nif;
        }
        if (stud.sexo != null) {
          selected.gender = stud.sexo;
        }
        if (stud.data_nascimento != null) {
          selected.data_nascimento = stud.data_nascimento;
        }
        if (stud.localidade != null) {
          selected.localidade = stud.localidade;
        }
      }
      scope.parentScope.$parent.hideLoader();
    }
  },
  kstkProfessionalRecordsLoadStudRefInfo: function kstkProfessionalRecordsLoadStudRefInfo(scope, selected, data) {
    if (selected.class != null && selected.class.length) {
      scope.parentScope.$parent.showLoader();
      scope.genericFactory.setRouteName("Class_Plan_Students");
      let map = {};
      map.year = selected.year;
      map.class = selected.class;
      scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (students) {
        selected.students = students.map((stud) => {
          return {
            id: stud.id,
            student: stud.name,
            gender: stud.sexo,
            ase: stud.ase,
            nee: stud.nee,
            cei: stud.cei,
            computer: stud.computador,
            birthday: stud.data_nascimento != null ? new Date(stud.data_nascimento).toLocaleDateString() : null,
            age: updateAge(stud.data_nascimento),
            expectedAge: stud.idade_escolar,
          };
        });
        scope.parentScope.$parent.hideLoader();
        scope.genericFactory.setRouteName("KSTK_Professional_Records");
      });
    }
  },
  kstkSocialAssessmentLoadRefStudInfo: function kstkSocialAssessmentLoadRefStudInfo(scope, selected, data) {
    if (selected.school != null && selected.school.length && selected.class != null && selected.class.length) {
      scope.parentScope.$parent.showLoader();
      scope.genericFactory.setRouteName("Class_Plan_Students");
      let map = {};
      map.year = getCurrentScholarYear();
      map.school = selected.school;
      map.class = selected.class;
      scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (students) {
        selected.students = students.map((stud) => {
          return {
            id: stud.id,
            student: stud.name,
            ase: stud.ase,
            nee: stud.nee,
            cei: stud.cei,
            computer: stud.computador,
            birthday: stud.data_nascimento != null ? new Date(stud.data_nascimento).toLocaleDateString() : null,
            age: updateAge(stud.data_nascimento),
            expectedAge: stud.idade_escolar,
          };
        });
        scope.parentScope.$parent.hideLoader();
        scope.genericFactory.setRouteName("KSTK_Social_Assessment_Family_Records");
      });
    }
  },
  kstkSocialAssessmentLoadStudInfo: function kstkSocialAssessmentLoadStudInfo(scope, selected, data) {
    if (selected.year != null && selected.year != "" && selected.name != null && selected.name != "") {
      scope.parentScope.$parent.showLoader();
      let stud = scope
        .getFromTableData("KSTK_Students")
        .filter((s) => s.id == selected.name && s.year == selected.year)[0];
      if (stud != null) {
        if (stud.cluster != null) {
          selected.cluster = stud.cluster;
        }
        if (stud.school != null) {
          selected.school = stud.school;
        }
        if (stud.courseType != null) {
          selected.courseType = stud.courseType;
        }
        if (stud.course != null) {
          selected.course = stud.course;
        }
        if (stud.educationLevel != null) {
          selected.educationLevel = stud.educationLevel;
        }
        if (stud.schoolYear != null) {
          selected.schoolYear = stud.schoolYear;
        }
        if (stud.class != null) {
          selected.class = stud.class;
        }
        if (stud.nif != null) {
          selected.nif = stud.nif;
        }
        if (stud.data_nascimento != null) {
          selected.data_nascimento = stud.data_nascimento;
        }
        if (stud.studContact != null) {
          selected.studContact = stud.studContact;
        }
        if (stud.e_mail_stud != null) {
          selected.e_mail_stud = stud.e_mail_stud;
        }
        if (stud.localidade != null) {
          selected.localidade = stud.localidade;
        }
        if (stud.status_matricula != null) {
          selected.status_matricula = stud.status_matricula;
        }
      }
      scope.parentScope.$parent.hideLoader();
    }
  },
  kstkSocialAssessmentSplitServicesView: function kstkSocialAssessmentSplitServicesView(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.caseServices && el.caseServices.length > 0) {
        // Add process holder
        if (el.service != null && el.service.length) {
          el.service.forEach((service) => {
            let processHolderEl = JSON.parse(JSON.stringify(el));
            processHolderEl.service = service;
            let serviceInfo = getServiceInfo(service);
            if (serviceInfo != null) {
              processHolderEl.internalProcessNumber = serviceInfo.internalProcessNumber;
              processHolderEl.serviceDateMs = serviceInfo.serviceDateMs;
              processHolderEl.serviceEndDateMs = serviceInfo.serviceEndDateMs;
              processHolderEl.serviceSituation = serviceInfo.serviceSituation;
              processHolderEl.ase = "Sem informação";
              processHolderEl.splitFlag = true;
              cleanNewElement(processHolderEl);
              data.push(processHolderEl);
            }
          });
        }

        // Add household elements
        if (el.household && el.household.length > 0) {
          el.household.forEach((householdEl) => {
            if (householdEl.service != null && householdEl.service.length) {
              householdEl.service.forEach((service) => {
                let householdServiceEl = JSON.parse(JSON.stringify(householdEl));
                householdServiceEl.service = service;
                householdServiceEl.uniqueProcessNumber = el.uniqueProcessNumber;
                householdServiceEl.householdNumber = householdEl.householdNumber;
                householdServiceEl.technician = el.technician;
                householdServiceEl.parish = el.parish;
                let serviceInfo = getServiceInfo(service);
                if (serviceInfo != null) {
                  householdServiceEl.internalProcessNumber = serviceInfo.internalProcessNumber;
                  householdServiceEl.serviceDateMs = serviceInfo.serviceDateMs;
                  householdServiceEl.serviceEndDateMs = serviceInfo.serviceEndDateMs;
                  householdServiceEl.serviceSituation = serviceInfo.serviceSituation;
                  getHouseholdMinorChildrenEducationalStatus(householdServiceEl);
                  householdServiceEl.splitFlag = true;
                  cleanNewElement(householdServiceEl);
                  data.push(householdServiceEl);
                }
              });
            }
          });
        }
      }
      data.splice(0, 1);

      function getServiceInfo(service) {
        let filteredService = el.caseServices.filter((serviceInfo) => serviceInfo.service == service);
        if (filteredService.length > 0) {
          return filteredService[0];
        } else {
          return null;
        }
      }

      function getHouseholdMinorChildrenEducationalStatus(householdEl) {
        let householdElNumber = householdEl.householdNumber != null ? householdEl.householdNumber : 1;
        if (el.householdMinorChildrenEducationalStatus != null) {
          el.householdMinorChildrenEducationalStatus.forEach((el) => {
            if (el.householdNumber == householdElNumber) {
              householdEl.cluster = el.cluster != null ? el.cluster : el.clusterOther != null ? el.clusterOther : null;
              householdEl.school = el.school != null ? el.school : el.schoolOther != null ? el.schoolOther : null;
              householdEl.courseType = el.courseType;
              householdEl.educationLevel = el.educationLevel;
              householdEl.schoolYear = el.schoolYear;
              householdEl.enrolledNursery = el.enrolledNursery;
              householdEl.integratedNursery = el.integratedNursery;
              householdEl.happyNursery = el.happyNursery;
              householdEl.integratedPreschool = el.integratedPreschool;
              householdEl.supportMeasures = el.supportMeasures;
              householdEl.rehabTherapies = el.rehabTherapies;
              householdEl.ase = el.ase;
              householdEl.familyAllowanceEchelon = el.familyAllowanceEchelon;
            }
          });
        }
      }
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }
  },
  kstkSocialAssessmentSplitHouseholdView: function kstkSocialAssessmentSplitHouseholdView(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      // Add process holder
      let processHolderEl = JSON.parse(JSON.stringify(el));
      processHolderEl.splitFlag = true;
      getHouseholdMinorChildrenEducationalStatus(processHolderEl);
      getHouseholdHealthStatus(processHolderEl);
      getHouseholdEmploymentStatus(processHolderEl);
      getMainUserProblems(processHolderEl);
      getHousingDetails(processHolderEl);
      cleanNewElement(processHolderEl);
      data.push(processHolderEl);

      // Add household elements
      if (el.household && el.household.length > 0) {
        el.household.forEach((householdEl) => {
          let householdInfoEl = JSON.parse(JSON.stringify(householdEl));
          householdInfoEl.uniqueProcessNumber = el.uniqueProcessNumber;
          householdInfoEl.technician = el.technician;
          householdInfoEl.situation = el.situation;
          householdInfoEl.householdType = el.householdType;
          householdInfoEl.parish = el.parish;
          householdInfoEl.splitFlag = true;
          getHouseholdMinorChildrenEducationalStatus(householdInfoEl);
          getHouseholdHealthStatus(householdInfoEl);
          getHouseholdEmploymentStatus(householdInfoEl);
          getMainUserProblems(householdInfoEl);
          getHousingDetails(householdInfoEl);
          cleanNewElement(householdInfoEl);
          data.push(householdInfoEl);
        });
      }

      data.splice(0, 1);

      function getHouseholdMinorChildrenEducationalStatus(householdEl) {
        let householdElNumber = householdEl.householdNumber != null ? householdEl.householdNumber : 1;
        if (el.householdMinorChildrenEducationalStatus != null) {
          el.householdMinorChildrenEducationalStatus.forEach((el) => {
            if (el.householdNumber == householdElNumber) {
              householdEl.cluster = el.cluster != null ? el.cluster : el.clusterOther != null ? el.clusterOther : null;
              householdEl.school = el.school != null ? el.school : el.schoolOther != null ? el.schoolOther : null;
              householdEl.courseType = el.courseType;
              householdEl.educationLevel = el.educationLevel;
              householdEl.schoolYear = el.schoolYear;
              householdEl.enrolledNursery = el.enrolledNursery;
              householdEl.integratedNursery = el.integratedNursery;
              householdEl.happyNursery = el.happyNursery;
              householdEl.integratedPreschool = el.integratedPreschool;
              householdEl.supportMeasures = el.supportMeasures;
              householdEl.rehabTherapies = el.rehabTherapies;
              householdEl.ase = el.ase;
              householdEl.familyAllowanceEchelon = el.familyAllowanceEchelon;
            }
          });
        }
      }

      function getHouseholdHealthStatus(householdEl) {
        let householdElNumber = householdEl.householdNumber != null ? householdEl.householdNumber : 1;
        if (el.householdHealthStatus != null) {
          el.householdHealthStatus.forEach((el) => {
            if (el.householdNumber == householdElNumber) {
              householdEl.healthCenter =
                el.healthCenter != null ? el.healthCenter : el.healthCenterOther != null ? el.healthCenterOther : null;
              householdEl.hospital =
                el.hospital != null ? el.hospital : el.hospitalOther != null ? el.hospitalOther : null;
              householdEl.familyDoctor = el.familyDoctor;
              householdEl.addictionsDependencies =
                el.addictionsDependencies != null
                  ? el.addictionsDependencies
                  : el.addictionsOther != null
                  ? el.addictionsOther
                  : null;
              householdEl.disabilityType = el.disabilityType;
              householdEl.amimPercentage = el.amimPercentage;
              householdEl.amimDateMs = el.amimDateMs;
              householdEl.vaccinationPlanUpdated = el.vaccinationPlanUpdated;
            }
          });
        }
      }

      function getHouseholdEmploymentStatus(householdEl) {
        let householdElNumber = householdEl.householdNumber != null ? householdEl.householdNumber : 1;
        if (el.householdEmploymentStatus != null) {
          el.householdEmploymentStatus.forEach((el) => {
            if (el.householdNumber == householdElNumber) {
              householdEl.employmentCenterID = el.employmentCenterID;
              householdEl.unemployementDateMs = el.unemployementDateMs;
              householdEl.professionalEducation = el.professionalEducation;
              householdEl.employmentComment = el.employmentComment;
            }
          });
        }
      }

      function getMainUserProblems(householdEl) {
        let householdElNumber = householdEl.householdNumber != null ? householdEl.householdNumber : 1;
        if (el.mainUserProblems != null) {
          el.mainUserProblems.forEach((el) => {
            if (el.householdNumber == householdElNumber) {
              householdEl.mainUserProblem =
                el.mainUserProblem != null
                  ? el.mainUserProblem
                  : el.mainUserProblemOther != null
                  ? el.mainUserProblemOther
                  : null;
            }
          });
        }
      }

      function getHousingDetails(householdEl) {
        householdEl.homeless = el.homeless;
        householdEl.houseType =
          el.houseTypeOther != null && el.houseTypeOther != ""
            ? el.houseTypeOther
            : el.houseType != null && el.houseType != ""
            ? el.houseType
            : null;
        householdEl.houseOccupationType =
          el.houseStatusOther != null && el.houseStatusOther != ""
            ? el.houseStatusOther
            : el.houseOccupationType != null && el.houseOccupationType != ""
            ? el.houseOccupationType
            : null;
        householdEl.houseTypology = el.houseTypology;
      }
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
      if (element.history != null) {
        delete element.history;
      }
    }
  },
  kstkSocialAssessmentSplitContactsView: function kstkSocialAssessmentSplitContactsView(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      // Add contacts elements
      if (el.contacts && el.contacts.length > 0) {
        el.contacts.forEach((contactsEl) => {
          // Add process holder
          let processHolderEl = JSON.parse(JSON.stringify(el));
          processHolderEl.splitFlag = true;
          getHousingDetails(processHolderEl);
          let contactsInfoEl = JSON.parse(JSON.stringify(contactsEl));
          processHolderEl.contactTechnician = contactsInfoEl.technician;
          processHolderEl.contactService = contactsInfoEl.service;
          processHolderEl.contactDateMs = contactsInfoEl.contactDateMs;
          processHolderEl.contactType = contactsInfoEl.contactType;
          processHolderEl.processType = contactsInfoEl.processType;
          processHolderEl.serviceComment = contactsInfoEl.serviceComment;
          cleanNewElement(processHolderEl);
          data.push(processHolderEl);
        });
      }

      data.splice(0, 1);

      function getHousingDetails(householdEl) {
        householdEl.homeless = el.homeless;
        householdEl.houseType =
          el.houseTypeOther != null && el.houseTypeOther != ""
            ? el.houseTypeOther
            : el.houseType != null && el.houseType != ""
            ? el.houseType
            : null;
        householdEl.houseOccupationType =
          el.houseStatusOther != null && el.houseStatusOther != ""
            ? el.houseStatusOther
            : el.houseOccupationType != null && el.houseOccupationType != ""
            ? el.houseOccupationType
            : null;
        householdEl.houseTypology = el.houseTypology;
      }
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
      if (element.history != null) {
        delete element.history;
      }
    }
  },
  kstkMunModulesGetStudentInfo: function kstkMunModulesGetStudentInfo(scope, selected, data) {
    if (selected.year != null && selected.year != "" && selected.name != null && selected.name != "") {
      scope.parentScope.$parent.showLoader();
      let stud = scope
        .getFromTableData("KSTK_Students")
        .filter((s) => s.id == selected.name && s.year == selected.year)[0];
      if (stud != null) {
        if (stud.cluster != null) {
          selected.cluster = stud.cluster;
        }
        if (stud.school != null) {
          selected.school = stud.school;
        }
        if (stud.courseType != null) {
          selected.courseType = stud.courseType;
        }
        if (stud.course != null) {
          selected.course = stud.course;
        }
        if (stud.educationLevel != null) {
          selected.educationLevel = stud.educationLevel;
        }
        if (stud.schoolYear != null) {
          selected.schoolYear = stud.schoolYear;
        }
        if (stud.class != null) {
          selected.class = stud.class;
        }
        if (stud.nif != null) {
          selected.nif = stud.nif;
        }
        if (stud.data_nascimento != null) {
          selected.data_nascimento = stud.data_nascimento;
        }
        if (stud.studContact != null) {
          selected.studContact = stud.studContact;
        }
        if (stud.e_mail_stud != null) {
          selected.e_mail_stud = stud.e_mail_stud;
        }
        if (stud.localidade != null) {
          selected.localidade = stud.localidade;
        }
        if (stud.status_matricula != null) {
          selected.status_matricula = stud.status_matricula;
        }
      }
      scope.parentScope.$parent.hideLoader();
    }
  },
  kstkMunModulesUpdateStudentsInfo: function kstkMunModulesUpdateStudentsInfo(scope, panel, args, selectedRow) {
    scope.parentScope.$parent.showLoader();
    let toUpdateRows = [];
    let numUpdatedRows = 0;
    let currentScholarYear = getCurrentScholarYear();
    let studsInfo = scope
      .getFromTableData("KSTK_Students")
      .filter((s) => s.organization == scope.currentUser.organization && s.year == currentScholarYear);
    for (let i = 0; i < scope.gridOptions.data.length; i++) {
      let element = scope.gridOptions.data[i];
      if (element.nif == null || element.nif == "") {
        continue;
      }
      let stud = studsInfo.filter((s) => s.nif == element.nif)[0];
      if (stud != null && element.year == currentScholarYear) {
        if (stud.cluster != null) {
          element.cluster = stud.cluster;
        }
        if (stud.school != null) {
          element.school = stud.school;
        }
        if (stud.courseType != null) {
          element.courseType = stud.courseType;
        }
        if (stud.course != null) {
          element.course = stud.course;
        }
        if (stud.educationLevel != null) {
          element.educationLevel = stud.educationLevel;
        }
        if (stud.schoolYear != null) {
          element.schoolYear = stud.schoolYear;
        }
        if (stud.class != null) {
          element.class = stud.class;
        }
        if (stud.name != null) {
          element.name = stud.id;
        }
        if (stud.nif != null) {
          element.nif = stud.nif;
        }
        if (stud.data_nascimento != null) {
          element.data_nascimento = stud.data_nascimento;
        }
        if (stud.studContact != null) {
          element.studContact = stud.studContact;
        }
        if (stud.e_mail_stud != null) {
          element.e_mail_stud = stud.e_mail_stud;
        }
        if (stud.localidade != null) {
          element.localidade = stud.localidade;
        }
        if (stud.status_matricula != null) {
          element.status_matricula = stud.status_matricula;
        }
        toUpdateRows.push(element);
      }
    }
    scope.parentScope.$parent.hideLoader();
    scope.parentScope.$parent.showDeterminateLoader(toUpdateRows.length);
    async.eachSeries(
      toUpdateRows,
      function iteratee(item, callback) {
        scope.genericFactory.modify(item.id, item).then(() => {
          numUpdatedRows += 1;
          scope.parentScope.$parent.updateDeterminateLoader();
          callback();
        });
      },
      function done() {
        if (numUpdatedRows == toUpdateRows.length) {
          scope.$parent.alertKSTKMunModulesUpdatedStudentsInfo(numUpdatedRows);
          scope.parentScope.$parent.hideLoader();
        }
      },
      function (err) {
        if (err) {
          console.log(err);
        } else {
          //Done
          console.log("done");
        }
      }
    );
  },
  classPlanDuplicateInstrumentInBatch: function classPlanDuplicateInstrumentInBatch(scope, panel, args, selectedRow) {
    scope.parentScope.$parent.showLoader();
    let toCreateRows = [];
    let numCreatedRows = 0;
    let alreadyAddedSchoolYearSubject = [];
    if (selectedRow != null) {
      for (let i = 0; i < scope.gridOptions.data.length; i++) {
        let element = scope.gridOptions.data[i];
        if (
          element.id == selectedRow.id ||
          element.year != selectedRow.year ||
          alreadyAddedSchoolYearSubject.indexOf(element.schoolYear + element.subject) != -1
        ) {
          continue;
        }
        let newElement = JSON.parse(JSON.stringify(selectedRow));
        delete newElement.id;
        newElement.schoolYear = element.schoolYear;
        newElement.subject = element.subject;
        newElement.createdAt = new Date().getTime();
        if (newElement.modifiedBy) {
          delete newElement.modifiedBy;
        }
        if (newElement.modifiedAt) {
          delete newElement.modifiedAt;
        }
        toCreateRows.push(newElement);
        alreadyAddedSchoolYearSubject.push(newElement.schoolYear + newElement.subject);
      }
    }

    // Eliminate duplicates in the toCreatRows array
    for (let i = 0; i < toCreateRows.length; ) {
      const toCreateRow = toCreateRows[i];
      let removedRow = false;
      for (let j = 0; j < scope.gridOptions.data.length; j++) {
        const gridRow = scope.gridOptions.data[j];
        if (
          toCreateRow.evalInstrument == gridRow.evalInstrument &&
          toCreateRow.year == gridRow.year &&
          toCreateRow.schoolyear == gridRow.schoolyear &&
          toCreateRow.subject == gridRow.subject
        ) {
          toCreateRows.splice(i, 1);
          removedRow = true;
          break;
        }
      }
      if (!removedRow) {
        i++;
      }
    }

    scope.parentScope.$parent.hideLoader();
    scope.parentScope.$parent.showDeterminateLoader(toCreateRows.length);
    async.eachSeries(
      toCreateRows,
      function iteratee(item, callback) {
        scope.genericFactory.create(item).then(() => {
          numCreatedRows += 1;
          scope.parentScope.$parent.updateDeterminateLoader();
          callback();
        });
      },
      function done() {
        if (numCreatedRows == toCreateRows.length) {
          scope.$parent.alertKSTKMunModulesUpdatedStudentsInfo(numCreatedRows);
          scope.parentScope.$parent.hideLoader();
        }
      },
      function (err) {
        if (err) {
          console.log(err);
        } else {
          //Done
          console.log("done");
        }
      }
    );
  },
  cpAnnualActivityPlanParseEnvolvedStuds: function cpAnnualActivityPlanParseEnvolvedStuds(scope, selected) {
    scope.parentScope.$parent.showLoader();
    if (selected.name != null && selected.name != "" && Array.isArray(selected.name) && selected.name.length > 0) {
      let activityStudents = selected.envolvedStudents.filter((s) => selected.name.indexOf(s.id) != -1);
      let studentsWithClassOrder = [];
      let studentWithoutClassOrder = [];

      studentsWithClassOrder = activityStudents.filter((stud) => stud.classOrder != null);
      studentWithoutClassOrder = activityStudents.filter((stud) => stud.classOrder == null);

      studentsWithClassOrder = studentsWithClassOrder.sort(function compareModules(a, b) {
        if (a.classOrder < b.classOrder) {
          return -1;
        }
        if (a.classOrder > b.classOrder) {
          return 1;
        }

        return 0;
      });

      studentWithoutClassOrder = studentWithoutClassOrder.sort(function compareModules(a, b) {
        if (
          a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
          b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        ) {
          return -1;
        }

        if (
          a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
          b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        ) {
          return 1;
        }

        return 0;
      });

      activityStudents = studentsWithClassOrder.concat(studentWithoutClassOrder);

      let classesMap = {};
      activityStudents.forEach((activityStudent) => {
        if (activityStudent.class != null && activityStudent.class != "") {
          if (classesMap[activityStudent.class] == null) {
            let parsedClass = scope
              .getFromTableData("Class_Plan_Classes")
              .filter((cl) => cl.id == activityStudent.class);
            if (parsedClass != null && Array.isArray(parsedClass) && parsedClass.length > 0) {
              classesMap[JSON.parse(JSON.stringify(activityStudent.class))] = parsedClass[0].class;
              activityStudent.parsedClass = parsedClass[0].class;
            }
          } else {
            activityStudent.parsedClass = classesMap[activityStudent.class];
          }
        }
        activityStudent.parsedName =
          (activityStudent.parsedClass ? activityStudent.parsedClass + " - " : "") +
          (activityStudent.classOrder ? activityStudent.classOrder + " - " : "") +
          activityStudent.name;
      });

      activityStudents = activityStudents.sort(function compareStuds(a, b) {
        if (
          a.class.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
          b.class.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        ) {
          return -1;
        }

        if (
          a.class.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
          b.class.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        ) {
          return 1;
        }

        return 0;
      });

      let parsedActivityStudents = [];

      activityStudents.forEach((activityStudent) => {
        parsedActivityStudents.push(activityStudent.parsedName);
      });

      selected.activityStudents = [];

      let activityStudents1 = (parsedActivityStudents || []).slice(0, getIndexOfHalfOfArray(parsedActivityStudents));
      let activityStudents2 = (parsedActivityStudents || []).slice(getIndexOfHalfOfArray(parsedActivityStudents));

      for (let i = 0; i < activityStudents1.length; i++) {
        let obj = {};
        if (activityStudents1[i] != null) {
          obj.left = activityStudents1[i];
        }
        if (activityStudents2[i] != null) {
          obj.right = activityStudents2[i];
        }
        selected.activityStudents.push(obj);
      }
    }
    scope.parentScope.$parent.hideLoader();
  },
  cpAnnualActivityPlanGetEnvolvedClasses: function cpAnnualActivityPlanGetEnvolvedClasses(scope, selected, data) {
    scope.parentScope.$parent.showLoader();
    scope.genericFactory.setRouteName("Class_Plan_Classes");
    let map = {};
    map.year = selected.year;
    map.cluster = selected.cluster;
    map.school = selected.school;
    map.schoolYear = selected.schoolYear;
    scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (classes) {
      selected.class = null;
      selected.envolvedClasses = classes.map((sb) => {
        return {
          id: sb.id,
          class: sb.class,
          schoolYear: sb.schoolYear,
          courseType: sb.courseType,
          parsedLabel:
            scope.genericScope.parseValue(sb.courseType, "courseType", "Class_Plan_Course_Types") + " - " + sb.class,
        };
      });
      scope.genericFactory.setRouteName("Class_Plan_Annual_Activity_Plans");
      scope.parentScope.$parent.hideLoader();
    });
  },
  cpAnnualActivityPlanGetEnvolvedSubjects: function cpAnnualActivityPlanGetEnvolvedSubjects(scope, selected, data) {
    scope.parentScope.$parent.showLoader();
    scope.genericFactory.setRouteName("Class_Plan_Subjects");
    let map = {};
    map.schoolYear = selected.schoolYear;
    scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (subjects) {
      selected.subject = null;
      selected.envolvedSubjects = subjects.map((sb) => {
        return {
          id: sb.id,
          subject: sb.schoolYear + sb.subject,
          schoolYear: sb.schoolYear,
          courseType: sb.courseType,
          parsedLabel:
            scope.genericScope.parseValue(sb.schoolYear, "schoolYear", "Class_Plan_School_Years") + " - " + sb.subject,
        };
      });
      scope.genericFactory.setRouteName("Class_Plan_Annual_Activity_Plans");
      scope.parentScope.$parent.hideLoader();
    });
  },
  cpSelfAssessmentGetEnvolvedSubjects: function cpSelfAssessmentGetEnvolvedSubjects(scope, selected, data) {
    scope.parentScope.$parent.showLoader();
    scope.genericFactory.setRouteName("Class_Plan_Subjects");
    let map = {};
    map.schoolYear = selected.schoolYear;
    scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (subjects) {
      selected.subject = [];
      selected.envolvedSubjects = subjects
        .map((sb) => {
          return {
            id: sb.id,
            subject: sb.schoolYear + sb.subject,
            schoolYear: sb.schoolYear,
            courseType: sb.courseType,
            parsedLabel:
              scope.genericScope.parseValue(sb.schoolYear, "schoolYear", "Class_Plan_School_Years") +
              " - " +
              sb.subject,
          };
        })
        .filter(
          (sb) => scope.genericScope.parseValue(sb.courseType, "courseType", "Class_Plan_Course_Types") == "Regular"
        );
      scope.genericFactory.setRouteName("Class_Plan_Self_Evaluation_Kpi_Assessments");
      scope.parentScope.$parent.hideLoader();
    });
  },
  cpSelfAssessmentGetWeakPointsInfo: function cpSelfAssessmentGetWeakPointsInfo(scope, selected, data) {
    scope.parentScope.$parent.showLoader();
    scope.genericFactory.setRouteName("Class_Plan_Self_Evaluation_Kpi_Assessments");
    scope.genericFactory.get(selected.assessmentDesc).then(function (kpiAssessment) {
      if (kpiAssessment != null) {
        selected.analysisPeriod = kpiAssessment.analysisPeriod.toString();
        selected.department = kpiAssessment.department;
        selected.subjectGroup = kpiAssessment.subjectGroup;
        selected.kpiArea = kpiAssessment.kpiArea;
        selected.kpi = kpiAssessment.kpi;
        selected.schoolYear = kpiAssessment.schoolYear;
        selected.rawSubjects = structuredClone(kpiAssessment.subject);
        selected.subject = [
          ...new Set(
            kpiAssessment.subject.map((el) => {
              return scope.genericScope.parseValue(el, "subject", "Class_Plan_Subjects");
            })
          ),
        ].toString();
        selected.assessmentDateMs = new Date(kpiAssessment.assessmentDateMs);
        selected.assessmentSubjectCoordinator = kpiAssessment.assessmentSubjectCoordinator;

        if (
          kpiAssessment.weakPointsActivitiesStrategies != null &&
          kpiAssessment.weakPointsActivitiesStrategies.length > 0 &&
          selected.weakPointsActivitiesStrategies != null &&
          selected.weakPointsActivitiesStrategies.length > 0
        ) {
          kpiAssessment.weakPointsActivitiesStrategies.forEach((weakPointAcSt) => {
            if (!checkIfWeakPointWasInserted(weakPointAcSt)) {
              selected.weakPointsActivitiesStrategies.push(weakPointAcSt);
            }
          });

          function checkIfWeakPointWasInserted(weakPointAcSt) {
            for (let j = 0; j < selected.weakPointsActivitiesStrategies.length; j++) {
              let el = selected.weakPointsActivitiesStrategies[j];
              if (el.weakPoint == weakPointAcSt.weakPoint) {
                selected.weakPointsActivitiesStrategies[j].weakPointActivityStrategy =
                  weakPointAcSt.weakPointActivityStrategy;
                return true;
              }
            }
            return false;
          }
        } else if (
          kpiAssessment.weakPointsActivitiesStrategies != null &&
          kpiAssessment.weakPointsActivitiesStrategies.length > 0 &&
          (selected.weakPointsActivitiesStrategies == null ||
            (selected.weakPointsActivitiesStrategies.length != null &&
              selected.weakPointsActivitiesStrategies.length == 0))
        ) {
          selected.weakPointsActivitiesStrategies = kpiAssessment.weakPointsActivitiesStrategies;
        }
      }

      scope.genericFactory.setRouteName("Class_Plan_Self_Evaluation_Improvement_Plans");
      scope.parentScope.$parent.hideLoader();
    });
  },

  cpAnnualActivityPlanGetEnvolvedStuds: function cpAnnualActivityPlanGetEnvolvedStuds(scope, selected, data) {
    if (selected.class != null && selected.class != "") {
      scope.parentScope.$parent.showLoader();
      scope.genericFactory.setRouteName("Class_Plan_Students");
      let map = {};
      map.class = selected.class;
      scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (students) {
        let studs = students.filter((s) => s.status_matricula == "Matriculado");
        if (studs != null && studs.length > 0) {
          if (selected.name != null && selected.name.length > 0) {
            selected.name = selected.name.filter((stName) => studs.find((stStuds) => stStuds.id == stName));
          } else {
            selected.name = studs.map((st) => {
              return st.id;
            });
          }
        }
        selected.envolvedStudents = studs.map((st) => {
          return {
            id: st.id,
            name: st.name,
            classOrder: st.classOrder,
            class: st.class,
            e_mail_stud: st.e_mail_stud,
            e_mail_ee: st.e_mail_ee,
            status_matricula: st.status_matricula,
            parsedLabel: scope.genericScope.parseValue(st.class, "class", "Class_Plan_Classes") + " - " + st.name,
          };
        });
        scope.genericFactory.setRouteName(scope.module.collection);
        scope.parentScope.$parent.hideLoader();
      });
    }
  },
  cpEducationPlanActivityGetClasses: function cpEducationPlanActivityGetClasses(scope, selected, data) {
    scope.parentScope.$parent.showLoader();
    scope.genericFactory.setRouteName("Class_Plan_Classes");
    selected.class = [];
    selected.student = [];
    let map = {};
    map.year = selected.year;
    map.school = selected.school;
    map.schoolYear = selected.schoolYear;
    scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (classes) {
      selected.envolvedClasses = classes.map((cls) => {
        return {
          id: cls.id,
          year: cls.year,
          school: cls.school,
          courseType: cls.courseType,
          class: cls.class,
          parsedLabel: scope.genericScope.parseValue(cls.school, "school", "Class_Plan_Schools") + " - " + cls.class,
        };
      });
      scope.genericFactory.setRouteName(scope.module.collection);
      scope.parentScope.$parent.hideLoader();
    });
  },
  cpEducationPlanActivityGetStuds: function cpEducationPlanActivityGetStuds(scope, selected, data) {
    if (selected.class != null && selected.class != "") {
      scope.parentScope.$parent.showLoader();
      scope.genericFactory.setRouteName("Class_Plan_Students");
      selected.student = [];
      let map = {};
      map.class = selected.class;
      scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (students) {
        scope.genericFactory.setRouteName("Class_Plan_Classes");
        let map2 = {};
        map2.year = selected.year;
        map2.school = selected.school;
        map2.schoolYear = selected.schoolYear;
        scope.genericFactory.getByProperties(map2, scope.currentUser.organization).then(function (classes) {
          let studs = students.filter((s) => s.status_matricula == "Matriculado");
          if (studs != null && studs.length > 0) {
            if (selected.student != null && selected.student.length > 0) {
              selected.student = selected.student.filter((stName) => studs.find((stStuds) => stStuds.id == stName));
            } else {
              selected.student = studs.map((st) => {
                return st.id;
              });
            }
          }
          selected.envolvedStudents = studs.map((st) => {
            return {
              id: st.id,
              student: st.name,
              nif: st.nif,
              birthdayMs: st.data_nascimento,
              classOrder: st.classOrder,
              class: st.class,
              gender: st.sexo,
              e_mail_stud: st.e_mail_stud,
              e_mail_ee: st.e_mail_ee,
              status_matricula: st.status_matricula,
              parsedLabel:
                parseValue(st.class, "class", ["Class_Plan_Classes"], [classes], null, "Class_Plan_Classes") +
                " - " +
                st.name,
            };
          });
          scope.genericFactory.setRouteName(scope.module.collection);
          scope.parentScope.$parent.hideLoader();
        });
      });
    }
  },
  vfxSchoolMealRecordSetup: function vfxSchoolMealRecordSetup(scope, selected, data) {
    let filteredEducationLevels = selected.educationLevel.filter((el) => el.length != 24);
    if (filteredEducationLevels != null && filteredEducationLevels.length > 0) {
      let parsedEducationLevels = filteredEducationLevels.sort();
      selected.mealRecords = [];
      parsedEducationLevels.forEach((eduLevel) => {
        if (eduLevel == " PRE" || eduLevel == "1º Ciclo") {
          selected.mealRecords = selected.mealRecords.concat([
            { educationLevel: eduLevel, mealType: "Alunos" },
            { educationLevel: eduLevel, mealType: "Lanches" },
            { educationLevel: eduLevel, mealType: "Alunos ETI" },
            { educationLevel: eduLevel, mealType: "Lanches ETI" },
            { educationLevel: eduLevel, mealType: "Take-away" },
          ]);
        } else {
          selected.mealRecords = selected.mealRecords.concat([
            { educationLevel: eduLevel, mealType: "Alunos" },
            { educationLevel: eduLevel, mealType: "Take-away" },
          ]);
        }
      });
      selected.mealRecords = selected.mealRecords.concat([{ mealType: "Adultos" }, { mealType: "Prova" }]);
    }
  },
  vfxSchoolMealRecordPanelSetup: function vfxSchoolMealRecordPanelSetup(scope, data, panelConfig) {
    if (scope.selectedRow != null) {
      scope.selected = scope.selectedRow;
      scope.selected.weekendsAndHolidays = [];

      let recordTableData = [];
      let columnDefs = [
        {
          name: "educationLevel",
          displayName: "Nível ensino",
          enableCellEdit: false,
          width: "100",
          pinnedLeft: true,
        },
        {
          name: "mealType",
          displayName: "Tipo refeição",
          enableCellEdit: false,
          width: "200",
          pinnedLeft: true,
        },
      ];

      let dateFromRegDateMs = new Date(scope.selected.regDateMs);
      let monthDays = new Date(dateFromRegDateMs.getFullYear(), dateFromRegDateMs.getMonth() + 1, 0).getDate();

      if (monthDays == null) {
        monthDays = 32;
      } else {
        monthDays += 1;
      }

      scope.selected.monthDays = monthDays;
      scope.selected.mealRecordDays = 0;

      for (let j = 1; j < monthDays; j++) {
        let day = j;
        let dayOfTheWeek = new Date(dateFromRegDateMs.getFullYear(), dateFromRegDateMs.getMonth(), day).getDay();
        if (dayOfTheWeek != 0 && dayOfTheWeek != 6) {
          scope.selected.mealRecordDays += 1;
          let holidayFlag =
            scope.selected.holidays &&
            Array.isArray(scope.selected.holidays) &&
            scope.selected.holidays.indexOf(day) != -1
              ? true
              : false;

          columnDefs.push({
            name: "day" + day,
            type: "number",
            displayName: holidayFlag ? day + " Int" : day + "",
            cellClass: "ptAvalColumnToEdit",
            treeAggregationType: "sumAttendanceOptions",
            //treeAggregationLabel: "",
            enableColumnMenus: false,
            calculatedFunction: "previewTableNumberRecordUpdateVFXMealRecord({},}{,'day" + day + "')",
            width: holidayFlag ? "80" : "60",
          });
        }
      }

      columnDefs.push({
        name: "monthlyTotal",
        displayName: "Total ref",
        enableCellEdit: false,
        width: "110",
        treeAggregationType: "sumAttendanceOptions",
        treeAggregationLabel: "",
      });

      columnDefs.push({
        name: "value",
        displayName: "Valor",
        enableCellEdit: false,
        width: "90",
        treeAggregationType: "sumAttendanceOptions",
        treeAggregationLabel: "",
      });

      if (panelConfig.importer != null) {
        columnDefs = columnDefs.concat([
          {
            name: "rowID",
            displayName: "ID",
            enableCellEdit: false,
            enableFiltering: false,
            width: 5,
            type: "number",
            sort: {
              priority: 0,
              direction: "asc",
            },
          },
        ]);
      }

      if (scope.selected.mealRecords && scope.selected.mealRecords.length > 0) {
        let lastName;
        let rowBackgroundColorID = 1;
        for (let k = 0; k < scope.selected.mealRecords.length; k++) {
          let mealRecord = scope.selected.mealRecords[k];
          mealRecord.dynamicIndex = k;

          //Setup row backgroundColorID by education level and mealType
          if (mealRecord.educationLevel + mealRecord.mealType != lastName) {
            rowBackgroundColorID == 0 ? (rowBackgroundColorID = 1) : (rowBackgroundColorID = 0);
            mealRecord.rowBackgroundColorID = rowBackgroundColorID;
            lastName = mealRecord.educationLevel + mealRecord.mealType;
          } else {
            mealRecord.rowBackgroundColorID = rowBackgroundColorID;
          }

          mealRecord.rowID = mealRecord.dynamicIndex + 1;

          recordTableData.push(mealRecord);
        }
      }

      return {
        data: recordTableData,
        columnDefs: columnDefs,
      };
    }
  },
  cpEducationPlanActivitySessionGetStudents: function cpEducationPlanActivitySessionGetStudents(scope, selected, data) {
    if (selected.activityName != null && selected.participantGroup != "") {
      scope.parentScope.$parent.showLoader();
      scope.genericFactory.setRouteName("Class_Plan_Education_Plan_Activity_Participants");
      scope.genericFactory.get(selected.participantGroup).then(function (activityParticipants) {
        selected.participantAttendanceRecords = [];
        if (
          activityParticipants != null &&
          Object.keys(activityParticipants).length > 0 &&
          activityParticipants.student != null &&
          activityParticipants.student.length > 0 &&
          activityParticipants.envolvedStudents != null &&
          activityParticipants.envolvedStudents.length
        ) {
          selected.participantAttendanceRecords = activityParticipants.envolvedStudents
            .filter((envStud) => activityParticipants.student.indexOf(envStud.id) != -1)
            .map((envStud) => {
              envStud.participant = envStud.student;
              envStud.participantType = "Aluno";
              return envStud;
            })
            .sort(function compareModules(a, b) {
              if (
                a.participant.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
                b.participant.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
              ) {
                return -1;
              }
              if (
                a.participant.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
                b.participant.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
              ) {
                return 1;
              }
              return 0;
            });

          if (
            activityParticipants.additionalParticipants != null &&
            activityParticipants.additionalParticipants.length > 0
          ) {
            selected.participantAttendanceRecords = selected.participantAttendanceRecords.concat(
              activityParticipants.additionalParticipants
            );
          }
        }
        scope.genericFactory.setRouteName(scope.module.collection);
        scope.parentScope.$parent.hideLoader();
      });
    }
  },
  cpEducationPlanActivitySessionAttendanceRecord: function cpEducationPlanActivitySessionAttendanceRecord(
    scope,
    data,
    panelConfig
  ) {
    if (scope.selectedRow != null) {
      scope.selected = scope.selectedRow;
      scope.selected.weekendsAndHolidays = [];

      let recordTableData = [];
      let columnDefs = [
        {
          name: "participant",
          displayName: "Nome",
          enableCellEdit: false,
          width: "200",
          pinnedLeft: true,
        },
        {
          name: "nif",
          displayName: "NIF",
          enableCellEdit: false,
          width: "100",
        },
      ];

      let dateFromRegDateMs = new Date(scope.selected.regDateMs);
      let monthDays = new Date(dateFromRegDateMs.getFullYear(), dateFromRegDateMs.getMonth() + 1, 0).getDate();

      if (monthDays == null) {
        monthDays = 32;
      } else {
        monthDays += 1;
      }

      scope.selected.monthDays = monthDays;
      scope.selected.activityDays = 0;

      for (let j = 1; j < monthDays; j++) {
        let day = j;
        let dayOfTheWeek = new Date(dateFromRegDateMs.getFullYear(), dateFromRegDateMs.getMonth(), day).getDay();
        if (
          dayOfTheWeek != 0 &&
          dayOfTheWeek != 6 &&
          (scope.selected.holidays == null ||
            (scope.selected.holidays &&
              Array.isArray(scope.selected.holidays) &&
              scope.selected.holidays.indexOf(day) == -1))
        ) {
          scope.selected.activityDays += 1;

          columnDefs.push({
            name: "day" + day,
            type: "number",
            displayName: day + "",
            cellClass: "ptAvalColumnToEdit",
            editableCellTemplate: "ui-grid/dropdownEditor",
            editDropdownOptionsArray: [
              {
                id: 1,
                value: 1,
              },
              {
                id: 2,
                value: 0,
              },
            ],
            editDropdownIdLabel: "value",
            treeAggregationType: "sumAttendanceOptions",
            //treeAggregationLabel: "",
            enableColumnMenus: false,
            calculatedFunction: "educationPlanSessionRecordUpdate({},}{,'day" + day + "')",
            width: "60",
          });
        }
      }

      columnDefs.push({
        name: "monthlyTotal",
        displayName: "Total",
        enableCellEdit: false,
        width: "75",
        treeAggregationType: scope.uiGridGroupingConstants.aggregation.SUM,
        treeAggregationLabel: "",
      });

      if (panelConfig.importer != null) {
        columnDefs = columnDefs.concat([
          {
            name: "rowID",
            displayName: "ID",
            enableCellEdit: false,
            enableFiltering: false,
            width: 5,
            type: "number",
            sort: {
              priority: 0,
              direction: "asc",
            },
          },
        ]);
      }

      if (scope.selected.participantAttendanceRecords && scope.selected.participantAttendanceRecords.length > 0) {
        let lastName;
        let rowBackgroundColorID = 1;
        for (let k = 0; k < scope.selected.participantAttendanceRecords.length; k++) {
          let studRecord = scope.selected.participantAttendanceRecords[k];
          studRecord.dynamicIndex = k;

          //Setup row backgroundColorID by participant
          if (studRecord.participant != lastName) {
            rowBackgroundColorID == 0 ? (rowBackgroundColorID = 1) : (rowBackgroundColorID = 0);
            studRecord.rowBackgroundColorID = rowBackgroundColorID;
            lastName = studRecord.participant;
          } else {
            studRecord.rowBackgroundColorID = rowBackgroundColorID;
          }

          studRecord.rowID = studRecord.dynamicIndex + 1;

          recordTableData.push(studRecord);
        }
      }

      return {
        data: recordTableData,
        columnDefs: columnDefs,
      };
    }
  },
  kstkNonTeachingStaffUpdateAlerts: function kstkNonTeachingStaffUpdateAlerts(scope, data) {
    scope.parentScope.$parent.showLoader();

    for (let u = 0; u < data.length; u++) {
      let el = data[u];
      let alerts = [];

      // Aposentação

      if (el.birthdayMs != null) {
        let sixtySixflag = dayjs(new Date(el.birthdayMs)).add(66, "year").subtract(1, "month").isSameOrBefore(dayjs());
        let seventyFlag = dayjs(new Date(el.birthdayMs)).add(70, "year").subtract(1, "month").isSameOrBefore(dayjs());
        if (seventyFlag) {
          alerts.push("Aposentação - 70 anos");
        } else if (sixtySixflag) {
          alerts.push("Aposentação - 66 anos");
        }
      }

      // Registo Criminal

      if (el.criminalRecordRegisterDateMs == null) {
        alerts.push("Registo Criminal - Não entregue");
      }

      if (el.criminalRecordExpirationDateMs != null) {
        let expiryDateFlag = dayjs(el.criminalRecordExpirationDateMs).subtract(1, "month").isSameOrBefore(dayjs());
        if (expiryDateFlag) {
          alerts.push("Registo Criminal - Inválido");
        }
      }

      // Termo de Contrato

      if (
        el.contractEndDateMs != null &&
        el.professionalBond != null &&
        (el.professionalBond == "CTC" ||
          el.professionalBond == "CTC - Excepcional" ||
          el.professionalBond == "IEFP" ||
          el.professionalBond == "C - NEE")
      ) {
        let contractEndFlag = dayjs(el.contractEndDateMs).subtract(1, "month").isSameOrBefore(dayjs());
        if (contractEndFlag) {
          alerts.push("Situação Contratual - Termo de Contrato");
        }
      }

      // Estado Temporário

      if (
        el.temporaryState != null &&
        el.temporaryStateEndDateMs != null &&
        el.temporaryState.indexOf("Mobilidade") != -1
      ) {
        let temporaryStateFlag = dayjs(el.temporaryStateEndDateMs).subtract(1, "month").isSameOrBefore(dayjs());
        if (temporaryStateFlag) {
          alerts.push("Estado Temporário - Termo de Mobilidade");
        }
      }

      if (
        el.temporaryState != null &&
        el.temporaryState == "Período Experimental" &&
        el.professionalBond != null &&
        el.professionalBond == "CTI"
      ) {
        if (el.function != null && el.function == "Assistente Operacional") {
          let temporaryStateFlag = dayjs(el.contractEndDateMs).subtract(120, "day").isSameOrBefore(dayjs());
          if (temporaryStateFlag) {
            alerts.push("Estado Temporário - Término de Período Experimental - CTI - AO");
          }
        } else if (el.function != null && el.function == "Assistente Técnico") {
          let temporaryStateFlag = dayjs(el.contractEndDateMs).subtract(90, "day").isSameOrBefore(dayjs());
          if (temporaryStateFlag) {
            alerts.push("Estado Temporário - Término de Período Experimental - CTI - AT");
          }
        }
      }

      if (
        el.temporaryState != null &&
        el.temporaryState == "Período Experimental" &&
        el.professionalBond != null &&
        el.professionalBond == "CTC"
      ) {
        if (
          el.function != null &&
          (el.function == "Assistente Operacional" || (el.function != null && el.function == "Assistente Técnico"))
        ) {
          let temporaryStateFlag = dayjs(el.contractEndDateMs).subtract(30, "day").isSameOrBefore(dayjs());
          if (temporaryStateFlag) {
            alerts.push("Estado Temporário - Término de Período Experimental - CTC - AO/AT");
          }
        }
      }

      if (alerts.length > 0) {
        el.alerts = alerts;
        el.alertsString = alerts.toString().replace(",", ", ");
      }
    }

    scope.parentScope.$parent.hideLoader();
  },
  kstkNonTeachingStaffAlertsSplitRecords: function kstkNonTeachingStaffAlertsSplitRecords(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.alerts && el.alerts.length > 0) {
        el.alerts.forEach((alert) => {
          let newAlert = JSON.parse(JSON.stringify(el));
          newAlert.alert = alert;
          newAlert.splitFlag = true;
          data.push(newAlert);
        });
      }
      data.splice(0, 1);
    }
  },
  kstkNonTeachingStaffIndirectCostsSplitRecords: function kstkNonTeachingStaffIndirectCostsSplitRecords(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.allocation == "Escritório" && el.indirectCostsPercentage != null) {
        if (el.costs && el.costs.length > 0) {
          el.costs.forEach((cost) => {
            let newEl = JSON.parse(JSON.stringify(el));
            newEl.year = el.year;
            newEl.civilYear = cost.civilYear;
            newEl.month = getMonthWithNumberBefore(cost.month);
            newEl.costsTotal = cost.costsTotal;
            newEl.splitFlag = true;
            data.push(newEl);
          });
        }
      } else if (el.allocation == "CEI") {
        if (el.ceiCosts && el.ceiCosts.length > 0) {
          el.ceiCosts.forEach((cost) => {
            let newEl = JSON.parse(JSON.stringify(el));
            newEl.year = el.year;
            newEl.civilYear = cost.civilYear;
            newEl.month = getMonthWithNumberBefore(cost.month);
            newEl.ceiInsurance = cost.ceiInsurance != null ? cost.ceiInsurance : 0;
            newEl.ceiGrant = cost.ceiGrant != null ? cost.ceiGrant : 0;
            newEl.splitFlag = true;
            data.push(newEl);
          });
        }
      }

      data.splice(0, 1);
    }
  },
  kstkNonTeachingStaffFaultAllowanceSplitRecords: function kstkNonTeachingStaffFaultAllowanceSplitRecords(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.monthlyFaultAllowance && el.monthlyFaultAllowance.length > 0) {
        el.monthlyFaultAllowance.forEach((monAllowance) => {
          let newEl = JSON.parse(JSON.stringify(el));
          newEl.year = monAllowance.year;
          newEl.civilYear = monAllowance.civilYear;
          newEl.month = monAllowance.month;
          newEl.totalMonthFaultAllowance = monAllowance.totalAllowance;
          newEl.splitFlag = true;
          data.push(newEl);
        });
      } else {
        let newEl = JSON.parse(JSON.stringify(el));
        newEl.splitFlag = true;
        data.push(newEl);
      }
      data.splice(0, 1);
    }
  },
  kstkNonTeachingStaffExpectedRevenueSplitRecords: function kstkNonTeachingStaffExpectedRevenueSplitRecords(
    scope,
    data
  ) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.expectedRevenue && el.expectedRevenue.length > 0) {
        el.expectedRevenue.forEach((expectedRev) => {
          let newEl = JSON.parse(JSON.stringify(el));
          newEl.year = el.year;
          newEl.civilYear = expectedRev.civilYear;
          newEl.month = getMonthWithNumberBefore(expectedRev.month);
          newEl.expectedRevNetMonthRemuneration = expectedRev.expectedRevNetMonthRemuneration;
          newEl.expectedRevMealAllowance = expectedRev.expectedRevMealAllowance;
          newEl.expectedRevEmployerCharges = expectedRev.expectedRevEmployerCharges;
          newEl.expectedRevFaultAllowance = expectedRev.expectedRevFaultAllowance;
          newEl.expectedRevHolidaysChristAllowance = expectedRev.expectedRevHolidaysChristAllowance;
          let importedHolidayAllowanceFromCosts = false;
          if (
            el.costs &&
            el.costs.length > 0 &&
            (newEl.expectedRevHolidaysChristAllowance == null || newEl.expectedRevHolidaysChristAllowance == "")
          ) {
            let filteredMatchingCost = el.costs.filter(
              (c) => c.civilYear == newEl.civilYear && c.month == expectedRev.month
            );
            if (filteredMatchingCost.length > 0) {
              let matchingCost = filteredMatchingCost[0];
              if (
                matchingCost.costsHolidaysChristAllowance != null &&
                !isNaN(Number(matchingCost.costsHolidaysChristAllowance))
              ) {
                newEl.expectedRevHolidaysChristAllowance = matchingCost.costsHolidaysChristAllowance;
                importedHolidayAllowanceFromCosts = true;
              }
            }
          }
          newEl.expectedRevTotal = !importedHolidayAllowanceFromCosts
            ? expectedRev.expectedRevTotal
            : Math.round((expectedRev.expectedRevTotal + newEl.expectedRevHolidaysChristAllowance) * 100) / 100;
          newEl.splitFlag = true;
          data.push(newEl);
        });
      }
      data.splice(0, 1);
    }
  },
  kstkNonTeachingStaffCostsSplitRecords: function kstkNonTeachingStaffCostsSplitRecords(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.costs && el.costs.length > 0) {
        el.costs.forEach((cost) => {
          let newEl = JSON.parse(JSON.stringify(el));
          newEl.year = el.year;
          newEl.civilYear = cost.civilYear;
          newEl.month = getMonthWithNumberBefore(cost.month);
          newEl.costsCareer = cost.costsCareer;
          newEl.costsCategory = cost.costsCategory;
          newEl.costsNetMonthRemuneration = cost.costsNetMonthRemuneration;
          newEl.costsFaults = cost.costsFaults;
          newEl.costsHolidaysChristAllowance =
            cost.costsHolidaysChristAllowance == null ? 0 : cost.costsHolidaysChristAllowance;
          newEl.costsMealAllowance = cost.costsMealAllowance;
          newEl.costsRepresentationCosts = cost.costsRepresentationCosts;
          newEl.costsFaultAllowance = cost.costsFaultAllowance;
          newEl.costsCompContract = cost.costsCompContract;
          newEl.costsExtraWork = cost.costsExtraWork;
          newEl.costsPension = cost.costsPension;
          newEl.costsRemunAdjustments = cost.costsRemunAdjustments;
          newEl.costsOtherAllowances = cost.costsOtherAllowances;
          newEl.costsOtherDiscounts = cost.costsOtherDiscounts;
          newEl.costsCGA = cost.costsCGA;
          newEl.costsCRSS = cost.costsCRSS;
          newEl.costsTotal = cost.costsTotal;
          newEl.splitFlag = true;
          data.push(newEl);
        });
      }
      data.splice(0, 1);
    }
  },
  kstkNonTeachingStaffCostsSplitSpreadRecords: function kstkNonTeachingStaffCostsSplitSpreadRecords(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.costs && el.costs.length > 0) {
        el.costs.forEach((cost) => {
          let newEl = JSON.parse(JSON.stringify(el));
          newEl.year = el.year;
          newEl.civilYear = cost.civilYear;
          newEl.month = getMonthWithNumberBefore(cost.month);
          newEl.costsCareer = cost.costsCareer;
          newEl.costsCategory = cost.costsCategory;

          let newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Venc. Base";
          newSubEl.value = cost.costsNetMonthRemuneration;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Faltas";
          if (cost.costsFaults != null) {
            newSubEl.value = cost.costsFaults * -1;
          }
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Subs. Férias/Natal";
          newSubEl.value = cost.costsHolidaysChristAllowance == null ? 0 : cost.costsHolidaysChristAllowance;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Subs. Refeição";
          newSubEl.value = cost.costsMealAllowance;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Representação";
          newSubEl.value = cost.costsRepresentationCosts;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Abono Falhas";
          newSubEl.value = cost.costsFaultAllowance;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Comp. Ter. Contrato";
          newSubEl.value = cost.costsCompContract;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Trabalho Extraord.";
          newSubEl.value = cost.costsExtraWork;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Pensão";
          newSubEl.value = cost.costsPension;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Acertos Remuneratórios";
          newSubEl.value = cost.costsRemunAdjustments;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Outros Abonos";
          newSubEl.value = cost.costsOtherAllowances;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Outros Descontos";
          newSubEl.value = cost.costsOtherDiscounts;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "CGA";
          newSubEl.value = cost.costsCGA;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "CRSS";
          newSubEl.value = cost.costsCRSS;
          newSubEl.splitFlag = true;
          data.push(newSubEl);

          /* newSubEl = JSON.parse(JSON.stringify(newEl));
          newSubEl.nonTeachStaffChargeType = "Total";
          newSubEl.value = cost.costsTotal;
          newSubEl.splitFlag = true;
          data.push(newSubEl); */
        });
      }
      data.splice(0, 1);
    }
  },
  kstkNonTeachingStaffExtensionCostsSplitRecords: function kstkNonTeachingStaffExtensionCostsSplitRecords(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.extensionCosts && el.extensionCosts.length > 0) {
        el.extensionCosts.forEach((cost) => {
          let newEl = JSON.parse(JSON.stringify(el));
          newEl.year = el.year;
          newEl.civilYear = cost.civilYear;
          newEl.month = getMonthWithNumberBefore(cost.month);
          newEl.costsCareer = cost.costsCareer;
          newEl.costsCategory = cost.costsCategory;
          newEl.costsNetMonthRemuneration = cost.costsNetMonthRemuneration;
          newEl.costsFaults = cost.costsFaults;
          newEl.costsHolidaysChristAllowance =
            cost.costsHolidaysChristAllowance == null ? 0 : cost.costsHolidaysChristAllowance;
          newEl.costsMealAllowance = cost.costsMealAllowance;
          newEl.costsRepresentationCosts = cost.costsRepresentationCosts;
          newEl.costsFaultAllowance = cost.costsFaultAllowance;
          newEl.costsCompContract = cost.costsCompContract;
          newEl.costsExtraWork = cost.costsExtraWork;
          newEl.costsPension = cost.costsPension;
          newEl.costsRemunAdjustments = cost.costsRemunAdjustments;
          newEl.costsOtherAllowances = cost.costsOtherAllowances;
          newEl.costsOtherDiscounts = cost.costsOtherDiscounts;
          newEl.costsCGA = cost.costsCGA;
          newEl.costsCRSS = cost.costsCRSS;
          newEl.costsTotal = cost.costsTotal;
          newEl.splitFlag = true;
          data.push(newEl);
        });
      }
      data.splice(0, 1);
    }
  },
  kstkNonTeachingStaffInsuranceCostsSplitRecords: function kstkNonTeachingStaffInsuranceCostsSplitRecords(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.insuranceCosts && el.insuranceCosts.length > 0) {
        el.insuranceCosts.forEach((inCosts) => {
          let newEl = JSON.parse(JSON.stringify(el));
          newEl.year = el.year;
          newEl.civilYear = inCosts.civilYear;
          newEl.month = getMonthWithNumberBefore(inCosts.month);
          newEl.insuranceCharge = inCosts.insuranceCharge;
          newEl.splitFlag = true;
          data.push(newEl);
        });
      }
      data.splice(0, 1);
    }
  },
  kstkNonTeachingStaffManagementControlFilterRecords: function kstkNonTeachingStaffManagementControlFilterRecords(
    scope,
    data
  ) {
    let monthInfoToSplit = [];

    let refDateMs = new Date(new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-01").getTime();
    let refYear = new Date().getFullYear();
    let refMonth = new Date().getMonth() + 1;
    let year = refYear;
    let month = JSON.parse(JSON.stringify(refMonth));
    for (let m = 1; m < 25; m++) {
      monthInfoToSplit.push({
        civilYear: new Date(refDateMs).getFullYear(),
        month: getMonthFromDateMs(refDateMs),
        date: refDateMs,
      });
      month = refMonth - m;
      if (month % 12 == 0) {
        year = year - 1;
        month = 12;
      } else {
        if (month > -12) {
          month = 12 + month;
        } else {
          month = 24 + month;
        }
      }
      if (month > 12) {
        month = month - 12;
      }
      refDateMs = new Date(year + "-" + Math.abs(month) + "-01").getTime();
    }

    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitByMonthFlag) {
        break;
      }

      if (el.history != null && Array.isArray(el.history) && el.history.length > 0) {
        pushNewElsByMonth(el);
      } else {
        if (
          el.situation == "Ativo" &&
          (scope.activeTabName == "Resultado de Exploração" ||
            (scope.activeTabName != "Consulta Custos Indiretos" &&
              scope.activeTabName != "Prolongamento" &&
              (el.allocation == "Sala" || el.allocation == "Apoio Educativo")) ||
            (scope.activeTabName == "Consulta Custos Indiretos" &&
              (el.allocation == "CEI" || (el.allocation == "Escritório" && el.indirectCostsPercentage != null))) ||
            (scope.activeTabName == "Prolongamento" && el.allocation == "Prolongamento AAAF"))
        ) {
          pushNewElsByMonth(el);
        }
      }

      data.splice(i, 1);
    }

    function pushNewElsByMonth(el) {
      let lastInsertedHistoryEl = null;
      for (let k = 0; k < monthInfoToSplit.length; k++) {
        let monthInfo = monthInfoToSplit[k];
        let activeInMonthStaffFlag = false;
        // Remove duplicate values when
        if (
          el.year != new Date(monthInfo.date).getFullYear() &&
          Number(el.year) + 1 != Number(new Date(monthInfo.date).getFullYear())
        ) {
          continue;
        }
        if (el.year == new Date(monthInfo.date).getFullYear() && new Date(monthInfo.date).getMonth() < 8) {
          continue;
        }
        if (el.year != new Date(monthInfo.date).getFullYear() && new Date(monthInfo.date).getMonth() > 7) {
          continue;
        }
        // Check if staff had an active contract on that month
        if (
          el.contractStartDateMs != null &&
          el.contractEndDateMs != null &&
          ((monthInfo.date >= el.contractStartDateMs && monthInfo.date <= el.contractEndDateMs) ||
            (new Date(monthInfo.date).getFullYear() == new Date(el.contractStartDateMs).getFullYear() &&
              new Date(monthInfo.date).getMonth() == new Date(el.contractStartDateMs).getMonth()) ||
            (new Date(monthInfo.date).getFullYear() == new Date(el.contractEndDateMs).getFullYear() &&
              new Date(monthInfo.date).getMonth() == new Date(el.contractEndDateMs).getMonth()))
        ) {
          activeInMonthStaffFlag = true;
        } else if (
          el.contractStartDateMs != null &&
          el.contractEndDateMs == null &&
          (monthInfo.date >= el.contractStartDateMs ||
            (new Date(monthInfo.date).getFullYear() == new Date(el.contractStartDateMs).getFullYear() &&
              new Date(monthInfo.date).getMonth() == new Date(el.contractStartDateMs).getMonth()))
        ) {
          activeInMonthStaffFlag = true;
        } else if (
          el.contractStartDateMs == null &&
          el.contractEndDateMs != null &&
          (monthInfo.date <= el.contractEndDateMs ||
            (new Date(monthInfo.date).getFullYear() == new Date(el.contractEndDateMs).getFullYear() &&
              new Date(monthInfo.date).getMonth() == new Date(el.contractEndDateMs).getMonth()))
        ) {
          activeInMonthStaffFlag = true;
        }
        if (!activeInMonthStaffFlag && (el.contractStartDateMs != null || el.contractEndDateMs != null)) {
          continue;
        }
        if (el.history && el.history.length > 0) {
          let insertedElFlag = false;
          let historyCivilYearMonths = [];
          for (let j = 0; j < el.history.length; j++) {
            let hEl = el.history[j];
            // Skip invalid history elements
            if (hEl.year != hEl.civilYear && ["setembro", "outubro", "novembro", "dezembro"].indexOf(hEl.month) != -1) {
              continue;
            }
            historyCivilYearMonths.push(hEl.civilYear + hEl.month);
            if (hEl.year == new Date(monthInfo.date).getFullYear() && new Date(monthInfo.date).getMonth() < 8) {
              continue;
            }
            if (hEl.year != new Date(monthInfo.date).getFullYear() && new Date(monthInfo.date).getMonth() > 7) {
              continue;
            }
            if (
              hEl.civilYear == monthInfo.civilYear &&
              hEl.month == monthInfo.month &&
              hEl.situation == "Ativo" &&
              (scope.activeTabName == "Resultado de Exploração" ||
                (scope.activeTabName != "Consulta Custos Indiretos" &&
                  scope.activeTabName != "Prolongamento" &&
                  (hEl.allocation == "Sala" || hEl.allocation == "Apoio Educativo")) ||
                (scope.activeTabName == "Consulta Custos Indiretos" &&
                  (hEl.allocation == "CEI" ||
                    (hEl.allocation == "Escritório" && hEl.indirectCostsPercentage != null))) ||
                (scope.activeTabName == "Prolongamento" && hEl.allocation == "Prolongamento AAAF"))
            ) {
              let newEl = JSON.parse(JSON.stringify(el));
              newEl.year = hEl.year;
              newEl.civilYear = hEl.civilYear;
              newEl.month = hEl.month;
              newEl.historyDateMs = hEl.historyDateMs;
              newEl.situation = hEl.situation;
              if (hEl.cluster != null) {
                newEl.cluster = hEl.cluster;
              }
              if (hEl.school != null) {
                newEl.school = hEl.school;
              }
              if (hEl.educationLevel != null) {
                newEl.educationLevel = hEl.educationLevel;
              }
              if (hEl.codeIGEFE != null) {
                newEl.codeIGEFE = hEl.codeIGEFE;
              }
              if (newEl.codeIGEFE != null && newEl.civilYear >= 2025) {
                if (newEl.codeIGEFE == 190) {
                  newEl.codeIGEFE = 509;
                } else {
                  newEl.codeIGEFE = 510;
                }
              }
              newEl.excludedRatio = hEl.excludedRatio;
              newEl.allocation = hEl.allocation;
              newEl.professionalBond = hEl.professionalBond;
              newEl.contractStartDateMs = hEl.contractStartDateMs;
              newEl.contractEndDateMs = hEl.contractEndDateMs;
              newEl.function = hEl.function;
              newEl.category = hEl.category;
              newEl.echelonPosition = hEl.echelonPosition;
              newEl.netMonthRemuneration = hEl.netMonthRemuneration;
              newEl.remunerationIndex = hEl.remunerationIndex;
              newEl.indirectCostsPercentage = hEl.indirectCostsPercentage;
              insertedElFlag = true;
              lastInsertedHistoryEl = JSON.parse(JSON.stringify(newEl));
              filterManagementControlFields(newEl);
              newEl.splitByMonthFlag = true;
              data.push(newEl);
            }
          }
          /* if (!insertedElFlag && lastInsertedHistoryEl == null) { */
          if (!insertedElFlag && historyCivilYearMonths.indexOf(monthInfo.civilYear + monthInfo.month) == -1) {
            let newEl = JSON.parse(JSON.stringify(el));
            if (
              newEl.situation == "Ativo" &&
              (scope.activeTabName == "Resultado de Exploração" ||
                (scope.activeTabName != "Consulta Custos Indiretos" &&
                  scope.activeTabName != "Prolongamento" &&
                  (newEl.allocation == "Sala" || newEl.allocation == "Apoio Educativo")) ||
                (scope.activeTabName == "Consulta Custos Indiretos" &&
                  (newEl.allocation == "CEI" ||
                    (newEl.allocation == "Escritório" && newEl.indirectCostsPercentage != null))) ||
                (scope.activeTabName == "Prolongamento" && newEl.allocation == "Prolongamento AAAF"))
            ) {
              newEl.civilYear = monthInfo.civilYear;
              newEl.month = monthInfo.month;
              if (newEl.codeIGEFE != null && newEl.civilYear >= 2025) {
                if (newEl.codeIGEFE == 190) {
                  newEl.codeIGEFE = 509;
                } else {
                  newEl.codeIGEFE = 510;
                }
              }
              filterManagementControlFields(newEl);
              newEl.splitByMonthFlag = true;
              data.push(newEl);
            }
          }
          /* else if (lastInsertedHistoryEl != null) {
            let newEl = JSON.parse(JSON.stringify(lastInsertedHistoryEl));
            newEl.civilYear = monthInfo.civilYear;
            newEl.month = monthInfo.month;
            filterManagementControlFields(newEl);
            newEl.splitByMonthFlag = true;
            data.push(newEl);
          } */
        } else {
          let newEl = JSON.parse(JSON.stringify(el));
          if (
            newEl.situation == "Ativo" &&
            (scope.activeTabName == "Resultado de Exploração" ||
              (scope.activeTabName != "Consulta Custos Indiretos" &&
                scope.activeTabName != "Prolongamento" &&
                (newEl.allocation == "Sala" || newEl.allocation == "Apoio Educativo")) ||
              (scope.activeTabName == "Consulta Custos Indiretos" &&
                (newEl.allocation == "CEI" ||
                  (newEl.allocation == "Escritório" && newEl.indirectCostsPercentage != null))) ||
              (scope.activeTabName == "Prolongamento" && newEl.allocation == "Prolongamento AAAF"))
          ) {
            newEl.civilYear = monthInfo.civilYear;
            newEl.month = monthInfo.month;
            if (newEl.codeIGEFE != null && newEl.civilYear >= 2025) {
              if (newEl.codeIGEFE == 190) {
                newEl.codeIGEFE = 509;
              } else {
                newEl.codeIGEFE = 510;
              }
            }
            filterManagementControlFields(newEl);
            newEl.splitByMonthFlag = true;
            data.push(newEl);
          }
        }
      }
    }

    function filterManagementControlFields(el) {
      if (el.expectedRevenue != null && Array.isArray(el.expectedRevenue) && el.expectedRevenue.length > 0) {
        el.expectedRevenue = el.expectedRevenue.filter(
          (cFEl) => el.civilYear == cFEl.civilYear && el.month == cFEl.month.toLowerCase()
        );
      }
      if (el.costs != null && Array.isArray(el.costs) && el.costs.length > 0) {
        el.costs = el.costs.filter((cFEl) => el.civilYear == cFEl.civilYear && el.month == cFEl.month.toLowerCase());
      }
      if (el.insuranceCosts != null && Array.isArray(el.insuranceCosts) && el.insuranceCosts.length > 0) {
        el.insuranceCosts = el.insuranceCosts.filter(
          (cFEl) => el.civilYear == cFEl.civilYear && el.month == cFEl.month.toLowerCase()
        );
      }
      if (el.ceiCosts != null && Array.isArray(el.ceiCosts) && el.ceiCosts.length > 0) {
        el.ceiCosts = el.ceiCosts.filter(
          (cFEl) => el.civilYear == cFEl.civilYear && el.month == cFEl.month.toLowerCase()
        );
      }
      if (el.extensionCosts != null && Array.isArray(el.extensionCosts) && el.extensionCosts.length > 0) {
        el.extensionCosts = el.extensionCosts.filter(
          (cFEl) => el.civilYear == cFEl.civilYear && el.month == cFEl.month.toLowerCase()
        );
      }
    }
  },
  cafETIRecordLoadStudents: function cafETIRecordLoadStudents(scope, selected, data) {
    return new Promise((resolve, reject) => {
      if (selected.studentAttendanceRecords && selected.studentAttendanceRecords.length > 0) {
        let resetEvals = scope.$mdDialog
          .confirm()
          .title("Atualizar Registos de Presenças de Alunos")
          .textContent(
            "Já tem registos carregados. Deseja continuar com a atualização eliminando os registos efetuados?"
          )
          .ariaLabel("Confirmar atualização")
          .ok("Sim")
          .cancel("Não")
          .clickOutsideToClose(false);
        scope.$mdDialog.show(resetEvals).then(
          function () {
            cafETIRecordLoadStudents(scope, selected, data);
          },
          function () {
            resolve(true);
          }
        );
      } else {
        cafETIRecordLoadStudents(scope, selected, data);
      }

      function cafETIRecordLoadStudents(scope, selected, data) {
        selected.studentAttendanceRecords = [];
        let splitSchedulesFlag = false;
        if (
          selected.splitSchedulesFlagcheckbox != null &&
          selected.splitSchedulesFlagcheckbox[""] &&
          selected.splitSchedulesFlagcheckbox[""] == true
        ) {
          splitSchedulesFlag = true;
        }
        // Load students
        scope.genericFactory.setRouteName("KSTK_Students");
        let students = [];
        let map = {};
        map.year = selected.year;
        map.cluster = selected.cluster;
        map.school = selected.school;
        if (selected.educationLevel) {
          map.educationLevel = selected.educationLevel;
        }
        if (selected.schoolYear) {
          map.schoolYear = selected.schoolYear;
        }
        if (selected.class) {
          map.class = selected.class;
        }
        scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (data) {
          if (data != null && Array.isArray(data) && data.length > 0) {
            students = data.filter((stud) => stud.status_matricula == "Matriculado");

            // Filter students if it is necessary
            if (selected.name != null && Array.isArray(selected.name) && selected.name.length > 0) {
              students = data.filter((stud) => selected.name.indexOf(stud.id) != -1);
            }

            // Sort students by names
            students = students.sort(function compareModules(a, b) {
              if (
                a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
              ) {
                return -1;
              }
              if (
                a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
                b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
              ) {
                return 1;
              }
              return 0;
            });

            // Load student ASE records
            scope.genericFactory.setRouteName("KSTK_ASEs");
            let map = {};
            map.year = selected.year;
            map.cluster = selected.cluster;
            map.school = selected.school;
            scope.genericFactory
              .getByProperties(map, scope.currentUser.organization)
              .then(function (studentASERecords) {
                // Load student ETI records
                scope.genericFactory.setRouteName("KSTK_ETIs");
                let map = {};
                map.year = selected.year;
                map.cluster = selected.cluster;
                map.school = selected.school;
                scope.genericFactory
                  .getByProperties(map, scope.currentUser.organization)
                  .then(function (studentETIRecords) {
                    // Filter students with active ETI record
                    if (studentETIRecords != null && Array.isArray(studentETIRecords) && studentETIRecords.length > 0) {
                      studentETIRecords = studentETIRecords.filter((stud) => stud.situation == "Frequenta");
                    }

                    if (studentETIRecords != null && Array.isArray(studentETIRecords) && studentETIRecords.length > 0) {
                      //Load ETI schedules
                      scope.genericFactory.setRouteName("KSTK_ETI_Schedules");
                      scope.genericFactory
                        .getByProperty("organization", scope.currentUser.organization)
                        .then(function (etiSchedules) {
                          for (let w = 0; w < students.length; w++) {
                            let stud = students[w];
                            let studToAdd = {};
                            studToAdd.studentID = stud.id;
                            studToAdd.cluster = stud.cluster;
                            studToAdd.school = stud.school;
                            studToAdd.name = stud.name;

                            let studentHasETIActiveRecordFlag = false;
                            for (let j = 0; j < studentETIRecords.length; j++) {
                              let studETIRecord = studentETIRecords[j];
                              if (studETIRecord.name === stud.id) {
                                if (studETIRecord.schedule) {
                                  studToAdd.schedule = studETIRecord.schedule;
                                  studentHasETIActiveRecordFlag = true;

                                  studToAdd.schedule.forEach((schedule) => {
                                    if (studToAdd.parsedSchedulesArr == null) {
                                      studToAdd.parsedSchedulesArr = [];
                                    }
                                    let filteredSchedule = etiSchedules.filter((sc) => sc.id == schedule);
                                    if (
                                      filteredSchedule &&
                                      Array.isArray(filteredSchedule) &&
                                      filteredSchedule.length
                                    ) {
                                      studToAdd.parsedSchedulesArr.push(filteredSchedule[0].schedule);

                                      //Split studs by schedule
                                      if (splitSchedulesFlag) {
                                        studToAdd.schedule = [schedule];
                                        studToAdd.parsedSchedulesArr = [filteredSchedule[0].schedule];
                                        studToAdd.parsedSchedule = (studToAdd.parsedSchedulesArr || "")
                                          .toString()
                                          .replace(/,/gi, ", ");
                                        completeStudToAddInfo(studToAdd, studETIRecord, scope);
                                        for (let k = 0; k < studentASERecords.length; k++) {
                                          let studASERecord = studentASERecords[k];
                                          if (studASERecord.name === stud.id) {
                                            studToAdd.aseEchelon = studASERecord.aseEchelon;
                                            break;
                                          }
                                        }
                                        let studToAddUniqueCopy = JSON.parse(JSON.stringify(studToAdd));
                                        selected.studentAttendanceRecords.push(studToAddUniqueCopy);
                                      }
                                    }
                                  });
                                  studToAdd.parsedSchedule = (studToAdd.parsedSchedulesArr || "")
                                    .toString()
                                    .replace(/,/gi, ", ");
                                }

                                if (!splitSchedulesFlag) {
                                  completeStudToAddInfo(studToAdd, studETIRecord, scope);
                                }

                                function completeStudToAddInfo(studToAdd, studETIRecord, scope) {
                                  if (
                                    studETIRecord.incomeEchelonManual != null &&
                                    studETIRecord.incomeEchelonManual != ""
                                  ) {
                                    studToAdd.incomeEchelon = studETIRecord.incomeEchelonManual;
                                  } else if (studETIRecord.incomeEchelon != null) {
                                    studToAdd.incomeEchelon = studETIRecord.incomeEchelon;
                                  }
                                  if (studETIRecord.schoolYear) {
                                    let schoolYears = scope.getFromTableData("Class_Plan_School_Years");
                                    let studParsedSchoolYear = schoolYears.filter(
                                      (schoYear) => schoYear.id == studETIRecord.schoolYear
                                    )[0].schoolYear;
                                    if (studParsedSchoolYear != null) {
                                      studToAdd.parsedSchoolYear = studParsedSchoolYear;
                                    }
                                  }
                                  if (studETIRecord.educationLevel) {
                                    let educationLevels = scope.getFromTableData("Class_Plan_Education_Levels");
                                    let studParsedEducationLevel = educationLevels.filter(
                                      (schoYear) => schoYear.id == studETIRecord.educationLevel
                                    )[0].educationLevel;
                                    if (studParsedEducationLevel != null) {
                                      studToAdd.parsedEducationLevel = studParsedEducationLevel;
                                    }
                                  }
                                }

                                break;
                              }
                            }
                            //Skip student if it does not have an active ETI record
                            if (!studentHasETIActiveRecordFlag || splitSchedulesFlag) {
                              continue;
                            } else {
                              for (let k = 0; k < studentASERecords.length; k++) {
                                let studASERecord = studentASERecords[k];
                                if (studASERecord.name === stud.id) {
                                  studToAdd.aseEchelon = studASERecord.aseEchelon;
                                  break;
                                }
                              }
                            }
                            selected.studentAttendanceRecords.push(studToAdd);
                          }

                          //Check if students were added to the studentAttendanceRecords array
                          if (selected.studentAttendanceRecords.length) {
                            scope.genericFactory.setRouteName("KSTK_Meal_CAF_Records");
                            resolve(true);
                          } else {
                            //If not, alert user
                            scope.genericScope.$parent.alertCAFETIRecordStudentsWithoutETIRecords();
                            scope.parentScope.$parent.hideLoader();
                            scope.genericFactory.setRouteName("KSTK_Meal_CAF_Records");
                            resolve(false);
                          }
                        });
                    } else {
                      scope.genericScope.$parent.alertCAFETIRecordStudentsWithoutETIRecords();
                      scope.parentScope.$parent.hideLoader();
                      scope.genericFactory.setRouteName("KSTK_Meal_CAF_Records");
                      resolve(false);
                    }
                  });
              });
          } else {
            scope.genericScope.$parent.alertCAFETIRecordStudentsMissing();
            scope.parentScope.$parent.hideLoader();
            scope.genericFactory.setRouteName("KSTK_Meal_CAF_Records");
            resolve(false);
          }
        });
      }
    });
  },
  etiCAFSplitRecordTable: function etiCAFSplitRecordTable(scope, data, panelConfig) {
    if (scope.selectedRow != null) {
      scope.selected = scope.selectedRow;
      let splitSchedulesFlag = false;
      if (
        scope.selected.splitSchedulesFlagcheckbox != null &&
        scope.selected.splitSchedulesFlagcheckbox[""] &&
        scope.selected.splitSchedulesFlagcheckbox[""] == true
      ) {
        splitSchedulesFlag = true;
      }
      scope.selected.weekendsAndHolidays = [];

      let recordTableData = [];
      let columnDefs = [
        {
          name: "name",
          displayName: "Nome",
          enableCellEdit: false,
          width: "200",
          pinnedLeft: true,
        },
        {
          name: "aseEchelon",
          displayName: "ASE",
          enableCellEdit: false,
          width: "70",
        },
        {
          name: "parsedSchedule",
          displayName: "Horário",
          enableCellEdit: false,
          width: "200",
          filter: {
            condition: function (searchTerm, cellValue, row, column) {
              if (cellValue != null || cellValue != undefined) {
                var re = new RegExp("^" + searchTerm + "$", "gi");
                return cellValue.match(re);
              }
              return null;
            },
          },
        },
      ];

      let dateFromRegDateMs = new Date(scope.selected.regDateMs);
      let monthDays = new Date(dateFromRegDateMs.getFullYear(), dateFromRegDateMs.getMonth() + 1, 0).getDate();

      if (monthDays == null) {
        monthDays = 32;
      } else {
        monthDays += 1;
      }

      scope.selected.monthDays = monthDays;
      scope.selected.activityDays = 0;

      for (let j = 1; j < monthDays; j++) {
        let day = j;
        let dayOfTheWeek = new Date(dateFromRegDateMs.getFullYear(), dateFromRegDateMs.getMonth(), day).getDay();
        if (
          dayOfTheWeek != 0 &&
          dayOfTheWeek != 6 &&
          (scope.selected.holidays == null ||
            (scope.selected.holidays &&
              Array.isArray(scope.selected.holidays) &&
              scope.selected.holidays.indexOf(day) == -1))
        ) {
          scope.selected.activityDays += 1;

          // CIMBAL - Vidigueira
          if (scope.selected.organization == "5fc118cde1fc9e0050504ef5") {
            columnDefs.push({
              name: "day" + day,
              displayName: day + "",
              cellClass: "ptAvalColumnToEdit",
              editableCellTemplate: "ui-grid/dropdownEditor",
              editDropdownOptionsArray: [
                {
                  id: 1,
                  value: 1,
                },
                {
                  id: 2,
                  value: 0,
                },
                {
                  id: 2,
                  value: "T",
                },
              ],
              editDropdownIdLabel: "value",
              treeAggregationType: "sumIncludingETICAFOptions",
              //treeAggregationLabel: "",
              enableColumnMenus: false,
              calculatedFunction: "etiCAFMealRecordUpdate({},}{,'day" + day + "')",
              width: "60",
            });
          } // CIMBAL - Serpa
          else if (scope.selected.organization == "6011a11ad9ea0100d4b3aa0d") {
            columnDefs.push({
              name: "day" + day,
              displayName: day + "",
              cellClass: "ptAvalColumnToEdit",
              editableCellTemplate: "ui-grid/dropdownEditor",
              editDropdownOptionsArray: [
                {
                  id: 1,
                  value: 1,
                },
                {
                  id: 2,
                  value: 0,
                },
                {
                  id: 2,
                  value: "F",
                },
              ],
              editDropdownIdLabel: "value",
              treeAggregationType: "sumIncludingETICAFOptions",
              //treeAggregationLabel: "",
              enableColumnMenus: false,
              calculatedFunction: "etiCAFMealRecordUpdate({},}{,'day" + day + "')",
              width: "60",
            });
          } // CIMBAL - Ourique
          else if (scope.selected.organization == "6001d7978b7f480098c8260f") {
            columnDefs.push({
              name: "day" + day,
              displayName: day + "",
              cellClass: "ptAvalColumnToEdit",
              editableCellTemplate: "ui-grid/dropdownEditor",
              editDropdownOptionsArray: [
                {
                  id: 1,
                  value: 1,
                },
                {
                  id: 2,
                  value: 0,
                },
                {
                  id: 3,
                  value: 0.5,
                },
                {
                  id: 4,
                  value: 1.5,
                },
                {
                  id: 5,
                  value: 2,
                },
              ],
              editDropdownIdLabel: "value",
              treeAggregationType: "sumIncludingETICAFOptions",
              treeAggregationLabel: "",
              enableColumnMenus: false,
              calculatedFunction: "etiCAFMealRecordUpdate({},}{,'day" + day + "')",
              width: "60",
            });
          } else {
            columnDefs.push({
              name: "day" + day,
              displayName: day + "",
              cellClass: "ptAvalColumnToEdit",
              editableCellTemplate: "ui-grid/dropdownEditor",
              editDropdownOptionsArray: [
                {
                  id: 1,
                  value: 1,
                },
                {
                  id: 2,
                  value: 0,
                },
              ],
              editDropdownIdLabel: "value",
              treeAggregationType: scope.uiGridGroupingConstants.aggregation.SUM,
              treeAggregationLabel: "",
              enableColumnMenus: false,
              calculatedFunction: "etiCAFMealRecordUpdate({},}{,'day" + day + "')",
              width: "60",
            });
          }
        } else {
          scope.selected.weekendsAndHolidays.push(day);
          columnDefs.push({
            name: "day" + day,
            displayName: day + "",
            enableCellEdit: false,
            enableColumnMenus: false,
            width: "60",
          });
        }
      }

      columnDefs.push({
        name: "monthlyTotal",
        displayName: "Total",
        enableCellEdit: false,
        width: "75",
        treeAggregationType: scope.uiGridGroupingConstants.aggregation.SUM,
        treeAggregationLabel: "",
      });

      columnDefs.push({
        name: "monthlyPayment",
        displayName: "Mensalidade",
        enableCellEdit: false,
        width: "150",
      });

      // CIMBAL - Vidigueira, Ourique...
      if (splitSchedulesFlag) {
        columnDefs.push({
          name: "monthlyTotalPayment",
          displayName: "Mensalidade Total",
          enableCellEdit: false,
          width: "150",
        });
      }

      // CIMBAL - Cuba
      if (scope.selected.organization == "5fc90dcd897ee20050a411da") {
        columnDefs.push({
          name: "situation",
          displayName: "Situação",
          enableCellEdit: false,
          width: "200",
        });

        columnDefs.push({
          name: "manualSituation",
          displayName: "Alteração",
          cellClass: "ptAvalColumnToEdit",
          editableCellTemplate: "ui-grid/dropdownEditor",
          editDropdownOptionsArray: [
            {
              id: 1,
              value: "Só almoço",
            },
            {
              id: 2,
              value: "Só prolongamento",
            },
            {
              id: 3,
              value: "",
            },
          ],
          editDropdownIdLabel: "value",
          width: "150",
        });
      }

      if (panelConfig.importer != null) {
        columnDefs = columnDefs.concat([
          {
            name: "rowID",
            displayName: "ID",
            enableCellEdit: false,
            enableFiltering: false,
            width: 5,
            type: "number",
            sort: {
              priority: 0,
              direction: "asc",
            },
          },
        ]);
      }

      if (scope.selected.studentAttendanceRecords && scope.selected.studentAttendanceRecords.length > 0) {
        let lastName;
        let rowBackgroundColorID = 1;
        for (let k = 0; k < scope.selected.studentAttendanceRecords.length; k++) {
          let studRecord = scope.selected.studentAttendanceRecords[k];
          studRecord.dynamicIndex = k;

          //Setup row backgroundColorID by name
          if (studRecord.name != lastName) {
            rowBackgroundColorID == 0 ? (rowBackgroundColorID = 1) : (rowBackgroundColorID = 0);
            studRecord.rowBackgroundColorID = rowBackgroundColorID;
            lastName = studRecord.name;
          } else {
            studRecord.rowBackgroundColorID = rowBackgroundColorID;
          }

          studRecord.rowID = studRecord.dynamicIndex + 1;

          recordTableData.push(studRecord);
        }
      }

      return {
        data: recordTableData,
        columnDefs: columnDefs,
      };
    }
  },
  etiCAFSplitReservationsRecordTable: function etiCAFSplitReservationsRecordTable(scope, data) {
    if (scope.selectedRow != null) {
      scope.selected = scope.selectedRow;
      scope.selected.weekendsAndHolidays = [];

      let recordTableData = [];
      let columnDefs = [
        {
          name: "name",
          displayName: "Nome",
          enableCellEdit: false,
          width: "200",
          pinnedLeft: true,
        },
        {
          name: "aseEchelon",
          displayName: "ASE",
          enableCellEdit: false,
          width: "70",
        },
        {
          name: "parsedSchedule",
          displayName: "Horário",
          enableCellEdit: false,
          width: "200",
          filter: {
            condition: function (searchTerm, cellValue, row, column) {
              if (cellValue != null || cellValue != undefined) {
                var re = new RegExp("^" + searchTerm + "$", "gi");
                return cellValue.match(re);
              }
              return null;
            },
          },
        },
      ];

      let dateFromRegDateMs = new Date(scope.selected.regDateMs);
      let monthDays = new Date(dateFromRegDateMs.getFullYear(), dateFromRegDateMs.getMonth() + 1, 0).getDate();

      if (monthDays == null) {
        monthDays = 32;
      } else {
        monthDays += 1;
      }

      scope.selected.monthDays = monthDays;

      for (let j = 1; j < monthDays; j++) {
        let day = j;
        let dayOfTheWeek = new Date(dateFromRegDateMs.getFullYear(), dateFromRegDateMs.getMonth(), day).getDay();
        if (
          dayOfTheWeek != 0 &&
          dayOfTheWeek != 6 &&
          (scope.selected.holidays == null ||
            (scope.selected.holidays &&
              Array.isArray(scope.selected.holidays) &&
              scope.selected.holidays.indexOf(day) == -1))
        ) {
          columnDefs.push({
            name: "day" + day + "Reservation",
            displayName: day + "",
            cellClass: "ptAvalColumnToEdit",
            editableCellTemplate: "ui-grid/dropdownEditor",
            editDropdownOptionsArray: [
              {
                id: 1,
                value: 1,
              },
              {
                id: 2,
                value: 0,
              },
            ],
            editDropdownIdLabel: "value",
            treeAggregationType: scope.uiGridGroupingConstants.aggregation.SUM,
            treeAggregationLabel: "",
            enableColumnMenus: false,
            calculatedFunction: "etiCAFMealRecordUpdate({},}{,'day" + day + "Reservation', true)",
            width: "60",
          });
        } else {
          scope.selected.weekendsAndHolidays.push(day);
          columnDefs.push({
            name: "day" + day + "Reservation",
            displayName: day + "",
            enableCellEdit: false,
            enableColumnMenus: false,
            width: "60",
          });
        }
      }

      columnDefs.push({
        name: "monthlyReservationsTotal",
        displayName: "Total",
        enableCellEdit: false,
        width: "75",
      });

      if (scope.selected.studentAttendanceRecords && scope.selected.studentAttendanceRecords.length > 0) {
        let lastName;
        let rowBackgroundColorID = 1;
        for (let k = 0; k < scope.selected.studentAttendanceRecords.length; k++) {
          let studRecord = scope.selected.studentAttendanceRecords[k];
          studRecord.dynamicIndex = k;

          //Setup row backgroundColorID by name
          if (studRecord.name != lastName) {
            rowBackgroundColorID == 0 ? (rowBackgroundColorID = 1) : (rowBackgroundColorID = 0);
            studRecord.rowBackgroundColorID = rowBackgroundColorID;
            lastName = studRecord.name;
          } else {
            studRecord.rowBackgroundColorID = rowBackgroundColorID;
          }

          recordTableData.push(studRecord);
        }
      }

      return {
        data: recordTableData,
        columnDefs: columnDefs,
      };
    }
  },
  etiCAFSplitRecords: function etiCAFSplitRecords(scope, data) {
    let studentsData = scope.getFromTableData("KSTK_Students");
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.studentAttendanceRecords && el.studentAttendanceRecords.length > 0) {
        for (let k = 0; k < el.studentAttendanceRecords.length; k++) {
          let studAttendance = el.studentAttendanceRecords[k];
          if (studAttendance.monthlyTotal != null) {
            let studRecord = studentsData.filter((el) => el.id == studAttendance.studentID)[0];
            if (studRecord != null) {
              if (scope.activeTabName == "Mensalidades") {
                let newStudAttendance = JSON.parse(JSON.stringify(el));
                getMainStudAttendanceAttributes(studAttendance, newStudAttendance, studRecord);
                newStudAttendance.parsedSchedule = studAttendance.parsedSchedule;
                data.push(newStudAttendance);
              } else {
                studAttendance.schedule.forEach((schedule) => {
                  let newStudAttendance = JSON.parse(JSON.stringify(el));
                  getMainStudAttendanceAttributes(studAttendance, newStudAttendance, studRecord);
                  newStudAttendance.schedule = schedule;
                  data.push(newStudAttendance);
                });
              }
            }
          }
        }
      }
      data.splice(0, 1);
    }

    function getMainStudAttendanceAttributes(studAttendance, newStudAttendance, studRecord) {
      newStudAttendance.name = studRecord.name;
      newStudAttendance.aseEchelon = studAttendance.aseEchelon == null ? "Sem escalão" : studAttendance.aseEchelon;
      newStudAttendance.studentID = studAttendance.studentID;
      newStudAttendance.parsedEducationLevel =
        studAttendance.parsedEducationLevel == "S/ nível ensino" ? null : studAttendance.parsedEducationLevel;
      newStudAttendance.monthlyPaymentWithoutExemption =
        studAttendance.monthlyPaymentWithoutExemption == null ? 0 : studAttendance.monthlyPaymentWithoutExemption;
      newStudAttendance.monthlyPayment = studAttendance.monthlyPayment == null ? 0 : studAttendance.monthlyPayment;
      newStudAttendance.monthlyTotalPayment =
        studAttendance.monthlyTotalPayment == null ? 0 : studAttendance.monthlyTotalPayment;
      newStudAttendance.monthlyTotal = studAttendance.monthlyTotal == null ? 0 : studAttendance.monthlyTotal;
      newStudAttendance.monthlyReservationsTotal =
        studAttendance.monthlyReservationsTotal == null ? 0 : studAttendance.monthlyReservationsTotal;
      if (studAttendance.situation != null) {
        newStudAttendance.situation = studAttendance.situation;
      }
      if (studAttendance.manualSituation != null) {
        newStudAttendance.situation =
          newStudAttendance.situation == null
            ? studAttendance.manualSituation
            : newStudAttendance.situation + "; " + studAttendance.manualSituation;
      }

      newStudAttendance.splitFlag = true;

      if (newStudAttendance.createdBy != null) {
        delete newStudAttendance.createdBy;
      }
      if (newStudAttendance.modifiedBy != null) {
        delete newStudAttendance.modifiedBy;
      }
      if (newStudAttendance.studentAttendanceRecords != null) {
        delete newStudAttendance.studentAttendanceRecords;
      }
    }

    return data;
  },
  etiKSTKSplitRecords: function etiKSTKSplitRecords(scope, data) {
    let schedules = scope.getFromTableData("KSTK_ETI_Schedules");
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.schedule && el.schedule.length > 0 && el.situation == "Frequenta") {
        for (let k = 0; k < el.schedule.length; k++) {
          let studSchedule = el.schedule[k];
          let newElement = JSON.parse(JSON.stringify(el));
          cleanNewElement(newElement);
          let parsedSchedule = schedules.filter((el) => el.id == studSchedule)[0];
          if (parsedSchedule != null) {
            newElement.parsedSchedule = parsedSchedule.schedule;
          } else {
            newElement.parsedSchedule = "Sem horário";
          }
          newElement.splitFlag = true;

          data.push(newElement);
        }
      }
      data.splice(0, 1);
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }
    return data;
  },
  aecKSTKSplitRecords: function aecKSTKSplitRecords(scope, data) {
    let aecs = scope.getFromTableData("KSTK_AEC_Acts");
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.aec && el.aec.length > 0 && el.situation == "Frequenta") {
        for (let k = 0; k < el.aec.length; k++) {
          let studAEC = el.aec[k];
          let newElement = JSON.parse(JSON.stringify(el));
          cleanNewElement(newElement);
          let parsedAEC = aecs.filter((el) => el.id == studAEC)[0];
          if (parsedAEC != null) {
            newElement.parsedAEC = parsedAEC.aec;
          } else {
            newElement.parsedAEC = "Sem AEC";
          }
          newElement.splitFlag = true;

          data.push(newElement);
        }
      }
      data.splice(0, 1);
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }
    return data;
  },
  cmsSplitMealsByASEEchelon: function cmsSplitMealsByASEEchelon(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      // Parse education level
      if (el.educationLevel != null) {
        let educationLevel = scope.parseValue(el.educationLevel, "educationLevel", "Transp_Education_Levels");
        if (educationLevel != null && educationLevel != "") {
          if (educationLevel == "PRE") {
            el.educationLevel = " PRE";
            el.parsedEducationLevel = " PRE";
          } else if (educationLevel == "2º Ciclo" || educationLevel == "3º Ciclo") {
            el.parsedEducationLevel = "2º Ciclo,3º Ciclo";
          } else {
            el.parsedEducationLevel = educationLevel;
          }
        }
      }

      if (el.nrMealsASEEchelonA != null) {
        let newEl = JSON.parse(JSON.stringify(el));
        newEl.splitFlag = true;
        newEl.aseEchelon = "A";
        newEl.nrMeals = el.nrMealsASEEchelonA;
        if (newEl.nrMeals != null && newEl.totalConsumedMeals != null && newEl.cost != null) {
          newEl.cost = Math.round((newEl.nrMeals / newEl.totalConsumedMeals) * newEl.cost * 100) / 100;
        }
        newEl.month = getMonthWithNumberBefore(newEl.month);
        cleanNewElement(newEl);
        data.push(newEl);
      }

      if (el.nrMealsASEEchelonB != null) {
        let newEl = JSON.parse(JSON.stringify(el));
        newEl.splitFlag = true;
        newEl.aseEchelon = "B";
        newEl.nrMeals = el.nrMealsASEEchelonB;
        if (newEl.nrMeals != null && newEl.totalConsumedMeals != null && newEl.cost != null) {
          newEl.cost = Math.round((newEl.nrMeals / newEl.totalConsumedMeals) * newEl.cost * 100) / 100;
        }
        newEl.month = getMonthWithNumberBefore(newEl.month);
        cleanNewElement(newEl);
        data.push(newEl);
      }

      if (el.nrMealsASEEchelonNotInNeed != null) {
        let newEl = JSON.parse(JSON.stringify(el));
        newEl.splitFlag = true;
        newEl.aseEchelon = "Não Beneficia";
        newEl.nrMeals = el.nrMealsASEEchelonNotInNeed;
        if (newEl.nrMeals != null && newEl.totalConsumedMeals != null && newEl.cost != null) {
          newEl.cost = Math.round((newEl.nrMeals / newEl.totalConsumedMeals) * newEl.cost * 100) / 100;
        }
        newEl.month = getMonthWithNumberBefore(newEl.month);
        cleanNewElement(newEl);
        data.push(newEl);
      }

      let newEl2 = JSON.parse(JSON.stringify(el));
      newEl2.splitFlag = true;
      newEl2.aseEchelon = "C";
      newEl2.nrMeals = 0;
      newEl2.month = getMonthWithNumberBefore(newEl2.month);
      cleanNewElement(newEl2);
      data.push(newEl2);

      let newEl3 = JSON.parse(JSON.stringify(el));
      newEl3.splitFlag = true;
      newEl3.aseEchelon = "D";
      newEl3.nrMeals = 0;
      newEl3.month = getMonthWithNumberBefore(newEl3.month);
      cleanNewElement(newEl3);
      data.push(newEl3);

      data.splice(i, 1);
    }

    let nrMealsByCivilYearMonthSchoolParsedEducationLevel = {};
    let nrMealsByCivilYearMonthSchoolASEEchelon = {};

    for (let i = 0; i < data.length; i++) {
      let el = data[i];

      let elKey = el.civilYear + el.month + el.school + el.parsedEducationLevel;

      if (nrMealsByCivilYearMonthSchoolParsedEducationLevel[elKey] == null) {
        nrMealsByCivilYearMonthSchoolParsedEducationLevel[elKey] = el.nrMeals;
      } else {
        nrMealsByCivilYearMonthSchoolParsedEducationLevel[elKey] += el.nrMeals;
      }

      let elKey2 = el.civilYear + el.month + el.school + el.parsedEducationLevel + el.aseEchelon;
      if (nrMealsByCivilYearMonthSchoolASEEchelon[elKey2] == null) {
        nrMealsByCivilYearMonthSchoolASEEchelon[elKey2] = el.nrMeals;
      } else {
        nrMealsByCivilYearMonthSchoolASEEchelon[elKey2] += el.nrMeals;
      }
    }

    for (let i = 0; i < data.length; i++) {
      let el = data[i];

      let elKey = el.civilYear + el.month + el.school + el.parsedEducationLevel;
      if (nrMealsByCivilYearMonthSchoolParsedEducationLevel[elKey] != null) {
        el.avgConsumedMeals =
          Math.round((nrMealsByCivilYearMonthSchoolParsedEducationLevel[elKey] / el.numbWorkdays) * 100) / 100;
      }
      let elKey2 = el.civilYear + el.month + el.school + el.parsedEducationLevel + el.aseEchelon;
      if (nrMealsByCivilYearMonthSchoolASEEchelon[elKey2] != null) {
        el.avgConsumedMealsByASEEchelon =
          Math.round((nrMealsByCivilYearMonthSchoolASEEchelon[elKey2] / el.numbWorkdays) * 100) / 100;
      }
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  kstkSchoolContactSplitByArchitecture: function kstkSchoolContactSplitByArchitecture(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.architectureTypes != null && Array.isArray(el.architectureTypes) && el.architectureTypes.length > 0) {
        for (let w = 0; w < el.architectureTypes.length; w++) {
          let schoolArchInfo = el.architectureTypes[w];

          if (
            schoolArchInfo.educationLevel != null &&
            Array.isArray(schoolArchInfo.educationLevel) &&
            schoolArchInfo.educationLevel.length > 0 &&
            schoolArchInfo.arquitectureType != null
          ) {
            let newElement = JSON.parse(JSON.stringify(el));
            newElement.arquitectureType = schoolArchInfo.arquitectureType;
            newElement.splitFlag = true;
            cleanNewElement(newElement);
            data.push(newElement);
          }
        }
      }

      data.splice(i, 1);
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  kstkSchoolContactSplitByEducationLevel: function kstkSchoolContactSplitByEducationLevel(scope, data) {
    let educationLevels = scope.getFromTableData("Class_Plan_Education_Levels");
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.educationLevel != null && Array.isArray(el.educationLevel) && el.educationLevel.length > 0) {
        let alreadyCounted2ndAnd3rdEducationLevel = false;
        for (let w = 0; w < el.educationLevel.length; w++) {
          let newEl = JSON.parse(JSON.stringify(el));
          let educationLevel = el.educationLevel[w];
          let fromTableEducationLevel = educationLevels.filter((el) => el.id == educationLevel)[0];
          if (fromTableEducationLevel != null) {
            let parsedEducationLevel = educationLevels.filter((el) => el.id == educationLevel)[0].educationLevel;
            if (parsedEducationLevel != null && parsedEducationLevel != "") {
              if (parsedEducationLevel.indexOf("2") != -1 || parsedEducationLevel.indexOf("3") != -1) {
                if (alreadyCounted2ndAnd3rdEducationLevel) {
                  continue;
                }
                alreadyCounted2ndAnd3rdEducationLevel = true;
                parsedEducationLevel = "2º e 3º Ciclos";
              }
              cleanNewElement(newEl);
              newEl.parsedEducationLevel = parsedEducationLevel;
              newEl.splitFlag = true;
              data.push(newEl);
            }
          }
        }
      }

      data.splice(i, 1);
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  kstkSchoolContactSplitByEducationLevelAndArchitecture: function kstkSchoolContactSplitByEducationLevelAndArchitecture(
    scope,
    data
  ) {
    let educationLevels = scope.getFromTableData("Class_Plan_Education_Levels");
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.educationLevel != null && Array.isArray(el.educationLevel) && el.educationLevel.length > 0) {
        let alreadyCounted2ndAnd3rdEducationLevel = false;
        for (let w = 0; w < el.educationLevel.length; w++) {
          let newEl = JSON.parse(JSON.stringify(el));
          let educationLevel = el.educationLevel[w];
          let fromTableEducationLevel = educationLevels.filter((el) => el.id == educationLevel)[0];
          if (fromTableEducationLevel != null) {
            let parsedEducationLevel = educationLevels.filter((el) => el.id == educationLevel)[0].educationLevel;
            if (parsedEducationLevel != null && parsedEducationLevel != "") {
              if (parsedEducationLevel.indexOf("2") != -1 || parsedEducationLevel.indexOf("3") != -1) {
                if (alreadyCounted2ndAnd3rdEducationLevel) {
                  continue;
                }
                alreadyCounted2ndAnd3rdEducationLevel = true;
                parsedEducationLevel = "2º e 3º Ciclos";
              } else if (parsedEducationLevel.indexOf("PRE") != -1) {
                parsedEducationLevel = " PRE";
              }
              newEl.parsedEducationLevel = parsedEducationLevel;

              if (
                newEl.architectureTypes != null &&
                Array.isArray(newEl.architectureTypes) &&
                newEl.architectureTypes.length > 0
              ) {
                for (let w = 0; w < newEl.architectureTypes.length; w++) {
                  let schoolArchInfo = newEl.architectureTypes[w];

                  if (
                    schoolArchInfo.educationLevel != null &&
                    Array.isArray(schoolArchInfo.educationLevel) &&
                    schoolArchInfo.educationLevel.length > 0 &&
                    schoolArchInfo.arquitectureType != null
                  ) {
                    let newSubElement = JSON.parse(JSON.stringify(newEl));
                    newSubElement.arquitectureType = schoolArchInfo.arquitectureType;
                    newSubElement.clusterSchoolArchType =
                      scope.parseValue(newSubElement.cluster, "cluster", "Class_Plan_Clusters") +
                      scope.parseValue(newSubElement.school, "school", "Class_Plan_Schools") +
                      newSubElement.arquitectureType;
                    newSubElement.schoolEducationLevel =
                      scope.parseValue(newSubElement.school, "school", "Class_Plan_Schools") +
                      (newSubElement.parsedEducationLevel == "PRE"
                        ? " " + newSubElement.parsedEducationLevel
                        : newSubElement.parsedEducationLevel);
                    newSubElement.splitFlag = true;
                    cleanNewElement(newSubElement);
                    data.push(newSubElement);
                  }
                }
              }
            }
          }
        }
      }

      data.splice(i, 1);
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  cmsNonTeachDescSetHistory: function cmsNonTeachDescSetHistory(scope, selected, data) {
    if (selected.history == null) {
      selected.history = [];
    }
    let monthHistoryNotSetFlag = true;
    let newHistoryObj = {};
    let historyDate = new Date();
    newHistoryObj.year = Number(selected.year);
    newHistoryObj.civilYear = historyDate.getFullYear();
    newHistoryObj.month = getMonthFromDateMs(historyDate);
    newHistoryObj.historyDateMs = historyDate;
    newHistoryObj.situation = selected.situation;
    newHistoryObj.cluster = selected.cluster;
    newHistoryObj.school = selected.school;
    newHistoryObj.educationLevel = selected.educationLevel;
    newHistoryObj.codeIGEFE = selected.codeIGEFE;
    newHistoryObj.excludedRatio = selected.excludedRatio;
    newHistoryObj.allocation = selected.allocation;
    newHistoryObj.indirectCostsPercentage = selected.indirectCostsPercentage;
    newHistoryObj.professionalBond = selected.professionalBond;
    newHistoryObj.contractStartDateMs =
      selected.contractStartDateMs == null || selected.contractStartDateMs == undefined
        ? null
        : selected.contractStartDateMs;
    newHistoryObj.contractEndDateMs =
      selected.contractEndDateMs == null || selected.contractEndDateMs == undefined ? null : selected.contractEndDateMs;
    newHistoryObj.function = selected.function;
    newHistoryObj.category = selected.category;
    newHistoryObj.echelonPosition = selected.echelonPosition;
    newHistoryObj.netMonthRemuneration = selected.netMonthRemuneration;
    newHistoryObj.remunerationIndex = selected.remunerationIndex;

    if (selected.history.length == 0) {
      selected.history.push(newHistoryObj);
    } else {
      for (let j = 0; j < selected.history.length; j++) {
        let historyEl = selected.history[j];
        if (historyEl.year == newHistoryObj.year && historyEl.month == newHistoryObj.month) {
          selected.history[j] = newHistoryObj;
          monthHistoryNotSetFlag = false;
          break;
        }
      }
      if (monthHistoryNotSetFlag) {
        selected.history.push(newHistoryObj);
      }
    }
  },
  cmsNonTeachDescCompleteHistory: function cmsNonTeachDescCompleteHistory(scope, selected, data) {
    if (selected.history == null || (selected.history != null && selected.history.length == 1)) {
      return true;
    }

    let selectedYear = Number(selected.year);

    let yearCivilYearMonthToValidate = [
      { year: selectedYear, civilYear: selectedYear, month: "setembro" },
      { year: selectedYear, civilYear: selectedYear, month: "outubro" },
      { year: selectedYear, civilYear: selectedYear, month: "novembro" },
      { year: selectedYear, civilYear: selectedYear, month: "dezembro" },
      { year: selectedYear, civilYear: selectedYear + 1, month: "janeiro" },
      { year: selectedYear, civilYear: selectedYear + 1, month: "fevereiro" },
      { year: selectedYear, civilYear: selectedYear + 1, month: "março" },
      { year: selectedYear, civilYear: selectedYear + 1, month: "abril" },
      { year: selectedYear, civilYear: selectedYear + 1, month: "maio" },
      { year: selectedYear, civilYear: selectedYear + 1, month: "junho" },
      { year: selectedYear, civilYear: selectedYear + 1, month: "julho" },
      { year: selectedYear, civilYear: selectedYear + 1, month: "agosto" },
    ];

    let lastHistEl = null;
    let orderedAndCompletedHistory = [];
    let gapsCompleted = 0;
    let historyElsWithSelectedYear = selected.history.filter((el) => el.year == selected.year).length;

    for (let m = 0; m < yearCivilYearMonthToValidate.length; m++) {
      if (gapsCompleted == historyElsWithSelectedYear - 1) {
        break;
      }
      let yearCivilYearMonthEl = yearCivilYearMonthToValidate[m];
      let filteredHistEl = selected.history.filter(
        (el) =>
          el.year == yearCivilYearMonthEl.year &&
          el.civilYear == yearCivilYearMonthEl.civilYear &&
          el.month == yearCivilYearMonthEl.month
      );
      if (filteredHistEl.length > 0) {
        orderedAndCompletedHistory.push(filteredHistEl[0]);
        if (lastHistEl == null) {
          lastHistEl = filteredHistEl[0];
        } else {
          gapsCompleted += 1;
          lastHistEl = filteredHistEl[0];
        }
      } else {
        if (lastHistEl != null) {
          let newHistoryObj = structuredClone(lastHistEl);
          newHistoryObj.year = yearCivilYearMonthEl.year;
          newHistoryObj.civilYear = yearCivilYearMonthEl.civilYear;
          newHistoryObj.month = yearCivilYearMonthEl.month;
          newHistoryObj.historyDateMs = new Date();
          orderedAndCompletedHistory.push(newHistoryObj);
        }
      }
    }

    let oldHistory = selected.history.filter((el) => el.year != selected.year);
    if (oldHistory.length > 0) {
      selected.history = oldHistory.concat(orderedAndCompletedHistory);
    } else {
      selected.history = orderedAndCompletedHistory;
    }

    return true;
  },
  cmsTranportGetInvoices: function cmsTranportGetInvoices(scope, selected, data) {
    scope.$parent.showLoader();
    scope.genericFactory.setRouteName("CMS_Cluster_Charges");
    let map = {};
    let refYear = [new Date().getFullYear() - 1 + "", new Date().getFullYear() + ""];
    map.civilYear = refYear;
    // Rubrica "Transportes Especiais"
    map.chargeType = "6495c47e9de1a0009670b0e1";
    map.documentType = "Fatura";
    scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (invoices) {
      scope.genericFactory.setRouteName("Transp_CMS_Costs");

      let invoicesToInsertUpdate = [];

      invoices.forEach((invoiceEl) => {
        if (
          !checkIfInvoiceWasAlreadyInserted(
            invoiceEl.organization,
            invoiceEl.civilYear,
            invoiceEl.supplyMonth,
            invoiceEl.invoice,
            Math.round(invoiceEl.value * 100) / 100
          )
        ) {
          let invoiceCopy = structuredClone(invoiceEl);
          let newInvoiceObj = {};
          newInvoiceObj.organization = invoiceCopy.organization;
          newInvoiceObj.year = invoiceCopy.year;
          newInvoiceObj.civilYear = invoiceCopy.civilYear;
          newInvoiceObj.month = invoiceCopy.supplyMonth;
          newInvoiceObj.cluster = invoiceCopy.cluster;
          newInvoiceObj.school = invoiceCopy.school;
          newInvoiceObj.invoiceNumber = invoiceCopy.invoice;
          newInvoiceObj.invoiceValue = Math.round(invoiceCopy.value * 100) / 100;
          newInvoiceObj.invoiceMode = "create";
          newInvoiceObj.createdBy = { name: scope.currentUser.name, surname: scope.currentUser.surname };
          newInvoiceObj.createdAt = new Date().getTime();
          invoicesToInsertUpdate.push(newInvoiceObj);
        }
      });

      let numCreatedUpdatedRows = 0;
      async.eachSeries(
        invoicesToInsertUpdate,
        function iteratee(item, callback) {
          if (item.invoiceMode == "create") {
            scope.genericFactory.create(item).then(() => {
              numCreatedUpdatedRows += 1;
              callback();
            });
          } else {
            scope.genericFactory.modify(item.id, item).then(() => {
              numCreatedUpdatedRows += 1;
              callback();
            });
          }
        },
        function done() {
          if (numCreatedUpdatedRows == invoicesToInsertUpdate.length) {
            scope.getModuleData();
            scope.parentScope.$parent.alertCMSUpdatedInvoicesCosts();
            scope.$parent.hideLoader();
          }
        },
        function (err) {
          if (err) {
            console.log(err);
          } else {
            //Done
            scope.getModuleData();
            scope.$parent.hideLoader();
          }
        }
      );

      function checkIfInvoiceWasAlreadyInserted(organization, civilYear, month, invoiceNumber, invoiceValue) {
        let filteredAlreadyInsertedInvoice = data.filter(
          (inv) =>
            inv.organization == organization &&
            inv.civilYear == civilYear &&
            inv.month == month &&
            inv.invoiceNumber == invoiceNumber
        );
        if (filteredAlreadyInsertedInvoice.length > 0) {
          if (filteredAlreadyInsertedInvoice[0].invoiceValue != invoiceValue) {
            let updatedInvoiceObj = filteredAlreadyInsertedInvoice[0];
            updatedInvoiceObj.invoiceValue = invoiceValue;
            updatedInvoiceObj.invoiceMode = "modify";
            invoicesToInsertUpdate.push(updatedInvoiceObj);
          }
          return true;
        } else {
          return false;
        }
      }
    });
  },
  cmsSchoolComplexBuildingGetRequests: function cmsSchoolComplexBuildingGetRequests(scope, selected, data) {
    scope.$parent.showLoader();
    scope.genericFactory.setRouteName("CMS_Management_Control_Twelfths");
    let map = {};
    let refYear = [new Date().getFullYear() - 1 + "", new Date().getFullYear() + ""];
    map.civilYear = refYear;
    // Syb rubrica Conservacao 67
    map.chargeSubType = "64a82abf2bbdd5007cf28d1c";
    map.documentType = "Fatura";
    scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (invoices) {
      scope.genericFactory.setRouteName("School_Complex_CMS_Building_Requests");

      let invoicesToInsertUpdate = [];

      invoices.forEach((invoiceEl) => {
        if (
          !checkIfInvoiceWasAlreadyInserted(
            invoiceEl.organization,
            invoiceEl.civilYear,
            invoiceEl.month,
            invoiceEl.document,
            Math.round(invoiceEl.value * 100) / 100
          )
        ) {
          let invoiceCopy = structuredClone(invoiceEl);
          let newInvoiceObj = {};
          newInvoiceObj.organization = invoiceCopy.organization;
          newInvoiceObj.year = invoiceCopy.year;
          newInvoiceObj.civilYear = invoiceCopy.civilYear;
          newInvoiceObj.month = invoiceCopy.month;
          newInvoiceObj.cluster = invoiceCopy.cluster;
          newInvoiceObj.school = invoiceCopy.school;
          newInvoiceObj.document = invoiceCopy.document;
          newInvoiceObj.chargeType = invoiceCopy.chargeType;
          newInvoiceObj.chargeSubType = invoiceCopy.chargeSubType;
          newInvoiceObj.economicClassification = invoiceCopy.economicClassification;
          newInvoiceObj.economicClassificationDesc = invoiceCopy.economicClassificationDesc;
          newInvoiceObj.value = Math.round(invoiceCopy.value * 100) / 100;
          newInvoiceObj.invoiceMode = "create";
          newInvoiceObj.createdBy = { name: scope.currentUser.name, surname: scope.currentUser.surname };
          newInvoiceObj.createdAt = new Date().getTime();
          invoicesToInsertUpdate.push(newInvoiceObj);
        }
      });

      let numCreatedUpdatedRows = 0;
      async.eachSeries(
        invoicesToInsertUpdate,
        function iteratee(item, callback) {
          if (item.invoiceMode == "create") {
            scope.genericFactory.create(item).then(() => {
              numCreatedUpdatedRows += 1;
              callback();
            });
          } else {
            scope.genericFactory.modify(item.id, item).then(() => {
              numCreatedUpdatedRows += 1;
              callback();
            });
          }
        },
        function done() {
          if (numCreatedUpdatedRows == invoicesToInsertUpdate.length) {
            scope.getModuleData();
            scope.parentScope.$parent.alertCMSUpdatedBuildingRequests();
            scope.$parent.hideLoader();
          }
        },
        function (err) {
          if (err) {
            console.log(err);
          } else {
            //Done
            scope.getModuleData();
            scope.$parent.hideLoader();
          }
        }
      );

      function checkIfInvoiceWasAlreadyInserted(organization, civilYear, month, invoiceNumber, invoiceValue) {
        let filteredAlreadyInsertedInvoice = data.filter(
          (inv) =>
            inv.organization == organization &&
            inv.civilYear == civilYear &&
            inv.month == month &&
            inv.value == invoiceNumber
        );
        if (filteredAlreadyInsertedInvoice.length > 0) {
          if (filteredAlreadyInsertedInvoice[0].value != invoiceValue) {
            let updatedInvoiceObj = filteredAlreadyInsertedInvoice[0];
            updatedInvoiceObj.value = invoiceValue;
            updatedInvoiceObj.invoiceMode = "modify";
            invoicesToInsertUpdate.push(updatedInvoiceObj);
          }
          return true;
        } else {
          return false;
        }
      }
    });
  },
  cmsContractValueFromLastMonth: function cmsContractValueFromLastMonth(scope, selected, data) {
    scope.genericScope.$parent.showLoader();

    let selectedYear = Number(selected.year);

    let yearCivilYearMonthToCheck = [
      { year: selectedYear, civilYear: selectedYear, month: "setembro" },
      { year: selectedYear, civilYear: selectedYear, month: "outubro" },
      { year: selectedYear, civilYear: selectedYear, month: "novembro" },
      { year: selectedYear, civilYear: selectedYear, month: "dezembro" },
      { year: selectedYear, civilYear: selectedYear + 1, month: "janeiro" },
      { year: selectedYear, civilYear: selectedYear + 1, month: "fevereiro" },
      { year: selectedYear, civilYear: selectedYear + 1, month: "março" },
      { year: selectedYear, civilYear: selectedYear + 1, month: "abril" },
      { year: selectedYear, civilYear: selectedYear + 1, month: "maio" },
      { year: selectedYear, civilYear: selectedYear + 1, month: "junho" },
      { year: selectedYear, civilYear: selectedYear + 1, month: "julho" },
      { year: selectedYear, civilYear: selectedYear + 1, month: "agosto" },
    ];

    let indexYearCivilYearMonthToCheck = null;

    for (let i = 0; i < yearCivilYearMonthToCheck.length; i++) {
      const el = yearCivilYearMonthToCheck[i];
      if (el.year == selected.year && el.civilYear == selected.civilYear && el.month == selected.month) {
        indexYearCivilYearMonthToCheck = i - 1;
      }
    }

    if (indexYearCivilYearMonthToCheck >= 0) {
      let filteredDataEl = data.filter(
        (el) =>
          el.year == yearCivilYearMonthToCheck[indexYearCivilYearMonthToCheck].year &&
          el.civilYear == yearCivilYearMonthToCheck[indexYearCivilYearMonthToCheck].civilYear &&
          el.month == yearCivilYearMonthToCheck[indexYearCivilYearMonthToCheck].month &&
          el.cluster == selected.cluster &&
          el.school == selected.school &&
          el.transport == selected.transport
      );

      if (filteredDataEl.length > 0) {
        filteredDataEl = filteredDataEl[0];
        if (filteredDataEl.contractNextMonthValue != null && !isNaN(Number(filteredDataEl.contractNextMonthValue))) {
          selected.contractInitialValue = filteredDataEl.contractNextMonthValue;
          scope.genericScope.$parent.hideLoader();
        } else if (
          filteredDataEl.contractInitialValue != null &&
          !isNaN(Number(filteredDataEl.contractInitialValue)) &&
          filteredDataEl.invoiceValue != null &&
          !isNaN(Number(filteredDataEl.invoiceValue))
        ) {
          selected.contractInitialValue =
            Math.round((filteredDataEl.contractInitialValue - filteredDataEl.invoiceValue) * 100) / 100;
          scope.genericScope.$parent.hideLoader();
        }
      }
    } else {
      scope.genericScope.$parent.hideLoader();
      return 0;
    }
  },
  kstkSetRecordHistoryByChange: function kstkSetRecordHistoryByChange(scope, selected, data) {
    if (scope.originalSelected != null && Object.keys(scope.originalSelected).length) {
      // Check if object was changed
      let originalSelectedExceptHistory = structuredClone(scope.originalSelected);
      delete originalSelectedExceptHistory.history;
      delete originalSelectedExceptHistory.lastModification;

      // Parse selected dates to numbers
      convertDatesOfObjectToMs(originalSelectedExceptHistory);

      let selectedExceptHistory = structuredClone(selected);
      delete selectedExceptHistory.history;
      delete selectedExceptHistory.lastModification;

      // Parse selected dates to numbers
      convertDatesOfObjectToMs(selectedExceptHistory);

      if (
        JSON.stringify(Object.entries(originalSelectedExceptHistory).sort()).replace(
          /,?"\$\$hashKey",?:?"[^"]*"/g,
          ""
        ) !== JSON.stringify(Object.entries(selectedExceptHistory).sort()).replace(/,?"\$\$hashKey",?:?"[^"]*"/g, "")
      ) {
        if (selected.history == null) {
          selected.history = [];
          selected.history.push(prepareHistoryObj(originalSelectedExceptHistory));
        }
        let newHistoryObj = prepareHistoryObj(selectedExceptHistory);
        selected.history.push(newHistoryObj);
      }

      function prepareHistoryObj(obj) {
        let newHistoryObj = structuredClone(obj);
        let historyDate = new Date(
          new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate()
        );
        newHistoryObj.historyDateMs = historyDate.getTime();
        newHistoryObj.historyDateCivilYear = historyDate.getFullYear() + "";
        newHistoryObj.historyDateMonth = getMonthFromDateMs(historyDate);
        newHistoryObj.historyEditorId = scope.currentUser.id;
        newHistoryObj.historyEditorName = scope.currentUser.name + " " + scope.currentUser.surname;

        return newHistoryObj;
      }
    }
  },
  kstkSetRecordHistoryByMonth: function kstkSetRecordHistoryByMonth(scope, selected, data) {
    if (selected.history == null) {
      selected.history = [];
    }
    let monthHistoryNotSetFlag = true;
    let newHistoryObj = JSON.parse(JSON.stringify(selected));
    delete newHistoryObj.history;
    let historyDate = new Date();
    newHistoryObj.historyDateMs = historyDate.getTime();
    newHistoryObj.historyDateCivilYear = historyDate.getFullYear() + "";
    newHistoryObj.historyDateMonth = getMonthFromDateMs(historyDate);
    newHistoryObj.historyEditorId = scope.currentUser.id;
    newHistoryObj.historyEditorName = scope.currentUser.name + " " + scope.currentUser.surname;

    if (selected.history.length == 0) {
      selected.history.push(newHistoryObj);
    } else {
      for (let j = 0; j < selected.history.length; j++) {
        let historyEl = selected.history[j];
        if (
          historyEl.historyDateCivilYear == newHistoryObj.historyDateCivilYear &&
          historyEl.historyDateMonth == newHistoryObj.historyDateMonth
        ) {
          selected.history[j] = newHistoryObj;
          monthHistoryNotSetFlag = false;
          break;
        }
      }
      if (monthHistoryNotSetFlag) {
        selected.history.push(newHistoryObj);
      }
    }
  },
  cmsNonTeachDescSplitMobility: function cmsNonTeachDescSplitMobility(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.mobilityType == null) {
        data.splice(i, 1);
      } else {
        i++;
      }
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  cmsNonTeachDescSplitAbsences: function cmsNonTeachDescSplitAbsences(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.absences != null && Array.isArray(el.absences) && el.absences.length > 0) {
        for (let w = 0; w < el.absences.length; w++) {
          let absences = el.absences[w];
          let newElement = JSON.parse(JSON.stringify(el));
          newElement.type = absences.type;
          newElement.absenceType = absences.absenceType;
          newElement.startDateMs = absences.startDateMs;
          newElement.endDateMs = absences.endDateMs;
          newElement.returnDateMs = absences.returnDateMs;
          newElement.splitFlag = true;
          /*  cleanNewElement(newElement); */
          data.push(newElement);
        }
      }

      data.splice(i, 1);
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  cmsSchoolMilkProvisionDeliveredMilkSplit: function cmsSchoolMilkProvisionDeliveredMilkSplit(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.splitFlag) {
        break;
      }
      if (el.deliveredMilk != null && Array.isArray(el.deliveredMilk) && el.deliveredMilk.length > 0) {
        for (let w = 0; w < el.deliveredMilk.length; w++) {
          let delivery = el.deliveredMilk[w];
          let newElement = JSON.parse(JSON.stringify(el));
          newElement.deliveryDesc = delivery.deliveryDesc;
          newElement.deliveryDateMs = delivery.deliveryDateMs;
          newElement.delPlainMilkPacks = delivery.deliveredPlainMilkNumberPacks;
          newElement.delPlainMilkBoxes = delivery.deliveredPlainMilkNumberBoxes;
          newElement.delChocMilkPacks = delivery.deliveredChocolateMilkNumberPacks;
          newElement.delChocMilkBoxes = delivery.deliveredChocolateMilkNumberBoxes;
          newElement.delLacFreeMilkPacks = delivery.deliveredLactoseFreeMilkNumberPacks;
          newElement.delLacFreeMilkBoxes = delivery.deliveredLactoseFreeMilkNumberBoxes;
          newElement.docType = delivery.docType;
          newElement.invoiceNumber = delivery.invoiceNumber;
          newElement.invoiceValue = delivery.invoiceValue;
          newElement.splitFlag = true;
          cleanNewElement(newElement);
          data.push(newElement);
        }
      }
      data.splice(i, 1);
    }
    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }
    return data;
  },
  cmsSchoolMilkProvisionDeliveredMilkExpDateAlertsSplit: function cmsSchoolMilkProvisionDeliveredMilkSplit(
    scope,
    data
  ) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.splitFlag) {
        break;
      }
      if (el.deliveredMilk != null && Array.isArray(el.deliveredMilk) && el.deliveredMilk.length > 0) {
        for (let w = 0; w < el.deliveredMilk.length; w++) {
          let delivery = el.deliveredMilk[w];
          let newElement = JSON.parse(JSON.stringify(el));
          let refDateMs = new Date().getTime();
          newElement.expDateAlerts = "";
          newElement.deliveryDesc = delivery.deliveryDesc;
          newElement.deliveryDateMs = delivery.deliveryDateMs;
          newElement.deliveredPlainMilkExpDateMs = delivery.deliveredPlainMilkExpDateMs;
          if (
            refDateMs > newElement.deliveredPlainMilkExpDateMs &&
            Math.round((refDateMs - newElement.deliveredPlainMilkExpDateMs) / 86400000) < 31
          ) {
            newElement.expDateAlerts += "Leite simples;";
          }
          newElement.deliveredChocolateMilkExpDateMs = delivery.deliveredChocolateMilkExpDateMs;
          if (
            refDateMs > newElement.deliveredChocolateMilkExpDateMs &&
            Math.round((refDateMs - newElement.deliveredChocolateMilkExpDateMs) / 86400000) < 31
          ) {
            newElement.expDateAlerts += "Leite achocolatado;";
          }
          newElement.deliveredLactoseFreeMilkExpDateMs = delivery.deliveredLactoseFreeMilkExpDateMs;
          if (
            refDateMs > newElement.deliveredLactoseFreeMilkExpDateMs &&
            Math.round((refDateMs - newElement.deliveredLactoseFreeMilkExpDateMs) / 86400000) < 31
          ) {
            newElement.expDateAlerts += "Leite sem lactose;";
          }
          newElement.invoiceNumber = delivery.invoiceNumber;
          newElement.invoiceValue = delivery.invoiceValue;
          newElement.splitFlag = true;
          cleanNewElement(newElement);
          data.push(newElement);
        }
      }
      data.splice(i, 1);
    }
    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }
    return data;
  },
  cmsSchoolMilkProvisionInvoiceSplit: function cmsSchoolMilkProvisionInvoiceSplit(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.splitFlag) {
        break;
      }
      if (el.deliveredMilk != null && Array.isArray(el.deliveredMilk) && el.deliveredMilk.length > 0) {
        for (let w = 0; w < el.deliveredMilk.length; w++) {
          let delivery = el.deliveredMilk[w];
          if (delivery.invoiceNumber != null && delivery.invoiceValue != null && delivery.deliveryDateMs != null) {
            let newElement = JSON.parse(JSON.stringify(el));
            newElement.invoiceNumber = delivery.invoiceNumber;
            newElement.invoiceValue = delivery.invoiceValue;
            newElement.invoiceDate = delivery.deliveryDateMs;
            newElement.splitFlag = true;
            cleanNewElement(newElement);
            data.push(newElement);
          }
        }
      }
      if (el.invoices != null && Array.isArray(el.invoices) && el.invoices.length > 0) {
        for (let w = 0; w < el.invoices.length; w++) {
          let invoice = el.invoices[w];
          let newElement = JSON.parse(JSON.stringify(el));
          newElement.invoiceNumber = invoice.invoiceNumber;
          newElement.invoiceValue = invoice.invoiceValue;
          newElement.invoiceDate = invoice.invoiceDate;
          newElement.splitFlag = true;
          cleanNewElement(newElement);
          data.push(newElement);
        }
      }
      data.splice(i, 1);
    }
    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }
    return data;
  },
  vfxArtsAttendanceSplit: function vfxArtsAttendanceSplit(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.splitFlag) {
        break;
      }

      if (el.attendance != null && Array.isArray(el.attendance) && el.attendance.length > 0) {
        for (let w = 0; w < el.attendance.length; w++) {
          let att = el.attendance[w];
          let newElement = JSON.parse(JSON.stringify(el));

          let refDate = new Date(att.dayMs);
          let refCreatedBy = JSON.parse(JSON.stringify(newElement.createdBy));

          newElement.professor = refCreatedBy.name + " " + refCreatedBy.surname;
          newElement.day =
            refDate.getDate().toString().length == 1
              ? "0" + refDate.getDate().toString()
              : refDate.getDate().toString();
          newElement.month = getMonthWithNumberBefore(getMonthFromDateMs(refDate));
          newElement.classType = att.classType;
          newElement.missedClassReasonProfessor = att.missedClassReasonProfessor;
          newElement.missedClassReasonCluster = att.missedClassReasonCluster;
          newElement.classReplacementDayMs = att.classReplacementDayMs
            ? new Date(att.classReplacementDayMs).toLocaleDateString()
            : null;
          newElement.summary = att.summary;
          newElement.situation = att.situation;
          newElement.comment = att.comment;

          newElement.splitFlag = true;
          cleanNewElement(newElement);
          data.push(newElement);
        }
      }

      data.splice(i, 1);
    }
    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }
    return data;
  },
  classPlanHonorBoardSplit: function classPlanHonorBoardSplit(scope, data) {
    let now = new Date();
    let parsedDate = now.getUTCDate() + " de " + getMonthFromDateMs(now.getTime()) + " de " + now.getFullYear();
    for (let i = 0; i < data.length; ) {
      let el = data[i];
      if (el.splitFlag) {
        break;
      }
      if (el.honorBoard != null && Array.isArray(el.honorBoard) && el.honorBoard.length > 0) {
        for (let w = 0; w < el.honorBoard.length; w++) {
          let hBoard = el.honorBoard[w];
          if (
            hBoard.honorBoardPrizes != null &&
            Array.isArray(hBoard.honorBoardPrizes) &&
            hBoard.honorBoardPrizes.length > 0
          ) {
            for (let w = 0; w < hBoard.honorBoardPrizes.length; w++) {
              let prize = hBoard.honorBoardPrizes[w];
              let newElement = JSON.parse(JSON.stringify(el));
              newElement.parsedHonorBoardPrizeType = "Valor";
              newElement.parsedHonorBoardPrize = prize;
              newElement.period = hBoard.period;
              newElement.prizeDesc = "Integrou o Quadro de Valor";
              newElement.parsedDate = parsedDate;
              // AEPA
              if (newElement.organization == "5d9be46d32273900a0187100") {
                newElement.locality = "Póvoa de Santo Adrião";
                newElement.parsedDate = "16 de outubro de " + now.getFullYear();
              } //AEAA
              else if (newElement.organization == "59f069bdb0c8f16d4e0a527c") {
                newElement.locality = "Castanheira do Ribatejo";
              }
              newElement.valuePrize = true;
              newElement.splitFlag = true;
              cleanNewElement(newElement);
              data.push(newElement);
            }
          }

          if (
            hBoard.honorBoardSchoolRecordcheckbox &&
            hBoard.honorBoardSchoolRecordcheckbox[""] &&
            hBoard.honorBoardSchoolRecordcheckbox[""] == true
          ) {
            let newElement = JSON.parse(JSON.stringify(el));
            newElement.parsedHonorBoardPrizeType = "Excelência";
            newElement.parsedHonorBoardPrize = "Excelência";
            newElement.parsedHonorBoardPrize = "Excelência";
            newElement.period = hBoard.period;
            newElement.recordAvg = hBoard.honorBoardSchoolRecordAvg;
            newElement.prizeDesc = "Integrou o Quadro de Excelência da Turma";
            newElement.parsedDate = parsedDate;
            if (newElement.organization == "5d9be46d32273900a0187100") {
              newElement.locality = "Póvoa de Santo Adrião";
              newElement.parsedDate = "16 de outubro de " + now.getFullYear();
            } else if (newElement.organization == "59f069bdb0c8f16d4e0a527c") {
              newElement.locality = "Castanheira do Ribatejo";
            }
            newElement.splitFlag = true;
            cleanNewElement(newElement);
            data.push(newElement);
          }
        }
      }
      data.splice(i, 1);
    }
    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }
    return data;
  },
  kstkChargeTransports: function kstkChargeTransports(scope, selected) {
    if (scope.mode == "modify") {
      return new Promise((resolve, reject) => {
        var confirm = scope.$mdDialog
          .confirm()
          .title("Replicar Utilizações")
          .textContent("Deseja replicar as utilizações registadas para os restantes registos do mesmo circuito?")
          .ariaLabel("Replicar Utilizações")
          .ok("Não")
          .cancel("Sim")
          .clickOutsideToClose(true);

        scope.$mdDialog.show(confirm).then(
          function () {
            resolve(true);
          },
          function () {
            if (
              selected.chargeHistory != null &&
              Array.isArray(selected.chargeHistory) &&
              selected.chargeHistory.length > 0
            ) {
              //Get new charged months
              let newChargedMonthsEvents = [];

              /* if (scope.orginalSelected.chargeHistory != null && Array.isArray(scope.orginalSelected.chargeHistory) && scope.orginalSelected.chargeHistory.length > 0) {
                for (let k = 0; k < selected.chargeHistory.length; k++) {
                  let selectedChargeEvent = selected.chargeHistory[k];
                  let newFlag = true;
                  for (let j = 0; j < scope.orginalSelected.chargeHistory.length; j++) {
                    let originalChargeEvent = scope.orginalSelected.chargeHistory[j];
                    if (originalChargeEvent.month == selectedChargeEvent.month) {
                      newFlag = false;
                      break;
                    }
                  }
                  if (newFlag) {
                    newChargedMonthsEvents.push(selectedChargeEvent);
                  }
                }
              } */

              newChargedMonthsEvents = selected.chargeHistory;

              if (newChargedMonthsEvents.length > 0) {
                scope.parentScope.$parent.showLoader();
                let toUpdateRows = [];
                let numUpdatedRows = 0;

                //Get toUpdateRows
                for (let i = 0; i < scope.genericScope.gridOptions.data.length; i++) {
                  let element = scope.genericScope.gridOptions.data[i];
                  if (
                    selected.year == element.year &&
                    selected.parsedTicket == element.parsedTicket &&
                    selected.id != element.id
                  ) {
                    //Verify if monthly cost was already charged
                    for (let k = 0; k < newChargedMonthsEvents.length; k++) {
                      let newChargeEvent = newChargedMonthsEvents[k];
                      let newFlag = true;
                      if (element.chargeHistory) {
                        for (let j = 0; j < element.chargeHistory.length; j++) {
                          let elementChargeEvent = element.chargeHistory[j];
                          if (elementChargeEvent.month == newChargeEvent.month) {
                            newFlag = false;
                            break;
                          }
                        }
                      } else {
                        element.chargeHistory = newChargedMonthsEvents;
                        break;
                      }
                      if (newFlag) {
                        element.chargeHistory.push(newChargeEvent);
                      }
                    }
                    toUpdateRows.push(element);
                  }
                }

                scope.parentScope.$parent.hideLoader();
                scope.parentScope.$parent.showDeterminateLoader(toUpdateRows.length);
                async.eachSeries(
                  toUpdateRows,
                  function iteratee(item, callback) {
                    scope.genericFactory.modify(item.id, item).then(() => {
                      numUpdatedRows += 1;
                      scope.parentScope.$parent.updateDeterminateLoader();
                      callback();
                    });
                  },
                  function done() {
                    if (numUpdatedRows == toUpdateRows.length) {
                      scope.parentScope.$parent.alertKSTKTransportChargesUpdatedCharges(numUpdatedRows);
                      scope.parentScope.$parent.hideLoader();
                    }
                  },
                  function (err) {
                    if (err) {
                      console.log(err);
                    } else {
                      //Done
                      console.log("done");
                    }
                  }
                );
                scope.parentScope.$parent.hideLoader();
                resolve(true);
              } else {
                // No new chargedMonths
                scope.parentScope.$parent.alertKSTKTransportChargesNoNewChargedMonths();
                resolve(false);
              }
            } else {
              // No new chargedMonths
              scope.parentScope.$parent.alertKSTKTransportChargesNoNewChargedMonths();
              resolve(false);
            }
          }
        );
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    }
  },
  logsSplitRecords: function logsSplitRecords(scope, data) {
    scope.clientsFactory.getByProperty("organization", scope.currentUser.organization).then(function (clients) {
      let oneMonthInMs = 1000 * 60 * 60 * 24 * 60;
      let nowInMs = new Date().getTime();
      let cachedOrgClients = {};
      for (let i = 0; i < data.length; ) {
        let el = data[i];

        if (nowInMs - new Date(el.date).getTime() > oneMonthInMs) {
          data.splice(i, 1);
          continue;
        }

        if (cachedOrgClients[el.who] != null && cachedOrgClients[el.who] == true) {
          data.splice(i, 1);
          continue;
        } else {
          let filteredClient = clients.filter(
            (client) => client.email == el.who && client.organization == el.organization
          );
          if (Array.isArray(filteredClient) && filteredClient.length > 0) {
            filteredClient = filteredClient[0];
            if (filteredClient.isKSTK == true) {
              cachedOrgClients[el.who] = filteredClient.isKSTK;
              data.splice(i, 1);
              continue;
            }
          }
        }

        switch (el.type) {
          case "module view":
            el.type = "Visita a módulo";
            break;
          case "report view":
            el.type = "Visita a relatório";
            break;
          case "report page view":
            el.type = "Visita a página de relatório";
            break;
          case "login":
            el.type = "Login";
            break;
          default:
            break;
        }

        el.dateInMs = new Date(JSON.parse(JSON.stringify(el.date))).getTime();
        el.date = new Date(el.date).toLocaleDateString();
        i++;
      }

      data.sort(function (a, b) {
        if (a.dateInMs > b.dateInMs) {
          return -1;
        }
        if (a.dateInMs < b.dateInMs) {
          return 1;
        }
      });

      return data;
    });
  },
  cmoLoadSchoolComplexSpaces: function cmoLoadSchoolComplexSpaces(scope, selected, data) {
    if (selected.school) {
      scope.parentScope.$parent.showLoader();
      let i = scope.deps.indexOf("KSTK_School_Complex_Areas");
      if (i != -1) {
        let fromTableData = JSON.parse(JSON.stringify(scope.depsData))[i].filter(
          (o) => o.organization == scope.currentUser.organization && o.school == selected.school
        );
        selected.schoolComplexSpaces = fromTableData;
        selected.schoolComplexSpaces.forEach((el) => {
          for (const field in el) {
            if (field != "functionalArea" && field != "space") {
              delete el[field];
            }
          }
        });
        scope.parentScope.$parent.hideLoader();
      }
    }
  },
  cmsSchoolComplexGetBuildings: function cmsSchoolComplexGetBuildings(scope, selected, data) {
    scope.parentScope.$parent.showLoader();
    scope.genericFactory.setRouteName("School_Complex_CMS_Buildings");
    let map = {};
    map.cluster = selected.cluster;
    map.school = selected.school;
    scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (buildings) {
      selected.spaceArea = null;
      selected.envolvedBuildings = buildings.map((build) => {
        return {
          id: build.id,
          building: build.building,
          parsedLabel: build.building,
        };
      });
      scope.genericFactory.setRouteName(scope.module.collection);
      scope.parentScope.$parent.hideLoader();
    });
  },
  cmsSchoolComplexGetSpaceAreas: function cmsSchoolComplexGetSpaceAreas(scope, selected, data) {
    scope.parentScope.$parent.showLoader();
    scope.genericFactory.setRouteName("School_Complex_CMS_Areas");
    let map = {};
    map.building = selected.building;
    scope.genericFactory.getByProperties(map, scope.currentUser.organization).then(function (areas) {
      selected.envolvedSpaceAreas = areas.map((area) => {
        return {
          id: area.id,
          spaceArea: area.area + " - " + area.spaceArea,
        };
      });
      scope.genericFactory.setRouteName(scope.module.collection);
      scope.parentScope.$parent.hideLoader();
    });
  },
  cmsSchoolComplexBuildingManagementSplitCosts: function cmsSchoolComplexBuildingManagementSplitCosts(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.materials != null && Array.isArray(el.materials) && el.materials.length > 0) {
        for (let w = 0; w < el.materials.length; w++) {
          let cost = el.materials[w];
          if (cost.materialCostWithIVA != null) {
            let newElement = JSON.parse(JSON.stringify(el));
            newElement.costType = "Materiais";
            newElement.cost = cost.materialCostWithIVA;
            newElement.splitFlag = true;
            cleanNewElement(newElement);
            data.push(newElement);
          }
        }
      }

      if (
        el.machinesAndVehicles != null &&
        Array.isArray(el.machinesAndVehicles) &&
        el.machinesAndVehicles.length > 0
      ) {
        for (let w = 0; w < el.machinesAndVehicles.length; w++) {
          let machineVehicle = el.machinesAndVehicles[w];
          if (machineVehicle.kmTotalCost != null) {
            let newElement = JSON.parse(JSON.stringify(el));
            newElement.costType = "Máquinas e viaturas";
            newElement.cost = machineVehicle.kmTotalCost;
            if (machineVehicle.km != null) {
              newElement.descCost = "Nº kms";
              newElement.descCostValue = machineVehicle.km;
            }
            newElement.splitFlag = true;
            cleanNewElement(newElement);
            data.push(newElement);
          }

          if (machineVehicle.hourTotalCost != null) {
            let newElement = JSON.parse(JSON.stringify(el));
            newElement.costType = "Máquinas e viaturas";
            newElement.cost = machineVehicle.hourTotalCost;
            if (machineVehicle.numberHours != null) {
              newElement.descCost = "Nº horas";
              newElement.descCostValue = machineVehicle.numberHours;
            }
            newElement.splitFlag = true;
            cleanNewElement(newElement);
            data.push(newElement);
          }
        }
      }

      if (el.labor != null && Array.isArray(el.labor) && el.labor.length > 0) {
        for (let w = 0; w < el.labor.length; w++) {
          let lab = el.labor[w];
          if (lab.totalCost != null) {
            let newElement = JSON.parse(JSON.stringify(el));
            newElement.costType = "Mão de obra";
            newElement.cost = lab.totalCost;
            if (lab.numberHours != null) {
              newElement.descCost = "Nº horas";
              newElement.descCostValue = lab.numberHours;
            }
            newElement.splitFlag = true;
            cleanNewElement(newElement);
            data.push(newElement);
          }
        }
      }

      if (el.administrativeCost != null) {
        let newElement = JSON.parse(JSON.stringify(el));
        newElement.costType = "Despesas administrativas";
        newElement.cost = el.administrativeCost;
        newElement.splitFlag = true;
        cleanNewElement(newElement);
        data.push(newElement);
      }

      if (el.totalCost != null) {
        let newElement = JSON.parse(JSON.stringify(el));
        newElement.costType = "Total";
        newElement.cost = el.totalCost;
        newElement.splitFlag = true;
        cleanNewElement(newElement);
        data.push(newElement);
      }

      data.splice(i, 1);
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  leanGameSendComplaintsToPlayers: function leanGameSendComplaintsToPlayers(scope, selected, data) {
    if (selected.id == null) {
      scope.genericScope.$parent.alertLeanGameSaveBeforeSendingComplaints();
      return;
    }
    scope.genericScope.$parent.showLoader();
    let numberOfComplaints = selected.complaint ? selected.complaint.length : 0;

    // Set up round specific complaints info

    if (selected.complaintInfo == null) {
      selected.complaintInfo = {};
    }
    if (selected.complaintInfo[selected.round] == null) {
      selected.complaintInfo[selected.round] = {};
      selected.complaintInfo[selected.round].availableComplaints = selected.complaint.slice();
      selected.complaintInfo[selected.round].sentComplaints = [];
    }

    let numberOfSentComplaints = selected.complaintInfo[selected.round].sentComplaints
      ? selected.complaintInfo[selected.round].sentComplaints.length
      : 0;

    // Check if all complaints where sent
    if (numberOfComplaints == numberOfSentComplaints) {
      scope.genericScope.$parent.alertLeanGameAllComplaintsSent();
      scope.parentScope.$parent.hideLoader();
      return;
    }

    // Check if it is possible to send the number of selected complaints
    if (numberOfComplaints - selected.numberComplaintsToSend - numberOfSentComplaints < 0) {
      scope.genericScope.$parent.alertLeanGameNumberOfAvailableComplaints(numberOfComplaints - numberOfSentComplaints);
      scope.parentScope.$parent.hideLoader();
      return;
    }

    // Create set of complaints to send
    let complaintsToSend = [];
    for (let c = 1; c < selected.numberComplaintsToSend + 1; c++) {
      let complaintToSend =
        selected.complaintInfo[selected.round].availableComplaints[
          Math.floor(Math.random() * selected.complaintInfo[selected.round].availableComplaints.length)
        ];
      selected.complaintInfo[selected.round].availableComplaints.splice(
        selected.complaintInfo[selected.round].availableComplaints.indexOf(complaintToSend),
        1
      );
      selected.complaintInfo[selected.round].availableComplaints =
        selected.complaintInfo[selected.round].availableComplaints.slice();
      complaintsToSend.push(complaintToSend);
      selected.complaintInfo[selected.round].sentComplaints.push(complaintToSend);
    }
    scope.genericFactory.modify(selected.id, selected).then((el) => {});

    scope.genericFactory.setRouteName("Lean_Games");
    let sentComplaints = 0;
    for (let l = 1; l < selected.numberOfTeams + 1; l++) {
      const team = l;
      complaintsToSend.forEach((complaint) => {
        let filteredComplaint = scope.getFromTableData("Lean_Game_Complaints").filter((el) => el.id == complaint);
        let complaintToSend = {};
        complaintToSend.organization = scope.currentUser.organization;
        complaintToSend.game = selected.id;
        complaintToSend.team = team.toString();
        complaintToSend.round = selected.round;
        complaintToSend.gameDuration = selected.gameDuration;
        if (filteredComplaint != null && Array.isArray(filteredComplaint) && filteredComplaint.length > 0) {
          complaintToSend.department = filteredComplaint[0].department;
          complaintToSend.channel = filteredComplaint[0].channel;
          complaintToSend.anonymous =
            filteredComplaint[0].customer == null || filteredComplaint[0].customer == "" ? "No" : "Yes";
          complaintToSend.customer = filteredComplaint[0].customer;
        }
        complaintToSend.complaint = complaint;
        complaintToSend.status = "Open";
        complaintToSend.timingIn = new Date().getTime();
        sentComplaints += 1;
        scope.genericFactory.create(complaintToSend).then((el) => {});
      });
    }

    scope.genericScope.$parent.alertLeanGameComplaintsSent(sentComplaints);
    scope.genericFactory.setRouteName("Lean_Game_Configurations");
    scope.parentScope.$parent.hideLoader();
  },
  leanGameSaveGameComplaint: function leanGameSaveGameComplaint(scope, selected, data) {
    let currentUserRole = scope.currentUser.role
      ? scope.getFromTableData("Lean_Game_Roles").filter((el) => el.id == scope.currentUser.role)[0].role
      : null;

    if (scope.currentUser.profile == "user") {
      // Game duration check
      if (scope.data.length > 0) {
        let timingInFirstComplaint = new Date().getTime();
        scope.data.forEach((dataEl) => {
          if (dataEl.timingIn < timingInFirstComplaint) {
            timingInFirstComplaint = dataEl.timingIn;
          }
        });
        let gameFinishedFlag = new Date().getTime() > selected.gameDuration * 60000 + timingInFirstComplaint;
        if (gameFinishedFlag) {
          scope.genericScope.$parent.alertLeanGameGameFinished();
          return false;
        }
      }

      // Status registered as Employee Customer Service

      if (selected.status == "Registered" && currentUserRole != "Employee Customer Service") {
        scope.genericScope.$parent.alertLeanGameRegisteredWrongRole();
        return false;
      }

      // Internal feedback is mandatory if the response category is 'Escalated to Board of Directors'

      if (
        selected.responseCategory == "Escalated to Board of Directors" &&
        (selected.internalFeedback == "" || selected.internalFeedback == null)
      ) {
        scope.genericScope.$parent.alertLeanGameInternalFeedbackMandatory();
        return false;
      }

      // Customer feedback is required before closing the complaint

      if (selected.status == "Closed" && (selected.feedback == "" || selected.feedback == null)) {
        scope.genericScope.$parent.alertLeanGameFeedbackMandatoryWhenClosing();
        return false;
      }
    }

    // Last modification
    selected.lastModification =
      new Date().getHours() +
      ":" +
      (new Date().getMinutes() > 9 ? new Date().getMinutes() : "0" + new Date().getMinutes()) +
      " ; " +
      (scope.currentUser.role == null ? "Admin" : currentUserRole);

    // Timing out according to complaint status
    if (selected.status != "Closed") {
      selected.timingOut = null;
      selected.parsedTimingOut = null;
    }
    if (selected.status == "Closed" && selected.timingOut == null) {
      selected.timingOut = new Date().getTime();
    }
  },
  leanGameSplitComplaints: function leanGameSplitComplaints(scope, data) {
    if (scope.currentUser.profile == "user") {
      // If the user does not have a game session associated or the game session is invalid, data should not be displayed
      if (
        scope.currentUser.game == null ||
        scope.currentUser.game == "" ||
        (scope.currentUser.game != null &&
          scope.getFromTableData("Lean_Game_Configurations").filter((el) => el.id == scope.currentUser.game).length ==
            0)
      ) {
        data.length = 0;
        return data;
      }

      let currentUserRole = scope.currentUser.role
        ? scope.getFromTableData("Lean_Game_Roles").filter((el) => el.id == scope.currentUser.role)[0].role
        : null;
      let currentActiveRound = scope.currentUser.game
        ? scope.getFromTableData("Lean_Game_Configurations").filter((el) => el.id == scope.currentUser.game)[0].round
        : null;

      for (let i = 0; i < data.length; ) {
        let el = data[i];

        let removedFlag = false;

        // Experts and QMs can only see Registered and Closed complaints
        /* if (
          (currentUserRole.indexOf("Expert") != -1 || currentUserRole == "Quality Manager") &&
          el.status != "Registered" &&
          el.status != "Closed"
        ) {
          removedFlag = true;
          data.splice(i, 1);
        } */

        // Filter complaints that are only envolved in the game active round
        if (!removedFlag && currentActiveRound != el.round) {
          removedFlag = true;
          data.splice(i, 1);
        }
        if (!removedFlag) {
          i += 1;
        }
      }
    } else {
      return data;
    }
  },
  refreshData: function refreshData(scope, args) {
    scope.getModuleData();
    scope.disabledCustomButtonFunctions.push("refreshData");
    setTimeout(function () {
      scope.disabledCustomButtonFunctions.splice(scope.disabledCustomButtonFunctions.indexOf("refreshData"), 1);
    }, 5000);
  },
  loadSchoolEntClusterInfo: function loadSchoolEntClusterInfo(scope, selected, data) {
    let selectedCluster;
    selectedCluster = selected.cluster[0];
    scope.parentScope.$parent.showLoader();
    let i = scope.deps.indexOf("KSTK_School_Contacts");
    if (i != -1) {
      let fromTableData = scope.depsData[i].filter(
        (o) =>
          o.organization == scope.currentUser.organization &&
          o.cluster == selectedCluster &&
          o.year == selected.year &&
          o.hq == "Sim"
      );
      fromTableData = fromTableData[0];

      selected.address = fromTableData.address;
      selected.nipc = fromTableData.nipc;
      selected.phoneNumber = fromTableData.phoneNumber;
      selected.email = fromTableData.email;
      selected.website = fromTableData.webAddress;

      if (fromTableData.schoolAdmin != null && fromTableData.schoolAdmin.length > 0) {
        let presidentEntityHeadInfo = fromTableData.schoolAdmin.filter((el) => el.position == "Diretor/a");
        if (presidentEntityHeadInfo.length > 0) {
          selected.entityHeads = presidentEntityHeadInfo;
        }
      }
    }
    scope.parentScope.$parent.hideLoader();
  },
  getEPECalendarsSums: function getEPECalendarsSums(scope, selected) {
    if (
      selected.periodOfUseCalendars != null &&
      Array.isArray(selected.periodOfUseCalendars) &&
      selected.periodOfUseCalendars.length > 0
    ) {
      let teachingPeriodPauses = [];
      let periodOfUseDays = [];

      let calendars = scope.getFromTableData("KSTK_Calendars");

      selected.periodOfUseCalendars.forEach((el) => {
        if (
          el.periodOfUseStartDateMs != null &&
          el.periodOfUseEndDateMs != null &&
          el.periodOfUseEndDateMs >= el.periodOfUseStartDateMs
        ) {
          let calendarSums =
            el.periodOfUseExclusiveDays != null && el.periodOfUseExclusiveDays.length > 0
              ? getEPETeachingPeriodPauses(
                  el,
                  calendars,
                  el.periodOfUseStartDateMs,
                  el.periodOfUseEndDateMs,
                  true,
                  true
                )
              : getEPETeachingPeriodPauses(
                  el,
                  calendars,
                  el.periodOfUseStartDateMs,
                  el.periodOfUseEndDateMs,
                  true,
                  true,
                  true
                );

          teachingPeriodPauses = teachingPeriodPauses.concat(calendarSums.addedTeachingPeriodPauses);
          periodOfUseDays = periodOfUseDays.concat(calendarSums.addedPeriodUseDays);
        }
      });

      let uniqueTeachingPeriodPauses = [...new Set(teachingPeriodPauses)];
      let uniquePeriodOfUseDays = [...new Set(periodOfUseDays)];

      /* selected.sumTeachingPeriodPauses = uniqueTeachingPeriodPauses.length; */
      selected.sumPeriodOfUse = uniquePeriodOfUseDays.length;
      if (selected.sumPeriodOfUse > 0) {
        selected.sumTeachingPeriodPauses = 365 - selected.sumPeriodOfUse;
      }
    }
  },
  classPlanHonorBoardSplitPrizes: function classPlanHonorBoardSplitPrizes(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.splitFlag) {
        break;
      }

      if (el.honorBoardPrizes != null && Array.isArray(el.honorBoardPrizes) && el.honorBoardPrizes.length > 0) {
        for (let w = 0; w < el.honorBoardPrizes.length; w++) {
          let prize = el.honorBoardPrizes[w];

          let newElement = JSON.parse(JSON.stringify(el));
          newElement.parsedHonorBoardPrizeType = "Valor";
          newElement.parsedHonorBoardPrize = prize;
          newElement.splitFlag = true;
          cleanNewElement(newElement);
          data.push(newElement);
        }
      }

      if (
        el.honorBoardSchoolRecordcheckbox &&
        el.honorBoardSchoolRecordcheckbox[""] &&
        el.honorBoardSchoolRecordcheckbox[""] == true
      ) {
        let newElement = JSON.parse(JSON.stringify(el));
        newElement.parsedHonorBoardPrizeType = "Excelência";
        newElement.parsedHonorBoardPrize = "Excelência";
        newElement.splitFlag = true;
        cleanNewElement(newElement);
        data.push(newElement);
      }

      data.splice(i, 1);
    }

    function cleanNewElement(element) {
      if (element.createdBy != null) {
        delete element.createdBy;
      }
      if (element.modifiedBy != null) {
        delete element.modifiedBy;
      }
    }

    return data;
  },
  kstkSocialAssessmentDomainFilter: function kstkSocialAssessmentDomainFilter(scope, data) {
    for (let i = 0; i < data.length; ) {
      let el = data[i];

      if (el.domainDesc != scope.activeTabName) {
        data.splice(i, 1);
      } else {
        i++;
      }
    }
  },
};

function kstkSocialAsessmentGetCalculatedIndicator(scope, selected) {
  let valueFields = ["municipalityValue", "area1Value", "area2Value"];
  if (selected.period != null && selected.indicator != null) {
    // VFX # Jovens (0-14 anos)
    if (selected.indicator == "663a2d59c9534a0260268e6b") {
      let refNumberEl, refPercentageEl;
      for (let i = 0; i < scope.genericScope.gridOptions.data.length; i++) {
        let el = scope.genericScope.gridOptions.data[i];
        //number
        if (el.period == selected.period && el.indicator == "663a1333c9534a0260268e29") {
          refNumberEl = el;
        }
        //percentage
        if (el.period == selected.period && el.indicator == "663a2cdbc9534a0260268e67") {
          refPercentageEl = el;
        }
        if (refNumberEl != null && refPercentageEl != null) {
          break;
        }
      }

      valueFields.forEach((valueField) => {
        if (refNumberEl[valueField] != null && refPercentageEl[valueField] != null) {
          selected[valueField] = Math.round(refNumberEl[valueField] * (refPercentageEl[valueField] / 100));
        }
      });
      return "Sim";
    }
  }
  return "Não";
}

function parseValue(id, field, deps, depsData, activeTab, auxFromTable) {
  if (id != "null" && id != "undefined" && id != null) {
    var total = "";
    var idToStrings = [];
    var depData = {};

    if (!Array.isArray(id) && id.length > 24) {
      total = id.substring(24);
      id = id.substring(0, 24);
    }

    if (auxFromTable == null) {
      var i = deps.indexOf(getFromTable(field, activeTab));
    } else {
      var i = deps.indexOf(auxFromTable);
    }

    if (i != -1) {
      if (typeof depsData !== "undefined" && depsData.length > 0) {
        depData = depsData[i];
        /* var name = depData.filter(el => el.id === id); */
        for (let k = 0; k < depData.length; k++) {
          let el = depData[k];
          if (el.id === id) {
            var name = new Array(el);
            break;
          }
        }
      }
    }

    if (Array.isArray(id)) {
      let numIDsParsed = 0;
      for (var j = 0; j < depData.length; j++) {
        for (var i = 0; i < id.length; i++) {
          if (depData[j].id === id[i]) {
            numIDsParsed += 1;
            idToStrings.push(depData[j][field]);
          }
        }
        if (numIDsParsed == id.length) {
          break;
        }
      }
      return idToStrings.toString() + total;
    }

    if (typeof name !== "undefined" && name.length > 0) {
      return name[0][field] + total;
    } else {
      return "";
    }
  } else {
    return "";
  }
}

function getFromTable(field, activeTab) {
  if (Object.keys(activeTab.fields).indexOf(field) != -1) {
    return activeTab.fields[field].fromTable;
  }
  for (let i = 0; i < Object.values(activeTab.fields).filter((el) => el.viewType === "dynamicField").length; i++) {
    const dynField = Object.values(activeTab.fields).filter((el) => el.viewType === "dynamicField")[i];
    if (dynField.dynamicFields[field]) {
      return dynField.dynamicFields[field].fromTable;
    }
  }
}

function cleanTopFilters(topFilters) {
  if (!(Object.keys(topFilters).length === 0 && topFilters.constructor === Object)) {
    for (const k in topFilters) {
      if (topFilters.hasOwnProperty(k)) {
        delete topFilters[k];
      }
    }
  }
}

function removeInputNumScrolls() {
  let stateCheck = setInterval(() => {
    if (document.readyState === "complete") {
      clearInterval(stateCheck);
      let textInputs = document.getElementsByClassName("textInput");
      for (let ii = 0; ii < textInputs.length; ii++) {
        const element = textInputs[ii];
        element.addEventListener("wheel", removeNumScroll);
      }
    }
  }, 100);
}

/* function removeSelectInput() {
  let stateCheck = setInterval(() => {
    if (document.readyState === 'complete') {
      clearInterval(stateCheck);
      let selectInputs = document.getElementsByClassName("selectInput");
      for (let ii = 0; ii < selectInputs.length; ii++) {
        const element = selectInputs[ii];
        element.addEventListener('keydown', function (ev) {
          ev.stopPropagation();
        });
      }
    }
  }, 100);
} */

function removeNumScroll() {
  this.blur();
}

function dynaFieldFilterKeyValue(dynaField, field) {
  for (const key in dynaField) {
    if (dynaField.hasOwnProperty(key)) {
      if (key != field && key != "value" && key != "vn") {
        delete dynaField[key];
      }
    }
  }
}

function openCustomPanel(scope, panel, functionArgs) {
  var position = panel.newPanelPosition().absolute().center();
  var config = {
    attachTo: angular.element(document.body),
    disableParentScroll: false,
    controller: functionArgs.controller,
    controllerAs: "ctrl",
    templateUrl: "templates/" + functionArgs.template,
    hasBackdrop: true,
    panelClass: "a-panel",
    position: position,
    trapFocus: true,
    zIndex: 1,
    clickOutsideToClose: true,
    escapeToClose: true,
    focusOnOpen: true,
    onDomRemoved: function () {
      scope.selectedRow = null;
      scope.getModuleData();
    },
    locals: {
      genericScope: scope,
    },
  };

  panel.open(config).then(function (result) {
    scope.panelRef = result;
  });
}

function openCustomPanelInsidePanel(scope, panel, functionArgs, selected, genericScope) {
  genericScope.$parent.showLoader();
  let customFunctions = functionArgs.customFunctions;
  let customFunctionsResult;
  let data = [selected];
  let columnDefs;

  if (customFunctions != null) {
    for (let i = 0; i < customFunctions.length; i++) {
      const element = customFunctions[i];
      if (utilFunctions[element] !== null && utilFunctions[element] !== undefined) {
        customFunctionsResult = utilFunctions[element](scope, [selected], functionArgs.panelConfig);
      }
    }
    if (customFunctionsResult.constructor === Object && Object.keys(customFunctionsResult).length !== 0) {
      if (Array.isArray(customFunctionsResult.data) && customFunctionsResult.data.length > 0) {
        data = customFunctionsResult.data;
        columnDefs = customFunctionsResult.columnDefs;
      } else {
        scope.parentScope.$parent.ptPreviewPanelWithoutData();
        genericScope.$parent.hideLoader();
        return;
      }
    } else if (Array.isArray(customFunctionsResult) && customFunctionsResult.length > 0) {
      data = customFunctionsResult;
    } else if (
      data == null ||
      data.length == 0 ||
      (Array.isArray(customFunctionsResult) && customFunctionsResult.length == 0)
    ) {
      scope.parentScope.$parent.ptPreviewPanelWithoutData();
      genericScope.$parent.hideLoader();
      return;
    }
  }

  var position = panel.newPanelPosition().absolute().center();
  var config = {
    attachTo: angular.element(document.body),
    disableParentScroll: false,
    controller: functionArgs.controller,
    controllerAs: "ctrl",
    templateUrl: "templates/" + functionArgs.template,
    hasBackdrop: true,
    panelClass: "preview-table-panel",
    position: position,
    trapFocus: true,
    zIndex: 1,
    focusOnOpen: true,
    animation: undefined,
    locals: {
      genericScope: genericScope,
      data: data,
      columnDefs: columnDefs,
      onSaveFunctions: functionArgs.onSaveFunctions,
      panelName: functionArgs.panelName,
      panelConfig: functionArgs.panelConfig,
      selected: selected,
    },
    onOpenComplete: function () {
      genericScope.$parent.hideLoader();
    },
    onDomRemoved: function () {
      scope.selectedRow = null;
      let previewGrid = document.getElementById("previewGrid");
      if (previewGrid != null) {
        previewGrid.destroy();
      }
      if (scope.panelRef && Array.isArray(scope.panelRef) && scope.panelRef.length > 0) {
        scope.panelRef.destroy();
      } else if (scope.panelRef && !Array.isArray(scope.panelRef)) {
        scope.panelRef.destroy();
      }
      scope.gridApi.selection.clearSelectedRows();
      //scope.getModuleData();
    },
  };

  panel.open(config).then(function (result) {
    scope.panelRef = result;
  });
}

function modulo(divident, divisor) {
  var cDivident = "";
  var cRest = "";

  for (var i in divident) {
    var cChar = divident[i];
    var cOperator = cRest + "" + cDivident + "" + cChar;

    if (cOperator < parseInt(divisor)) {
      cDivident += "" + cChar;
    } else {
      cRest = cOperator % divisor;
      if (cRest == 0) {
        cRest = "";
      }
      cDivident = "";
    }
  }
  cRest += "" + cDivident;
  if (cRest == "") {
    cRest = 0;
  }
  return cRest;
}

function getFilterIndex(scope, fields, filter) {
  let filterIndex = 2;
  let copyOfFields = JSON.parse(JSON.stringify(fields));
  for (let j = 0; j < copyOfFields.length; ) {
    let el = copyOfFields[j];
    if (filter == el) {
      break;
    }
    if (
      (scope.activeTab.fields[el].hidden != undefined && scope.activeTab.fields[el].hidden == true) ||
      (scope.activeTab.fields[el].hiddenInTable != undefined && scope.activeTab.fields[el].hiddenInTable == true)
    ) {
      copyOfFields.splice(j, 1);
    } else {
      j++;
    }
  }
  filterIndex = copyOfFields.indexOf(filter) + filterIndex;
  return filterIndex;
}

function getCMSTwelfthClusterRowPerm(scope, el) {
  if (
    el.createdBy == null ||
    el.createdBy == "admin" ||
    (el.createdBy != null && el.createdBy.id && el.createdBy.id != scope.currentUser.id)
  ) {
    return false;
  }
  return true;
}

function getSocialAssessmentChildhoodGaranteePerm(scope, el) {
  if (
    el.householdMinorChildrenEducationalStatus == null ||
    (el.householdMinorChildrenEducationalStatus != null && el.householdMinorChildrenEducationalStatus.length == 0)
  ) {
    return false;
  }
  return true;
}

function updateAge(birthdayMS) {
  if (birthdayMS != undefined && birthdayMS != null && Date.now() > birthdayMS) {
    var ageDifMs = Date.now() - birthdayMS;
    var ageDate = new Date(ageDifMs);
    var age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age;
  }
}

function getLearningProfileFromEssentialLearningEval(learningProfiles, essentialLearning, essentialLearningEval) {
  if (essentialLearningEval != null) {
    let roundedEssentialLearningEval = Math.round(Math.round(essentialLearningEval * 10) / 10);
    for (let j = 0; j < learningProfiles.learningProfilesEvalLimits.length; j++) {
      let learningProfile = learningProfiles.learningProfilesEvalLimits[j];
      if (
        learningProfile.essentialLearning == essentialLearning &&
        roundedEssentialLearningEval >= learningProfile.bottomEvalLimit &&
        roundedEssentialLearningEval <= learningProfile.topEvalLimit
      ) {
        return learningProfile.learningProfile;
      }
    }
  }
}

function ptClassPlanSchoolRecordCalculatedStudEvals(scope, selected, studEvals, profFlag) {
  for (let i = 0; i < studEvals.length; i++) {
    let studEval = studEvals[i];
    studEval.classOrder = selected.studentFinalCritEvaluations[i].student
      ? selected.studentFinalCritEvaluations[i].student.classOrder
      : selected.studentFinalCritEvaluations[i].studentClassOrder;
    studEval.autoFinalEssenLearnCritEvalGrade = ptGetAEEvalLevel(selected, i);
    if (profFlag) {
      studEval.autoFinalGrade = ptGetFinalGrade(selected, i, true);
      studEval.finalEvalLevel = ptGetFinalEvalLevel(
        scope,
        selected,
        i,
        scope.getFromTableData("Class_Plan_Final_Evaluation_Levels"),
        null,
        null,
        true
      );
    } else {
      studEval.autoFinalGrade = ptGetFinalGrade(selected, i, false);
      studEval.finalEvalLevel = ptGetFinalEvalLevel(
        scope,
        selected,
        i,
        scope.getFromTableData("Class_Plan_Final_Evaluation_Levels"),
        null,
        null,
        false
      );
    }
  }
}

function ptGetAEEvalLevel(selected, index) {
  let autoFinalEssenLearnCritEvalGrade = 0;
  if (
    selected.studentFinalCritEvaluations[index].studentFinalEssenLearnCritEvals != null &&
    selected.studentFinalCritEvaluations[index].studentFinalEssenLearnCritEvals.length > 0
  ) {
    let evaluatedEssenLearnWeight = 0;
    let essenLearnWithOnlyQualitativeEvalsFlag = false;
    selected.studentFinalCritEvaluations[index].studentFinalEssenLearnCritEvals.map(function (ev) {
      if (ev.essentialLearningAvg != null || ev.essentialLearningQuantEval != null) {
        evaluatedEssenLearnWeight += ev.essentialLearningWeight;
      }
      if (
        ev.essentialLearningAvg == null &&
        ev.essentialLearningQuantEval == null &&
        ev.essentialLearningEvalDesc != null
      ) {
        essenLearnWithOnlyQualitativeEvalsFlag = true;
      }
    });
    if (essenLearnWithOnlyQualitativeEvalsFlag) {
      return null;
    }
    selected.studentFinalCritEvaluations[index].studentFinalEssenLearnCritEvals.map(function (ev) {
      if (autoFinalEssenLearnCritEvalGrade != null) {
        if (ev.essentialLearningAvg == null && ev.essentialLearningQuantEval == null) {
          if (evaluatedEssenLearnWeight == 0) {
            autoFinalEssenLearnCritEvalGrade = null;
            return;
          }
        } else if (ev.essentialLearningQuantEval != null && !isNaN(Number(ev.essentialLearningQuantEval))) {
          if (ev.essentialLearningQuantEval > 200) {
            autoFinalEssenLearnCritEvalGrade = null;
            return;
          }
          if (evaluatedEssenLearnWeight > 0 && evaluatedEssenLearnWeight < 100) {
            getNewEssentialLearningWeight(ev, evaluatedEssenLearnWeight);
          }
          autoFinalEssenLearnCritEvalGrade +=
            Number(ev.essentialLearningQuantEval) * (ev.essentialLearningWeight / 100);
        } else {
          if (evaluatedEssenLearnWeight > 0 && evaluatedEssenLearnWeight < 100) {
            getNewEssentialLearningWeight(ev, evaluatedEssenLearnWeight);
          }
          autoFinalEssenLearnCritEvalGrade += Number(ev.essentialLearningAvg) * (ev.essentialLearningWeight / 100);
        }
      }
    });
    /* return Math.round(autoFinalEssenLearnCritEvalGrade * 100) / 100; */
  }
  function getNewEssentialLearningWeight(ev, evaluatedEssenLearnWeight) {
    if (
      selected.essenLearnsWithFixedEvalWeight == null ||
      (selected.essenLearnsWithFixedEvalWeight &&
        selected.essenLearnsWithFixedEvalWeight.indexOf(ev.essentialLearning) == -1)
    ) {
      ev.essentialLearningWeight = (ev.essentialLearningWeight * 100) / evaluatedEssenLearnWeight;
      ev.newEssentialLearningWeight = Math.round(ev.essentialLearningWeight * 100) / 100 + "%";
    }
  }
  if (autoFinalEssenLearnCritEvalGrade != null && !isNaN(autoFinalEssenLearnCritEvalGrade)) {
    return Math.round(autoFinalEssenLearnCritEvalGrade * 100) / 100;
  }
}

function ptGetFinalGrade(selected, index, profFlag) {
  if (
    selected.studentFinalCritEvaluations[index].studentFinalEssenLearnCritEvals != null &&
    selected.studentFinalCritEvaluations[index].studentFinalEssenLearnCritEvals.length > 0
  ) {
    let autoFinalGrade = 0;
    if (
      selected.studentFinalCritEvaluations[index].finalEssenLearnCritEvalGrade != null &&
      selected.studentFinalCritEvaluations[index].finalEssenLearnCritEvalGrade != ""
    ) {
      autoFinalGrade =
        Math.round(
          Number(selected.studentFinalCritEvaluations[index].finalEssenLearnCritEvalGrade) *
            (selected.studentFinalCritEvaluations[index].essenLearnCritWeight / 100) *
            100
        ) / 100;
    } else {
      let aeEvalLevel = ptGetAEEvalLevel(selected, index);
      if (aeEvalLevel != null || (aeEvalLevel != null && !isNaN(Number(aeEvalLevel)) && aeEvalLevel <= 200)) {
        autoFinalGrade =
          Math.round(
            Number(aeEvalLevel) * (selected.studentFinalCritEvaluations[index].essenLearnCritWeight / 100) * 100
          ) / 100;
      } else {
        return null;
      }
    }
    if (
      selected.studentFinalCritEvaluations[index].studentFinalCritEvals != null &&
      selected.studentFinalCritEvaluations[index].studentFinalCritEvals.length > 0
    ) {
      selected.studentFinalCritEvaluations[index].studentFinalCritEvals.map(function (ev) {
        if (ev.evaluation == null && ev.autoCritEvalAvg == null) {
          autoFinalGrade = null;
        } else {
          if (ev.evaluation != null) {
            autoFinalGrade += Number(ev.evaluation) * (ev.criterionWeight / 100);
          } else if (ev.autoCritEvalAvg != null) {
            autoFinalGrade += Number(ev.autoCritEvalAvg) * (ev.criterionWeight / 100);
          }
        }
      });
    }
    if (autoFinalGrade != null && !isNaN(autoFinalGrade)) {
      if (!profFlag) {
        return Number(Math.round(autoFinalGrade));
      } else {
        return autoFinalGrade;
      }
    }
  }
}

function ptGetFinalEvalLevel(scope, selected, index, fromTableData, subDynamicIndex, finalGradeCrit, profFlag) {
  if (subDynamicIndex != null && finalGradeCrit != null) {
    selected["studentFinalCritEvaluations"][index].autoFinalGrade = finalGradeCrit;
  }
  if (
    scope != null &&
    selected != null &&
    index != null &&
    fromTableData != null &&
    selected["studentFinalCritEvaluations"][index].autoFinalGrade != null
  ) {
    let finGrade;
    let finalLevel;

    if (selected["studentFinalCritEvaluations"][index].finalGrade != null) {
      finGrade = Number(selected["studentFinalCritEvaluations"][index].finalGrade);
    } else {
      finGrade = Number(selected["studentFinalCritEvaluations"][index].autoFinalGrade);
    }

    // Prioritize professional NAs evals
    if (
      profFlag &&
      ((Array.isArray(selected.ignoreNAEvalsFlag) && selected.ignoreNAEvalsFlag.length == 0) ||
        selected.ignoreNAEvalsFlagcheckbox == null ||
        (selected.ignoreNAEvalsFlagcheckbox && selected.ignoreNAEvalsFlagcheckbox[""] == false))
    ) {
      if (
        selected["studentFinalCritEvaluations"][index].studLowAttendancecheckbox &&
        selected["studentFinalCritEvaluations"][index].studLowAttendancecheckbox[""] &&
        selected["studentFinalCritEvaluations"][index].studLowAttendancecheckbox[""] == true &&
        selected["studentFinalCritEvaluations"][index].profRecoveryInstrEvalsFlag == "Normal"
      ) {
        return "NA (Não cumpriu assiduidade)";
      }
      if (
        selected.evalsUpTo200Flagcheckbox &&
        selected.evalsUpTo200Flagcheckbox[""] &&
        selected.evalsUpTo200Flagcheckbox[""] == true
      ) {
        if (finGrade < 95) {
          return "NA (Aval < 95)";
        }
      } else if (finGrade < 47.5) {
        return "NA (Aval < 9,5)";
      }
    }

    if (
      selected.convertFinalGradeTo20Scalecheckbox &&
      selected.convertFinalGradeTo20Scalecheckbox[""] &&
      selected.convertFinalGradeTo20Scalecheckbox[""] == true
    ) {
      if (!isNaN(Number(finGrade))) {
        finGrade = Math.round((20 * finGrade) / 10) / 10;
        if (profFlag) {
          finGrade = Math.round(Math.round(finGrade * 10) / 10);
        }
      }
      return finGrade;
    }

    if (selected.finalEvalLevels == null || selected.finalEvalLevels.length == 0) {
      return finGrade;
    }

    if (
      selected.ignoreEvalLevelsFlagcheckbox &&
      selected.ignoreEvalLevelsFlagcheckbox[""] &&
      selected.ignoreEvalLevelsFlagcheckbox[""] == true
    ) {
      return finGrade;
    }

    for (let i = 0; i < selected.finalEvalLevels.length; i++) {
      let finalEvalLevel = selected.finalEvalLevels[i];
      if (finGrade >= finalEvalLevel.bottomEvalLimit && finGrade <= finalEvalLevel.topEvalLimit) {
        if (!isNaN(finalEvalLevel.evalLevel)) {
          finalLevel = Number(finalEvalLevel.evalLevel);
        } else {
          finalLevel = finalEvalLevel.evalLevel;
        }
      }
    }

    if (finalLevel == null) {
      return null;
    } else {
      return finalLevel;
    }
  }
}

function ptConvertEvalToFinalEvalLevel(selected, evalField) {
  if (selected[evalField] != null && !isNaN(selected[evalField]) && selected.finalEvalLevels != null) {
    let finalLevel;
    for (let i = 0; i < selected.finalEvalLevels.length; i++) {
      let finalEvalLevel = selected.finalEvalLevels[i];
      let roundedEssenLearnAvg = Math.round(selected[evalField]);
      if (
        roundedEssenLearnAvg >= finalEvalLevel.bottomEvalLimit &&
        roundedEssenLearnAvg <= finalEvalLevel.topEvalLimit
      ) {
        if (!isNaN(finalEvalLevel.evalLevel)) {
          finalLevel = Number(finalEvalLevel.evalLevel);
        } else {
          finalLevel = finalEvalLevel.evalLevel;
        }
      }
    }
    if (finalLevel == null) {
      return null;
    } else {
      return finalLevel;
    }
  } else {
    return null;
  }
}

function ptProfSubjectStudEvalLevel(scope, selected, index, fromTableData) {
  if (
    scope != null &&
    selected != null &&
    index != null &&
    fromTableData != null &&
    selected["studentProfSubjectsEvals"][index].evalSubject != null
  ) {
    if (selected.finalEvalLevels == null || selected.finalEvalLevels.length == 0) {
      return null;
    }

    let finGrade;
    let finalLevel;

    if (selected["studentProfSubjectsEvals"][index].evalSubjectManual != null) {
      finGrade = Number(selected["studentProfSubjectsEvals"][index].evalSubjectManual);
    } else {
      finGrade = Number(selected["studentProfSubjectsEvals"][index].evalSubject);
    }

    for (let i = 0; i < selected.finalEvalLevels.length; i++) {
      let finalEvalLevel = selected.finalEvalLevels[i];
      if (finGrade >= finalEvalLevel.bottomEvalLimit && finGrade <= finalEvalLevel.topEvalLimit) {
        if (!isNaN(finalEvalLevel.evalLevel)) {
          finalLevel = Number(finalEvalLevel.evalLevel);
        } else {
          finalLevel = finalEvalLevel.evalLevel;
        }
      }
    }

    if (finalLevel == null) {
      return null;
    } else {
      return finalLevel;
    }
  }
}

function parseTopFiltersData(scope, data, topFilters) {
  var parseValue = scope.parseValue;
  var parsedTopFiltersData = [];
  for (let i = 0; i < data.length; i++) {
    let row = data[i];
    topFilters.forEach((key) => {
      if (row.hasOwnProperty(key)) {
        let parsedValue;
        if (scope.activeTab.fields[key]) {
          if (scope.activeTab.fields[key].fromTable != null) {
            row[key] = parseValue(row[key], key);
            parsedValue = row[key];
          } else if (scope.activeTab.fields[key].viewType == "date") {
            row[key] = new Date(row[key]).toLocaleDateString();
            parsedValue = row[key];
          } else {
            parsedValue = row[key];
          }

          if (parsedValue != null && parsedValue != "") {
            if (parsedTopFiltersData[key] == null) {
              parsedTopFiltersData[key] = [];
            }
            if (parsedTopFiltersData[key].indexOf(parsedValue.toString()) == -1) {
              parsedTopFiltersData[key].push(parsedValue.toString());
            }
          }

          /* if (scope.activeTab.fields[key].viewType == "dynamicField") {
            for (let j = 0; j < row[key].length; j++) {
              let dynamicSubRow = row[key][j];
              for (const dynKey in dynamicSubRow) {
                if (dynamicSubRow.hasOwnProperty(dynKey)) {
                  let dynamicElement = dynamicSubRow[dynKey];
                  if (scope.activeTab.fields[key].dynamicFields[dynKey]) {

                    if (scope.activeTab.fields[key].dynamicFields[dynKey].fromTable != null) {
                      dynamicSubRow[dynKey] = parseValue(dynamicElement, dynKey);
                    }
                    if (scope.activeTab.fields[key].dynamicFields[dynKey].viewType == "date") {
                      dynamicSubRow[dynKey] = new Date(dynamicElement).toLocaleDateString();
                    }

                  }
                }
              }
            }
          } */

          /* if (parsedValue != null) {
            if (parsedTopFiltersData[key] == null) {
              parsedTopFiltersData[key] = [];
            }
            let duplicatedFlag = false;
            parsedTopFiltersData[key].forEach(topFilterEl => {
              if (topFilterEl.parsedValue == parsedValue) {
                duplicatedFlag = true;
              }
            });
            if (!duplicatedFlag) {
              let valueObj = {};
              valueObj.parsedValue = parsedValue.toString();
              valueObj.rawValue = rowElement;
              parsedTopFiltersData[key].push(valueObj);
            }
          } */
        }
      }
    });
  }
  return parsedTopFiltersData;
}

function fastParseTopFiltersData(scope, data) {
  var parseValue = scope.parseValue;
  for (const field in data) {
    if (Object.hasOwnProperty.call(data, field)) {
      let fieldFilterDataArr = data[field];
      let parsedFieldFilterDataArr = [];
      fieldFilterDataArr.forEach((value) => {
        let parsedValue;
        if (scope.activeTab.fields[field]) {
          if (scope.activeTab.fields[field].fromTable != null) {
            parsedValue = parseValue(value, field);
          } else if (scope.activeTab.fields[field].viewType == "date") {
            parsedValue = new Date(value).toLocaleDateString();
          } else {
            parsedValue = value;
          }
          if (parsedValue != null && parsedValue != "" && parsedFieldFilterDataArr.indexOf(parsedValue) == -1) {
            parsedFieldFilterDataArr.push(parsedValue);
          }
        }
      });
      if (parsedFieldFilterDataArr.length > 0) {
        data[field] = parsedFieldFilterDataArr;
      } else {
        data[field] = [];
      }
    }
  }
  return data;
}

/* function parseTopFiltersDataByCurrentUser(scope, currentUser, topFilters) {
  let parseValue = scope.parseValue;
  let topFiltersData = {};

  topFilters.forEach(topFiltersField => {
    if (topFiltersData[topFiltersField] == null) {
      topFiltersData[topFiltersField] = {};
    }


    if (topFiltersData[topFiltersField][currentUserTopFilterValue] == null) {
      topFiltersData[topFiltersField][currentUserTopFilterValue] = {};
    }
    let parsedValue;
    if (scope.activeTab.fields[topFiltersField].customLabel) {
      parsedValue = eval(scope.activeTab.fields[topFiltersField].customLabel.replace(new RegExp('{}', 'g'), 'fromTableRow'));
    } else if (scope.activeTab.fields[topFiltersField].fromTable != null) {
      parsedValue = parseValue(currentUserTopFilterValue, topFiltersField);
    } else if (scope.activeTab.fields[topFiltersField].viewType == "date") {
      parsedValue = new Date(currentUserTopFilterValue).toLocaleDateString()
    } else {
      parsedValue = currentUserTopFilterValue;
    }
    topFiltersData[topFiltersField][currentUserTopFilterValue].parsedValue = parsedValue;
    topFiltersData[topFiltersField][currentUserTopFilterValue].value = currentUserTopFilterValue;
  });

  for (const topFiltersField in topFilters) {
    if (Object.hasOwnProperty.call(topFilters, topFiltersField)) {
      const element = topFilters[topFiltersField];

    }
  }
  for (const field in data) {
    if (Object.hasOwnProperty.call(data, field)) {
      let fieldFilterDataArr = data[field];
      let parsedFieldFilterDataArr = [];
      fieldFilterDataArr.forEach(value => {
        let parsedValue;
        if (scope.activeTab.fields[field]) {
          if (scope.activeTab.fields[field].fromTable != null) {
            parsedValue = parseValue(value, field);
          } else if (scope.activeTab.fields[field].viewType == "date") {
            parsedValue = new Date(value).toLocaleDateString()
          } else {
            parsedValue = value;
          }
          if (parsedValue != null && parsedValue != "" && parsedFieldFilterDataArr.indexOf(parsedValue) == -1) {
            parsedFieldFilterDataArr.push(parsedValue);
          }
        }
      });
      if (parsedFieldFilterDataArr.length > 0) {
        data[field] = parsedFieldFilterDataArr;
      } else {
        data[field] = []
      }
    }
  }
  return data;
} */

function getUniqueArrObjects(arr, comp) {
  const unique = arr
    .map((e) => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
}

function parseCustomLabel(labelValue) {
  if (labelValue == null || labelValue == "") {
    return "";
  } else {
    return labelValue + " - ";
  }
}

function updateQuantitaveEvalFromQualitative(selected, row, performanceLevelDescs) {
  if (selected.evalInstrumentQualitativeLevels) {
    let qualitativeEvalLevels = JSON.parse(selected.evalInstrumentQualitativeLevels);
    for (let j = 0; j < qualitativeEvalLevels.qualitativeLevels.length; j++) {
      const qualitativeLevel = qualitativeEvalLevels.qualitativeLevels[j];
      if (qualitativeLevel.qualitativeLevel == row.evaluationQualitativeLevel) {
        row.evaluation = qualitativeLevel.quantitativeLevel;
        // Set row performance level desc if it matches the case
        if (Array.isArray(performanceLevelDescs) && performanceLevelDescs.length > 0) {
          let filteredPerformanceLevelDesc = performanceLevelDescs.filter(
            (el) => el.performanceCrit == row.performanceCrit && el.qualitativeLevel == row.evaluationQualitativeLevel
          );
          if (Array.isArray(filteredPerformanceLevelDesc) && filteredPerformanceLevelDesc.length > 0) {
            row.performanceLevelDesc = filteredPerformanceLevelDesc[0].performanceDesc;
          }
        }
      } else if (row.evaluationQualitativeLevel == null) {
        row.evaluation = null;
        break;
      }
    }
  }
  return row.evaluationQualitativeLevel;
}

function updateEssenLearnAvgMap(selected, row, updatedField) {
  if (
    selected.studentEvaluations[row.dynamicIndex].essentialLearningQuestionEvaluation != null &&
    selected.studentEvaluations[row.dynamicIndex].essentialLearningQuestionEvaluation.length > 0
  ) {
    let allQuestionsEvaluatedFlag = true;
    let correctionByEssenLearnWeightFlag = false;
    let essenLearnFinalEvalMap = {};
    // Setup correction by essen learn if free quotation flag is true
    if (selected.freeQuotationEvalInstrFlag) {
      correctionByEssenLearnWeightFlag = true;
    }
    if (
      selected.correctionByEssenLearnWeightcheckbox &&
      selected.correctionByEssenLearnWeightcheckbox[""] &&
      selected.correctionByEssenLearnWeightcheckbox[""] == true
    ) {
      correctionByEssenLearnWeightFlag = true;
    }
    selected.studentEvaluations[row.dynamicIndex].essentialLearningQuestionEvaluation.map(function (ev) {
      if (ev.evaluation != null) {
        if (essenLearnFinalEvalMap[ev.essentialLearning] == null) {
          essenLearnFinalEvalMap[ev.essentialLearning] = {};
          essenLearnFinalEvalMap[ev.essentialLearning].parsedEssentialLearning = ev.parsedEssentialLearning;
          essenLearnFinalEvalMap[ev.essentialLearning].essentialLearning = ev.essentialLearning;
          if (correctionByEssenLearnWeightFlag && !selected.freeQuotationEvalInstrFlag) {
            essenLearnFinalEvalMap[ev.essentialLearning].evaluation = ev.evaluation;
          } else if (selected.freeQuotationEvalInstrFlag) {
            essenLearnFinalEvalMap[ev.essentialLearning].evaluation = ev.evaluation;
            essenLearnFinalEvalMap[ev.essentialLearning].totalWeight = ev.weight;
          } else {
            essenLearnFinalEvalMap[ev.essentialLearning].evaluation =
              Math.round(Number(ev.evaluation) * (Number(ev.weight) / 100) * 100) / 100;
          }
        } else {
          if (correctionByEssenLearnWeightFlag && !selected.freeQuotationEvalInstrFlag) {
            essenLearnFinalEvalMap[ev.essentialLearning].evaluation += ev.evaluation;
          } else if (selected.freeQuotationEvalInstrFlag) {
            essenLearnFinalEvalMap[ev.essentialLearning].evaluation += ev.evaluation;
            essenLearnFinalEvalMap[ev.essentialLearning].totalWeight += ev.weight;
          } else {
            essenLearnFinalEvalMap[ev.essentialLearning].evaluation +=
              Math.round(Number(ev.evaluation) * (Number(ev.weight) / 100) * 100) / 100;
          }
        }
      } else {
        allQuestionsEvaluatedFlag = false;
        selected.studentEvaluations[row.dynamicIndex].essenLearnFinalEvalMap = null;
      }
    });
    if (allQuestionsEvaluatedFlag) {
      if (selected.freeQuotationEvalInstrFlag) {
        for (const essentialLearning in essenLearnFinalEvalMap) {
          if (Object.hasOwnProperty.call(essenLearnFinalEvalMap, essentialLearning)) {
            let essentialLearningEvals = essenLearnFinalEvalMap[essentialLearning];
            essenLearnFinalEvalMap[essentialLearning].evaluation =
              Math.round(
                (Number(essentialLearningEvals.evaluation) / Number(essentialLearningEvals.totalWeight)) * 10000
              ) / 100;
          }
        }
      }
      selected.studentEvaluations[row.dynamicIndex].essenLearnFinalEvalMap = essenLearnFinalEvalMap;
    }
    return row[updatedField];
  }
}

function classPlanGetQuantitativeEvalsFromQualitative(qualitativeTotalEvals, qualitativeEvalLevels) {
  let totalEvals = 0;
  let numEvals = 0;
  qualitativeTotalEvals.forEach((qualitativeLevel) => {
    qualitativeEvalLevels.forEach((qualitativelLevelDesc) => {
      if (
        qualitativeLevel == qualitativelLevelDesc.qualitativeLevel &&
        qualitativelLevelDesc.quantitativeLevel != null &&
        !isNaN(Number(qualitativelLevelDesc.quantitativeLevel))
      ) {
        totalEvals += qualitativelLevelDesc.quantitativeLevel;
        numEvals += 1;
      }
    });
  });
  return Math.round((totalEvals / numEvals) * 100) / 100;
}

function classPlanGetStudentExpertiseFieldEvals(
  essentialLearningStudentEvals,
  studDataSplitByAE,
  essentialLearnings,
  expertiseFields
) {
  if (
    essentialLearningStudentEvals.length > 0 &&
    studDataSplitByAE.length > 0 &&
    essentialLearnings.length > 0 &&
    expertiseFields.length > 0
  ) {
    let studentExpertiseFieldsEvalsMap = {};
    let studEvalInstrumentWithSpecificExpertiseFields = studDataSplitByAE.filter((el) => el.expertiseFields != null);

    if (
      studEvalInstrumentWithSpecificExpertiseFields &&
      Array.isArray(studEvalInstrumentWithSpecificExpertiseFields) &&
      studEvalInstrumentWithSpecificExpertiseFields.length > 0
    ) {
      //Get expertise field evals by default with some specific eval instrument expertise fields

      for (let k = 0; k < studDataSplitByAE.length; k++) {
        let essenLearnStudEval = studDataSplitByAE[k];
        if (essenLearnStudEval.essenLearnAvg != null) {
          let essentialLearningDesc = essentialLearnings.filter(
            (el) => el.id == essenLearnStudEval.essentialLearning
          )[0];
          if (essentialLearningDesc != null) {
            if (essenLearnStudEval.evalInstrumentQualitativeLevels) {
              let qualitativeEvalLevels = JSON.parse(essenLearnStudEval.evalInstrumentQualitativeLevels);
              if (
                qualitativeEvalLevels &&
                qualitativeEvalLevels.qualitativeLevels &&
                Array.isArray(qualitativeEvalLevels.qualitativeLevels) &&
                qualitativeEvalLevels.qualitativeLevels.length > 0 &&
                qualitativeEvalLevels.qualitativeLevels[0].quantitativeLevel == null
              ) {
                continue;
              }
              if (typeof essenLearnStudEval.essenLearnAvg == "string") {
                essenLearnStudEval.essenLearnAvg = classPlanGetQuantitativeEvalsFromQualitative(
                  essenLearnStudEval.qualitativeTotalEvals,
                  qualitativeEvalLevels
                );
              }
            }
            if (
              essentialLearningDesc.expertiseField != null &&
              Array.isArray(essentialLearningDesc.expertiseField) &&
              essentialLearningDesc.expertiseField.length > 0
            ) {
              for (let w = 0; w < essentialLearningDesc.expertiseField.length; w++) {
                let essenLearnExpField = essentialLearningDesc.expertiseField[w];
                if (
                  essenLearnStudEval.expertiseFields &&
                  Array.isArray(essenLearnStudEval.expertiseFields) &&
                  essenLearnStudEval.expertiseFields.length > 0 &&
                  essenLearnStudEval.expertiseFields.indexOf(essenLearnExpField) == -1
                ) {
                  continue;
                }
                if (studentExpertiseFieldsEvalsMap[essenLearnExpField] == null) {
                  studentExpertiseFieldsEvalsMap[essenLearnExpField] = {};
                  studentExpertiseFieldsEvalsMap[essenLearnExpField].totalEvals = essenLearnStudEval.essenLearnAvg;
                  studentExpertiseFieldsEvalsMap[essenLearnExpField].numEvals = 1;
                  studentExpertiseFieldsEvalsMap[essenLearnExpField].parsedExpertiseField = expertiseFields.filter(
                    (el) => el.id == essenLearnExpField
                  )[0].expertiseField;
                  studentExpertiseFieldsEvalsMap[essenLearnExpField].associatedEssentialLearning = [];
                  studentExpertiseFieldsEvalsMap[essenLearnExpField].associatedEssentialLearning.push(
                    essentialLearningDesc.essentialLearning
                  );
                } else {
                  studentExpertiseFieldsEvalsMap[essenLearnExpField].totalEvals += essenLearnStudEval.essenLearnAvg;
                  studentExpertiseFieldsEvalsMap[essenLearnExpField].numEvals += 1;
                  if (
                    studentExpertiseFieldsEvalsMap[essenLearnExpField].associatedEssentialLearning.indexOf(
                      essentialLearningDesc.essentialLearning
                    ) == -1
                  ) {
                    studentExpertiseFieldsEvalsMap[essenLearnExpField].associatedEssentialLearning.push(
                      essentialLearningDesc.essentialLearning
                    );
                  }
                }
              }
            }
          }
        }
      }
    } else {
      //Get expertise field evals by default without specific eval instrument expertise fields
      essentialLearningStudentEvals.forEach((essenLearnStudEval) => {
        let essentialLearningDesc = essentialLearnings.filter((el) => el.id == essenLearnStudEval.essentialLearning)[0];
        //Check if essentialLearningAvg is null because essential learning could not have been evaluated in the selected period/semester
        if (essentialLearningDesc != null && essenLearnStudEval.essentialLearningAvg != null) {
          if (
            essentialLearningDesc.expertiseField != null &&
            Array.isArray(essentialLearningDesc.expertiseField) &&
            essentialLearningDesc.expertiseField.length > 0
          ) {
            essentialLearningDesc.expertiseField.forEach((essenLearnExpField) => {
              if (studentExpertiseFieldsEvalsMap[essenLearnExpField] == null) {
                studentExpertiseFieldsEvalsMap[essenLearnExpField] = {};
                studentExpertiseFieldsEvalsMap[essenLearnExpField].totalEvals = essenLearnStudEval.essentialLearningAvg;
                studentExpertiseFieldsEvalsMap[essenLearnExpField].numEvals = 1;
                studentExpertiseFieldsEvalsMap[essenLearnExpField].parsedExpertiseField = expertiseFields.filter(
                  (el) => el.id == essenLearnExpField
                )[0].expertiseField;
                studentExpertiseFieldsEvalsMap[essenLearnExpField].associatedEssentialLearning = [];
                studentExpertiseFieldsEvalsMap[essenLearnExpField].associatedEssentialLearning.push(
                  essentialLearningDesc.essentialLearning
                );
              } else {
                studentExpertiseFieldsEvalsMap[essenLearnExpField].totalEvals +=
                  essenLearnStudEval.essentialLearningAvg;
                studentExpertiseFieldsEvalsMap[essenLearnExpField].numEvals += 1;
                if (
                  studentExpertiseFieldsEvalsMap[essenLearnExpField].associatedEssentialLearning.indexOf(
                    essentialLearningDesc.essentialLearning
                  ) == -1
                ) {
                  studentExpertiseFieldsEvalsMap[essenLearnExpField].associatedEssentialLearning.push(
                    essentialLearningDesc.essentialLearning
                  );
                }
              }
            });
          }
        }
      });
    }

    let studentExpertiseFieldsEvalsArray = [];
    if (Object.keys(studentExpertiseFieldsEvalsMap).length > 0) {
      for (const expertiseField in studentExpertiseFieldsEvalsMap) {
        if (studentExpertiseFieldsEvalsMap.hasOwnProperty(expertiseField)) {
          let expertiseFieldEvalsDesc = studentExpertiseFieldsEvalsMap[expertiseField];
          if (expertiseFieldEvalsDesc.associatedEssentialLearning) {
            expertiseFieldEvalsDesc.associatedEssentialLearning = expertiseFieldEvalsDesc.associatedEssentialLearning
              .toString()
              .replace(/,/gi, ", ");
          }
          expertiseFieldEvalsDesc.expertiseFieldEval =
            Math.round((expertiseFieldEvalsDesc.totalEvals / expertiseFieldEvalsDesc.numEvals) * 100) / 100;
          studentExpertiseFieldsEvalsArray.push(expertiseFieldEvalsDesc);
        }
      }
      if (studentExpertiseFieldsEvalsArray.length > 0) {
        studentExpertiseFieldsEvalsArray.sort(function (a, b) {
          if (a["parsedExpertiseField"] < b["parsedExpertiseField"]) {
            return -1;
          }
          if (a["parsedExpertiseField"] > b["parsedExpertiseField"]) {
            return 1;
          }
        });
        return studentExpertiseFieldsEvalsArray;
      }
    }
    {
      return null;
    }
  } else {
    return null;
  }
}

function classPlanGetEvalDescByEvalInstrument(studEvalsByEssenLearn, evalInstruments) {
  let evalDescByEvalInstruments = [];
  studEvalsByEssenLearn.forEach((studEssenLearn) => {
    let evalInstrument = evalInstruments.filter((el) => el.id == studEssenLearn.evalInstrument);
    if (evalInstrument != null && Array.isArray(evalInstrument) && evalInstrument.length > 0) {
      let evalInstrumentName = evalInstrument[0].evalInstrument;
      evalDescByEvalInstruments.push(evalInstrumentName + " - " + studEssenLearn.essentialLearningEval);
    }
  });
  if (evalDescByEvalInstruments.length > 0) {
    evalDescByEvalInstruments.sort();
    return evalDescByEvalInstruments.toString().replace(/\,/gi, ", ");
  } else {
    return null;
  }
}

function countDecimals(value) {
  if (Math.floor(value) === value) return 0;
  if (value.toString().indexOf(".") != -1) {
    return value.toString().split(".")[1].length || 0;
  } else if (value.toString().indexOf(",") != -1) {
    return value.toString().split(",")[1].length || 0;
  }
}

function validateEmail(email) {
  let splitEmail;
  let validateEmailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  splitEmail = email.replace(/\ /gi, "").split(",");
  if (splitEmail.length == 1) {
    return validateEmailRegex.test(email);
  } else {
    for (let k = 0; k < splitEmail.length; k++) {
      const mailToBeTested = splitEmail[k];
      if (!validateEmailRegex.test(mailToBeTested)) {
        return false;
      }
    }
    return true;
  }
}

function getFilteredCoursePlan(selected, profCoursePlans, courseEvalFlag) {
  let selectedProfCoursePlan = profCoursePlans.filter((el) => el.id == selected.coursePlanRef)[0];
  if (!selectedProfCoursePlan) {
    return null;
  }
  if (!courseEvalFlag) {
    let filteredProfCoursePlan = selectedProfCoursePlan.coursePlan.filter(
      (el) =>
        el.subject == selected.subject && el.schoolYear == selected.schoolYear && el.studyYear == selected.studyYear
    )[0];
    if (!filteredProfCoursePlan) {
      return null;
    }
    return filteredProfCoursePlan;
  } else {
    return selectedProfCoursePlan.coursePlan;
  }
}

function vfxETIUpdateComparticipations(scope, panel, args, selectedRow) {
  scope.parentScope.$parent.showLoader();
  let toUpdateRows = [];
  let numUpdatedRows = 0;
  let currentScholarYear = getCurrentScholarYear();
  for (let i = 0; i < scope.gridOptions.data.length; i++) {
    let element = scope.gridOptions.data[i];
    if (element.cluster && element.school && element.schedule && element.year == currentScholarYear) {
      let fromTableComparticipation;
      if (element.aseEchelon == "A" || element.aseEchelon == "B") {
        fromTableComparticipation = scope
          .getFromTableData("VFX_ETI_Fam_Comparticipations")
          .filter(
            (el) =>
              el.cluster == element.cluster &&
              el.school == element.school &&
              el.schedule == element.schedule &&
              el.aseEchelon == element.aseEchelon
          );
      } else {
        fromTableComparticipation = scope
          .getFromTableData("VFX_ETI_Fam_Comparticipations")
          .filter(
            (el) =>
              el.cluster == element.cluster &&
              el.school == element.school &&
              el.schedule == element.schedule &&
              el.aseEchelon == null
          );
      }
      if (fromTableComparticipation[0]) {
        element.comparticipationValue = fromTableComparticipation[0].comparticipation;
        toUpdateRows.push(element);
      }
    }
  }
  scope.parentScope.$parent.hideLoader();
  scope.parentScope.$parent.showDeterminateLoader(toUpdateRows.length);
  async.eachSeries(
    toUpdateRows,
    function iteratee(item, callback) {
      scope.genericFactory.modify(item.id, item).then(() => {
        numUpdatedRows += 1;
        scope.parentScope.$parent.updateDeterminateLoader();
        callback();
      });
    },
    function done() {
      if (numUpdatedRows == toUpdateRows.length) {
        scope.$parent.alertVFXETIUpdateComparticipations(numUpdatedRows);
        scope.parentScope.$parent.hideLoader();
      }
    },
    function (err) {
      if (err) {
        console.log(err);
      } else {
        //Done
        console.log("done");
      }
    }
  );
}

function ptUpdateEvalQualitativeOnInstrSchoolRecord(scope, panel, args, selectedRow) {
  scope.parentScope.$parent.showLoader();
  let toUpdateRows = [];
  let numUpdatedRows = 0;

  scope.genericFactory.setRouteName("Class_Plan_Eval_Qualitative_Levels");
  scope.genericFactory
    .getByProperty("organization", scope.currentUser.organization)
    .then(function (evalQualitativeLevels) {
      for (let i = 0; i < scope.gridOptions.data.length; i++) {
        let element = scope.gridOptions.data[i];
        if (
          element.year == "2020" &&
          element.organization == "59f069bdb0c8f16d4e0a527c" &&
          element.evalInstrumentQualitativeLevels != null &&
          typeof element.evalInstrumentQualitativeLevels == "string"
        ) {
          let evalInstrumentQualitativeLevelsObj;
          evalInstrumentQualitativeLevelsObj = JSON.parse(element.evalInstrumentQualitativeLevels);
          let evalInstrumentQualitativeLevels = evalQualitativeLevels.filter(
            (el) => el.id == evalInstrumentQualitativeLevelsObj.id
          )[0];
          if (evalInstrumentQualitativeLevels != null) {
            if (evalInstrumentQualitativeLevels.performanceCritDesc != null) {
              delete evalInstrumentQualitativeLevels.performanceCritDesc;
            }
            if (evalInstrumentQualitativeLevels.createdBy != null) {
              delete evalInstrumentQualitativeLevels.createdBy;
            }
            if (evalInstrumentQualitativeLevels.modifiedBy != null) {
              delete evalInstrumentQualitativeLevels.modifiedBy;
            }
            element.evalInstrumentQualitativeLevels = JSON.stringify(evalInstrumentQualitativeLevels);
            toUpdateRows.push(element);
          }
        }
      }
      scope.parentScope.$parent.hideLoader();
      scope.genericFactory.setRouteName("Class_Plan_School_Records");
      scope.parentScope.$parent.showDeterminateLoader(toUpdateRows.length);
      async.eachSeries(
        toUpdateRows,
        function iteratee(item, callback) {
          scope.genericFactory.modify(item.id, item).then(() => {
            numUpdatedRows += 1;
            scope.parentScope.$parent.updateDeterminateLoader();
            callback();
          });
        },
        function done() {
          if (numUpdatedRows == toUpdateRows.length) {
            console.log("Done");
            scope.parentScope.$parent.hideLoader();
          }
        },
        function (err) {
          if (err) {
            console.log(err);
          } else {
            //Done
            console.log("done");
          }
        }
      );
    });
}

function getReducedImage(scope, imageBinaryString) {
  return fetch("/api/Transp_Studs/reduceImageSize", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: scope.genericFactory.$http.defaults.headers.common["Authorization"],
    },
    body: JSON.stringify({
      data: imageBinaryString,
    }),
  });
}

function b64toBlob(b64Data, contentType) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  const sliceSize = 512;

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {
    type: contentType,
  });
  return blob;
}

function removeMatchingDuplicateElements(reference, target, refFields, targetFields, fromTableData) {
  if (reference.length > 0 && target.length > 0) {
    for (let k = 0; k < reference.length; k++) {
      let refEl = reference[k];
      let refWithoutRefFieldsFlag = false;
      //Check if target already has elements or if they were all duplicates
      if (target.length > 0) {
        //Check if target is list of objects. If it is then the elements from ref and target need to be compared field by field
        if (target[0] != null && target[0].constructor === Object) {
          //Compare all refEls with all existing targetEls according to the refFields and targetFields
          for (let w = 0; w < target.length; w++) {
            let targetEl = target[w];
            let duplicateFlag = true;
            for (let i = 0; i < refFields.length; i++) {
              let rField = refFields[i];
              if (targetEl[targetFields[i]] && refEl[rField]) {
                let parsedRefElField;
                // Check if reference element field value needs to be parsed before it is compared with target field value
                if (fromTableData[i] != null) {
                  parsedRefElField = fromTableData[i].filter(
                    (el) => el.id == refEl[rField] && el.organization == refEl.organization
                  )[0];
                  if (parsedRefElField && targetEl[targetFields[i]] != parsedRefElField[rField]) {
                    duplicateFlag = false;
                    break;
                  }
                } else {
                  if (targetEl[targetFields[i]] != refEl[rField]) {
                    duplicateFlag = false;
                    break;
                  }
                }
              } else if (
                refEl[rField] == null ||
                refEl[rField] == "" ||
                (Array.isArray(refEl[rField]) && refEl[rField].length == 0)
              ) {
                refWithoutRefFieldsFlag = true;
                break;
              }
            }
            if (refWithoutRefFieldsFlag) {
              refWithoutRefFieldsFlag = false;
              break;
            }
            if (duplicateFlag) {
              target.splice(w, 1);
              break;
            }
          }
        }
        //Check if target is a simple list of strings
        else if (typeof target[0] == "string") {
          if (refEl[refFields] && target.indexOf(refEl[refFields]) != -1) {
            target.splice(target.indexOf(refEl[refFields]), 1);
          }
        }
      } else {
        break;
      }
    }
  }
}

function getCurrentScholarYear() {
  let date = new Date();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();
  if (monthIndex + 1 < 9) {
    return (year - 1).toString();
  } else {
    return year.toString();
  }
}

function getMonthWithNumberBefore(month) {
  if (month != null) {
    let parsedMonth = month.toLowerCase();
    switch (parsedMonth) {
      case "janeiro":
        return "01 janeiro";
      case "fevereiro":
        return "02 fevereiro";
      case "março":
        return "03 março";
      case "abril":
        return "04 abril";
      case "maio":
        return "05 maio";
      case "junho":
        return "06 junho";
      case "julho":
        return "07 julho";
      case "agosto":
        return "08 agosto";
      case "setembro":
        return "09 setembro";
      case "outubro":
        return "10 outubro";
      case "novembro":
        return "11 novembro";
      case "dezembro":
        return "12 dezembro";
      default:
        break;
    }
  } else {
    return null;
  }
}

function getNumberStringFromMonth(month) {
  if (month != null) {
    let parsedMonth = month.toLowerCase();
    switch (parsedMonth) {
      case "janeiro":
        return "01";
      case "fevereiro":
        return "02";
      case "março":
        return "03";
      case "abril":
        return "04";
      case "maio":
        return "05";
      case "junho":
        return "06";
      case "julho":
        return "07";
      case "agosto":
        return "08";
      case "setembro":
        return "09";
      case "outubro":
        return "10";
      case "novembro":
        return "11";
      case "dezembro":
        return "12";
      default:
        break;
    }
  } else {
    return null;
  }
}

function getMonthFromDateMs(date) {
  if (date != null && !isNaN(date)) {
    const formatter = new Intl.DateTimeFormat("pt", {
      month: "long",
    });
    const month = formatter.format(new Date(date));
    return month;
  }
  return null;
}

function getScholarYearFromCivilYearAndMonth(year, month) {
  if (year != null && month != null) {
    let parsedMonth = month.toLowerCase();

    if (["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto"].indexOf(parsedMonth) != -1) {
      return (Number(year) - 1).toString();
    } else {
      return year;
    }
  } else {
    return year;
  }
}

function getProfessorModulesByCourseType(newProfParsedCourseTypes) {
  let professorModules = [];
  professorModules = professorModules.concat([
    "class_plan_flexEdu_horizontal_articulation",
    "class_plan_flexEdu_vertical_articulation",
    "class_plan_class_description",
    "class_plan_action_plan",
    "myDocuments",
  ]);
  if (newProfParsedCourseTypes.indexOf("Regular") != -1) {
    professorModules = professorModules.concat([
      "class_plan_school_record",
      "class_plan_parent_communication",
      "class_plan_evaluation_instrument",
    ]);
  }
  if (newProfParsedCourseTypes.indexOf("Profissional") != -1) {
    professorModules = professorModules.concat([
      "class_plan_prof_school_record",
      "class_plan_prof_evaluation_instrument",
    ]);
  }
  return professorModules;
}

function getStudentsOrderedByClassOrderAndName(studs) {
  let studentsWithClassOrder = [];
  let studentWithoutClassOrder = [];

  studentsWithClassOrder = studs.filter((stud) => stud.classOrder != null);
  studentWithoutClassOrder = studs.filter((stud) => stud.classOrder == null);

  studentsWithClassOrder = studentsWithClassOrder.sort(function compareModules(a, b) {
    if (a.classOrder < b.classOrder) {
      return -1;
    }
    if (a.classOrder > b.classOrder) {
      return 1;
    }

    return 0;
  });

  studentWithoutClassOrder = studentWithoutClassOrder.sort(function compareModules(a, b) {
    if (
      a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") < b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    ) {
      return -1;
    }

    if (
      a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    ) {
      return 1;
    }

    return 0;
  });

  return studentsWithClassOrder.concat(studentWithoutClassOrder);
}

function getIndexOfHalfOfArray(arr) {
  if (arr && Array.isArray(arr) && arr.length) {
    return Math.round(arr.length / 2);
  }
}

function previewTableNumberRecordUpdateVFXMealRecord(selected, row, updatedField) {
  let monthlyTotal = 0;
  let monthlyPayment = 0;

  for (let j = 1; j < selected.monthDays; j++) {
    let day = j;

    if (row["day" + day] != null && !isNaN(Number(row["day" + day])) && !Number.isInteger(row["day" + day])) {
      row["day" + day] = null;
      continue;
    }

    if (
      selected.holidays &&
      selected.holidays.indexOf(day) != -1 &&
      row.mealType.indexOf("ETI") == -1 &&
      row.mealType.indexOf("Take-away") == -1 &&
      row.mealType.indexOf("Adultos") == -1 &&
      row.mealType.indexOf("Prova") == -1
    ) {
      row["day" + day] = null;
      continue;
    }

    if (
      (selected.holidays == null || (selected.holidays && selected.holidays.indexOf(day) == -1)) &&
      (row.mealType.indexOf("ETI") != -1 || row.mealType.indexOf("Take-away") != -1)
    ) {
      row["day" + day] = null;
      continue;
    }

    if (row["day" + day] != null && !isNaN(Number(row["day" + day]))) {
      monthlyTotal += Number(row["day" + day]);
    }
  }

  row.monthlyTotal = monthlyTotal;
  if (row.monthlyTotal != null) {
    if (row.mealType.indexOf("Alunos") != -1 || row.mealType == "Prova" || row.mealType == "Take-away") {
      row.value = Math.round(2.35 * row.monthlyTotal * 100) / 100;
    } else if (row.mealType.indexOf("Lanches") != -1) {
      row.value = Math.round(0.8 * row.monthlyTotal * 100) / 100;
    } else if (row.mealType == "Adultos") {
      row.value = Math.round(4.34 * row.monthlyTotal * 100) / 100;
    }
  }

  return row[updatedField];
}

function educationPlanSessionRecordUpdate(selected, row, updatedField, reservationMode) {
  let monthlyTotal = 0;
  let studHasAttendaceRecordsFlag = false;
  let monthlyPayment = 0;
  let consecutivePresentDays = 0;
  let daysWithTaxPayment = 0;

  for (let j = 1; j < selected.monthDays; j++) {
    let day = j;
    if (selected.weekendsAndHolidays.indexOf(day) != -1) {
      row["day" + day] = null;
      continue;
    }
    if (row["day" + day] != null) {
      if (row["day" + day] != null) {
        studHasAttendaceRecordsFlag = true;
        if (row["day" + day] == 1) {
          consecutivePresentDays += 1;
          monthlyTotal += 1;
        } else if (row["day" + day] == 0) {
          consecutivePresentDays = 0;
        }

        if (row["day" + day] != 0 && row["day" + day] != 1) {
          row["day" + day] = null;
        }

        if (consecutivePresentDays >= 10) {
          row.presentInTenOrMoreDays = true;
        }
      }
    }
  }

  if (studHasAttendaceRecordsFlag) {
    row.monthlyTotal = monthlyTotal;
  }

  return row[updatedField];
}

function etiCAFMealRecordUpdate(selected, row, updatedField, reservationMode) {
  let monthlyTotal = 0;
  let studHasAttendaceRecordsFlag = false;
  let consecutiveMissingDays = 0;
  let consecutiveMissingDaysMonthlyPaymentFlag = false;
  let monthlyPayment = 0;
  let daysWithTaxPayment = 0;

  for (let j = 1; j < selected.monthDays; j++) {
    let day = j;
    if (reservationMode) {
      day = day + "Reservation";
    }
    if (selected.weekendsAndHolidays.indexOf(day) != -1) {
      row["day" + day] = null;
      continue;
    }
    if (row["day" + day] != null) {
      if (row["day" + day] != null) {
        studHasAttendaceRecordsFlag = true;
        if (row["day" + day] == 1) {
          consecutiveMissingDays = 0;
          monthlyTotal += 1;
        }
        // CIMBAL - Serpa
        else if (row["day" + day] == "F" && selected.organization == "6011a11ad9ea0100d4b3aa0d") {
          monthlyTotal += 1;
        }
        // CIMBAL - Vidigueira
        else if (
          row["day" + day] == "0" &&
          row["day" + day + "Reservation"] == "1" &&
          selected.organization == "5fc118cde1fc9e0050504ef5"
        ) {
          monthlyTotal += 1;
        } else if (row["day" + day] == 0) {
          consecutiveMissingDays += 1;
        } else if (row["day" + day] == 0.5) {
          monthlyTotal += 0.5;
        } else if (row["day" + day] == "T") {
          monthlyTotal += 1;
          daysWithTaxPayment += 1;
        }

        if (
          row["day" + day] != 0 &&
          row["day" + day] != 0.5 &&
          row["day" + day] != 1 &&
          row["day" + day] != 1.5 &&
          row["day" + day] != 2 &&
          row["day" + day] != "T" &&
          row["day" + day] != "F"
        ) {
          row["day" + day] = null;
        }
      }
      if (consecutiveMissingDays >= 12) {
        consecutiveMissingDaysMonthlyPaymentFlag = true;
      }
    }
  }

  // Reservation mode

  if (reservationMode) {
    if (monthlyTotal > 0 || studHasAttendaceRecordsFlag) {
      row.monthlyReservationsTotal = monthlyTotal;
    }
    return row[updatedField];
  }

  if (studHasAttendaceRecordsFlag) {
    row.monthlyTotal = monthlyTotal;

    // CIMBAL - Ourique
    if (selected.organization == "6001d7978b7f480098c8260f") {
      let dailyPayment = 0;
      if (row.parsedSchedulesArr.length > 0 && row.parsedSchedulesArr.indexOf("Almoço") != -1) {
        if (row.parsedEducationLevel == "PRE") {
          dailyPayment = 1.38;
        } else {
          switch (row.aseEchelon) {
            case "A":
              dailyPayment = 0;
              break;
            case "B":
              dailyPayment = 0.73;
              break;
            default:
              dailyPayment = 1.46;
              break;
          }
        }
      }

      if (row.parsedSchedulesArr.length > 0 && row.parsedSchedulesArr.indexOf("Sandes") != -1) {
        if (row.parsedEducationLevel == "PRE") {
          dailyPayment = 0.62;
        } else {
          switch (row.aseEchelon) {
            case "A":
              dailyPayment = 0;
              break;
            case "B":
              dailyPayment = 0.23;
              break;
            default:
              dailyPayment = 0.45;
              break;
          }
        }
      }

      monthlyPayment += monthlyTotal * dailyPayment;
      if (!isNaN(monthlyPayment) && monthlyPayment != null && monthlyPayment != null) {
        row.monthlyPayment = Math.round(monthlyPayment * 100) / 100;
      }

      let monthlyTotalPayment = 0;
      for (let k = 0; k < selected.studentAttendanceRecords.length; k++) {
        let studRecord = selected.studentAttendanceRecords[k];
        if (row.studentID == studRecord.studentID && !isNaN(Number(studRecord.monthlyPayment))) {
          monthlyTotalPayment += studRecord.monthlyPayment;
        }
      }

      if (!isNaN(Number(monthlyTotalPayment)) && monthlyTotalPayment != null && monthlyTotalPayment != 0) {
        row.monthlyTotalPayment = Math.round(monthlyTotalPayment * 100) / 100;
      }
      if (monthlyTotal == 0 && monthlyTotalPayment == 0) {
        row.monthlyTotalPayment = 0;
      }
      return row[updatedField];
    }

    // CIMBAL - Vidigueira
    if (selected.organization == "5fc118cde1fc9e0050504ef5") {
      let dailyPayment = 0;
      if (row.parsedSchedulesArr.length > 0 && row.parsedSchedulesArr.indexOf("Almoço") != -1) {
        switch (row.aseEchelon) {
          case "A":
            dailyPayment = 0;
            break;
          case "B":
            dailyPayment = 0.73;
            break;
          default:
            dailyPayment = 1.46;
            break;
        }
      }

      if (row.parsedSchedulesArr.length > 0 && row.parsedSchedulesArr.indexOf("Prolong. Almoço") != -1) {
        switch (row.aseEchelon) {
          case "A":
            dailyPayment = 0;
            break;
          case "B":
            dailyPayment = 0.13;
            break;
          default:
            dailyPayment = 0.25;
            break;
        }
      }

      if (row.parsedSchedulesArr.length > 0 && row.parsedSchedulesArr.indexOf("Prolong. Tarde") != -1) {
        switch (row.aseEchelon) {
          case "A":
            dailyPayment = 0;
            break;
          case "B":
            dailyPayment = 0.25;
            break;
          default:
            dailyPayment = 0.5;
            break;
        }
      }

      // Days with tax payment
      if (daysWithTaxPayment > 0) {
        monthlyPayment = monthlyPayment + 0.3 * daysWithTaxPayment;
      }

      monthlyPayment += monthlyTotal * dailyPayment;
      if (!isNaN(monthlyPayment) && monthlyPayment != null && monthlyPayment != null) {
        row.monthlyPayment = Math.round(monthlyPayment * 100) / 100;
      }

      let monthlyTotalPayment = 0;
      for (let k = 0; k < selected.studentAttendanceRecords.length; k++) {
        let studRecord = selected.studentAttendanceRecords[k];
        if (row.studentID == studRecord.studentID && !isNaN(Number(studRecord.monthlyPayment))) {
          monthlyTotalPayment += studRecord.monthlyPayment;
        }
      }

      if (!isNaN(Number(monthlyTotalPayment)) && monthlyTotalPayment != null && studHasAttendaceRecordsFlag) {
        row.monthlyTotalPayment = Math.round(monthlyTotalPayment * 100) / 100;
      }
      if (monthlyTotal == 0 && monthlyTotalPayment == 0) {
        row.monthlyTotalPayment = 0;
      }
      return row[updatedField];
    }

    // CIMBAL - Aljustrel
    if (selected.organization == "600573998b7f480098c82b69") {
      if (row.parsedSchedulesArr.length > 0 && row.parsedSchedulesArr.indexOf("Almoço") != -1) {
        switch (row.aseEchelon) {
          case "A":
            monthlyPayment = 0;
            break;
          case "B":
            monthlyPayment = 16.06;
            break;
          case "C":
            monthlyPayment = 32.12;
            break;
        }
      }
      if (
        row.parsedSchedulesArr.length > 0 &&
        (row.parsedSchedulesArr.indexOf("Prolong. Manhã") != -1 ||
          row.parsedSchedulesArr.indexOf("Prolong. Tarde") != -1)
      ) {
        if (monthlyPayment == null) {
          monthlyPayment = 0;
        }

        switch (row.incomeEchelon) {
          case "I":
            monthlyPayment += 5;
            break;
          case "II":
            monthlyPayment += 10;
            break;
          case "III":
            monthlyPayment += 15;
            break;
          case "IV":
            monthlyPayment += 20;
            break;
          case "V":
            monthlyPayment += 25;
            break;
          case "VI":
            monthlyPayment += 30;
            break;
        }
      }

      monthlyPayment = Math.round(monthlyPayment * 100) / 100;
    }

    // CIMBAL - Cuba
    if (selected.organization == "5fc90dcd897ee20050a411da") {
      switch (row.incomeEchelon) {
        case "I":
          monthlyPayment = 20;
          break;
        case "II":
          monthlyPayment = 25;
          break;
        case "III":
          monthlyPayment = 30;
          break;
        case "IV":
          monthlyPayment = 35;
          break;
        case "V":
          monthlyPayment = 40;
          break;
        case "VI":
          monthlyPayment = 50;
          break;
      }

      // Get monthly payment without any exemption

      switch (row.parsedSchedule) {
        case "Almoço":
          row.monthlyPaymentWithoutExemption = Math.round((monthlyPayment / 3) * 10) / 10;
          break;
        case "Prolongamento de Horário":
          row.monthlyPaymentWithoutExemption = Math.round(((2 * monthlyPayment) / 3) * 10) / 10;
          break;
        default:
          row.monthlyPaymentWithoutExemption = monthlyPayment;
      }

      // Check if stud has ase echelon A or B

      let removeLunchScheduleFlag = false;
      if (row.aseEchelon != null && (row.aseEchelon == "A" || row.aseEchelon == "B")) {
        if (row.parsedSchedulesArr.indexOf("Almoço") != -1) {
          row.situation = "art. 5.º, n.º 4 do RASE";
          removeLunchScheduleFlag = true;
        }
      }

      // If the stud has ase echelon A or B the lunch is excluded from the schedule

      let studSchedule;
      if (removeLunchScheduleFlag) {
        if (row.parsedSchedulesArr.length > 1 && row.parsedSchedulesArr.indexOf("Prolongamento de Horário") != -1) {
          studSchedule = "Prolongamento de Horário";
        } else {
          studSchedule = "";
          monthlyPayment = 0;
        }
      } else {
        studSchedule = row.parsedSchedule;
      }

      //Manual edit stud schedule, manualSituation row field

      if (row.manualSituation && row.manualSituation != null) {
        switch (row.manualSituation) {
          case "Só almoço":
            studSchedule = "Almoço";
            break;
          case "Só prolongamento":
            studSchedule = "Prolongamento de Horário";
            break;
        }
      }

      switch (studSchedule) {
        case "Almoço":
          monthlyPayment = Math.round((monthlyPayment / 3) * 10) / 10;
          break;
        case "Prolongamento de Horário":
          monthlyPayment = Math.round(((2 * monthlyPayment) / 3) * 10) / 10;
          break;
      }

      // 50% of the monthly payment if the stud didnt check-in for 12 straight days

      if (consecutiveMissingDaysMonthlyPaymentFlag) {
        monthlyPayment = Math.round((monthlyPayment / 2) * 10) / 10;
        if (row.situation && row.situation != "" && row.situation.indexOf("Sem frequência") == -1) {
          row.situation = row.situation + "; Sem frequência";
        } else {
          row.situation = "Sem frequência";
        }
      }
    }

    // CIMBAL - Serpa
    if (selected.organization == "6011a11ad9ea0100d4b3aa0d") {
      let dailyPayment = 0;
      if (row.parsedSchedulesArr.length > 0 && row.parsedSchedulesArr.indexOf("Refeições") != -1) {
        switch (row.aseEchelon) {
          case "A":
            dailyPayment = 0;
            break;
          case "B":
            dailyPayment = Math.round((3 / selected.activityDays) * 100) / 100;
            break;
          case "C":
            dailyPayment = Math.round((4.5 / selected.activityDays) * 100) / 100;
            break;
          case "D":
            dailyPayment = Math.round((4.5 / selected.activityDays) * 100) / 100;
            break;
          case "E":
            dailyPayment = Math.round((6 / selected.activityDays) * 100) / 100;
            break;
          default:
            dailyPayment = Math.round((6 / selected.activityDays) * 100) / 100;
            break;
        }
      }

      let receptionDays = [];
      let extensionDays = [];
      let receptionExtensionDays = [];
      let receptionExtensionFlag = false;

      if (row.parsedSchedulesArr.indexOf("Prolongamento") != -1 || row.parsedSchedulesArr.indexOf("Recepção") != -1) {
        for (let k = 0; k < selected.studentAttendanceRecords.length; k++) {
          let studRecord = selected.studentAttendanceRecords[k];
          if (row.studentID == studRecord.studentID) {
            for (let j = 1; j < selected.monthDays; j++) {
              let day = j;
              if (studRecord["day" + day] == 1 || studRecord["day" + day] == "F") {
                if (studRecord.parsedSchedulesArr.indexOf("Recepção") != -1 && receptionDays.indexOf(day) == -1) {
                  receptionDays.push(day);
                } else if (
                  studRecord.parsedSchedulesArr.indexOf("Prolongamento") != -1 &&
                  extensionDays.indexOf(day) == -1
                ) {
                  extensionDays.push(day);
                }
              }
            }
          }
        }

        if (receptionDays.length > 0 && extensionDays.length > 0) {
          receptionExtensionFlag = true;
          receptionDays.forEach((day) => {
            if (receptionExtensionDays.indexOf(day) == -1) {
              receptionExtensionDays.push(day);
            }
          });
          extensionDays.forEach((day) => {
            if (receptionExtensionDays.indexOf(day) == -1) {
              receptionExtensionDays.push(day);
            }
          });
        }
      }

      if (
        row.parsedSchedulesArr.length > 0 &&
        (row.parsedSchedulesArr.indexOf("Prolongamento") != -1 || row.parsedSchedulesArr.indexOf("Recepção") != -1)
      ) {
        switch (row.aseEchelon) {
          case "A":
            dailyPayment = 0;
            break;
          case "B":
            dailyPayment = Math.round((9 / selected.activityDays) * 100) / 100;
            break;
          case "C":
            dailyPayment = Math.round((18 / selected.activityDays) * 100) / 100;
            break;
          case "D":
            dailyPayment = Math.round((30 / selected.activityDays) * 100) / 100;
            break;
          case "E":
            dailyPayment = Math.round((35 / selected.activityDays) * 100) / 100;
            break;
          default:
            dailyPayment = Math.round((43 / selected.activityDays) * 100) / 100;
            break;
        }
      }

      if (receptionExtensionFlag) {
        monthlyPayment += receptionExtensionDays.length * dailyPayment;
      } else {
        monthlyPayment += monthlyTotal * dailyPayment;
      }

      if (!isNaN(monthlyPayment) && monthlyPayment != null) {
        row.monthlyPayment = Math.round(monthlyPayment * 100) / 100;
      }

      let monthlyTotalPayment = 0;
      let receptionExtensionPaymentAlreadyAddedFlag = false;

      for (let k = 0; k < selected.studentAttendanceRecords.length; k++) {
        let studRecord = selected.studentAttendanceRecords[k];
        if (row.studentID == studRecord.studentID && !isNaN(Number(studRecord.monthlyPayment))) {
          if (
            studRecord.parsedSchedulesArr.indexOf("Prolongamento") != -1 ||
            studRecord.parsedSchedulesArr.indexOf("Recepção") != -1
          ) {
            if (!receptionExtensionPaymentAlreadyAddedFlag) {
              receptionExtensionPaymentAlreadyAddedFlag = true;
              monthlyTotalPayment += studRecord.monthlyPayment;
            }
          } else {
            monthlyTotalPayment += studRecord.monthlyPayment;
          }
        }
      }

      if (!isNaN(Number(monthlyTotalPayment)) && monthlyTotalPayment != null) {
        row.monthlyTotalPayment = Math.round(monthlyTotalPayment * 100) / 100;
      }
      if (monthlyTotal == 0 && monthlyTotalPayment == 0) {
        row.monthlyTotalPayment = 0;
      }
      return row[updatedField];
    }

    if (!isNaN(monthlyPayment) && monthlyPayment != null) {
      row.monthlyPayment = monthlyPayment;
    }
  } else {
    row.monthlyPayment = null;
  }

  return row[updatedField];
}

function parsePropNameValueMap(
  propNameValueMap,
  org,
  noOrgRequestFlag,
  paginationSize,
  paginationStartIndex,
  remoteMethodFlag
) {
  let query = "";
  let lastPos = 0;
  let totalOrConditions = "";
  /* paginationSize = 20;
  paginationStartIndex = 0; */
  for (const key in propNameValueMap) {
    if (propNameValueMap.hasOwnProperty(key)) {
      let element = propNameValueMap[key];
      if (Array.isArray(element) && element.length == 0) {
        continue;
      }
      if (Array.isArray(element) && element.length > 1) {
        let orCondition = "";
        if (element.length > 0) {
          if (totalOrConditions == "") {
            orCondition = "{'or': [";
          } else {
            orCondition = ",{'or': [";
          }

          for (let i = 0; i < element.length; i++) {
            const arrEl = element[i];
            if (typeof arrEl == "string" && remoteMethodFlag == null) {
              orCondition += "{ '" + key + "': {'like':'" + arrEl + "','options':'i'}}";
            } else {
              orCondition += "{ '" + key + "': '" + arrEl + "' }";
            }
            if (i < element.length - 1) {
              orCondition += ",";
            }
          }
          orCondition += "]}";
          totalOrConditions += orCondition;
        }
      } else {
        if (Array.isArray(element) && element.length == 1) {
          element = element[0];
        }
        if (query == "") {
          if (typeof element == "string" && remoteMethodFlag == null) {
            query = "{'where':{ 'and': [{ '" + key + "':{'like':'" + element + "','options':'i'}}]}}";
          } else {
            query = "{'where':{ 'and': [{ '" + key + "':" + element + "}]}}";
          }
        } else {
          lastPos = query.length;
          if (remoteMethodFlag == null) {
            query = [
              query.slice(0, lastPos - 3),
              ",{'" + key + "':{'like':'" + element + "','options':'i'}}",
              query.slice(lastPos - 3),
            ].join("");
          } else {
            query = [query.slice(0, lastPos - 3), ",{'" + key + "':'" + element + "'", query.slice(lastPos - 3)].join(
              ""
            );
          }
        }
      }
    }
  }
  if (query == "" && totalOrConditions != "") {
    query = "{'where':{'and': [" + totalOrConditions + "]}}";
  } else if (totalOrConditions != "") {
    lastPos = query.length;
    query = [query.slice(0, lastPos - 3), "," + totalOrConditions + "", query.slice(lastPos - 3)].join("");
  }
  if (query != "") {
    if (noOrgRequestFlag == null) {
      if (remoteMethodFlag == null) {
        query = [
          query.slice(0, query.length - 3),
          "," + "{'organization':{'like':'" + org + "','options':'i'}}",
          query.slice(query.length - 3),
        ].join("");
      } else {
        query = [
          query.slice(0, query.length - 3),
          "," + "{'organization':'" + org + "'}",
          query.slice(query.length - 3),
        ].join("");
      }
    }
    if (
      paginationSize != null &&
      !isNaN(Number(paginationSize)) &&
      paginationStartIndex != null &&
      !isNaN(Number(paginationStartIndex))
    ) {
      query = [
        query.slice(0, query.length - 1),
        "," + "'limit':" + paginationSize + "," + "'skip':" + paginationStartIndex,
        query.slice(query.length - 1),
      ].join("");
    }
    query = query.replace(/\'/g, '"');
  }

  return query;
}

function parseFileName(file, label, index) {
  let validateComplexFileName = /[a-zA-Z0-9]{24}-/gi;
  if (validateComplexFileName.test(file.name)) {
    let fileName = file.name.substring(25);
    if (fileName && fileName.length > 0) {
      return fileName;
    }
  } else if (label != null && index != null) {
    return label + " - " + (index + 1);
  } else {
    return file.name;
  }
}

function getEPETeachingPeriodPauses(
  selected,
  calendarYearMonths,
  startDate,
  endDate,
  getEPEPeriodOfUseByDayFlag,
  getEPECalendarsSumsFlag,
  getEPEPeriodOfUseAllDaysFlag
) {
  if (
    calendarYearMonths != null &&
    Array.isArray(calendarYearMonths) &&
    calendarYearMonths.length > 0 &&
    typeof startDate == "object" &&
    typeof endDate == "object"
  ) {
    let months = [];
    let numTeachingPeriodPauses = 0;
    let startingMonth = startDate.getMonth() + 1;
    let endingMonth = endDate.getMonth() + 1;
    let excludeWeekendsFlag = false;

    // EPE daily period of use
    let periodOfUseDays = 0;
    let periodOfUseExclusiveDays = selected.periodOfUseExclusiveDays;
    let epePeriodOfUseByDayFlag = false;
    let parsedPeriodOfUseExclusiveDays = [];
    // Workaround to get added period of use and teaching pauses with all days
    if (getEPEPeriodOfUseAllDaysFlag) {
      epePeriodOfUseByDayFlag = true;
      parsedPeriodOfUseExclusiveDays = [0, 1, 2, 3, 4, 5, 6];
    }
    if (periodOfUseExclusiveDays != null && periodOfUseExclusiveDays.length > 0 && getEPEPeriodOfUseByDayFlag) {
      epePeriodOfUseByDayFlag = true;
      periodOfUseExclusiveDays.forEach((day) => {
        switch (day) {
          case "Segunda":
            parsedPeriodOfUseExclusiveDays.push(1);
            break;
          case "Terça":
            parsedPeriodOfUseExclusiveDays.push(2);
            break;
          case "Quarta":
            parsedPeriodOfUseExclusiveDays.push(3);
            break;
          case "Quinta":
            parsedPeriodOfUseExclusiveDays.push(4);
            break;
          case "Sexta":
            parsedPeriodOfUseExclusiveDays.push(5);
            break;
          case "Sábado":
            parsedPeriodOfUseExclusiveDays.push(6);
            break;
          case "Domingo":
            parsedPeriodOfUseExclusiveDays.push(0);
            break;
          default:
            break;
        }
      });
    }

    // Filter selected calendars
    calendarYearMonths = calendarYearMonths.filter(
      (calendar) => selected.calendarDesc && selected.calendarDesc.indexOf(calendar.id) != -1
    );
    if (calendarYearMonths.length == 0) {
      return 0;
    }
    if (
      selected.excludeWeekendscheckbox != null &&
      selected.excludeWeekendscheckbox[""] &&
      selected.excludeWeekendscheckbox[""] == true
    ) {
      excludeWeekendsFlag = true;
    }
    if (startingMonth > endingMonth) {
      endingMonth += 12;
    }
    let currentYear = startDate.getFullYear();
    let currentYearChangedFlag = false;

    for (let j = startingMonth; j < endingMonth + 1; j++) {
      let month;
      if (j > 12) {
        month = j - 12;
        if (!currentYearChangedFlag) {
          currentYear += 1;
          currentYearChangedFlag = true;
        }
      } else {
        month = j;
      }
      let monthNumber = JSON.parse(JSON.stringify(month));
      month = getMonthFromDateMs(new Date(currentYear + "-" + month + "-01"));
      if (j == startingMonth) {
        months.push({ year: currentYear, month: month, startDay: startDate.getDate(), date: startDate });
      } else if (j == endingMonth) {
        months.push({ year: currentYear, month: month, endDay: endDate.getDate(), date: endDate });
      } else {
        months.push({ year: currentYear, month: month, monthNumber: monthNumber });
      }
    }

    let addedTeachingPeriodPauses = [];
    let addedPeriodUseDays = [];

    function checkIfTeachingPeriodPauseCanBeAdded(dateString) {
      if (addedTeachingPeriodPauses.indexOf(dateString) == -1) {
        addedTeachingPeriodPauses.push(dateString);
        return true;
      } else {
        return false;
      }
    }

    function checkIfPeriodOfUseDaysCanBeAdded(dateString) {
      if (addedPeriodUseDays.indexOf(dateString) == -1 && addedTeachingPeriodPauses.indexOf(dateString) == -1) {
        addedPeriodUseDays.push(dateString);
        return true;
      } else {
        return false;
      }
    }

    calendarYearMonths.forEach((calendarYearMonths) => {
      if (calendarYearMonths.calendarMonthDesc && calendarYearMonths.calendarMonthDesc.length) {
        calendarYearMonths.calendarMonthDesc.forEach((calendarYearMonth) => {
          months.forEach((epeMonth) => {
            if (
              calendarYearMonths.year.indexOf(epeMonth.year.toString()) != -1 &&
              calendarYearMonth.year &&
              calendarYearMonth.year == epeMonth.year &&
              calendarYearMonth.month == epeMonth.month &&
              calendarYearMonth.teachingPeriodPauses &&
              calendarYearMonth.teachingPeriodPauses.length
            ) {
              // Get teaching period pauses based on the starting day
              if (epeMonth.startDay != null) {
                let dt = new Date(epeMonth.date);
                let month = dt.getMonth() + 1;
                let year = dt.getFullYear();
                let daysInMonth = new Date(year, month, 0).getDate();
                for (let d = 1; d < daysInMonth + 1; d++) {
                  let dayNumber = d;
                  let dayNumberDate = new Date(year + "-" + month + "-" + dayNumber);
                  // Checking if the ending date is surpassed
                  if (dayNumberDate.getTime() > endDate.getTime()) {
                    break;
                  }
                  if (dayNumber >= epeMonth.startDay) {
                    // Count teaching period pauses days
                    if (calendarYearMonth.teachingPeriodPauses.indexOf(dayNumber) != -1) {
                      // Checking if the ending date is surpassed
                      if (dayNumberDate.getTime() > endDate.getTime()) {
                        break;
                      }
                      if (excludeWeekendsFlag && checkIfDateIsInWeekend(dayNumberDate)) {
                        continue;
                      } else {
                        if (checkIfTeachingPeriodPauseCanBeAdded(dayNumberDate.toString())) {
                          numTeachingPeriodPauses += 1;
                        }
                      }
                    }
                  }
                }
              }
              // Get teaching period pauses based on the ending day
              else if (epeMonth.endDay != null) {
                let dt = new Date(epeMonth.date);
                let month = dt.getMonth() + 1;
                let year = dt.getFullYear();
                let daysInMonth = new Date(year, month, 0).getDate();
                for (let d = 1; d < daysInMonth + 1; d++) {
                  let dayNumber = d;
                  let dayNumberDate = new Date(year + "-" + month + "-" + dayNumber);
                  if (dayNumber <= epeMonth.endDay) {
                    // Count teaching period pauses days
                    if (calendarYearMonth.teachingPeriodPauses.indexOf(dayNumber) != -1) {
                      if (excludeWeekendsFlag && checkIfDateIsInWeekend(dayNumberDate)) {
                        continue;
                      } else {
                        if (checkIfTeachingPeriodPauseCanBeAdded(dayNumberDate.toString())) {
                          numTeachingPeriodPauses += 1;
                        }
                      }
                    }
                  }
                }
              } else {
                let dt = new Date(epeMonth.year + "-0" + epeMonth.monthNumber + "-01");
                let month = dt.getMonth() + 1;
                let year = dt.getFullYear();
                let daysInMonth = new Date(year, month, 0).getDate();
                for (let d = 1; d < daysInMonth + 1; d++) {
                  let dayNumber = d;
                  let dayNumberDate = new Date(year + "-" + month + "-" + dayNumber);
                  // Count teaching period pauses days
                  if (calendarYearMonth.teachingPeriodPauses.indexOf(dayNumber) != -1) {
                    if (excludeWeekendsFlag) {
                      if (!checkIfDateIsInWeekend(dayNumberDate)) {
                        if (checkIfTeachingPeriodPauseCanBeAdded(dayNumberDate.toString())) {
                          numTeachingPeriodPauses += 1;
                        }
                      }
                    } else {
                      if (checkIfTeachingPeriodPauseCanBeAdded(dayNumberDate.toString())) {
                        numTeachingPeriodPauses += 1;
                      }
                    }
                  }
                }
              }
            }
          });
        });
      }
    });

    if (epePeriodOfUseByDayFlag) {
      calendarYearMonths.forEach((calendarYearMonths) => {
        months.forEach((epeMonth) => {
          let filteredCalendarMonth = null;
          if (calendarYearMonths.calendarMonthDesc && calendarYearMonths.calendarMonthDesc.length) {
            filteredCalendarMonth = calendarYearMonths.calendarMonthDesc.filter(
              (el) =>
                calendarYearMonths.year.indexOf(epeMonth.year.toString()) != -1 &&
                el.year &&
                el.year == epeMonth.year &&
                el.month == epeMonth.month
            );
            if (filteredCalendarMonth.length == 0) {
              filteredCalendarMonth = null;
            } else {
              filteredCalendarMonth = filteredCalendarMonth[0];
            }
          }
          // Get teaching period pauses based on the starting day
          if (epeMonth.startDay != null) {
            let dt = new Date(epeMonth.date);
            let month = dt.getMonth() + 1;
            let year = dt.getFullYear();
            let daysInMonth = new Date(year, month, 0).getDate();
            for (let d = 1; d < daysInMonth + 1; d++) {
              let dayNumber = d;
              let dayNumberDate = new Date(year + "-" + month + "-" + dayNumber);
              // Checking if the ending date is surpassed
              if (dayNumberDate.getTime() > endDate.getTime()) {
                break;
              }
              if (dayNumber >= epeMonth.startDay) {
                // Count period of use days
                if (
                  filteredCalendarMonth == null ||
                  filteredCalendarMonth.teachingPeriodPauses == null ||
                  filteredCalendarMonth.teachingPeriodPauses.indexOf(dayNumber) == -1
                ) {
                  if (excludeWeekendsFlag && checkIfDateIsInWeekend(dayNumberDate)) {
                    continue;
                  } else {
                    if (
                      parsedPeriodOfUseExclusiveDays.indexOf(dayNumberDate.getDay()) != -1 &&
                      checkIfPeriodOfUseDaysCanBeAdded(dayNumberDate.toString())
                    ) {
                      periodOfUseDays += 1;
                    }
                  }
                }
              }
            }
          }
          // Get teaching period pauses based on the ending day
          else if (epeMonth.endDay != null) {
            let dt = new Date(epeMonth.date);
            let month = dt.getMonth() + 1;
            let year = dt.getFullYear();
            let daysInMonth = new Date(year, month, 0).getDate();
            for (let d = 1; d < daysInMonth + 1; d++) {
              let dayNumber = d;
              let dayNumberDate = new Date(year + "-" + month + "-" + dayNumber);
              if (dayNumber <= epeMonth.endDay) {
                // Count period of use days
                if (
                  filteredCalendarMonth == null ||
                  filteredCalendarMonth.teachingPeriodPauses == null ||
                  filteredCalendarMonth.teachingPeriodPauses.indexOf(dayNumber) == -1
                ) {
                  if (excludeWeekendsFlag && checkIfDateIsInWeekend(dayNumberDate)) {
                    continue;
                  } else {
                    if (
                      parsedPeriodOfUseExclusiveDays.indexOf(dayNumberDate.getDay()) != -1 &&
                      checkIfPeriodOfUseDaysCanBeAdded(dayNumberDate.toString())
                    ) {
                      periodOfUseDays += 1;
                    }
                  }
                }
              }
            }
          } else {
            let dt = new Date(epeMonth.year + "-0" + epeMonth.monthNumber + "-01");
            let month = dt.getMonth() + 1;
            let year = dt.getFullYear();
            let daysInMonth = new Date(year, month, 0).getDate();
            for (let d = 1; d < daysInMonth + 1; d++) {
              let dayNumber = d;
              let dayNumberDate = new Date(year + "-" + month + "-" + dayNumber);
              // Count period of use days
              if (
                filteredCalendarMonth == null ||
                filteredCalendarMonth.teachingPeriodPauses == null ||
                filteredCalendarMonth.teachingPeriodPauses.indexOf(dayNumber) == -1
              ) {
                if (excludeWeekendsFlag && checkIfDateIsInWeekend(dayNumberDate)) {
                  continue;
                } else {
                  if (
                    parsedPeriodOfUseExclusiveDays.indexOf(dayNumberDate.getDay()) != -1 &&
                    checkIfPeriodOfUseDaysCanBeAdded(dayNumberDate.toString())
                  ) {
                    periodOfUseDays += 1;
                  }
                }
              }
            }
          }
        });
      });
    }

    function checkIfDateIsInWeekend(date) {
      let dayOfTheWeek = date.getDay();
      if (dayOfTheWeek == 0 || dayOfTheWeek == 6) {
        return true;
      } else {
        return false;
      }
    }

    if (epePeriodOfUseByDayFlag && getEPECalendarsSumsFlag) {
      return { addedPeriodUseDays: addedPeriodUseDays, addedTeachingPeriodPauses: addedTeachingPeriodPauses };
    } else if (epePeriodOfUseByDayFlag) {
      selected.periodOfUseNumb = periodOfUseDays;
      return periodOfUseDays;
    } else {
      return numTeachingPeriodPauses;
    }
  }
}

function getEPEPeriodOfUse(selected, startDate, endDate) {
  let daysDiff;
  if (new Date(endDate).getTime() == new Date(startDate).getTime()) {
    daysDiff = 1;
  } else {
    daysDiff = dayjs(endDate).diff(dayjs(startDate), "d");
    if (daysDiff > 0) {
      daysDiff += 1;
    }
  }

  if (
    selected.excludeWeekendscheckbox != null &&
    selected.excludeWeekendscheckbox[""] &&
    selected.excludeWeekendscheckbox[""] == true
  ) {
    if (typeof startDate != "object") {
      startDate = new Date(startDate);
    }
    if (typeof endDate != "object") {
      endDate = new Date(endDate);
    }
    daysDiff = calcBusinessDays(startDate, endDate);
  }

  if (selected.teachingPeriodPauses != null && selected.teachingPeriodPauses > 0) {
    daysDiff = daysDiff - selected.teachingPeriodPauses;
  }

  selected.periodOfUseNumb = daysDiff;

  if (daysDiff > 1) {
    return daysDiff + " dias";
  } else if (daysDiff == 1) {
    return "1 dia";
  } else {
    return "0 dias";
  }
}

function calcBusinessDays(dDate1, dDate2) {
  var startDate = dDate1;
  var endDate = dDate2;

  // Validate input
  if (endDate <= startDate) {
    return 0;
  }

  // Calculate days between dates
  var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
  startDate.setHours(0, 0, 0, 1); // Start just after midnight
  endDate.setHours(23, 59, 59, 999); // End just before midnight
  var diff = endDate - startDate; // Milliseconds between datetime objects
  var days = Math.ceil(diff / millisecondsPerDay);

  // Subtract two weekend days for every week in between
  var weeks = Math.floor(days / 7);
  days -= weeks * 2;

  // Handle special cases
  var startDay = startDate.getDay();
  var endDay = endDate.getDay();

  // Remove weekend not previously removed.
  if (startDay - endDay > 1) {
    days -= 2;
  }
  // Remove start day if span starts on Sunday but ends before Saturday
  if (startDay == 0 && endDay != 6) {
    days--;
  }
  // Remove end day if span ends on Saturday but starts after Sunday
  if (endDay == 6 && startDay != 0) {
    days--;
  }

  return days;
}

function getProviderInterlocutorsDesc(scope, provider) {
  let serviceProviders = scope.getFromTableData("KSTK_School_Ent_Service_Providers");
  let providerDesc = serviceProviders.filter((el) => el.id == provider);
  let interlocutorsDesc = [];
  if (providerDesc != null && Array.isArray(providerDesc) && providerDesc.length > 0) {
    providerDesc = providerDesc[0];
    if (
      providerDesc.interlocutors != null &&
      Array.isArray(providerDesc.interlocutors) &&
      providerDesc.interlocutors.length > 0
    ) {
      providerDesc.interlocutors.forEach((interlocutor) => {
        let interlocutorDesc = [];
        for (const field in interlocutor) {
          if (Object.hasOwnProperty.call(interlocutor, field)) {
            const fieldValue = interlocutor[field];
            interlocutorDesc.push(fieldValue);
          }
        }
        if (interlocutorDesc.length > 0) {
          interlocutorsDesc.push(interlocutorDesc);
        }
      });
      if (interlocutorsDesc.length > 0) {
        let parsedInterlocutorDesc = [];
        interlocutorsDesc.forEach((interlocutorDesc) => {
          parsedInterlocutorDesc.push(interlocutorDesc.toString().replace(/\,/gi, ", "));
        });
        return parsedInterlocutorDesc;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}

function getEPEEntityHeads(scope, entity, selected, rowEntityDescs) {
  let entityDesc;
  if (rowEntityDescs != null) {
    entityDesc = rowEntityDescs;
  } else {
    let entities = scope.getFromTableData("KSTK_School_Ent_Descs");
    entityDesc = entities.filter((el) => el.id == entity);
  }
  let entityHeadsDesc = [];
  if (entityDesc != null && Array.isArray(entityDesc) && entityDesc.length > 0) {
    let entityHeads;
    if (rowEntityDescs != null) {
      entityHeads = rowEntityDescs;
    } else {
      entityDesc = entityDesc[0];
      entityHeads = entityDesc.entityHeads;
    }
    if (entityHeads != null && Array.isArray(entityHeads) && entityHeads.length > 0) {
      entityHeads.forEach((entityHead) => {
        if (
          entityHead.school == null ||
          rowEntityDescs != null ||
          (entityHead.school != null && entityHead.school == selected.school)
        ) {
          let entityHeadDesc = [];
          for (const field in entityHead) {
            if (Object.hasOwnProperty.call(entityHead, field) && field != "school") {
              const fieldValue = entityHead[field];
              if (fieldValue != null && fieldValue != "") {
                entityHeadDesc.push(fieldValue);
              }
            }
          }
          if (entityHeadDesc.length > 0) {
            entityHeadsDesc.push(entityHeadDesc);
          }
        }
      });
      if (entityHeadsDesc.length > 0) {
        let parsedEntityHeadDescArr = [];
        entityHeadsDesc.forEach((entityHeadDesc) => {
          parsedEntityHeadDescArr.push(entityHeadDesc.toString().replace(/\,/gi, ", "));
        });
        return parsedEntityHeadDescArr;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}

function getLeanGameTimer(scope, currentUser, data) {
  if (data.length > 0) {
    scope.customCalculatedFieldsIntervals.push(
      setInterval(function () {
        // Get visible rows

        let dataToGetTimer = scope.gridApi.core.getVisibleRows(scope.gridApi.grid).map((el) => el.entity);

        // Check if there is more than one game session in the visible rows. Disable timer in that case

        let lastGameSession;
        let lastRound;
        let moreThanOneGameSessionFlag = false;
        let moreThanOneRoundFlag = false;
        for (let g = 0; g < dataToGetTimer.length; g++) {
          const el = dataToGetTimer[g];
          if (lastGameSession == null) {
            lastGameSession = el.game;
          } else if (lastGameSession != el.game) {
            moreThanOneGameSessionFlag = true;
            break;
          }
          if (lastRound == null) {
            lastRound = el.round;
          } else if (lastRound != el.round) {
            moreThanOneRoundFlag = true;
            break;
          }
        }

        if (dataToGetTimer.length > 0 && !moreThanOneGameSessionFlag && !moreThanOneRoundFlag) {
          let timingInFirstComplaint = new Date().getTime();
          dataToGetTimer.forEach((dataEl) => {
            if (dataEl.timingIn < timingInFirstComplaint) {
              timingInFirstComplaint = dataEl.timingIn;
            }
          });
          let timeLeft = dataToGetTimer[0].gameDuration * 60000 + timingInFirstComplaint - new Date().getTime();

          if (timeLeft < 0) {
            scope.customCalculatedFieldsValues["leanGameTimer"] = "Game ended 🎉";
          } else {
            if (timeLeft / 1000 > 60) {
              let minutes = Math.floor(timeLeft / 60000);
              let seconds = Math.round((timeLeft - minutes * 60000) / 1000);
              if (seconds < 10) {
                seconds = "0" + seconds;
              }
              timeLeft = minutes + ":" + seconds;
            } else {
              timeLeft = Math.round(timeLeft / 1000);
            }
            scope.customCalculatedFieldsValues["leanGameTimer"] = "Time left: " + timeLeft;
          }
        } else {
          scope.customCalculatedFieldsValues["leanGameTimer"] = null;
        }
      }, 1000)
    );
  }
}

function getIsAbsentCMS(selected) {
  if (selected.absences != null && Array.isArray(selected.absences) && selected.absences.length > 0) {
    let absentFlag = false;
    for (let i = 0; i < selected.absences.length; i++) {
      const el = selected.absences[i];
      if (i == selected.absences.length - 1) {
        if (el.returnDateMs != null) {
          let parsedReturnDate =
            typeof el.returnDateMs == "number" ? el.returnDateMs : new Date(el.returnDateMs).getTime();
          if (parsedReturnDate >= new Date().getTime()) {
            absentFlag = true;
          }
        } else {
          absentFlag = true;
        }
      }

      // Old way to check if is absent. Check if any period was concurrent with the present day
      /* if (el.startDateMs != null && el.endDateMs != null) {
        let parsedStartDate = typeof el.startDateMs == "number" ? el.startDateMs : new Date(el.startDateMs).getTime();
        let parsedEndDate = typeof el.endDateMs == "number" ? el.endDateMs : new Date(el.endDateMs).getTime();
        if (
          parsedStartDate <= parsedEndDate &&
          parsedStartDate <= new Date().getTime() &&
          parsedEndDate + 86400000 >= new Date().getTime()
        ) {
          absentFlag = true;
          break;
        }
      } */
    }
    if (absentFlag) {
      return 1;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}

function getTotalAbsencesCMS(selected, mode) {
  if (selected.absences != null && Array.isArray(selected.absences) && selected.absences.length > 0) {
    let absentDays = [];
    for (let i = 0; i < selected.absences.length; i++) {
      const el = selected.absences[i];

      if (mode == "current") {
        if (i == selected.absences.length - 1 && el.startDateMs != null && el.returnDateMs == null) {
          let refDateMs = new Date(
            new Date().getFullYear() +
              "-" +
              (new Date().getMonth() + 1) +
              (new Date().getDate() < 10 ? "-0" + new Date().getDate() : "-" + new Date().getDate())
          ).getTime();
          let dateDiffMs = refDateMs - el.startDateMs;
          let dateDiffInDays = Math.round(dateDiffMs / 86400000);
          absentDays = dateDiffInDays;
          return absentDays;
        }
      } else if (mode == "total") {
        if (el.startDateMs != null && (el.endDateMs != null || el.returnDateMs != null)) {
          let parsedStartDate = typeof el.startDateMs == "number" ? el.startDateMs : new Date(el.startDateMs).getTime();
          let parsedEndDate;
          if (el.returnDateMs != null) {
            parsedEndDate = typeof el.returnDateMs == "number" ? el.returnDateMs : new Date(el.returnDateMs).getTime();
            parsedEndDate -= 86400000;
          } else {
            parsedEndDate = typeof el.endDateMs == "number" ? el.endDateMs : new Date(el.endDateMs).getTime();
          }
          if (parsedStartDate <= parsedEndDate) {
            for (let j = parsedStartDate; j < parsedEndDate + 86400000; ) {
              /* Count only weekdays */
              if (absentDays.indexOf(j) == -1 && [0, 6].indexOf(new Date(j).getDay()) == -1) {
                absentDays.push(j);
              }
              if (absentDays.indexOf(j) == -1) {
                absentDays.push(j);
              }
              /* Add one day */
              j += 86400000;
            }
          }
        }
      }
    }
    return absentDays.length;
  } else {
    return 0;
  }
}

function getCMSSchoolMonthSupplyCost(selected, supplies) {
  let totalSupplyCost = 0;
  let filteredSupply = supplies.filter((s) => s.year == selected.year && s.school == selected.school);
  if (filteredSupply.length > 0) {
    filteredSupply.forEach((supply) => {
      if (supply.invoices != null && supply.invoices.length > 0) {
        supply.invoices.forEach((invoice) => {
          if (
            invoice.invoiceDate != null &&
            new Date(invoice.invoiceDate).getFullYear() == selected.civilYear &&
            getMonthFromDateMs(invoice.invoiceDate) == selected.supplyMonth
          ) {
            if (invoice.invoiceValue != null) {
              totalSupplyCost += invoice.invoiceValue;
            }
          }
        });
      }
      if (supply.deliveredMilk != null && supply.deliveredMilk.length > 0) {
        supply.deliveredMilk.forEach((delivery) => {
          if (
            delivery.deliveryDateMs != null &&
            new Date(delivery.deliveryDateMs).getFullYear() == selected.civilYear &&
            getMonthFromDateMs(delivery.deliveryDateMs) == selected.supplyMonth
          ) {
            if (delivery.invoiceValue != null) {
              if (delivery.docType == null || delivery.docType == "Fatura") {
                totalSupplyCost += delivery.invoiceValue;
              } else {
                totalSupplyCost -= delivery.invoiceValue;
              }
            }
          }
        });
      }
    });
  }
  return Math.round(totalSupplyCost * 100) / 100;
}

function getCMSNonTeachingStaffMonthExpenses(
  selected,
  nonTeachingStaffExpenses,
  educationLevel,
  field,
  totalField,
  expensesMode
) {
  let total = 0;
  let filteredNonTeachingStaff = nonTeachingStaffExpenses.filter((s) => validateHistory(s));

  function validateHistory(el) {
    let historyValidated = false;
    let matchingHistoryEl = false;
    let activeInMonthStaffFlag = false;

    let refDate;
    if (selected.date != null) {
      refDate = selected.date;
    } else if (selected.supplyInvoiceDateMs != null) {
      if (typeof selected.supplyInvoiceDateMs.getMonth === "function") {
        refDate = selected.supplyInvoiceDateMs.getTime();
      } else {
        refDate = selected.supplyInvoiceDateMs;
      }
    }

    if (el.year != new Date(refDate).getFullYear() && Number(el.year) + 1 != Number(new Date(refDate).getFullYear())) {
      return false;
    }
    if (el.year == new Date(refDate).getFullYear() && new Date(refDate).getMonth() < 8) {
      return false;
    }
    if (el.year != new Date(refDate).getFullYear() && new Date(refDate).getMonth() > 7) {
      return false;
    }

    // Check if staff had an active contract on that month
    if (
      el.contractStartDateMs != null &&
      el.contractEndDateMs != null &&
      ((refDate >= el.contractStartDateMs && refDate <= el.contractEndDateMs) ||
        (new Date(refDate).getFullYear() == new Date(el.contractStartDateMs).getFullYear() &&
          new Date(refDate).getMonth() == new Date(el.contractStartDateMs).getMonth()) ||
        (new Date(refDate).getFullYear() == new Date(el.contractEndDateMs).getFullYear() &&
          new Date(refDate).getMonth() == new Date(el.contractEndDateMs).getMonth()))
    ) {
      activeInMonthStaffFlag = true;
    } else if (
      el.contractStartDateMs != null &&
      el.contractEndDateMs == null &&
      (refDate >= el.contractStartDateMs ||
        (new Date(refDate).getFullYear() == new Date(el.contractStartDateMs).getFullYear() &&
          new Date(refDate).getMonth() == new Date(el.contractStartDateMs).getMonth()))
    ) {
      activeInMonthStaffFlag = true;
    } else if (
      el.contractStartDateMs == null &&
      el.contractEndDateMs != null &&
      (refDate <= el.contractEndDateMs ||
        (new Date(refDate).getFullYear() == new Date(el.contractEndDateMs).getFullYear() &&
          new Date(refDate).getMonth() == new Date(el.contractEndDateMs).getMonth()))
    ) {
      activeInMonthStaffFlag = true;
    }
    if (!activeInMonthStaffFlag && (el.contractStartDateMs != null || el.contractEndDateMs != null)) {
      return false;
    }

    if (el.history != null && Array.isArray(el.history) && el.history.length > 0) {
      for (let h = 0; h < el.history.length; h++) {
        let hEl = el.history[h];
        if (hEl.year != el.year) {
          continue;
        }

        if (
          hEl.year != new Date(refDate).getFullYear() &&
          Number(hEl.year) + 1 != Number(new Date(refDate).getFullYear())
        ) {
          continue;
        }
        if (hEl.year == new Date(refDate).getFullYear() && new Date(refDate).getMonth() < 8) {
          continue;
        }
        if (hEl.year != new Date(refDate).getFullYear() && new Date(refDate).getMonth() > 7) {
          continue;
        }

        if (hEl.year == selected.year && hEl.civilYear == selected.civilYear && hEl.month == selected.supplyMonth) {
          matchingHistoryEl = true;
        }
        if (
          hEl.year == selected.year &&
          hEl.civilYear == selected.civilYear &&
          hEl.month == selected.supplyMonth &&
          (hEl.school == selected.school || (hEl.school == null && el.school == selected.school)) &&
          (((expensesMode == null || expensesMode == "DGAL") &&
            (hEl.allocation == "Sala" || hEl.allocation == "Apoio Educativo")) ||
            (expensesMode == "Extensions" && hEl.allocation == "Prolongamento AAAF")) &&
          hEl.situation == "Ativo"
        ) {
          let refEducationLevel = null;
          if (hEl.educationLevel != null) {
            refEducationLevel = hEl.educationLevel;
          } else if (el.educationLevel != null) {
            refEducationLevel = el.educationLevel;
          }
          if (
            educationLevel == "prePrimary" &&
            (refEducationLevel == null ||
              (refEducationLevel &&
                refEducationLevel.indexOf("5dfe2ad3d8954900740d6bed") == -1 &&
                refEducationLevel.indexOf("63e5060eb2671c009c7222e1") == -1))
          ) {
            continue;
          } else if (
            educationLevel == "primary" &&
            (refEducationLevel == null ||
              (refEducationLevel &&
                refEducationLevel.indexOf("5dfe2abfd8954900740d6bea") == -1 &&
                refEducationLevel.indexOf("63e5060eb2671c009c7222e1") == -1))
          ) {
            continue;
          } else if (
            educationLevel == "secondThirdSecondary" &&
            (refEducationLevel == null ||
              (refEducationLevel &&
                refEducationLevel.indexOf("5dfe2ac6d8954900740d6beb") == -1 &&
                refEducationLevel.indexOf("5dfe2acbd8954900740d6bec") == -1 &&
                refEducationLevel.indexOf("5dfe2ad8d8954900740d6bee") == -1 &&
                refEducationLevel.indexOf("63e5060eb2671c009c7222e1") == -1))
          ) {
            continue;
          }

          historyValidated = true;
          break;
        }
      }
      if (matchingHistoryEl) {
        if (historyValidated) {
          return true;
        } else {
          return false;
        }
      }
    }
    if (
      el.year == selected.year &&
      el.school == selected.school &&
      (((expensesMode == null || expensesMode == "DGAL") &&
        (el.allocation == "Sala" || el.allocation == "Apoio Educativo")) ||
        (expensesMode == "Extensions" && el.allocation == "Prolongamento AAAF")) &&
      el.situation == "Ativo"
    ) {
      if (
        educationLevel == "prePrimary" &&
        (el.educationLevel == null ||
          (el.educationLevel &&
            el.educationLevel.indexOf("5dfe2ad3d8954900740d6bed") == -1 &&
            el.educationLevel.indexOf("63e5060eb2671c009c7222e1") == -1))
      ) {
        return false;
      } else if (
        educationLevel == "primary" &&
        (el.educationLevel == null ||
          (el.educationLevel &&
            el.educationLevel.indexOf("5dfe2abfd8954900740d6bea") == -1 &&
            el.educationLevel.indexOf("63e5060eb2671c009c7222e1") == -1))
      ) {
        return false;
      } else if (
        educationLevel == "secondThirdSecondary" &&
        (el.educationLevel == null ||
          (el.educationLevel &&
            el.educationLevel.indexOf("5dfe2ac6d8954900740d6beb") == -1 &&
            el.educationLevel.indexOf("5dfe2acbd8954900740d6bec") == -1 &&
            el.educationLevel.indexOf("5dfe2ad8d8954900740d6bee") == -1 &&
            el.educationLevel.indexOf("63e5060eb2671c009c7222e1") == -1))
      ) {
        return false;
      }

      return true;
    } else {
      return false;
    }
  }

  // Includes Outros education level in every filter
  /* if (educationLevel == "prePrimary") {
    filteredNonTeachingStaff = filteredNonTeachingStaff.filter(
      (s) =>
        s.educationLevel &&
        (s.educationLevel.indexOf("5dfe2ad3d8954900740d6bed") != -1 ||
          s.educationLevel.indexOf("63e5060eb2671c009c7222e1") != -1)
    );
  } else if (educationLevel == "primary") {
    filteredNonTeachingStaff = filteredNonTeachingStaff.filter(
      (s) =>
        s.educationLevel &&
        (s.educationLevel.indexOf("5dfe2abfd8954900740d6bea") != -1 ||
          s.educationLevel.indexOf("63e5060eb2671c009c7222e1") != -1)
    );
  } else if (educationLevel == "secondThirdSecondary") {
    filteredNonTeachingStaff = filteredNonTeachingStaff.filter(
      (s) =>
        s.educationLevel &&
        (s.educationLevel.indexOf("5dfe2ac6d8954900740d6beb") != -1 ||
          s.educationLevel.indexOf("5dfe2acbd8954900740d6bec") != -1 ||
          s.educationLevel.indexOf("5dfe2ad8d8954900740d6bee") != -1 ||
          s.educationLevel.indexOf("63e5060eb2671c009c7222e1") != -1)
    );
  } */

  if (filteredNonTeachingStaff.length > 0) {
    let totalRows = [];
    for (let k = 0; k < filteredNonTeachingStaff.length; k++) {
      let filteredNonTeachingStaffEl = filteredNonTeachingStaff[k];
      if (filteredNonTeachingStaffEl[field] != null && filteredNonTeachingStaffEl[field].length > 0) {
        filteredNonTeachingStaffEl[field].forEach((el) => {
          if (selected.civilYear == el.civilYear && selected.supplyMonth == el.month.toLowerCase()) {
            if (el[totalField] != null) {
              totalRows.push(filteredNonTeachingStaffEl);
              total += el[totalField];
            }
          }
        });
      }
    }
    // console.log(totalRows);
  }
  return Math.round(total * 100) / 100;
}

function getMonthlyDeliverySchoolMilk(monthlyDelivery, mode) {
  let total = 0;
  monthlyDelivery.forEach((delivery) => {
    if (mode == "plain") {
      delivery.deliveredPlainMilkNumberPacks != null ? (total += delivery.deliveredPlainMilkNumberPacks) : (total += 0);
      delivery.deliveredChocolateMilkNumberPacks != null
        ? (total += delivery.deliveredChocolateMilkNumberPacks)
        : (total += 0);
    } else {
      delivery.deliveredLactoseFreeMilkNumberPacks != null
        ? (total += delivery.deliveredLactoseFreeMilkNumberPacks)
        : (total += 0);
    }
  });
  return total;
}

function cmsDownloadTwelfthInvoice(scope, functionArgs, gridData) {
  if (scope.selectedRow != null) {
    let selected = scope.selectedRow;

    let containerName = scope.currentUser.organization + scope.module.collection;
    scope.storageFactory
      .getContainer(containerName)
      .then((ct) => {
        scope.storageFactory.listFilesInContainer(containerName).then((fileList) => {
          //Get files by selected id
          let uploadedFiles = [];
          for (let k = 0; k < fileList.data.length; k++) {
            let storedFile = fileList.data[k];
            if (storedFile.name.indexOf(selected.id) != -1) {
              uploadedFiles.push(storedFile);
            }
          }
          if (uploadedFiles.length > 0) {
            const linkSource = "/api/containers/" + containerName + "/download/" + uploadedFiles[0].name;
            const downloadLink = document.createElement("a");
            const fN = parseFileName(uploadedFiles[0]);
            downloadLink.href = linkSource;
            downloadLink.download = fN;
            downloadLink.click();
          } else {
            scope.$mdDialog.show(
              scope.$mdDialog.alert().clickOutsideToClose(true).title("Nenhuma fatura disponível.").ok("Ok")
            );
          }
        });
      })
      .catch((response) => {
        if (response.status === 404) {
          scope.$mdDialog.show(
            scope.$mdDialog.alert().clickOutsideToClose(true).title("Nenhuma fatura disponível.").ok("Ok")
          );
        }
      });
  }
}

function cmsGetMobilityAlerts(selected) {
  let alerts = [];
  if (selected.mobilityExitDateMs != null && selected.mobilityTrialPeriod != null) {
    let mobilityExitDateMs =
      typeof selected.mobilityExitDateMs.getMonth === "function"
        ? selected.mobilityExitDateMs.getTime()
        : selected.mobilityExitDateMs;
    let mobilityTrialPeriod =
      typeof selected.mobilityTrialPeriod.getMonth === "function"
        ? selected.mobilityTrialPeriod.getTime()
        : selected.mobilityTrialPeriod;
    if (
      selected.mobilityExitDateMs < new Date().getTime() &&
      mobilityExitDateMs + 86400000 * selected.mobilityTrialPeriod - new Date().getTime() < 432000000 &&
      mobilityExitDateMs + 86400000 * selected.mobilityTrialPeriod - new Date().getTime() > 0
    ) {
      alerts.push(
        Math.ceil((mobilityExitDateMs + 86400000 * selected.mobilityTrialPeriod - new Date().getTime()) / 86400000) +
          "d até fim PE"
      );
    }

    if (
      selected.mobilityExitDateMs < new Date().getTime() &&
      mobilityExitDateMs + 47335428000 - new Date().getTime() < 2592000000 &&
      mobilityExitDateMs + 47335428000 - new Date().getTime() > 0
    ) {
      alerts.push(Math.ceil((mobilityExitDateMs + 47335428000 - new Date().getTime()) / 86400000) + "d até fim PC");
    }

    /*  if (
      selected.mobilityExitDateMs < new Date().getTime() &&
      new Date().getTime() - (mobilityExitDateMs + 86400000 * selected.mobilityTrialPeriod) > 2592000000
    ) {
      alerts.push(
        Math.floor((new Date().getTime() - (mobilityExitDateMs + 86400000 * selected.mobilityTrialPeriod)) / 86400000) +
          "d após 30d PE"
      );
    } */

    if (alerts.length == 0) {
      return "Sem alertas";
    } else {
      return alerts.toString().replace(/\,/g, ", ");
    }
  } else {
    return "Sem alertas";
  }
}

function convertDatesOfObjectToMs(obj) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (obj[key] != null && !Array.isArray(obj[key]) && typeof obj[key].getMonth === "function") {
        obj[key] = obj[key].getTime();
      } else if (obj[key] != null && Array.isArray(obj[key]) && obj[key].length) {
        obj[key].forEach((el) => {
          for (const key2 in el) {
            if (Object.prototype.hasOwnProperty.call(el, key2)) {
              if (el[key2] != null && !Array.isArray(el[key2]) && typeof el[key2].getMonth === "function") {
                el[key2] = el[key2].getTime();
              }
            }
          }
        });
      }
    }
  }
}

(function (a) {
  let check = false;
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      a
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      a.substr(0, 4)
    )
  )
    check = true;
})(navigator.userAgent || navigator.vendor || window.opera);

function mobileAndTabletCheck() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}
